import {push} from 'connected-react-router'
import {omit} from 'ramda'
import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {delay, filter, map, mergeMap} from 'rxjs/operators'
import {
  loadUserInfo,
  saveSelectedRestaurant,
  saveToLocalStorage,
  saveUserInfo
} from '../../utils/localStorage'
import {normalizeByEmail} from '../../utils/utils'
import {closeUserSettingsModal, hideAddOrEditUserModal} from '../modules/modal'
import {handleNetworkError} from '../observables/pipes'
import {setEditedUser} from './admin'
import {setSelectedBusiness} from './businesses'
import {setSelectedRestaurant, setEditedRestaurant} from './restaurants'

const persistedUserInfo = loadUserInfo()

export const initialStateUsers = {
  creatingOrUpdatingUser: false,
  registering: false,
  registrationEmailSend: false,
  verifying: false,
  verifyFailed: false,
  loggingIn: false,
  loginEmailSend: false,
  userExistsWarning: false,
  userDoesNotExistWarning: false,
  authToken: persistedUserInfo ? persistedUserInfo.authToken : false,
  isRegistered: persistedUserInfo ? persistedUserInfo.isRegistered : false,
  isAuthenticated: persistedUserInfo
    ? persistedUserInfo.isAuthenticated
    : false,
  user: persistedUserInfo
    ? persistedUserInfo.user
    : {
        id: '',
        email: '',
        firstName: '',
        lastName: ''
      },
  users: {}
}

export const addUserOptimistic = createAction('users/ADD_USER_OPTIMISTIC')

export const login = createAction('users/LOGIN_REQUESTED')
export const loginSucceeded = createAction('users/LOGIN_SUCCEEDED')
export const verifyUser = createAction('users/VERIFY_USER')
export const verifyUserSucceeded = createAction('users/VERIFY_USER_SUCCEEDED')
export const verifyUserFailed = createAction('users/VERIFY_USER_FAILED')
export const registerUser = createAction('users/REGISTER_USER_REQUESTED')
export const registerUserSucceeded = createAction(
  'users/REGISTER_USER_SUCCEEDED'
)
export const toggleRegistered = createAction('users/TOGGLE_REGISTERED')
export const updateUserEmail = createAction('users/UPDATE_USER_EMAIL')
export const userExistsWarningOn = createAction('users/USER_EXISTS_WARNING_ON')
export const userDoesNotExistWarningOn = createAction(
  'users/USER_DOES_NOT_EXIST_WARNING_ON'
)

export const postUser = createAction('users/POST_USER_REQUESTED')
export const postUserSucceeded = createAction('users/POST_USER_SUCCEEDED')

export const putUser = createAction('users/PUT_USER_REQUESTED')
export const putUserSucceeded = createAction('users/PUT_USER_SUCCEEDED')

export const resetRegLog = createAction('users/RESET_REG_LOG')
export const resetLoginState = createAction('users/RESET_LOGIN_STATE')
export const resetRegisterState = createAction('users/RESET_REGISTER_STATE')

export const updateUserOptimistic = createAction('users/UPDATE_USER_OPTIMISTIC')
export const updateUsersOptimistic = createAction(
  'users/UPDATE_USERS_OPTIMISTIC'
)

export const setSendingLoginEmail = createAction(
  'users/SET_SENDING_LOGIN_EMAIL'
)

export const getRestaurantUsers = createAction(
  'users/GET_RESTAURANT_USERS_REQUESTED'
)

export const getRestaurantUsersSuceeded = createAction(
  'users/GET_RESTAURANT_USERS_SUCCEEDED'
)

export const deleteUserOptimistic = createAction('users/DELETE_USER_OPTIMISTIC')
export const deleteUser = createAction('users/DELETE_USER_REQUESTED')
export const deleteUserSucceeded = createAction('users/DELETE_USER_SUCCEEDED')

const reducer = createReducer(
  {
    [deleteUserOptimistic]: (state, payload) => ({
      ...state,
      users: omit([payload], state.users)
    }),
    [login]: state => ({...state, loggingIn: true, userExistsWarning: false}),
    [loginSucceeded]: (state, payload) => ({
      ...state,
      user: payload,
      loggingIn: false,
      loginEmailSend: true
    }),
    [verifyUser]: state => ({...state, verifying: true}),
    [verifyUserSucceeded]: (state, {user, isAuthenticated, isRegistered}) => ({
      ...state,
      user,
      verifying: false,
      verifyFailed: false,
      isAuthenticated,
      isRegistered
    }),
    [verifyUserFailed]: state => ({
      ...state,
      verifying: false,
      verifyFailed: true
    }),
    [registerUser]: state => ({...state, registering: true}),
    [registerUserSucceeded]: state => ({
      ...state,
      registering: false,
      registrationEmailSend: true
    }),
    [resetRegLog]: () => initialStateUsers,
    [resetLoginState]: state => ({
      ...state,
      loggingIn: false,
      loginEmailSend: false,
      userDoesNotExistWarning: false
    }),
    [resetRegisterState]: state => ({
      ...state,
      registering: false,
      registrationEmailSend: false,
      userExistsWarning: false
    }),
    [userExistsWarningOn]: state => ({
      ...state,
      userExistsWarning: true
    }),
    [userDoesNotExistWarningOn]: state => ({
      ...state,
      userDoesNotExistWarning: true
    }),
    [updateUserEmail]: (state, payload) => ({
      ...state,
      user: {...state.user, email: payload}
    }),
    [addUserOptimistic]: (state, payload) => ({
      ...state,
      users: {...state.users, [payload.email]: payload}
    }),
    [updateUserOptimistic]: (state, payload) => ({
      ...state,
      user: {...state.user, ...payload}
    }),
    [updateUsersOptimistic]: (state, payload) => ({
      ...state,
      users: {...state.users, [payload.email]: payload}
    }),
    [toggleRegistered]: state => ({
      ...state,
      isRegistered: !state.isRegistered
    }),
    [postUser]: state => ({...state, creatingOrUpdatingUser: true}),
    [postUserSucceeded]: (state, payload) => ({
      ...state,
      creatingOrUpdatingUser: false,
      users: {
        ...state.users,
        [payload.email]: payload
      }
    }),
    [putUser]: state => ({...state, creatingOrUpdatingUser: true}),
    [putUserSucceeded]: state => ({...state, creatingOrUpdatingUser: false}),
    [getRestaurantUsersSuceeded]: (state, payload) => ({
      ...state,
      users: payload
    })
  },
  initialStateUsers
)

const registerUserEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(registerUser().type),
    mergeMap(({payload: registerData}) =>
      api.createUser(registerData).pipe(
        map(({response: {message}}) => {
          return message === 'user exists'
            ? userExistsWarningOn()
            : registerUserSucceeded()
        }),
        handleNetworkError
      )
    )
  )

const postUserEpic = (action$, state$, {api, of}) =>
  action$.pipe(
    ofType(postUser().type),
    mergeMap(({payload}) =>
      api.postUser(payload).pipe(
        mergeMap(({response: user}) =>
          of(
            postUserSucceeded({...user, type: 'user'}),
            hideAddOrEditUserModal(),
            setEditedUser(undefined)
          )
        ),
        handleNetworkError
      )
    )
  )

const putUserEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(putUser().type),
    mergeMap(({payload: {data, resendInvitationEmail}}) =>
      api.putUser({data, resendInvitationEmail}).pipe(
        mergeMap(({response: userResponse}) =>
          of(
            putUserSucceeded(userResponse),
            closeUserSettingsModal(),
            hideAddOrEditUserModal(),
            setEditedUser(undefined)
          )
        ),
        handleNetworkError
      )
    )
  )

const loginEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(login().type),
    mergeMap(({payload: loginData}) =>
      api.loginUser(loginData).pipe(
        map(({response}) => {
          return response.message === 'user does not exist'
            ? userDoesNotExistWarningOn()
            : loginSucceeded(response)
        }),
        handleNetworkError
      )
    )
  )

const verifyUserEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(verifyUser().type),
    mergeMap(({payload: {token, isLogin}}) =>
      api.verifyUser(token, isLogin).pipe(
        mergeMap(({response}) => {
          const {authToken, business, restaurant, user} = response

          const isAuthenticated = true
          const isRegistered = true

          // save user to localStorage
          const userInfo = {
            user,
            authToken,
            isAuthenticated,
            isRegistered
          }

          saveToLocalStorage('selectedBusiness', business)
          saveSelectedRestaurant(restaurant)
          saveUserInfo(userInfo)

          let actions = [
            verifyUserSucceeded({
              user,
              authToken,
              isAuthenticated,
              isRegistered
            }),
            setSelectedBusiness(business),
            setSelectedRestaurant(restaurant),
            setEditedRestaurant(restaurant)
          ]

          return of(...actions)
        }),
        handleNetworkError
      )
    )
  )

const verifyUserSucceededEpic = (action$, state$) =>
  action$.pipe(
    ofType(verifyUserSucceeded().type),
    delay(4000),
    filter(() => {
      const {
        error: {linkExpired},
        router: {
          location: {pathname}
        }
      } = state$.value

      return !linkExpired && pathname.split('/')[1] === 'verify'
    }),
    map(() => push('/'))
  )

const getRestaurantUsersEpic = (action$, state$, {api}) =>
  action$.pipe(
    ofType(getRestaurantUsers().type),
    mergeMap(() => {
      const {
        restaurants: {editedRestaurant, selectedRestaurant}
      } = state$.value

      const restaurantId = editedRestaurant
        ? editedRestaurant.id
        : selectedRestaurant.id

      return api.getRestaurantUsers(restaurantId).pipe(
        map(({response: users}) => {
          const usersWithType = users.map(u => ({...u, type: 'user'}))

          /**
           * user email is a primary key! it makes life a bit easier to use it
           * as a key to store users when it comes to updating the users after
           * an admin created a "new" user that does already exist in the db...
           */
          const usersByEmail = normalizeByEmail(usersWithType)
          return getRestaurantUsersSuceeded(usersByEmail)
        }),
        handleNetworkError
      )
    })
  )

const deleteUserEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(deleteUser().type),
    mergeMap(({payload: {restaurantId, user}}) =>
      api.deleteUser({restaurantId, user}).pipe(
        map(({response}) => deleteUserSucceeded(response)),
        handleNetworkError
      )
    )
  )

export default reducer

export const userEpics = combineEpics(
  deleteUserEpic,
  getRestaurantUsersEpic,
  loginEpic,
  postUserEpic,
  putUserEpic,
  registerUserEpic,
  verifyUserEpic,
  verifyUserSucceededEpic
)
