import React from 'react'
import LayoutContentRight from '../../core/components/LayoutContentRight'
import FooterContainer from '../../core/containers/FooterContainer'
import LayoutWithAdminDrawerContainer from '../../core/containers/LayoutWithAdminDrawerContainer'
import AdminHeaderContainer from '../containers/AdminHeaderContainer'
import BusinessPartnerAdminContentContainer from '../containers/BusinessPartnerAdminContentContainer'

const BusinessPartnerAdmin = () => (
  <LayoutWithAdminDrawerContainer
    renderContentRight={() => (
      <LayoutContentRight
        renderFooter={() => <FooterContainer />}
        renderHeader={() => <AdminHeaderContainer />}
        renderMain={() => <BusinessPartnerAdminContentContainer />}
      />
    )}
  />
)

export default BusinessPartnerAdmin
