import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {getDateDetails} from '../../../utils/dateUtils'
import Icon from '../../icons/components/Icon'
import {addImageTransformation} from '../../../utils/utils'
import ImageOrFallBack from '../../core/components/ImageOrFallback'
import {extractIndicatorData} from '../../../../build/utils/compositeUtils'

const HitRowBigBase = styled.div`
  border-color: ${p => p.theme.border.color.dark};
  border-top: ${p => p.theme.border.default};
  border-bottom: ${p => p.isLast && p.theme.border.default};
  margin-bottom: ${p => p.isLast && '30px'};
  margin-top: ${p => p.marginTop && '52px'};
  display: grid;
  grid-template-columns: 71px 1fr 71px;
  align-items: center;

  &:hover {
    background-color: ${p => p.theme.colors.mediumGray};
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.boxGray};
  height: 71px;
  width: 71px;
  border-color: ${p => p.theme.border.color.darkerGray};
`

const BoxText = styled.span`
  ${p => p.theme.fonts.family.default};
  font-weight: ${p => p.theme.fonts.weight.normal}px;
  font-size: ${p => p.theme.fonts.size.huge}px;
  color: ${p => p.theme.colors.lightestGray2};
  text-shadow: 0px 1px rgba(0, 0, 0, 0.2);
`

const HitInfoGrid = styled.div`
  align-content: center;
  display: grid;
  grid-template-rows: 20px, 15px;
  grid-row-gap: 4px;
  height: 71px;
`
const HitInfoText = styled.span`
  ${p => p.theme.fonts.family.default};
  padding-left: 10px;
`

const Subtitle = styled(HitInfoText)`
  font-weight: ${p => p.theme.fonts.weight.thin};
  font-size: ${p => p.theme.fonts.size.small}px;
  color: ${p => p.theme.colors.darkerGray};
`

const Title = styled(HitInfoText)`
  font-weight: ${p => p.theme.fonts.weight.bold};
  font-size: ${p => p.theme.fonts.size.normal}px;
`

const HitRowBig = ({
  className,
  id,
  isLast,
  isNoResultsPlaceholder = false,
  marginTop,
  onMouseDown,
  result,
  style,
  webEntryDomain
}) => {
  const componentListString = result.components
    ? result.components.map(c => c.title).join(', ')
    : ''

  const subTitleStringWithoutDate =
    componentListString.length > 35
      ? `${componentListString.slice(0, 32)}...`
      : componentListString

  const {dayOfMonth, dayOfWeek, month, year} = getDateDetails(
    result.productionDate
  )
  const dateString = `${
    componentListString === '' ? '' : '-'
  } ${dayOfWeek}  ${dayOfMonth}. ${month} ${year}`

  const subTitleString = `${subTitleStringWithoutDate} ${dateString}`

  const {
    co2: {hasAward: hasCo2Award}
  } = extractIndicatorData(webEntryDomain, result)
  const imageUrlWithTransformParams = addImageTransformation(
    result.imageUrl,
    '-/progressive/yes/-/scale_crop/140x140/center'
  )

  return (
    <HitRowBigBase
      className={className}
      id={id}
      isLast={isLast}
      marginTop={marginTop}
      onMouseDown={() => onMouseDown && onMouseDown(result)}
      style={style}
    >
      {isNoResultsPlaceholder ? (
        <div />
      ) : (
        <ImageOrFallBack
          imageUrl={imageUrlWithTransformParams}
          fallback={() => (
            <Box>
              <BoxText>{result.title.slice(0, 2)}</BoxText>
            </Box>
          )}
        />
      )}
      <HitInfoGrid>
        <Title>{result.title}</Title>
        <Subtitle>{subTitleString}</Subtitle>
      </HitInfoGrid>
      {hasCo2Award ? (
        <Icon
          height="64px"
          size={40}
          src={`${process.env.PUBLIC_URL}/assets/icons/co2-award-big.svg`}
          width="64px"
        />
      ) : (
        <div />
      )}
    </HitRowBigBase>
  )
}

HitRowBig.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isLast: PropTypes.bool.isRequired,
  isNoResultsPlaceholder: PropTypes.bool,
  marginTop: PropTypes.bool,
  onMouseDown: PropTypes.func,
  result: PropTypes.object,
  style: PropTypes.object,
  webEntryDomain: PropTypes.string
}

export default HitRowBig
