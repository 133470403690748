import React from 'react'
import PropTypes from 'prop-types'
import {addImageTransformation} from '../../../../build/utils/utils'

const RecipeIconWithImage = ({renderNotification, imageUrl, size = 64}) => {
  const imageUrlWithTransformParams = addImageTransformation(
    imageUrl,
    '-/progressive/yes/-/scale_crop/40x40/center'
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 64 70"
    >
      <defs>
        <pattern
          id={`pattern-${imageUrl}`}
          width="138.784%"
          height="100%"
          x="-38.784%"
        >
          <use transform="scale(.05405)" xlinkHref={`#${imageUrl}`} />
        </pattern>
        <image
          id={imageUrl}
          width="657"
          height="370"
          xlinkHref={imageUrlWithTransformParams}
        />
      </defs>
      <g id="recipe-picture-icon-multiple" fill="none" fillRule="evenodd">
        <g id="recipe-icon-multiple">
          <g id="recipe-icon" fill="#9DA2A6">
            <path
              id="Shape"
              fillRule="nonzero"
              d="M20.8345026,8.73018974 L16.2593345,8.73018974 L15.3129385,8.73018974 L15.3129385,7.62587692 L15.3129385,3.20862564 L16.4172513,3.20862564 L17.5215641,6.5215641 L20.8345026,7.62587692 L20.8345026,8.73018974 Z M19.7301897,14.2517538 L4.26981026,14.2517538 L4.26981026,13.147441 L19.7301897,13.147441 L19.7301897,14.2517538 Z M19.7301897,16.4603795 L4.26981026,16.4603795 L4.26981026,15.3560667 L19.7301897,15.3560667 L19.7301897,16.4603795 Z M19.7301897,18.6690051 L4.26981026,18.6690051 L4.26981026,17.5646923 L19.7301897,17.5646923 L19.7301897,18.6690051 Z M14.1070289,20.8776308 L4.26981026,20.8776308 L4.26981026,19.7733179 L14.1070289,19.7733179 L14.1070289,20.8776308 Z M16.4172513,1 L0.956871799,1 L0.956871799,28.6078205 L23.0431282,28.6078205 L23.0431282,7.62587692 L16.4172513,1 Z"
              transform="translate(18 20)"
            />
          </g>
          {renderNotification && renderNotification()}
        </g>
        <rect
          id="Rectangle-12"
          width="19.5"
          height="19.5"
          x="13.25"
          y="35.25"
          fill={`url(#pattern-${imageUrl})`}
          stroke="#787C80"
          strokeWidth=".5"
          rx="4"
        />
      </g>
    </svg>
  )
}

RecipeIconWithImage.propTypes = {
  imageId: PropTypes.string.isRequired,
  renderNotification: PropTypes.func,
  size: PropTypes.number,
  imageUrl: PropTypes.string.isRequired
}

export default RecipeIconWithImage
