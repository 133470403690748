import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import {parseQueryString} from '../../../utils/utils'
import {removeComponent} from '../epics/epics'
import DeleteRowButton from '../components/DeleteRowButton'

const mapStateToProps = (state, {component, componentIndex, composite}) => {
  const {menuId, recipeId} = parseQueryString(state.router.location.search)
  const isEditedComposite = composite.id === menuId || composite.id === recipeId

  return {
    show:
      state.search.searchInput === '' &&
      component.component.type === 'product' &&
      component.component.id === '0' &&
      isEditedComposite &&
      state.components.editedComponentIndex === componentIndex
  }
}

const mapDispatchToProps = {
  removeComponent
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClick: ({composite, componentIndex, removeComponent}) => () => {
      removeComponent({composite, componentIndex})
    }
  })
)(DeleteRowButton)
