import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import useMoment from '../../../hooks/useMoment'

// TODO: remove margin/padding +/- hack?!
const DuplicateRecipeListBase = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.colors.darkGray};
  box-sizing: border-box;
  height: ${p => p.theme.sizes.spacerBarHeight}px;
  width: auto;
  margin-left: -50vw;
  padding-left: 50vw;
  margin-right: -50vw;
  padding-right: 50vw;
  border-bottom: ${p => p.theme.border.default};
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const ListText = styled.div`
  align-self: center;
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  color: ${p => p.theme.colors.white};
  padding-left: ${p => p.theme.sizes.duplicateRecipeListPadding}px;
  width: calc(100% - 425px);
`

const DuplicateText = styled.span`
  color: ${p => p.theme.colors.white};
`

const ExpandCollapseTrigger = styled.span``
const TriggerText = styled.span`
  text-decoration: underline;
`

const DuplicateRecipeList = ({style, className, recipe, duplicateRecipes}) => {
  const [t] = useTranslation()
  const [listExpanded, setListExpanded] = useState()
  const moment = useMoment()

  if (duplicateRecipes.length <= 1) {
    return null
  }

  const duplicateRecipeListData = duplicateRecipes.map(r => {
    const m = moment(r.productionDate, 'YYYY-MM-DD')
    return {
      id: r.id,
      productionDate: {
        year: m.format('YYYY'),
        month: m.format('MMMM'),
        day: m.format('DD')
      }
    }
  })

  const numberOfDuplicates = duplicateRecipeListData.length
  const numberOfLinksToShowWhenCollapsed = 3
  const showShowMoreTrigger =
    !listExpanded && numberOfDuplicates - numberOfLinksToShowWhenCollapsed > 0
  const data = listExpanded
    ? duplicateRecipeListData
    : duplicateRecipeListData.slice(0, numberOfLinksToShowWhenCollapsed)
  const leadingText = t('duplicateRecipeList.leadingText', {
    recipeTitle: recipe.title
  })
  const trailingText = t('duplicateRecipeList.trailingText')

  return (
    <DuplicateRecipeListBase
      id="DuplicateRecipeListBase"
      style={style}
      className={className}
    >
      <ListText>
        {leadingText}
        {data.map(({id, productionDate: {year, day, month}}, index) => {
          const isLast = index + 1 === data.length
          const dateString = `${day}. ${month} ${
            year < moment().format('YYYY') ? year : ''
          }`

          return (
            <DuplicateText id={`DuplicateText${index}`} key={id}>
              {dateString}
              {isLast ? '' : ', '}
            </DuplicateText>
          )
        })}
        {showShowMoreTrigger && (
          <ExpandCollapseTrigger onClick={() => setListExpanded(true)}>
            ...{' '}
            <TriggerText>{`${numberOfDuplicates -
              numberOfLinksToShowWhenCollapsed} more`}</TriggerText>
          </ExpandCollapseTrigger>
        )}
        {listExpanded && (
          <ExpandCollapseTrigger onClick={() => setListExpanded(false)}>
            {' '}
            <TriggerText>collapse</TriggerText>
          </ExpandCollapseTrigger>
        )}
        {trailingText}
      </ListText>
    </DuplicateRecipeListBase>
  )
}

DuplicateRecipeList.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  recipe: PropTypes.object.isRequired,
  duplicateRecipes: PropTypes.array.isRequired
}

export default DuplicateRecipeList
