import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CellInput from '../../dataGrid/components/CellInput'

const SectionLeftCellInput = styled(CellInput)`
  color: #c2c7cc;
  padding-right: 20px;
  text-align: end;
`

const SectionLeft = ({
  editMode,
  onFocus,
  onChange,
  onBlur,
  tempValue,
  value
}) => (
  <SectionLeftCellInput
    editMode={editMode}
    paddingRight="20px"
    onFocus={onFocus}
    onChange={onChange}
    onBlur={onBlur}
    tempValue={tempValue}
    value={value}
  />
)

SectionLeft.propTypes = {
  editMode: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tempValue: PropTypes.string,
  value: PropTypes.string
}

export default SectionLeft
