import PropTypes from 'prop-types'
import React from 'react'

import {withSliceType} from '../../../utils/utils'
import GeneratedMenuContainer from '../../weekPlanningView/containers/GeneratedMenuContainer'
import PopOver from '../../popover/components/PopOver'
import ViewPagerNavigatorContainer from '../../core/containers/ViewPagerNavigatorContainer'

const COMPONENTS = {
  generatedMenu: GeneratedMenuContainer,
  editableMenuDetails: withSliceType(ViewPagerNavigatorContainer, 'menu')
}

const CalendarPopOver = ({
  onClickOutside,
  calendarPopOverPosition,
  calendarPopOverOpen,
  calendarPopOverArrowTop,
  calendarPopOverContentType
}) => {
  const Component = COMPONENTS[calendarPopOverContentType]

  if (!Component) {
    return null
  }

  return (
    <PopOver
      showArrows
      wideShadow
      popOverPosition={calendarPopOverPosition}
      popOverOpen={calendarPopOverOpen}
      popOverArrowTop={calendarPopOverArrowTop}
      onClickOutside={onClickOutside}
    >
      <Component />
    </PopOver>
  )
}

CalendarPopOver.propTypes = {
  onClickOutside: PropTypes.func,
  calendarPopOverPosition: PropTypes.object,
  calendarPopOverOpen: PropTypes.bool,
  calendarPopOverArrowTop: PropTypes.number,
  calendarPopOverContentType: PropTypes.string
}

export default CalendarPopOver
