import {curry, pick} from 'ramda'
import {v4} from 'uuid'
import {restaurantLicenseCheck} from './dateUtils'

export const generateRestaurant = () => ({
  city: '',
  dailyGuests: '',
  id: v4(),
  name: '',
  postalCode: '',
  phone: '',
  restaurantType: '',
  street: '',
  type: 'restaurant',
  website: ''
})

export const getLicenseType = curry((webEntryDomain, licenses) => {
  const licenseType =
    webEntryDomain === 'klimateller'
      ? 'klimateller'
      : licenses.eaternity
      ? 'eaternity'
      : licenses.chef
      ? 'chef'
      : licenses.klimateller
      ? 'klimateller'
      : null

  if (licenseType === null) {
    throw new Error('getLicenseType util: could not determine licenseType')
  } else {
    return licenseType
  }
})

export const checkLicenseOfSelectedRestaurant = state => {
  const {
    config: {webEntryDomain},
    restaurants: {selectedRestaurant},
    users: {
      user: {email}
    }
  } = state.value

  if (!selectedRestaurant) {
    return false
  }

  return (
    restaurantLicenseCheck(webEntryDomain, selectedRestaurant.licenses) ||
    email.endsWith('@eaternity.ch')
  )
}

export const pickRestaurantFormFields = pick([
  'additionalInfo',
  'addressAdditionalInfo',
  'city',
  'dailyGuests',
  'geoLocation',
  'name',
  'phone',
  'postalCode',
  'restaurantType',
  'shortName',
  'street',
  'website'
])
