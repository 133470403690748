import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {hideSearchModal} from '../../../redux/modules/modal'
import {setSearchTypes} from '../../../redux/modules/search'
import {selectSearchResults} from '../../../redux/selectors/search'
import {
  selectRecipesOfSelectedRestaurant,
  selectRecipesOfSelectedBusiness
} from '../../../redux/selectors/selectors'
import {prepareForBasicSearch} from '../../../utils/searchUtils'
import SearchResults from '../components/SearchResults'
import {processHitRowBigClick} from '../epics/epics'

const mapStateToProps = state => {
  const {
    config: {webEntryDomain},
    menus: {menus},
    products: {products},
    recipes: {recipeCollections},
    search: {searchInput, searchTypes}
  } = state

  const {searchResults} = selectSearchResults(state)
  const businessRecipes = selectRecipesOfSelectedBusiness(state)
  const restaurantRecipes = selectRecipesOfSelectedRestaurant(state)
  const recipes = {...businessRecipes, ...restaurantRecipes}

  let _defaultResults = []
  if (searchTypes.includes('menus')) {
    _defaultResults = [..._defaultResults, ...Object.values(menus)]
  }
  if (searchTypes.includes('recipes')) {
    _defaultResults = [..._defaultResults, ...Object.values(recipes)]
  }

  // we allow the creation of menus without a title... those are
  // filtered out because we sort by title in the component
  const defaultResults = _defaultResults.filter(r => {
    return (
      r.title &&
      (r.type === 'menu' ||
        (recipeCollections[r.recipeCollectionId].name !== 'klimateller' &&
          recipeCollections[r.recipeCollectionId].name !== 'chef'))
    )
  })

  const components = {...products, ...recipes}

  return {
    searchInput,
    searchResults:
      searchInput === ''
        ? prepareForBasicSearch(components, defaultResults)
        : prepareForBasicSearch(components, searchResults),
    searchTypes,
    webEntryDomain
  }
}

const mapDispatchToProps = {
  hideSearchModal,
  processHitRowBigClick,
  setSearchTypes
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onHitRowMouseDown: ({processHitRowBigClick}) => result => {
      processHitRowBigClick(result)
    },
    onLinkClick: ({inputToFocusRef, setSearchTypes}) => searchType => {
      setSearchTypes([searchType])
      inputToFocusRef.current.focus()
    }
  })
)(SearchResults)
