import {connect} from 'react-redux'
import {selectSearchResults} from '../../../redux/selectors/search'
import AdminSearchResults from '../components/AdminSearchResults'
import {processRestaurantSearchItemClick} from '../epics/epics'

const mapStateToProps = state => {
  const {
    businesses: {selectedBusiness},
    restaurants: {restaurants},
    search: {searchInput},
    users: {
      user: {defaultBusinessId, defaultRestaurantId}
    }
  } = state
  const {groupedSearchResults} = selectSearchResults(state)
  const allRestaurants = Object.values(restaurants)
  const results =
    searchInput === '' ? allRestaurants : groupedSearchResults.restaurants
  const resultsWithOutDefaultRestaurant = results.filter(
    r => r.id !== defaultRestaurantId
  )

  return {
    results:
      selectedBusiness && selectedBusiness.id === defaultBusinessId
        ? results
        : resultsWithOutDefaultRestaurant
  }
}

const mapDispatchToProps = {
  onSearchHitRowClick: processRestaurantSearchItemClick
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearchResults)
