import {omit} from 'ramda'
import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, map, mergeMap} from 'rxjs/operators'
import {
  loadFromLocalStorage,
  saveToLocalStorage
} from '../../utils/localStorage'
import {normalizeById} from '../../utils/utils'
import {handleNetworkError} from '../observables/pipes'
import {addMenus, replaceMenusInReducer, updateMenuInReducer} from '../utils'
import {postRecipeSucceeded} from './recipes'
import {
  createProductThenCreateMenu,
  createProductThenUpdateMenu,
  createRecipeThenCreateMenu,
  createRecipeThenUpdateMenu
} from './sharedActions'
import {checkLicenseOfSelectedRestaurant} from '../../utils/restaurantUtils'

export const initialStateMenus = {
  editedMenuId: loadFromLocalStorage('editedMenuId') || undefined,
  menus: {}
}

export const getAllMenus = createAction('menus/GET_ALL_MENUS_REQUESTED')
export const getAllMenusSucceeded = createAction(
  'menus/GET_ALL_MENUS_SUCCEEDED'
)

export const addMenu = createAction('menus/ADD_MENU')

export const postMenu = createAction('menus/POST_MENU_REQUESTED')

export const postMenuSucceeded = createAction('menus/POST_MENU_SUCCEEDED')

export const replaceEditedMenu = createAction('menus/REPLACE_EDITED_MENU')

export const putMenu = createAction('menus/PUT_MENU_REQUESTED')

export const putMenuSucceeded = createAction('menus/PUT_MENU_SUCCEEDED')

export const deleteMenu = createAction('menus/DELETE_MENU')

export const deleteMenuOptimistic = createAction('menus/DELETE_MENU_OPTIMISTIC')

export const deleteMenuSucceeded = createAction('menus/DELETE_MENU_SUCCEEDED')

export const setEditedMenuId = createAction('menus/SET_EDITED_MENU_ID')

export const setGenerated = createAction('menus/SET_GENERATED')

export const updateMenuOptimistic = createAction('menus/UPDATE_MENU_OPTIMISTIC')

export const replaceWithEnrichedMenus = createAction(
  'menus/REPLACE_WITH_ENRICHED_MENUS'
)

const reducer = createReducer(
  {
    [addMenu]: (state, payload) => {
      return {
        ...state,
        menus: {
          ...state.menus,
          [payload.id]: payload
        }
      }
    },

    [getAllMenusSucceeded]: replaceMenusInReducer,

    [deleteMenuOptimistic]: (state, payload) => {
      const {menu} = payload
      return {
        ...state,
        editedMenuId: undefined,
        menus: omit([menu.id], state.menus)
      }
    },

    [replaceWithEnrichedMenus]: addMenus,

    [replaceEditedMenu]: (state, payload) => {
      const {menus, editedMenuId} = state
      const menu = payload
      if (!menu.id === editedMenuId) {
        throw new Error('editedMenuId does not equal menu id')
      }
      return {
        ...state,
        menus: {
          ...menus,
          [menu.id]: menu
        }
      }
    },

    [setEditedMenuId]: (state, payload) => {
      saveToLocalStorage('editedMenuId', payload)
      return {
        ...state,
        editedMenuId: payload
      }
    },

    [updateMenuOptimistic]: updateMenuInReducer,
    [setGenerated]: (state, payload) => ({
      ...state,
      menus: {...state.menus, [payload.id]: {...payload, generated: true}}
    }),
    [createProductThenCreateMenu]: updateMenuInReducer,
    [createProductThenUpdateMenu]: updateMenuInReducer,
    [createRecipeThenCreateMenu]: updateMenuInReducer,
    [createRecipeThenUpdateMenu]: updateMenuInReducer
  },
  initialStateMenus
)

export const getAllMenusEpic = (action$, state$, {api}) =>
  action$.pipe(
    ofType(getAllMenus().type),
    filter(() => checkLicenseOfSelectedRestaurant(state$)),
    mergeMap(({payload: restaurantId}) =>
      api.getAllMenus(restaurantId).pipe(
        map(({response: menus}) => {
          const menusById = normalizeById(menus)
          return getAllMenusSucceeded(menusById)
        }),
        handleNetworkError
      )
    )
  )

export const postMenuEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(postMenu().type),
    mergeMap(({payload: {menu: updatedEditedMenu}}) =>
      api.postMenu(updatedEditedMenu).pipe(
        map(({response: responseMenu}) => postMenuSucceeded(responseMenu)),
        handleNetworkError
      )
    )
  )

export const putMenuEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(putMenu().type),
    mergeMap(({payload: {menu}}) => {
      return api.putMenu(menu).pipe(
        map(({response: responseMenu}) => putMenuSucceeded(responseMenu)),
        handleNetworkError
      )
    })
  )

export const deleteMenuEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(deleteMenu().type),
    filter(({payload: {menu}}) => !menu.generated),
    mergeMap(({payload: {menu}}) => {
      return api.deleteMenu(menu.id).pipe(
        map(response => deleteMenuSucceeded(response)),
        handleNetworkError
      )
    })
  )

export const createProductThenCreateMenuEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(createProductThenCreateMenu().type),
    mergeMap(({payload: {menu, product}}) => {
      return api.postProduct(product).pipe(
        map(() => postMenu({menu})),
        handleNetworkError
      )
    })
  )

export const createProductThenUpdateMenuEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(createProductThenUpdateMenu().type),
    mergeMap(({payload: {menu, product}}) => {
      return api.postProduct(product).pipe(
        map(() => putMenu({menu})),
        handleNetworkError
      )
    })
  )

export const createRecipeThenCreateMenuEpic = (action$, _, {of, api}) =>
  action$.pipe(
    ofType(createRecipeThenCreateMenu().type),
    mergeMap(({payload: {menu, recipe}}) => {
      return api.postRecipe(recipe).pipe(
        mergeMap(({response: responseRecipe}) =>
          of(postRecipeSucceeded(responseRecipe), postMenu({menu}))
        ),
        handleNetworkError
      )
    })
  )

export const createRecipeThenUpdateMenuEpic = (action$, _, {of, api}) =>
  action$.pipe(
    ofType(createRecipeThenUpdateMenu().type),
    mergeMap(({payload: {menu, recipe}}) => {
      return api.postRecipe(recipe).pipe(
        mergeMap(({response: responseRecipe}) =>
          of(postRecipeSucceeded(responseRecipe), putMenu({menu}))
        ),
        handleNetworkError
      )
    })
  )

export const menuEpics = combineEpics(
  createProductThenCreateMenuEpic,
  createProductThenUpdateMenuEpic,
  createRecipeThenCreateMenuEpic,
  createRecipeThenUpdateMenuEpic,
  deleteMenuEpic,
  getAllMenusEpic,
  postMenuEpic,
  putMenuEpic
)

export default reducer
