import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Link from '../../core/components/Link'
import RegLogText from './RegLogText'

const LinkLineBase = styled(RegLogText)`
  margin-top: 24px;
  margin-bottom: 40px;
`

const LinkLine = ({className, linkText, onClick, styles, text}) => (
  <LinkLineBase className={className} styles={styles}>
    {text}
    <Link onClick={onClick}>{linkText}</Link>
  </LinkLineBase>
)

LinkLine.propTypes = {
  className: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.object,
  text: PropTypes.string
}

export default LinkLine
