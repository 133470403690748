/*
all incoming messages from the websocket should arrive here and be distributed to the correct reducers with epics
 */
import compareVersions from 'compare-versions'
import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators'
import createSocket from 'socket.io-client'
import {showLoginAgainModal} from '../../../build/redux/modules/modal'
import {getComponentEnrichFields} from '../../utils/componentUtils'
import {saveToLocalStorage} from '../../utils/localStorage'
import {normalizeById} from '../../utils/utils'
import {replaceWithEnrichedMenus} from './menus'
import {setModalType, showReloadModal} from './modal'
import {setBackendVersion, setIncomingBackendVersion} from './network'
import {replaceWithEnrichedRecipes} from './recipes'
import {loadFromLocalStorage} from '../../../build/utils/localStorage'

const initialStateWebsocket = {
  removedFromRestaurantName: loadFromLocalStorage('removedFromRestaurantName')
}

export const setRemovedFromRestaurantName = createAction(
  'websocket/SET_REMOVED_FROM_RESTAURANT_ID'
)

export const newEnrichedComposites = createAction(
  'websocket/NEW_ENRICHED_COMPOSITES'
)

export const startEnrichStream = createAction('websocket/START_ENRICH_STREAM')
export const stopEnrichStream = createAction('websocket/STOP_ENRICH_STREAM')

export const startSyncStream = createAction('websocket/START_SYNC_STREAM')
export const stopSyncStream = createAction('websocket/STOP_SYNC_STREAM')

export const subscribeToAdminSocket = createAction(
  'websocket/SUBSCRIBE_TO_ADMIN_SOCKET'
)
export const unsubscribeFromAdminSocket = createAction(
  'websocket/UNSUBSCRIBE_FROM_ADMIN_SOCKET'
)

const reducer = createReducer(
  {
    [setRemovedFromRestaurantName]: (state, payload) => ({
      ...state,
      removedFromRestaurantName: payload
    })
  },
  initialStateWebsocket
)

const userLoggedInFilter = state$ =>
  filter(() => {
    const {
      users: {
        user: {id: userId}
      }
    } = state$.value

    return userId
  })

const newEnrichedCompositesEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(newEnrichedComposites().type),
    mergeMap(({payload: compositesEnrichData}) => {
      const compositesEnrichDataById = normalizeById(compositesEnrichData)
      const {
        menus: {menus},
        recipes: {recipes}
      } = state$.value

      const compositesById = {...menus, ...recipes}

      const updatedComposites = compositesEnrichData
        .filter(enrichData => compositesById[enrichData.id])
        .map(enrichData => {
          const {components: enrichedComponents, id} = enrichData

          const matchingStatusOk =
            enrichData.serviceStatus.matchingStatus &&
            String(enrichData.serviceStatus.matchingStatus)[0] !== '6'

          const composite = compositesById[id]

          return {
            ...composite,
            ...enrichData,
            components: composite.components.map((component, index) => {
              const {
                component: {id: componentId, type: componentType}
              } = component

              const enrichedComponent = enrichedComponents[index]

              let enrichedSubrecipe
              let enrichedSubrecipeMatchingStatusOk = true
              if (componentType === 'recipe') {
                enrichedSubrecipe = compositesEnrichDataById[componentId]
                enrichedSubrecipeMatchingStatusOk =
                  enrichedSubrecipe !== undefined &&
                  String(enrichedSubrecipe.serviceStatus.matchingStatus)[0] !==
                    '6'
              }

              const correctComponent =
                enrichedComponent &&
                enrichedComponent.component.id === componentId

              return correctComponent && matchingStatusOk
                ? {
                    ...component,
                    ...getComponentEnrichFields(enrichedComponent),
                    unmatched: false
                  }
                : correctComponent &&
                  !matchingStatusOk &&
                  componentType === 'recipe' &&
                  enrichedSubrecipe !== undefined &&
                  !enrichedSubrecipeMatchingStatusOk
                ? {
                    ...component,
                    ...getComponentEnrichFields(enrichedComponent),
                    unmatched: true
                  }
                : component
            })
          }
        })

      const updatedMenusById = normalizeById(
        updatedComposites.filter(c => c.type === 'menu')
      )
      const updatedRecipesById = normalizeById(
        updatedComposites.filter(c => c.type === 'recipe')
      )

      return of(
        replaceWithEnrichedMenus(updatedMenusById),
        replaceWithEnrichedRecipes(updatedRecipesById)
      )
    })
  )

const enrichStreamEpic = (action$, state$, {fromEvent}) =>
  action$.pipe(
    ofType(startEnrichStream().type),
    userLoggedInFilter(state$),
    switchMap(() => {
      const {
        users: {
          user: {id: namespace}
        }
      } = state$.value

      const websocketNameSpace =
        process.env.NODE_ENV === 'production'
          ? `/${namespace}`
          : `http://localhost:5001/${namespace}`
      const socket = createSocket(websocketNameSpace, {forceNew: false})
      const socket$ = fromEvent(socket, 'new enrich data')

      return socket$.pipe(
        map(({payload: {enrichDataOnly}}) =>
          newEnrichedComposites(enrichDataOnly)
        ),
        takeUntil(action$.ofType(stopEnrichStream().type))
      )
    })
  )

const adminSocketEpic = (action$, state$, {of, fromEvent}) =>
  action$.pipe(
    ofType(subscribeToAdminSocket().type),
    userLoggedInFilter(state$),
    switchMap(() => {
      const {
        users: {
          user: {id: namespace}
        }
      } = state$.value

      const websocketNameSpace =
        process.env.NODE_ENV === 'production'
          ? `/${namespace}`
          : `http://localhost:5001/${namespace}`
      const socket = createSocket(websocketNameSpace, {forceNew: false})
      const socket$ = fromEvent(socket, 'admin')

      return socket$.pipe(
        mergeMap(({payload: {data, message}}) => {
          const messageToGenerateActionsMap = {
            'removed from restaurant': () => {
              const {restaurantName} = data
              const modalType = 'removedFromRestaurant'

              saveToLocalStorage('removedFromRestaurantName', restaurantName)
              saveToLocalStorage('modalType', modalType)
              saveToLocalStorage('loginAgainModalOpen', true)

              return [
                setRemovedFromRestaurantName(restaurantName),
                setModalType(modalType),
                showLoginAgainModal()
              ]
            }
          }

          const actions = messageToGenerateActionsMap[message]()

          return of(...actions)
        }),
        takeUntil(action$.ofType(unsubscribeFromAdminSocket().type))
      )
    })
  )

const syncStreamEpic = (action$, state$, {fromEvent, of}) =>
  action$.pipe(
    ofType(startSyncStream().type),
    userLoggedInFilter(state$),
    switchMap(() => {
      const {
        users: {
          user: {id: namespace}
        }
      } = state$.value

      const websocketNameSpace =
        process.env.NODE_ENV === 'production'
          ? `/${namespace}`
          : `http://localhost:5001/${namespace}`

      const socket = createSocket(websocketNameSpace, {forceNew: false})
      const socket$ = fromEvent(socket, 'sync')

      return socket$.pipe(
        map(({payload: {windowId: remoteWindowId, backendVersion}}) => {
          const {
            network: {backendVersion: currentBackendVersion}
          } = state$.value

          const differentVersion =
            currentBackendVersion &&
            compareVersions(backendVersion, currentBackendVersion) !== 0

          const editedFromDifferentWindow =
            sessionStorage.getItem('windowId') !== remoteWindowId

          return {
            backendVersion,
            currentBackendVersion,
            differentVersion,
            editedFromDifferentWindow
          }
        }),
        filter(
          ({
            currentBackendVersion,
            differentVersion,
            editedFromDifferentWindow,
            message
          }) =>
            !currentBackendVersion ||
            differentVersion ||
            editedFromDifferentWindow ||
            !message
        ),
        mergeMap(
          ({backendVersion, differentVersion, editedFromDifferentWindow}) => {
            let actions
            let modalType
            if (editedFromDifferentWindow) {
              modalType = 'dataEditedElsewhere'
              saveToLocalStorage('modalType', modalType)
              actions = [setModalType(modalType), showReloadModal()]
            } else if (differentVersion) {
              modalType = 'newVersion'
              saveToLocalStorage('modalType', modalType)
              actions = [
                setIncomingBackendVersion(backendVersion),
                setModalType(modalType),
                showReloadModal()
              ]
            } else {
              saveToLocalStorage('backendVersion', backendVersion)
              actions = [setBackendVersion(backendVersion)]
            }

            return of(...actions)
          }
        ),
        takeUntil(action$.ofType(stopSyncStream().type))
      )
    })
  )

export default reducer

export const websocketEpics = combineEpics(
  adminSocketEpic,
  enrichStreamEpic,
  newEnrichedCompositesEpic,
  syncStreamEpic
)
