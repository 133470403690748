import {combineEpics, ofType} from 'redux-observable'
import {createAction} from 'redux-act'
import {flatten, reverse} from 'ramda'
import {mergeMap} from 'rxjs/operators'
import {push} from 'connected-react-router'

import {
  addRecipeOptimistic,
  postRecipe,
  updateRecipeOptimistic
} from '../../../redux/modules/recipes'
import {createOrUpdateComposite} from '../../dataGrid/epics/epics'
import {
  generateDeepCopyData,
  flatCopyComposite
} from '../../dataGrid/utils/utils'
import {generateStandardRecipe} from '../../../utils/recipeUtils'
import {hideSearchModal} from '../../../redux/modules/modal'
import {productionDateToDate} from '../../../utils/dateUtils'
import {setSelectedDate} from '../../../redux/modules/date'
import {
  selectRecipesOfSelectedRestaurant,
  selectRecipesOfSelectedBusiness
} from '../../../redux/selectors/selectors'
import {getPermanentCompositeCopy} from '../../../utils/compositeUtils'

export const processAddButtonClick = createAction(
  'search/PROCESS_ADD_BUTTON_CLICK'
)

export const processHitRowBigClick = createAction(
  'search/PROCESS_HIT_ROW_BIG_CLICK'
)

export const processRecipePreviewClick = createAction(
  'search/PROCESS_RECIPE_PREVIEW_CLICK'
)

const processAddButtonClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processAddButtonClick().type),
    mergeMap(
      ({payload: {compositeType, recipeCollectionId, searchInput, t}}) => {
        let newRecipe
        switch (compositeType) {
          case 'recipe':
            newRecipe = generateStandardRecipe({
              permanent: true,
              recipeCollectionId,
              title: searchInput || t('global.newRecipeTitle'),
              t
            })

            return of(
              addRecipeOptimistic({recipe: newRecipe}),
              postRecipe(newRecipe),
              push(`recipes?recipeId=${newRecipe.id}`),
              hideSearchModal()
            )

          default:
            break
        }
      }
    )
  )

const processHitRowBigClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processHitRowBigClick().type),
    mergeMap(({payload: result}) => {
      const {
        recipes: {recipes},
        restaurants: {
          selectedRestaurant: {recipeCollectionId}
        },
        users: {
          user: {id: authorId}
        }
      } = state$.value

      const getNextPath = composite => {
        const pathMap = {
          menu: `/dayplanning?menuId=${composite.id}`,
          recipe: `/recipes?recipeId=${composite.id}`
        }

        return pathMap[composite.type]
      }

      let actions = []
      if (result.type === 'menu') {
        actions = [
          setSelectedDate(productionDateToDate(result.productionDate)),
          push(getNextPath(result))
        ]
      } else if (result.type === 'recipe') {
        const fullRecipe = recipes[result.id]
        const recipesOfSelectedBusiness = selectRecipesOfSelectedBusiness(
          state$.value
        )
        const recipesOfSelectedRestaurant = selectRecipesOfSelectedRestaurant(
          state$.value
        )
        const allRecipes = {
          ...recipesOfSelectedBusiness,
          ...recipesOfSelectedRestaurant
        }
        const permanentRecipeCopyInSelectedRestaurantRecipeCollection = getPermanentCompositeCopy(
          recipesOfSelectedRestaurant,
          fullRecipe
        )

        if (permanentRecipeCopyInSelectedRestaurantRecipeCollection) {
          actions = [
            push(
              getNextPath(
                permanentRecipeCopyInSelectedRestaurantRecipeCollection
              )
            )
          ]
        } else {
          const deepCopyData = generateDeepCopyData({
            id: result.id,
            recipes: allRecipes,
            depth: 0
          })

          const {recipe: deepCopiedRecipe, recipePostList} = deepCopyData

          const reversedPostListWithoutDeepCopy = reverse(
            recipePostList.filter(r => r.id !== deepCopiedRecipe.id)
          )

          const permanentRecipeCopy = {
            ...deepCopiedRecipe,
            authorId,
            recipeCollectionId,
            permanent: true
          }

          actions = [
            ...flatten(
              reversedPostListWithoutDeepCopy.map(_recipe => {
                const recipe = {..._recipe, authorId, recipeCollectionId}
                return [updateRecipeOptimistic({recipe}), postRecipe(recipe)]
              })
            ),
            addRecipeOptimistic({recipe: permanentRecipeCopy}),
            postRecipe(permanentRecipeCopy),
            push(getNextPath(permanentRecipeCopy))
          ]
        }
      }

      return of(...actions, hideSearchModal())
    })
  )

const processRecipePreviewClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processRecipePreviewClick().type),
    mergeMap(({payload: recipe}) => {
      const {
        recipes: {recipes},
        restaurants: {selectedRestaurant}
      } = state$.value

      const recipeCopyInSelectedRestaurantRecipeCollection = getPermanentCompositeCopy(
        selectRecipesOfSelectedRestaurant(state$.value),
        recipe
      )

      if (recipeCopyInSelectedRestaurantRecipeCollection) {
        return of(
          hideSearchModal(),
          push(
            `/recipes?recipeId=${recipeCopyInSelectedRestaurantRecipeCollection.id}`
          )
        )
      } else {
        const hasSubRecipes =
          recipe.components.filter(({component}) => component.type === 'recipe')
            .length > 0

        let actions = []
        let _recipeToAdd
        if (hasSubRecipes) {
          const deepCopyData = generateDeepCopyData({
            id: recipe.id,
            recipes,
            depth: 0
          })
          const {recipe: deepCopiedRecipe, recipePostList} = deepCopyData
          _recipeToAdd = deepCopiedRecipe
          const reversedRecipePostList = reverse(recipePostList)

          actions = [
            ...flatten(
              reversedRecipePostList.map(_recipe => {
                const recipe = {
                  ..._recipe,
                  recipeCollectionId: selectedRestaurant.recipeCollectionId
                }
                return [updateRecipeOptimistic({recipe}), postRecipe(recipe)]
              })
            )
          ]
        } else {
          _recipeToAdd = flatCopyComposite(recipe)
        }

        // add the recipe to the users private recipeCollection
        const recipeToAdd = {
          ..._recipeToAdd,
          generated: true,
          recipeCollectionId: selectedRestaurant.recipeCollectionId
        }

        actions = [
          ...actions,
          createOrUpdateComposite({composite: recipeToAdd}),
          hideSearchModal(),
          push(`/recipes?recipeId=${recipeToAdd.id}`)
        ]

        return of(...actions)
      }
    })
  )

export const searchEpics = combineEpics(
  processAddButtonClickEpic,
  processHitRowBigClickEpic,
  processRecipePreviewClickEpic
)
