import PropTypes from 'prop-types'
import React, {memo} from 'react'
import styled from 'styled-components'

import HitRowContainer from '../containers/HitRowContainer'
import NoResultsPseudoHitContainer from '../containers/NoResultsPseudoHitContainer'
import SearchInputPseudoHitContainer from '../containers/SearchInputPseudoHitContainer'

const SearchResultsBase = styled.div`
  ${p => p.theme.fonts.family.default};
`

const SearchResults = ({pseudoHitContainerHidden, hide, searchResults}) => {
  if (hide) {
    return null
  }

  return (
    <SearchResultsBase id="SearchResultsBase">
      <SearchInputPseudoHitContainer />
      {searchResults.map((result, index) => {
        const isFirst = index === 0 && pseudoHitContainerHidden
        return (
          <HitRowContainer
            index={index + 1}
            isFirst={isFirst}
            key={index}
            result={result}
          />
        )
      })}
      <NoResultsPseudoHitContainer />
    </SearchResultsBase>
  )
}

SearchResults.propTypes = {
  pseudoHitContainerHidden: PropTypes.bool.isRequired,
  hide: PropTypes.bool.isRequired,
  searchResults: PropTypes.array.isRequired
}

export default memo(SearchResults)
