import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import {addADay} from '../../calendar/utils/calendarUtils'
import {setSelectedDate} from '../../../redux/modules/date'
import CalendarDayListNextButton from '../../calendar/components/CalendarDayListNextButton'

const mapStateToProps = state => ({
  selectedDate: state.date.selectedDate
})

const mapDispatchToProps = {
  setSelectedDate
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClick: ({selectedDate, setSelectedDate}) => () =>
      setSelectedDate(addADay(selectedDate))
  })
)(CalendarDayListNextButton)
