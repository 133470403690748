import PropTypes from 'prop-types'
import React from 'react'

import {HeaderRow} from './HeaderRow'
import RowItem from './RowItem'

const TitleRow = ({compositeTitle, compositeType, selectedComponent, t}) => (
  <HeaderRow>
    <RowItem isOnly>
      {selectedComponent
        ? t(`componentDetails.headerRow.${selectedComponent.type}.title`)
        : t(`searchAndAdd.headerRow.${compositeType}.title`, {
            title: `${
              compositeTitle.length > 20
                ? compositeTitle.slice(0, 20) + '...'
                : compositeTitle
            }`
          })}
    </RowItem>
  </HeaderRow>
)

TitleRow.propTypes = {
  compositeTitle: PropTypes.string.isRequired,
  compositeType: PropTypes.string.isRequired,
  selectedComponent: PropTypes.object,
  t: PropTypes.func.isRequired
}

export default TitleRow
