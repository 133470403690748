import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {map, mergeMap} from 'rxjs/operators'
import {handleNetworkError} from '../observables/pipes'
import {
  loadFromLocalStorage,
  saveToLocalStorage
} from '../../utils/localStorage'
import {normalizeById} from '../../utils/utils'
import {closeBillingDetailsModal} from './modal'
import {omit} from 'ramda'
import {setBusinessPartnerName} from './businessPartners'

const initialStateBusinesses = {
  creatingOrUpdatingBusiness: false,
  businesses: {},
  selectedBusiness: loadFromLocalStorage('selectedBusiness')
}

export const addOrUpdateBusinessOptimistically = createAction(
  'businesses/ADD_OR_UPDATE_BUSINESS_OPTIMISTICALLY'
)
export const getBusinesses = createAction('businesses/GET_BUSINESSES_REQUESTED')
export const getBusinessesSucceeded = createAction(
  'businesses/GET_BUSINESS_SUCCEEDED'
)
export const postBusiness = createAction('businesses/POST_BUSINESS_REQUESTED')
export const postBusinessSucceeded = createAction(
  'businesses/POST_BUSINESS_SUCCEEDED'
)
export const putBusiness = createAction('businesses/PUT_BUSINESS_REQUESTED')
export const putBusinessSucceeded = createAction(
  'businesses/PUT_BUSINESS_SUCCEEDED'
)
export const setSelectedBusiness = createAction(
  'businesses/SET_SELECTED_BUSINESS'
)
export const setCreatingOrUpdatingBusiness = createAction(
  'businesses/SET_CREATING_OR_UPDATING_BUSINESS'
)
export const deleteBusinessOptimistic = createAction(
  'businesses/DELETE_BUSINESS_OPTIMISTIC'
)
export const deleteBusiness = createAction('businesses/DELETE_BUSINESS')
export const deleteBusinessSucceeded = createAction(
  'businesses/DELETE_BUSINESS_SUCCEEDED'
)

const reducer = createReducer(
  {
    [addOrUpdateBusinessOptimistically]: (state, payload) => ({
      ...state,
      businesses: {...state.businesses, [payload.id]: payload}
    }),
    [deleteBusinessOptimistic]: (state, payload) => ({
      ...state,
      businesses: omit([payload], state.businesses)
    }),
    [getBusinessesSucceeded]: (state, payload) => ({
      ...state,
      businesses: payload
    }),
    [postBusiness]: state => ({...state, creatingOrUpdatingBusiness: true}),
    [putBusiness]: state => ({...state, creatingOrUpdatingBusiness: true}),
    [setSelectedBusiness]: (state, payload) => ({
      ...state,
      selectedBusiness: payload
    }),
    [setCreatingOrUpdatingBusiness]: (state, payload) => ({
      ...state,
      creatingOrUpdatingBusiness: payload
    })
  },
  initialStateBusinesses
)

const getBusinessesEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(getBusinesses().type),
    mergeMap(() =>
      api.getBusinesses().pipe(
        mergeMap(({response: businesses}) => {
          const [businessPartnerBusiness] = businesses.filter(
            ({businessPartnerId}) => businessPartnerId
          )
          const businessPartnerName = businessPartnerBusiness
            ? businessPartnerBusiness.name
            : ''

          const businessesById = normalizeById(businesses)

          saveToLocalStorage('businessPartnerName', businessPartnerName)

          return of(
            getBusinessesSucceeded(businessesById),
            setBusinessPartnerName(businessPartnerName)
          )
        }),
        handleNetworkError
      )
    )
  )

const postBusinessEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(postBusiness().type),
    mergeMap(({payload: business}) => {
      return api.postBusiness(business).pipe(
        map(() => postBusinessSucceeded()),
        handleNetworkError
      )
    })
  )

const putBusinessEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(putBusiness().type),
    mergeMap(({payload: business}) =>
      api.putBusiness(business).pipe(
        mergeMap(() =>
          of(
            putBusinessSucceeded(),
            setCreatingOrUpdatingBusiness(false),
            closeBillingDetailsModal()
          )
        ),
        handleNetworkError
      )
    )
  )

const deleteBusinessEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(deleteBusiness().type),
    mergeMap(({payload: businessId}) =>
      api.deleteBusiness(businessId).pipe(
        map(() => deleteBusinessSucceeded()),
        handleNetworkError
      )
    )
  )

export const businessEpics = combineEpics(
  deleteBusinessEpic,
  getBusinessesEpic,
  postBusinessEpic,
  putBusinessEpic
)

export default reducer
