import styled, {css} from 'styled-components'

export const TextStyle = css`
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.normal};
  color: ${p => p.theme.colors.grayFont};
  line-height: 1.42;
  letter-spacing: 0.35px;
`

const Text = styled.span`
  ${() => TextStyle};
`

export default Text
