import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import {parseQueryString} from '../../../utils/utils'
import {removeComponent} from '../epics/epics'
import ComponentRowPopOverArrow from '../components/ComponentRowPopOverArrow'

const mapStateToProps = (state, {componentIndex, composite}) => {
  const {menuId, recipeId} = parseQueryString(state.router.location.search)
  const isEditedComposite = composite.id === menuId || composite.id === recipeId

  return {
    show:
      isEditedComposite &&
      state.components.editedComponentIndex === componentIndex
  }
}

const mapDispatchToProps = {
  removeComponent
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClick: ({composite, componentIndex, removeComponent}) => () => {
      removeComponent({composite, componentIndex})
    }
  })
)(ComponentRowPopOverArrow)
