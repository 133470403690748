import {connect} from 'react-redux'

import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import {selectSelectedIndicatorData} from '../../../redux/selectors/indicatorSelectors'
import IndicatorGroup from '../components/IndicatorGroup'

const mapStateToProps = (state, {indicatorType}) => {
  const composite = selectCompositeByIdFromUrl(state)
  const cloudIsAvailable =
    String(composite.serviceStatus.cloudStatus)[0] === '2'
  const matchingStatusOk =
    String(composite.serviceStatus.matchingStatus)[0] !== '6'

  const {score} = selectSelectedIndicatorData(state, indicatorType)
  return {
    indicatorType,
    score: cloudIsAvailable && matchingStatusOk ? score : 0
  }
}

export default connect(mapStateToProps)(IndicatorGroup)
