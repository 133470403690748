import styled from 'styled-components'

export const HeaderRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${p => p.theme.sizes.popOverHeaderRowHeight}px;
  border-bottom: ${p => p.theme.border.default};
`
