import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {IconPlusGrey} from '../../../assets/icons'
import ProductIcon from '../../icons/components/ProductIcon'

const BoxItemBase = styled.div`
  height: 104px;
  width: 104px;
  box-sizing: border-box;
  padding: ${p => (p.hasImageUrlOrTitle ? 0 : 1)}px;
`
const BoxAround = styled.div`
  cursor: pointer;
  height: ${p => (p.smallBoxAround ? 100 : 104)}px;
  width: ${p => (p.smallBoxAround ? 100 : 104)}px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.5px solid #cbc7c9;
  border-radius: 4px;
  box-shadow: 0px 1px 0px 0px
    rgba(0, 0, 0, ${p => (p.hasImageUrlOrTitle ? 0.1 : 0)});
`

const Box = styled.div`
  position: relative;
  height: 81px;
  margin-top: -0.5px;
  margin-right: -0.5px;
  margin-left: -0.5px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: ${p => (p.hasImageUrlOrTitle ? 0.5 : 0)}px solid #787c80;
  overflow: hidden;
  z-index: 1;
`

const Plus = styled.img`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const ImageOrHeaderDiv = styled.div`
  height: 103.5px;
  background-image: url(${p => p.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${p => (p.imageUrl ? '' : p.theme.colors.boxGray)};
`

const Header = styled.div`
  height: 44px;
  color: ${p => p.theme.colors.lightestGray2};
  ${p => p.theme.fonts.family.default};
  font-size: 36.5px;
  font-weight: 300;
  line-height: 44px;
  text-align: center;
  text-shadow: 0px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 63%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -70%);
  /* This is a shorthand of translateX(-50%) and translateY(-50%) */
  z-index: 1;
`

const Title = styled.div`
  top: 86px;
  height: 15px;
  width: 80px;
  color: #3d454c;
  ${p => p.theme.fonts.family.default};
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 1;
`

const IconCenterer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ImageOrHeader = ({imageUrl, title}) => (
  <div>
    <ImageOrHeaderDiv imageUrl={imageUrl}>
      {imageUrl ? '' : <Header>{title ? title.slice(0, 2) : ''}</Header>}
    </ImageOrHeaderDiv>
  </div>
)

ImageOrHeader.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string
}

const BoxItem = ({
  className,
  imageUrl,
  imageType = 'image',
  showPlusIcon = false,
  title
}) => {
  const hasImageUrlOrTitle = imageUrl || title
  const titleText = title
    ? `${title.slice(0, 8)}${title.length > 7 ? '...' : ''}`
    : ''

  return (
    <BoxItemBase className={className} hasImageUrlOrTitle={hasImageUrlOrTitle}>
      <BoxAround
        hasImageUrlOrTitle={hasImageUrlOrTitle}
        smallBoxAround={showPlusIcon}
      >
        <Box
          hasImageUrlOrTitle={hasImageUrlOrTitle}
          centerImage={imageType === 'icon'}
        >
          {imageUrl && imageType === 'icon' ? (
            <IconCenterer>
              <ProductIcon name={imageUrl} size={70} />
            </IconCenterer>
          ) : !showPlusIcon || imageUrl ? (
            <ImageOrHeader title={title} imageUrl={imageUrl} />
          ) : (
            <Plus draggable={false} src={IconPlusGrey} />
          )}
        </Box>
        <Title id="BoxItemTitle">{titleText}</Title>
      </BoxAround>
    </BoxItemBase>
  )
}

BoxItem.propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string,
  imageType: PropTypes.string,
  showPlusIcon: PropTypes.bool,
  title: PropTypes.string
}

export default BoxItem
