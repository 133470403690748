import {v4} from 'uuid'

import {dateToProductionDateString} from './dateUtils'
import {generateStandardComponent} from './componentUtils'

export const generateStandardMenu = ({
  cloudStatus,
  id,
  matchingStatus,
  menuCollectionId,
  menuLineId,
  productionDate,
  title = '',
  t
}) => ({
  generated: true,
  id: id || v4(),
  menuCollectionId,
  title,
  imageUrl: '',
  productionDate: productionDate || dateToProductionDateString(Date.now()),
  menuLineId,
  plannedPortions: 1,
  servings: 1,
  serviceStatus: {
    cloudStatus: cloudStatus || 404,
    matchingStatus: matchingStatus || 200
  },
  type: 'menu',
  components: [
    generateStandardComponent(
      t ? t('generateStandardMenu.placeholder.starter') : 'Starter'
    ),
    generateStandardComponent(
      t ? t('generateStandardMenu.placeholder.main') : 'Main'
    ),
    generateStandardComponent(
      t ? t('generateStandardMenu.placeholder.dessert') : 'Dessert'
    )
  ]
})

export const completeMenusOfDay = (formatedDate, menusOfDay, menuLines, t) => {
  const newMenusOfDay = menuLines.map((menuLine, index) => {
    const menuInMenuLine = menusOfDay.find(
      menu =>
        menu.productionDate === formatedDate && menu.menuLineId === menuLine.id
    )

    return (
      menuInMenuLine ||
      generateStandardMenu({
        productionDate: formatedDate,
        menuLineId: menuLine.id,
        t
      })
    )
  })

  return newMenusOfDay
}
