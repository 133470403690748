import {Widget} from '@uploadcare/react-widget'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import PopoverHeaderBar from './PopoverHeaderBar'
import upperFirst from 'lodash/upperFirst'
import Button from './Button'
import {addImageTransformation} from '../../../utils/utils'

const CompositeImageSelectorBase = styled.div``

const Body = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PreviewImageBox = styled.div`
  height: 227px;
  width: 300px;
  border: ${p => p.theme.border.default};
  border-radius: 4px;
`

const PreviewImage = styled.img`
  width: 100%;
  height: auto;
`

const WidgetButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`

const RemoveImageButton = styled(Button)`
  margin-left: 20px;
`

const CompositeImageSelector = ({
  composite,
  goBack,
  processImageChanged,
  removeImage
}) => {
  const [t, i18n] = useTranslation()
  const hasImageUrl = !!composite.imageUrl
  const imageUrlWithTransformParams =
    hasImageUrl &&
    addImageTransformation(
      composite.imageUrl,
      '-/progressive/yes/-/scale_crop/600x454/center'
    )

  return (
    <CompositeImageSelectorBase>
      <PopoverHeaderBar
        title={upperFirst(t('global.image'))}
        onArrowClick={goBack}
      />
      <Body>
        {composite.imageUrl && (
          <PreviewImageBox>
            <PreviewImage src={imageUrlWithTransformParams} />
          </PreviewImageBox>
        )}
        <WidgetButtonWrapper>
          {hasImageUrl ? (
            <RemoveImageButton onClick={() => removeImage(composite)} primary>
              {upperFirst(t('global.remove'))}
            </RemoveImageButton>
          ) : (
            <Widget
              locale={i18n.language}
              onChange={file => processImageChanged({composite, file})}
              publicKey="9c56b2b327dd9eae92da"
              systemDialog
            />
          )}
        </WidgetButtonWrapper>
      </Body>
    </CompositeImageSelectorBase>
  )
}

CompositeImageSelector.propTypes = {
  composite: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  processImageChanged: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired
}

export default CompositeImageSelector
