import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import PopOverDialog from './PopOverDialog'

const PopOverBase = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
`

const OnClickOutside = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const PopOver = props => {
  const {
    children,
    popOverPosition,
    popOverOpen,
    popOverArrowTop,
    onClickOutside,
    wideShadow,
    showArrows = true,
    dialogStyle
  } = props

  const left = popOverPosition ? popOverPosition.left : undefined
  const right = popOverPosition ? popOverPosition.right : undefined
  const top = popOverPosition ? popOverPosition.top : undefined

  const arrowTopAdapted = popOverArrowTop - top

  return (
    popOverOpen && (
      <PopOverBase id="PopOverBase">
        {onClickOutside && (
          <OnClickOutside id="OnClickOutside" onClick={onClickOutside} />
        )}

        <PopOverDialog
          arrowTopPosition={arrowTopAdapted}
          showArrowOnLeft={left > 0}
          style={{
            zIndex: 2,
            position: 'absolute',
            left,
            right,
            top,
            ...dialogStyle
          }}
          showArrows={showArrows}
          wideShadow={wideShadow}
        >
          {children}
        </PopOverDialog>
      </PopOverBase>
    )
  )
}

PopOver.propTypes = {
  showArrows: PropTypes.bool,
  popOverPosition: PropTypes.object,
  popOverOpen: PropTypes.bool.isRequired,
  popOverArrowTop: PropTypes.number,
  onClickOutside: PropTypes.func,
  wideShadow: PropTypes.bool,
  dialogStyle: PropTypes.object
}

export default PopOver
