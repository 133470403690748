import {uniqWith} from 'ramda'

import {
  sortByUpdateOrProductionDate,
  deepCompareComposites
} from '../../../utils/compositeUtils'

export const generateDuplicateRecipeData = recipesWithSameTitle => {
  if (recipesWithSameTitle === undefined || recipesWithSameTitle.length === 0) {
    return {
      duplicateRecipes: [],
      hasDuplicateRecipes: false,
      numberOfDuplicateRecipes: 0,
      uniqueRecipeResults: []
    }
  } else if (recipesWithSameTitle.length === 1) {
    return {
      duplicateRecipes: recipesWithSameTitle,
      hasDuplicateRecipes: false,
      numberOfDuplicateRecipes: 1,
      uniqueRecipeResults: recipesWithSameTitle
    }
  } else {
    /*
    recipe search results are sorted by updateAt field (descending)
    if possible or -if not - by productionDate here. The idea is that
    recipes with identical titles do not all show up in the seach
    results but rather just the one that was used (updated) last...
    */
    const sortedRecipeResults = sortByUpdateOrProductionDate(
      recipesWithSameTitle
    )

    /*
    deepCompareRecipes is curried and expects an array of recipes as first
    argument. These recipes are used to get the full recipes from the ids in
    the components. The returned function is binary and takes the two composites
    to be compared as input. This is exaclty what uniqWith needs. See here:
    https://ramdajs.com/docs/#uniqWith
    */
    const deepCompareRecipes = deepCompareComposites(recipesWithSameTitle)

    const uniqueRecipeResults = uniqWith(
      deepCompareRecipes,
      sortedRecipeResults
    )

    const duplicateRecipes = recipesWithSameTitle.filter(r =>
      uniqueRecipeResults.map(r => r.id).includes(r.id)
    )

    const numberOfDuplicateRecipes = duplicateRecipes.length
    const hasDuplicateRecipes = numberOfDuplicateRecipes > 1

    return {
      duplicateRecipes,
      hasDuplicateRecipes,
      numberOfDuplicateRecipes
    }
  }
}
