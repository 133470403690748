import styled from 'styled-components'

const AdminViewRightColumnGrid = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-sizing: border-box;
  display: grid;
  grid-column: 2;
  grid-template-columns: auto;
  grid-template-rows: 60px 136px auto;
`

export default AdminViewRightColumnGrid
