import styled from 'styled-components'

const AdminViewLeftColumn = styled.div`
  background-color: ${p => p.theme.colors.background};
  display: grid;
  grid-column: 1;
  grid-template-columns: ${p => p.theme.sizes.adminLeftColumnWidth}px;
  grid-template-rows:
    ${p => p.theme.sizes.adminButtonBoxHeigth}px
    calc(
      100vh -
        ${p =>
          p.theme.sizes.headerHeight +
          p.theme.sizes.footerHeight +
          p.theme.sizes.adminButtonBoxHeigth}px
    );
  align-content: start;
`

export default AdminViewLeftColumn
