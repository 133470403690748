import {createAction, createReducer} from 'redux-act'

export const initialStateError = {
  linkExpired: false
}

export const setLinkExpired = createAction('network/SET_LINK_EXPIRED')

const reducer = createReducer(
  {
    [setLinkExpired]: (state, payload) => ({
      ...state,
      linkExpired: payload
    })
  },
  initialStateError
)

export default reducer
