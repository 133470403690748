import {omit} from 'ramda'
import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, map, mergeMap} from 'rxjs/operators'
import {normalizeById} from '../../utils/utils'
import {handleNetworkError} from '../observables/pipes'
import {updateProductInReducer} from '../utils'
import {
  createProductThenCreateMenu,
  createProductThenCreateRecipe,
  createProductThenUpdateMenu,
  createProductThenUpdateRecipe
} from './sharedActions'
import {checkLicenseOfSelectedRestaurant} from '../../utils/restaurantUtils'

export const initialStateProducts = {
  products: {
    0: {
      id: '0',
      title: '',
      type: 'product',
      permanent: true
    }
  },
  configurationOptions: {
    transport: ['air', 'ground'],
    production: [
      'standard',
      'greenhouse',
      'organic',
      'fair-trade',
      'farm',
      'wild-caught',
      'sustainable-fish'
    ],
    conservation: [
      'fresh',
      'frozen',
      'dried',
      'conserved',
      'canned',
      'boiled-down'
    ],
    processing: [
      'raw',
      'unboned',
      'boned',
      'skinned',
      'beheaded',
      'fillet',
      'cut',
      'boiled',
      'peeled'
    ],
    packaging: [
      'none',
      'plastic',
      'paper',
      'pet',
      'tin',
      'alu',
      'glas',
      'cardboard',
      'tetra'
    ]
  },
  configureProductModalOpen: false
}

export const deleteProduct = createAction('products/DELETE_PRODUCT_REQUESTED')
export const deleteProductSucceeded = createAction(
  'products/DELETE_PRODUCT_SUCCEEDED'
)
export const getAllProducts = createAction(
  'products/GET_ALL_PRODUCTS_REQUESTED'
)
export const getAllProductsSucceeded = createAction(
  'products/GET_ALL_PRODUCTS_SUCCEEDED'
)
export const postProduct = createAction('products/POST_PRODUCT_REQUESTED')
export const postProductSucceeded = createAction(
  'products/POST_PRODUCT_SUCCEEDED'
)

export const updateProductSucceeded = createAction(
  'products/PUT_PRODUCT_SUCCEEDED'
)
export const closeConfigureProductModal = createAction(
  'products/CLOSE_CONFIGURE_PRODUCT_MODAL'
)
export const openConfigureProductModal = createAction(
  'products/OPEN_CONFIGURE_PRODUCT_MODAL'
)

const reducer = createReducer(
  {
    [createProductThenCreateMenu]: updateProductInReducer,
    [createProductThenUpdateMenu]: updateProductInReducer,
    [createProductThenCreateRecipe]: updateProductInReducer,
    [createProductThenUpdateRecipe]: updateProductInReducer,
    [deleteProduct]: (state, payload) => ({
      ...state,
      products: omit([payload.id], state.products)
    }),
    [getAllProductsSucceeded]: (state, payload) => ({
      ...state,
      products: {
        0: {
          id: '0',
          title: '',
          type: 'product',
          permanent: true
        },
        ...payload
      }
    }),
    [closeConfigureProductModal]: state => ({
      ...state,
      configureProductModalOpen: false
    }),
    [openConfigureProductModal]: state => ({
      ...state,
      configureProductModalOpen: true
    })
  },
  initialStateProducts
)

const getAllProductsEpic = (action$, state$, {api}) =>
  action$.pipe(
    ofType(getAllProducts().type),
    filter(() => checkLicenseOfSelectedRestaurant(state$)),
    mergeMap(({payload: restaurantId}) =>
      api.getAllProducts(restaurantId).pipe(
        map(({response: _products}) => {
          const locale = window.__localeId__
            ? window.__localeId__.split('-')[0]
            : 'en'
          const products = _products.map(p => ({
            ...p,
            title: p.titles && p.titles[locale] ? p.titles[locale] : p.title
          }))
          const productsById = normalizeById(products)
          return getAllProductsSucceeded(productsById)
        }),
        handleNetworkError
      )
    )
  )

const postProductEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(postProduct().type),
    mergeMap(({payload: product}) =>
      api.postProduct(product).pipe(
        map(({response: {results}}) => postProductSucceeded(results)),
        handleNetworkError
      )
    )
  )

const deleteProductEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(deleteProduct().type),
    mergeMap(({payload: product}) =>
      api.deleteProduct(product).pipe(
        map(({response}) => deleteProductSucceeded(response)),
        handleNetworkError
      )
    )
  )

export const productEpics = combineEpics(
  deleteProductEpic,
  getAllProductsEpic,
  postProductEpic
)

export default reducer
