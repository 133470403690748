import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {selectDuplicateRecipeData} from '../../../redux/selectors/search'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import DuplicateRecipeList from '../components/DuplicateRecipeList'

const mapStateToProps = state => {
  const recipe = selectCompositeByIdFromUrl(state)
  const {duplicateRecipes} = selectDuplicateRecipeData(state, recipe)

  return {
    duplicateRecipes,
    recipe
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(DuplicateRecipeList)
