import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {generateDateRange, isToday} from '../utils/calendarUtils'
import useMoment from '../../../hooks/useMoment'

const CalendarDayGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: ${p => p.theme.sizes.dayListHeight}px;
  width: 100%;
  grid-column-gap: ${p => p.theme.sizes.calendarGutter}px;
  @media print {
    display: none;
  }
`

const DayLabel = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  text-align: center;
`

const DayOfWeek = styled.div`
  @media print {
    color: ${props => (props.isSelected ? 'black' : 'white')};
    font-weight: ${props => (props.isSelected ? '800' : '200')};
  }
`

const DayOfMonth = styled.div`
  padding: ${props => (props.isToday || props.isSelected ? '6px' : '')};
  border-radius: 40px;
  background-color: ${props =>
    props.isSelected ? '#4da0d0' : props.isToday ? '#010101' : 'none'};
  color: ${props => (props.isToday || props.isSelected ? '#fff' : '')};
  margin-left: ${props =>
    props.isToday || props.isSelected ? '0.25em' : '0.5em'};
  width: 20px;
  height: 20px;
  @media print {
    color: ${props => (props.isSelected ? 'black' : 'white')};
    font-weight: ${props => (props.isSelected ? '800' : '200')};
  }
`

const CalendarDayGridItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column: ${p => p.column};
  grid-row: 1;
`

const ArrowOrNothingWrapper = styled.div`
  justify-self: ${p => (p.isFirst ? 'start' : p.isLast ? 'end' : 'center')};
`

const CalendarDayList = ({
  className,
  fromDate,
  leftArrowContainer,
  onDayClick,
  rightArrowContainer,
  selectedDate = null,
  style,
  toDate
}) => {
  const moment = useMoment()
  const dateRange = generateDateRange(fromDate, toDate)

  return (
    <CalendarDayGrid style={style} className={className}>
      {dateRange.map((date, index) => {
        const column = index + 1
        const isFirst = index === 0
        const isLast = index === dateRange.length - 1
        return (
          <CalendarDayGridItem
            key={date}
            column={column}
            isFirst={isFirst}
            isLast={isLast}
          >
            <ArrowOrNothingWrapper isFirst>
              {isFirst && leftArrowContainer && leftArrowContainer()}
            </ArrowOrNothingWrapper>
            <DayLabel onClick={() => onDayClick && onDayClick(date)}>
              <DayOfWeek
                isSelected={
                  selectedDate && date.getTime() === selectedDate.getTime()
                }
              >
                {moment(date).format('dd')}.
              </DayOfWeek>
              <DayOfMonth
                isToday={isToday(date)}
                isSelected={
                  selectedDate && date.getTime() === selectedDate.getTime()
                }
              >
                {moment(date).format('DD')}
              </DayOfMonth>
            </DayLabel>
            <ArrowOrNothingWrapper isLast>
              {isLast && rightArrowContainer && rightArrowContainer()}
            </ArrowOrNothingWrapper>
          </CalendarDayGridItem>
        )
      })}
    </CalendarDayGrid>
  )
}

CalendarDayList.propTypes = {
  className: PropTypes.string,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  leftArrowContainer: PropTypes.func.isRequired,
  onDayClick: PropTypes.func,
  rightArrowContainer: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  style: PropTypes.object,
  toDate: PropTypes.instanceOf(Date).isRequired
}

export default CalendarDayList
