import {connect} from 'react-redux'
import {setSelectedDate} from '../../../redux/modules/date'
import {toggleDatePicker} from '../../../redux/modules/view'
import DatePicker from '../components/DatePicker'

const mapStateToProps = state => ({
  adminDrawerOpen: state.admin.adminDrawerOpen,
  selectedDate: state.date.selectedDate,
  open: state.view.datePickerOpen
})

const mapDispatchToProps = {
  setSelectedDate,
  toggleDatePicker
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker)
