import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const PopOverTitleRowBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${p => p.theme.colors.darkGray};
  ${p => p.theme.fonts.family.tall};
  border-bottom: ${p => p.theme.border.default};
  font-size: ${p => p.theme.fonts.size.big}px;
  line-height: 60px;
`

const PopOverTitleRow = ({children}) => (
  <PopOverTitleRowBase>{children}</PopOverTitleRowBase>
)

PopOverTitleRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default PopOverTitleRow
