import React from 'react'
import styled from 'styled-components'

import CalendarDaySelectorContainer from '../../calendar/containers/CalendarDaySelectorContainer'
import DatePickerContainer from '../../calendar/containers/DatePickerContainer'
import DayEditContainer from '../containers/DayEditContainer'
import InfoPopOverContainer from '../containers/InfoPopOverContainer'
import LastDayButtonContainer from '../containers/LastDayButtonContainer'
import NextDayButtonContainer from '../containers/NextDayButtonContainer'
import SpacerBar from '../../core/components/SpacerBar'

const DayPlanningBase = styled.div``

const Main = styled.div`
  display: flex;
`

const CalendarColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${p => -p.theme.sizes.dayListHeight}px;
  width: 100%;
  margin-left: ${p => p.theme.sizes.dayPlanningContentMarginRight}px;
`

const DayPlanningPopOverColumn = styled.div`
  margin-top: ${p =>
    -(
      p.theme.sizes.spacerBarHeight +
      p.theme.sizes.headerHeight -
      p.theme.sizes.popOverMargin
    )}px;
  margin-bottom: ${p =>
    -(p.theme.sizes.footerHeight - p.theme.sizes.popOverMargin)}px;
  z-index: 1;
`
const Wrapper = styled.div`
  margin: 0 15px;
`

const DayPlanningMain = () => (
  <DayPlanningBase>
    <SpacerBar>
      <DatePickerContainer />
    </SpacerBar>
    <Main>
      <CalendarColumn>
        <Wrapper>
          <CalendarDaySelectorContainer
            leftArrowContainer={() => <LastDayButtonContainer />}
            rightArrowContainer={() => <NextDayButtonContainer />}
          />
        </Wrapper>
        <DayEditContainer />
      </CalendarColumn>
      <DayPlanningPopOverColumn>
        <InfoPopOverContainer sliceType={'menu'} />
      </DayPlanningPopOverColumn>
    </Main>
  </DayPlanningBase>
)

export default DayPlanningMain
