import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import ReactList from 'react-list'
import TWEEN from '@tweenjs/tween.js'
import styled from 'styled-components'

import {getStartOfWeekMoment} from '../utils/calendarUtils'
import moment from 'moment'
import CalendarWeekContainer from '../containers/CalendarWeekContainer'

const CalendarBase = styled.div`
  overflow-x: auto;
  height: 100%;
  width: ${p => p.theme.sizes.calendarContentWidth};
  max-width: ${p => p.theme.sizes.calendarContentMaxWidth};
  min-width: ${p => p.theme.sizes.calendarContentMinWidth};
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const WEEKS_INTO_THE_PAST = 10 * 52
const WEEKS_INTO_THE_FUTURE = 100 * 52

// TODO: check if there are additional events needed, in particular for touch devices
const MANUAL_EVENTS = ['wheel', 'mousewheel', 'touchmove']

class Calendar extends Component {
  constructor(props) {
    super(props)
    this.initialDate = props.selectedDate
    this.weekDay = moment(props.selectedDate).weekday()
    this.isScrollingManually = false
    this.lastScrollPos = 0

    this.onScroll = e => {
      if (this.isScrollingManually) {
        const newPos = e.nativeEvent.target.scrollLeft
        this.onManualScrollThrottled(newPos)
      }
    }
  }

  onManualEvent = () => {
    this.isScrollingManually = true

    if (this.scrollTween && this.scrollTween.isPlaying()) {
      this.scrollTween.stop()
    }

    this.onManualScrollEnd()
  }

  onManualScrollThrottled = throttle(scrollPos => {
    this.scrollDirection = scrollPos < this.lastScrollPos ? 'left' : 'right'
    this.lastScrollPos = scrollPos
    this.setSelectedDateWhileScrolling()
    this.snapAfterScroll()
  }, 300)

  onManualScrollEnd = debounce(() => {
    this.isScrollingManually = false
  }, 50)

  componentDidMount() {
    MANUAL_EVENTS.forEach(event =>
      document.addEventListener(event, this.onManualEvent, true)
    )
    window.addEventListener('resize', this.onResize, true)
  }

  componentWillUnmount() {
    MANUAL_EVENTS.forEach(event =>
      document.removeEventListener(event, this.onManualEvent, true)
    )

    window.removeEventListener('resize', this.onResize, true)
  }

  onResize = () => {
    this.setScrollPositionByDate(this.props.selectedDate)
  }

  snapAfterScroll = debounce(() => {
    this.setScrollPositionByDate(this.props.selectedDate)
  }, 50)

  setSelectedDateWhileScrolling() {
    const [firstIndex, lastIndex] = this.reactListRef.getVisibleRange()
    // TODO: check if clientWidth is browser independent
    const width = this.reactListRef.scrollParent.clientWidth
    const scrollAmountPercent = (this.lastScrollPos % width) / width

    const index =
      this.scrollDirection === 'left'
        ? scrollAmountPercent < 0.7
          ? firstIndex
          : lastIndex
        : scrollAmountPercent > 0.3
        ? lastIndex
        : firstIndex
    const date = this.indexToDate(index)
    this.props.setSelectedDate(
      moment(date)
        .weekday(this.weekDay)
        .toDate()
    )
  }

  indexToDate(index) {
    return getStartOfWeekMoment(this.initialDate)
      .add(index - WEEKS_INTO_THE_PAST, 'weeks')
      .toDate()
  }
  dateToIndex(date) {
    return (
      getStartOfWeekMoment(date).diff(
        getStartOfWeekMoment(this.initialDate),
        'weeks'
      ) + WEEKS_INTO_THE_PAST
    )
  }

  UNSAFE_componentWillReceiveProps({selectedDate}) {
    this.weekDay = moment(selectedDate).weekday()
    this.setScrollPositionByDate(selectedDate)
  }

  setScrollPositionByDate(date) {
    if (!this.isScrollingManually) {
      const scrollToIndex = this.dateToIndex(date)
      if (this.reactListRef) {
        this.scrollToSmoothly(this.reactListRef.getSpaceBefore(scrollToIndex))
      }
    }
  }

  scrollToSmoothly(scrollPos) {
    if (scrollPos !== this.reactListRef.scrollParent.scrollLeft) {
      if (this.scrollTween) {
        this.scrollTween.stop()
      }
      this.scrollTween = new TWEEN.Tween(this.reactListRef.scrollParent)
        .to({scrollLeft: scrollPos}, 600)
        .easing(TWEEN.Easing.Quartic.Out)
        .start()
    }
  }

  render() {
    return (
      <CalendarBase onScroll={this.onScroll}>
        <ReactList
          ref={ref => (this.reactListRef = ref)}
          initialIndex={WEEKS_INTO_THE_PAST}
          pageSize={10}
          type="uniform"
          axis="x"
          length={WEEKS_INTO_THE_PAST + WEEKS_INTO_THE_FUTURE}
          itemRenderer={(index, key) => {
            return (
              <CalendarWeekContainer
                key={key}
                style={{
                  display: 'inline-flex'
                }}
                startOfWeek={this.indexToDate(index)}
              />
            )
          }}
        />
      </CalendarBase>
    )
  }
}

Calendar.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDate: PropTypes.func
}

export default Calendar
