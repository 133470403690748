import emailValidator from 'email-validator'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import AgreementText from '../../core/components/AgreementText'
import Blink from '../../core/components/Blink'
import {BelowTheBoxGroup} from './BelowTheBoxGroup'
import {InfoBox} from './InfoBox'
import LinkLine from './LinkLine'
import {NavigateButton} from './NavigateButton'
import RegLogBase from './RegLogBase'
import {RegLogButton} from './RegLogButton'
import RegLogContent from './RegLogContent'
import RegLogHeader from './RegLogHeader'
import RegLogInput from './RegLogInput'
import RegLogLogo from './RegLogLogo'
import RegLogNotReceivedText from './RegLogNotReceivedText'
import RegLogText from './RegLogText'

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ContentWrapper = styled.div``

const RegisterAgreementText = styled(AgreementText)`
  font-size: ${p => p.theme.fonts.size.small}px;
  font-weight: ${p => p.theme.fonts.weight.thin};
  line-height: 1.5em;
  text-align: center;
  padding: 0 20px;
  height: 20px;
`

const Register = ({
  email,
  goToLogin,
  onChange,
  register,
  registering,
  registrationEmailSend,
  resetRegister,
  userExistsWarning,
  webEntryDomain
}) => {
  const [t] = useTranslation()

  const isEmail = emailValidator.validate(email)

  return (
    <RegLogBase
      tabIndex={0}
      onKeyDown={({keyCode}) => {
        keyCode === 13 && register()
      }}
    >
      <ContentWrapper>
        <RegLogContent>
          <RegLogLogo />
          <RegLogHeader>{t('register.header')}</RegLogHeader>
          <RegLogText width="340">{t('register.subHeaderText')}</RegLogText>
          <ColumnDiv>
            {userExistsWarning ? (
              <ColumnDiv>
                <InfoBox>{t('register.emailAlreadyRegisteredMessage')}</InfoBox>
                <NavigateButton full big blue onClick={goToLogin}>
                  {t('register.emailAlreadyRegisteredButtonText')}
                </NavigateButton>
              </ColumnDiv>
            ) : registrationEmailSend ? (
              <ColumnDiv>
                <InfoBox>{t('register.emailSendMessage')}</InfoBox>
                <RegLogNotReceivedText t={t} reset={resetRegister} />
              </ColumnDiv>
            ) : (
              <ColumnDiv>
                <RegLogInput
                  disabled={registering || userExistsWarning}
                  onChange={onChange}
                  placeholder={t('logReg.emailPlaceholder')}
                  value={email}
                  onKeyDown={({keyCode}) => keyCode === 13 && register}
                />
                <RegLogButton
                  disabled={!isEmail || registering}
                  full
                  blue
                  fluid
                  onClick={register}
                >
                  {registering ? (
                    <RowDiv>
                      {t('logReg.sendingEmail')}
                      <Blink />
                    </RowDiv>
                  ) : (
                    t('register.buttonText')
                  )}
                </RegLogButton>
                <LinkLine
                  linkText={t('register.loginLinkText')}
                  onClick={goToLogin}
                  text={t('register.haveAccountText')}
                />
              </ColumnDiv>
            )}
          </ColumnDiv>
        </RegLogContent>
        <BelowTheBoxGroup>
          <RegisterAgreementText webEntryDomain={webEntryDomain} />
        </BelowTheBoxGroup>
      </ContentWrapper>
    </RegLogBase>
  )
}

Register.propTypes = {
  email: PropTypes.string.isRequired,
  goToLogin: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  registering: PropTypes.bool.isRequired,
  registrationEmailSend: PropTypes.bool.isRequired,
  resetRegister: PropTypes.func.isRequired,
  userExistsWarning: PropTypes.bool.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default Register
