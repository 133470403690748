import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {getEndOfWeek, getStartOfWeek} from '../utils/calendarUtils'
import CalendarDayList from './CalendarDayList'

const Container = styled.div`
  width: 100%;
  height: ${p => p.theme.sizes.dayListHeight}px;
`

const CalendarDaySelector = ({
  leftArrowContainer,
  rightArrowContainer,
  selectedDate,
  setSelectedDate
}) => {
  const startOfWeek = getStartOfWeek(selectedDate)
  const endOfWeek = getEndOfWeek(selectedDate)

  return (
    <Container>
      <CalendarDayList
        fromDate={startOfWeek}
        leftArrowContainer={leftArrowContainer}
        onDayClick={setSelectedDate}
        rightArrowContainer={rightArrowContainer}
        selectedDate={selectedDate}
        toDate={endOfWeek}
      />
    </Container>
  )
}

CalendarDaySelector.propTypes = {
  leftArrowContainer: PropTypes.func.isRequired,
  rightArrowContainer: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired
}

export default CalendarDaySelector
