import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {processAddButtonClick} from '../../../modules/search/epics/epics'
import {hideSearchModal} from '../../../redux/modules/modal'
import {getAllProducts} from '../../../redux/modules/products'
import {getAllRecipes} from '../../../redux/modules/recipes'
import {resetSearchInput} from '../../../redux/modules/search'
import SearchModalContent from '../components/SearchModalContent'

const mapStateToProps = state => {
  const {
    config: {webEntryDomain},
    network: {getProductsSucceeded, getRecipesSucceeded},
    recipes: {recipes},
    router: {
      location: {pathname}
    },
    restaurants: {selectedRestaurant},
    search: {searchInput, searchTypes, welcomeBoxAndRecipePreviewOpen}
  } = state

  const [searchType] = searchTypes

  return {
    pathname,
    recipeCollectionId:
      selectedRestaurant && selectedRestaurant.recipeCollectionId,
    searchInput,
    searchType,
    showAddButton:
      searchTypes.length === 1 &&
      searchType === 'recipes' &&
      !Object.values(recipes)
        .map(r => r.title)
        .includes(searchInput),
    showWelcomeBoxAndRecipePreview:
      welcomeBoxAndRecipePreviewOpen &&
      searchInput === '' &&
      pathname.includes('search'),
    getProductsSucceeded,
    getRecipesSucceeded,
    getAllDataSucceeded: getProductsSucceeded && getRecipesSucceeded,
    webEntryDomain
  }
}

const mapDispatchToProps = {
  getAllProducts,
  getAllRecipes,
  hideSearchModal,
  processAddButtonClick,
  resetSearchInput
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onAddButtonMouseDown: ({
      processAddButtonClick,
      recipeCollectionId,
      searchInput
    }) => t => {
      processAddButtonClick({
        recipeCollectionId,
        searchInput,
        compositeType: 'recipe',
        t
      })
    }
  })
)(SearchModalContent)
