import {curry} from 'ramda'
import {v4} from 'uuid'
import {generateStandardComponent} from './componentUtils'
import {dateToProductionDateString} from './dateUtils'

export const generateStandardInstruction = t => ({
  title: t
    ? t('generateStandardInstruction.placeholder.title')
    : 'Instructions',
  instruction: t
    ? t('generateStandardInstruction.placeholder.instruction')
    : 'Add your instructions here'
})

export const generateEmptyInstruction = () => ({
  title: '',
  instruction: ''
})

export const _generateStandardRecipe = curry(
  (
    presetDate,
    {
      authorId,
      cloudStatus,
      id,
      matchingStatus,
      permanent,
      productionDate,
      recipeCollectionId,
      title = '',
      t
    }
  ) => {
    const createAndUpdateDate = Date.now()

    return {
      /*
  Adding a generated flag for now to distinguish a new recipe from one
  coming in from the search. Flag is removed when first posted/added to the
  recipes slice. Hope this is a good idea...
   */
      generated: true,
      createdAt: createAndUpdateDate,
      updatedAt: createAndUpdateDate,
      id: id || v4(),
      authorId: authorId || '',
      title,
      recipeCollectionId,
      serviceStatus: {
        cloudStatus: cloudStatus || 404,
        matchingStatus: matchingStatus || 200
      },
      imageUrl: '',
      permanent: permanent || false,
      productionDate: productionDate || presetDate,
      servings: 4,
      type: 'recipe',
      instructions: [generateStandardInstruction(t)],
      components: [
        generateStandardComponent(
          t
            ? t('generateStandardRecipe.placeholder.ingredients')
            : 'Ingredients'
        ),
        generateStandardComponent(''),
        generateStandardComponent('')
      ]
    }
  }
)

export const generateStandardRecipe = _generateStandardRecipe(
  dateToProductionDateString(Date.now())
)

export const findDirectParent = (composites, subrecipe) =>
  composites.find(composite =>
    composite.components
      .flatMap(({component}) => (component.id === '0' ? [] : [component.id]))
      .includes(subrecipe.id)
  )
