import styled from 'styled-components'

const RegLogContent = styled.div`
  box-sizing: border-box;
  align-items: center;
  background-color: ${p => p.theme.colors.white};
  border-radius: 3px;
  border: solid 1px ${p => p.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 445px;
  width: 465px;
  z-index: 3;
`

export default RegLogContent
