import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import AddRowWrapper from '../../core/components/AddRowWrapper'
import Button from '../../core/components/Button'
import SpacerBar from '../../core/components/SpacerBar'
import InfoPopOverContainer from '../../dayPlanningView/containers/InfoPopOverContainer'
import RecipeOrProductGridContainer from '../containers/RecipeOrProductGridContainer'

const RecipeEditBase = styled.div``

const ScrollContainer = styled.div`
  height: ${p => p.theme.sizes.dataGridHeightMenu};
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const CreateOrConfigureButton = styled(Button)`
  height: 38px;
  margin-right: 12px;
`

const TwoColumns = styled.div`
  display: flex;
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${p => p.theme.sizes.dayPlanningContentMarginRight}px;
`

const RightColumn = styled.div`
  margin-top: ${p =>
    -(
      p.theme.sizes.spacerBarHeight +
      p.theme.sizes.headerHeight -
      p.theme.sizes.popOverMargin
    )}px;
  margin-bottom: ${p =>
    -(p.theme.sizes.footerHeight - p.theme.sizes.popOverMargin)}px;
  z-index: 1;
`

const RecipeEditSpaceBar = styled(SpacerBar)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const CreateOrConfigureButtonWrapper = styled(AddRowWrapper)`
  &:hover {
    border: none;
  }
`

const RecipeOrProductEditMain = ({
  addOrConfigureButtonText,
  className,
  onConfigureButtonClick,
  resetSelectedComponent,
  setDayPlanningPopOverContentType,
  style
}) => {
  useEffect(() => {
    setDayPlanningPopOverContentType('search')
    resetSelectedComponent()
  }, [resetSelectedComponent, setDayPlanningPopOverContentType])

  return (
    <RecipeEditBase style={style} className={className}>
      <RecipeEditSpaceBar />
      <TwoColumns>
        <LeftColumn>
          <ScrollContainer>
            <RecipeOrProductGridContainer />
            <CreateOrConfigureButtonWrapper>
              <CreateOrConfigureButton
                data-cy="recipeOrProductCreateOrConfigureButton"
                onClick={onConfigureButtonClick && onConfigureButtonClick}
                primary
              >
                {addOrConfigureButtonText}
              </CreateOrConfigureButton>
            </CreateOrConfigureButtonWrapper>
          </ScrollContainer>
        </LeftColumn>
        <RightColumn>
          <InfoPopOverContainer sliceType={'recipe'} />
        </RightColumn>
      </TwoColumns>
    </RecipeEditBase>
  )
}

RecipeOrProductEditMain.propTypes = {
  addOrConfigureButtonText: PropTypes.string.isRequired,
  className: PropTypes.string,
  onConfigureButtonClick: PropTypes.func.isRequired,
  resetSelectedComponent: PropTypes.func.isRequired,
  setDayPlanningPopOverContentType: PropTypes.func.isRequired,
  style: PropTypes.object
}

export default RecipeOrProductEditMain
