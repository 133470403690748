import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  IconAnimalWelfareActive,
  IconAnimalWelfareInactive,
  IconForestActive,
  IconForestInactive,
  IconLocalActive,
  IconLocalInactive,
  IconSeasonActive,
  IconSeasonInactive,
  IconWaterActive,
  IconWaterInactive
} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EnvironmentIconGroup = ({
  awards,
  iconHeight = '25px',
  iconWidth = '27px',
  size
}) => {
  const iconData = [
    {
      iconActive: IconWaterActive,
      iconInactive: IconWaterInactive,
      hasAward: awards.water
    },
    {
      iconActive: IconForestActive,
      iconInactive: IconForestInactive,
      hasAward: false
    },
    {
      iconActive: IconAnimalWelfareActive,
      iconInactive: IconAnimalWelfareInactive,
      hasAward: false
    },
    {
      iconActive: IconLocalActive,
      iconInactive: IconLocalInactive,
      hasAward: false
    },
    {
      iconActive: IconSeasonActive,
      iconInactive: IconSeasonInactive,
      hasAward: false
    }
  ]

  return (
    <ValueWrapper>
      {iconData.map(({iconActive, iconInactive, hasAward}, index) => (
        <Icon
          src={hasAward ? iconActive : iconInactive}
          width={iconWidth}
          height={iconHeight}
          size={size}
          key={index}
        />
      ))}
    </ValueWrapper>
  )
}

EnvironmentIconGroup.propTypes = {
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  size: PropTypes.number,
  awards: PropTypes.object.isRequired
}

export default EnvironmentIconGroup
