import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Link from '../../core/components/Link'
import {TextStyle} from '../../core/components/Text'
import RecipePreviewContainer from '../containers/RecipePreviewContainer'
import SearchBarContainer from '../containers/SearchBarContainer'
import SearchResultsContainer from '../containers/SearchResultsContainer'
import SearchModalAddButton from './SearchModalAddButton'
import WelcomeBox from './WelcomeBox'
import WelcomeBoxKlimaTeller from './WelcomeBoxKlimaTeller'

const SearchModalContentBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: ${p => p.theme.colors.white};
  width: ${p => p.theme.sizes.fullScreenModalWidth}px;
  height: 100vh;
  padding-bottom: ${p => p.theme.sizes.searchModalContentMarginBottom}px;
  padding-top: ${p => p.theme.sizes.searchModalContentMarginTop}px;
  z-index: 1;
`

const ContentWrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(
    100vh -
      (
        ${p =>
          p.theme.sizes.searchModalContentMarginBottom +
          p.theme.sizes.searchModalContentMarginTop +
          p.theme.sizes.searchBarHeight}px
      )
  );

  width: ${p =>
    p.theme.sizes.fullScreenModalWidth -
    2 * p.theme.sizes.fullScreenModalMargin}px;
`

const SearchResultsWrapper = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: ${p => p.theme.sizes.fullScreenModalMargin}px;
  padding-right: ${p => p.theme.sizes.fullScreenModalMargin}px;
  -webkit-overflow-scrolling: touch;
`

const WelcomeBoxAndRecipePreviewWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  padding-top: 30px;
  margin-left: ${p => p.theme.sizes.fullScreenModalMargin}px;
  margin-right: ${p => p.theme.sizes.fullScreenModalMargin}px;
  margin-bottom: ${p => p.marginBottom};
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(
    100vh -
      ${p =>
        p.theme.sizes.searchModalContentMarginBottom +
        p.theme.sizes.searchModalContentMarginTop +
        p.theme.sizes.searchBarHeight}px
  );
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const RecipesArePrivateHint = styled.div`
  ${() => TextStyle};
  font-size: ${p => p.theme.fonts.size.small}px;
  margin-left: ${p => p.theme.sizes.fullScreenModalMargin}px;
  margin-right: ${p => p.theme.sizes.fullScreenModalMargin}px;
  letter-spacing: 0.1px;
  padding: 30px 0;
`

const SearchModalContent = ({
  onAddButtonMouseDown,
  searchInput,
  showAddButton,
  showWelcomeBoxAndRecipePreview,
  webEntryDomain
}) => {
  const [t] = useTranslation()
  const inputToFocusRef = React.createRef()
  const mailtoMap = {
    eaternity: 'info@eaternity.ch',
    klimateller: 'hallo@klimateller.de'
  }

  return (
    <SearchModalContentBase id="SearchModalContent">
      <SearchBarContainer inputToFocusRef={inputToFocusRef} />
      {showWelcomeBoxAndRecipePreview ? (
        <>
          <WelcomeBoxAndRecipePreviewWrapper
            marginBottom={webEntryDomain !== 'klimateller' && '30px'}
          >
            {webEntryDomain === 'klimateller' ? (
              <WelcomeBoxKlimaTeller t={t} />
            ) : (
              <WelcomeBox t={t} />
            )}
            <RecipePreviewContainer />
          </WelcomeBoxAndRecipePreviewWrapper>
          {webEntryDomain === 'klimateller' && (
            <RecipesArePrivateHint>
              {t('searchModal.recipesArePrivateHint')}{' '}
              <Link href={`mailto:${mailtoMap[webEntryDomain]}`}>
                {mailtoMap[webEntryDomain]}
              </Link>
              .
            </RecipesArePrivateHint>
          )}
        </>
      ) : (
        <ContentWrapper>
          <SearchResultsWrapper>
            <SearchResultsContainer
              inputToFocusRef={inputToFocusRef}
              onAddButtonMouseDown={() => onAddButtonMouseDown(t)}
            />
          </SearchResultsWrapper>
          {showAddButton && (
            <SearchModalAddButton
              id="SearchModalAddButton"
              onMouseDown={() => onAddButtonMouseDown(t)}
              searchInput={searchInput}
              t={t}
            />
          )}
        </ContentWrapper>
      )}
    </SearchModalContentBase>
  )
}

SearchModalContent.propTypes = {
  onAddButtonMouseDown: PropTypes.func.isRequired,
  showAddButton: PropTypes.bool.isRequired,
  searchInput: PropTypes.string.isRequired,
  showWelcomeBoxAndRecipePreview: PropTypes.bool.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default SearchModalContent
