import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {IconLeftArrowPopover} from '../../../assets/icons/index'
import Icon from '../../icons/components/Icon'

const ArrowDiv = styled.div`
  position: absolute;
`

const TitleDiv = styled.div`
  margin: 20px auto;
`
const Title = styled.div`
  width: auto;
  color: #3d454c;
  ${p => p.theme.fonts.family.default};
  font-size: 21px;
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 0px 0px;
`

const IndicatorDetailHeader = ({title, onArrowClick}) => {
  return (
    <Title>
      <ArrowDiv id="IconLeftArrowPopover">
        <Icon
          draggable={false}
          src={IconLeftArrowPopover}
          onClick={() => onArrowClick('environment')}
          width="60px"
          height="60px"
        />
      </ArrowDiv>
      <TitleDiv>{title}</TitleDiv>
    </Title>
  )
}

IndicatorDetailHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onArrowClick: PropTypes.func.isRequired
}

export default IndicatorDetailHeader
