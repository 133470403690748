import styled from 'styled-components'

import EmailInput from '../../core/components/EmailInput'

const RegLogInput = styled(EmailInput)`
  border-radius: 3px;
  border: ${p => p.theme.border.default};
  box-sizing: border-box;
  flex: 1;
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.normal};
  margin-top: 20px;
  outline: none;
  padding: 15px 18px;
  width: 374px;

  ${p => p.theme.fonts.family.default};
  text-align: left;

  &:focus {
    border-color: ${p => p.theme.colors.blue};
  }

  ::placeholder {
    color: ${p => p.theme.colors.gray};
    font-weight: ${p => p.theme.fonts.weight.thin};
  }
`

export default RegLogInput
