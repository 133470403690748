import React from 'react'

import Icon from '../../icons/components/Icon'

const RegLogLogo = () => {
  return (
    <Icon
      src={`${process.env.PUBLIC_URL}/assets/icons/logo.svg`}
      width="140px"
      height="138px"
      size={120}
    />
  )
}

export default RegLogLogo
