import moment from 'moment'
import {getLicenseType} from './restaurantUtils'

/**
 * Function to get some details of a m instance
 * @param  {Date} date A formated date string
 * @return {Object]}   Object holding some date details
 */
export const getDateDetails = date => {
  const m = moment.utc(date)
  return {
    dayOfMonth: m.format('DD'),
    dayOfMonthShort: m.format('D'),
    dayOfWeek: m.format('dddd'),
    dayOfWeekShort: m.format('ddd'),
    month: m.format('MMMM'),
    monthShort: m.format('MMM'),
    year: m.format('YYYY')
  }
}

export const productionDateToMoment = productionDate =>
  moment.utc(productionDate, 'YYYY-MM-DD')

export const productionDateToDate = productionDate =>
  productionDateToMoment(productionDate).toDate()

export const dateToProductionDateString = date =>
  moment.utc(date).format('YYYY-MM-DD')

export const dateStringToUnixTime = date => moment.utc(date).format('x')

export const diffByProductionDate = (a, b) =>
  dateStringToUnixTime(a.productionDate) -
  dateStringToUnixTime(b.productionDate)

export const getDateDiffInDays = (moment, date1, date2) => {
  const m1 = moment.utc(date1)
  const m2 = moment.utc(date2)
  return m1.diff(m2, 'days')
}

export const restaurantLicenseCheck = (webEntryDomain, licenses) => {
  const licenseType = getLicenseType(webEntryDomain, licenses)
  const licenseExpiryDate = licenses[licenseType]
  const today = moment.utc().format('YYYY-MM-DD')
  const daysUntilLicenseExpires = getDateDiffInDays(
    moment,
    licenseExpiryDate,
    today
  )
  const restaurantIsLicensed = !!licenses && daysUntilLicenseExpires > 0

  return restaurantIsLicensed
}

export const getDaysUntilLicenseExpiry = (
  moment,
  webEntryDomain,
  restaurant
) => {
  const {licenses} = restaurant
  const licenseType = licenses && getLicenseType(webEntryDomain, licenses)
  const licenseExpiryDate = licenses[licenseType]
  const today = moment.utc().format('YYYY-MM-DD')
  const daysUntilLicenseExpiry = getDateDiffInDays(
    moment,
    licenseExpiryDate,
    today
  )

  return daysUntilLicenseExpiry > 0 ? daysUntilLicenseExpiry : 0
}
