import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import Unlicensed from '../components/Unlicensed'
import {showBuyLicenseModal} from '../../../redux/modules/modal'
import {getUserRestaurants} from '../../../redux/modules/restaurants'
import {
  processSwitchRestaurant,
  changeDefaultRestaurant
} from '../../admin/epics/epics'

const mapStateToProps = ({
  config: {webEntryDomain},
  restaurants: {selectedRestaurant, restaurants},
  network: {getUserRestaurantsSucceeded}
}) => ({
  selectedRestaurant,
  webEntryDomain,
  restaurants,
  getUserRestaurantsSucceeded
})

const mapDispatchToProps = dispatch => ({
  onUseOtherAccountClick: () => {
    window.localStorage.clear()
    dispatch(push('/login'))
    window.location.reload()
  },
  goHome: () => dispatch(push('/')),
  showBuyLicenseModal: () => dispatch(showBuyLicenseModal()),
  getUserRestaurants: () => dispatch(getUserRestaurants()),
  onSelectRestaurant: restaurant => {
    dispatch(changeDefaultRestaurant(restaurant))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Unlicensed)
