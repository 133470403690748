import {createAction, createReducer} from 'redux-act'

export const initialStateViewPagerNavigator = {
  hierarchy: [
    {
      view: 'root'
    }
  ]
}

export const goBack = createAction('viewPagerNavigator/GO_BACK')
export const goToRoot = createAction('viewPagerNavigator/GO_TO_ROOT')
export const goToView = createAction('viewPagerNavigator/GO_TO_VIEW')
export const closeServingSetter = createAction(
  'viewPagerNavigator/CLOSE_SERVINGS_SETTINGS'
)
const reducer = createReducer(
  {
    [goBack]: state => ({
      ...state,
      hierarchy: state.hierarchy.slice(0, -1)
    }),
    [closeServingSetter]: state => ({
      ...state,
      hierarchy: state.hierarchy
        .map(obj => obj.view)
        .includes('menuServingsView')
        ? [
            {
              view: 'root'
            }
          ]
        : state.hierarchy
    }),
    [goToRoot]: state => ({
      ...state,
      hierarchy: [
        {
          view: 'root'
        }
      ]
    }),
    [goToView]: (state, payload) => ({
      ...state,
      hierarchy: [...state.hierarchy, payload]
    })
  },
  initialStateViewPagerNavigator
)

export default reducer
