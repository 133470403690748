import {connect} from 'react-redux'

import {setSelectedDate} from '../../../redux/modules/date'
import Calendar from '../components/Calendar'

const mapStateToProps = state => ({
  selectedDate: state.date.selectedDate
})

const mapDispatchToProps = {
  setSelectedDate
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
