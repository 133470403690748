import {createSelector} from 'reselect'

import {selectCompositeByIdFromUrl, getWebEntryDomain} from './selectors'
import {extractIndicatorData} from '../../utils/compositeUtils'

export const getSelectedIndicatorType = state =>
  state.indicators.selectedIndicatorType

export const selectIndicatorData = createSelector(
  [getWebEntryDomain, selectCompositeByIdFromUrl],
  extractIndicatorData
)

export const selectSelectedIndicatorData = createSelector(
  [
    selectIndicatorData,
    getSelectedIndicatorType,
    (_, passedIndicatorType) => passedIndicatorType
  ],
  (indicatorData, selectedIndicatorType, passedIndicatorType) => {
    const indicatorType = passedIndicatorType || selectedIndicatorType
    return indicatorData[indicatorType]
  }
)
