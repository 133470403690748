import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, mergeMap} from 'rxjs/operators'
import {setSelectedComponent} from '../../../redux/modules/components'
import {setEditMode, setPreventBlur} from '../../../redux/modules/dataGrid'
import {updateSearchInput} from '../../../redux/modules/search'
import {
  getEditedComponentIndex,
  selectCompositeByIdFromUrl,
  selectParentByIdFromUrl,
  selectProductByIdFromUrl,
  selectRecipeOrProduct
} from '../../../redux/selectors/selectors'
import {generateRecipeOrProduct} from '../../../utils/recipeOrProductUtils'
import {replaceComponent} from '../../dataGrid/epics/epics'
import {convertProductToRecipe} from '../../recipeOrProduct/epics/epics'

export const processHitRowClick = createAction(
  'searchAndAdd/PROCESS_HIT_ROW_CLICK'
)

const processHitRowClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processHitRowClick().type),
    // TODO: decide what to do when the search result is a menu!
    filter(({payload: result}) => result.type !== 'menu'),
    mergeMap(({payload: {result, t}}) => {
      const state = state$.value
      const {
        router: {
          location: {pathname}
        }
      } = state
      const editedComponentIndex = getEditedComponentIndex(state)
      const componentToAdd =
        result.type === 'recipe' ? {...result, permanent: false} : result
      const currentComponent = selectProductByIdFromUrl(state)

      // special case: HitRow was clicked on /select route (recipeOrProduct)
      if (pathname === '/select') {
        return of(
          setSelectedComponent(result),
          convertProductToRecipe({
            componentToAdd,
            currentComponent,
            componentIndex: 0,
            parent: selectParentByIdFromUrl(state),
            recipeOrProduct:
              currentComponent &&
              generateRecipeOrProduct(currentComponent.title),
            searchInput: state.search.searchInput,
            t
          })
        )
      }

      const composite = selectCompositeByIdFromUrl(state)
      const _currentComponent =
        composite.components[editedComponentIndex].component
      const recipeOrProduct = selectRecipeOrProduct(state, _currentComponent.id)

      return of(
        setSelectedComponent(result),
        replaceComponent({
          addRow: false,
          componentToAdd,
          currentComponent: {
            ..._currentComponent,
            permanent: recipeOrProduct ? recipeOrProduct.permanent : false
          },
          componentIndex: editedComponentIndex,
          composite
        }),
        setPreventBlur(false),
        updateSearchInput(result.title),
        setEditMode(false)
      )
    })
  )

export const searchAndAddEpics = combineEpics(processHitRowClickEpic)
