import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import LayoutContentRight from '../../core/components/LayoutContentRight'
import LayoutWithAdminDrawerContainer from '../../core/containers/LayoutWithAdminDrawerContainer'
import LoadingIndicatorModalContainer from '../../modal/containers/LoadingIndicatorModalContainer'
import DayPlanningMain from '../components/DayPlanningMain'
import NavigationContainer from '../../core/containers/NavigationContainer'
import FooterContainer from '../../core/containers/FooterContainer'

const DayPlanning = ({getAllDataSucceeded, refetchIfNeeded}) => {
  useEffect(() => {
    refetchIfNeeded()
  }, [refetchIfNeeded])

  return getAllDataSucceeded ? (
    <LayoutWithAdminDrawerContainer
      renderContentRight={() => (
        <LayoutContentRight
          renderHeader={() => <NavigationContainer />}
          renderMain={() => <DayPlanningMain />}
          renderFooter={() => <FooterContainer />}
        />
      )}
    />
  ) : (
    <LoadingIndicatorModalContainer isOpen />
  )
}

DayPlanning.propTypes = {
  getAllDataSucceeded: PropTypes.bool.isRequired,
  refetchIfNeeded: PropTypes.func.isRequired
}

export default DayPlanning
