import {combineEpics} from 'redux-observable'
import {adminInterfaceEpics} from '../modules/admin/epics/epics'
import {coreEpics} from '../modules/core/epics/epics'
import {dataGridEpics} from '../modules/dataGrid/epics/epics'
import {dayPlanningViewEpics} from '../modules/dayPlanningView/epics/epics'
import {modalEpics} from '../modules/modal/epics/epics'
import {productEditViewEpics} from '../modules/productEditView/epics/epics'
import {recipeEditViewEpics} from '../modules/recipeEditView/epics/epics'
import {recipeOrProductEpics} from '../modules/recipeOrProduct/epics/epics'
import {searchEpics} from '../modules/search/epics/epics'
import {searchAndAddEpics} from '../modules/searchAndAdd/epics/epics'
import {businessEpics} from './modules/businesses'
import {exportsEpics} from './modules/exports'
import {businessPartnerEpics} from './modules/businessPartners'
import {formEpics} from './modules/forms'
import {menuEpics} from './modules/menus'
import {networkEpics} from './modules/network'
import {productEpics} from './modules/products'
import {recipeEpics} from './modules/recipes'
import {restaurantEpics} from './modules/restaurants'
import {userEpics} from './modules/users'
import {resizeEpic} from './modules/view'
import {websocketEpics} from './modules/websocket'

export const rootEpic = combineEpics(
  adminInterfaceEpics,
  businessEpics,
  businessPartnerEpics,
  coreEpics,
  dataGridEpics,
  dayPlanningViewEpics,
  exportsEpics,
  formEpics,
  menuEpics,
  modalEpics,
  networkEpics,
  productEditViewEpics,
  productEpics,
  recipeEditViewEpics,
  recipeEpics,
  recipeOrProductEpics,
  resizeEpic,
  restaurantEpics,
  searchAndAddEpics,
  searchEpics,
  userEpics,
  websocketEpics
)
