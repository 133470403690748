import styled from 'styled-components'

export const InfoBox = styled.div`
  ${p => p.theme.fonts.family.default};
  background-color: ${p => p.theme.colors.darkGray};
  box-sizing: border-box;
  color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  justify-content: center;
  line-height: 1.7em;
  margin-top: 20px;
  max-height: 110px;
  padding: 55px 10px;
  text-align: center;
  width: 306px;
`
