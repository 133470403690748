import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {setEditMode, setPreventBlur} from '../../../redux/modules/dataGrid'
import {updateSearchInput} from '../../../redux/modules/search'
import {
  selectSearchResults,
  selectTopSuggestion
} from '../../../redux/selectors/search'
import {generateStandardProduct} from '../../../utils/productUtils'
import HitRow from '../components/HitRow'
import {processHitRowClick} from '../epics/epics'

const mapStateToProps = (state, {t}) => {
  const {
    search: {searchInput, searchTypes},
    view: {hoveringOtherRow}
  } = state
  const {hasMultiplePerfectMatches, hasPerfectMatch} = selectSearchResults(
    state
  )
  const topSuggestion = selectTopSuggestion(state)
  const hitType = searchTypes.includes('menus') ? 'menu' : 'ingredient'

  return {
    hide:
      searchInput === '' ||
      (hasPerfectMatch && topSuggestion.type === 'product') ||
      hasMultiplePerfectMatches,
    hoveringOtherRow,
    index: 0,
    isFirst: true,
    productCollectionId:
      state.restaurants.selectedRestaurant.productCollectionId,
    result: {title: searchInput},
    subRowText: t('hitRow.pseudoHit.subRowText', {
      hitType: t(`hitRow.hitType.${hitType}`),
      title: searchInput
    })
  }
}

const mapDispatchToProps = {
  processHitRowClick,
  setEditMode,
  setPreventBlur,
  updateSearchInput
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClick: ({processHitRowClick, productCollectionId, result, t}) => () => {
      processHitRowClick({
        result: generateStandardProduct({
          title: result.title,
          productCollectionId
        }),
        t
      })
    }
  })
)(HitRow)
