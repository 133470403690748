import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React, {createRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Button from '../../core/components/Button'
import UserSearchResultsContainer from '../containers/UserSearchResultsContainer'
import AdminOptionsList from './AdminOptionsList'
import AdminSearchBar from './AdminSearchBar'
import AdminViewLeftColumn from './AdminViewLeftColumn'
import AdminViewRightColumnGrid from './AdminViewRightColumnGrid'
import ButtonAndTextPanel from './ButtonAndTextPanel'

const UserAdminContentBase = styled.div`
  display: grid;
  grid-template-columns:
    ${p => p.theme.sizes.adminLeftColumnWidth}px
    minmax(
      300px,
      ${p => p.theme.sizes.maxWidth - p.theme.sizes.adminLeftColumnWidth}px
    );
  grid-template-rows: calc(
    100vh - ${p => p.theme.sizes.headerHeight + p.theme.sizes.footerHeight}px
  );
`

const SearchList = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-sizing: border-box;
  padding: 25px 60px;
`

const UserAdminContent = ({
  defaultRestaurantSelected,
  editedRestaurant,
  getRestaurantUsers,
  location: {pathname},
  searchInput,
  selectedRestaurant,
  setSearchTypes,
  showAddOrEditUserModal,
  updateSearchInput
}) => {
  const [t] = useTranslation()

  useEffect(() => {
    getRestaurantUsers()
    setSearchTypes(['users'])
  }, [editedRestaurant, getRestaurantUsers, selectedRestaurant, setSearchTypes])

  const textInputRef = createRef()

  const adminOptions =
    pathname === '/settings'
      ? [
          {text: t('userAdmin.userAdministration')},
          {text: t('userAdmin.menuPlanConfiguration')}
        ]
      : [{text: t('userAdmin.userAdministration')}]

  return (
    <UserAdminContentBase>
      <AdminViewLeftColumn>
        <ButtonAndTextPanel />
        <AdminOptionsList options={adminOptions} />
      </AdminViewLeftColumn>
      <AdminViewRightColumnGrid>
        <AdminSearchBar
          placeholderText={upperFirst(t('userAdmin.searchPlaceholder'))}
          searchInput={searchInput}
          textInputRef={textInputRef}
          updateSearchInput={updateSearchInput}
        />
        <SearchList>
          {
            <Button primary huge fluid onClick={() => showAddOrEditUserModal()}>
              Add User
            </Button>
          }
        </SearchList>
        <UserSearchResultsContainer />
      </AdminViewRightColumnGrid>
    </UserAdminContentBase>
  )
}

UserAdminContent.propTypes = {
  defaultRestaurantSelected: PropTypes.bool.isRequired,
  editedRestaurant: PropTypes.object,
  getRestaurantUsers: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  searchInput: PropTypes.string.isRequired,
  selectedRestaurant: PropTypes.object.isRequired,
  setSearchTypes: PropTypes.func.isRequired,
  showAddOrEditUserModal: PropTypes.func.isRequired,
  updateSearchInput: PropTypes.func.isRequired
}

export default UserAdminContent
