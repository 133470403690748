import Button from '../../core/components/Button'
import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const ButtonAndTextPanelBase = styled.div`
  align-self: start;
  border-right: ${p => p.theme.border.default};
  box-sizing: border-box;
  border-bottom: ${p => p.theme.border.default};
  padding: 5px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`
const Text = styled.p`
  ${p => p.theme.fonts.family.default};
  color: ${p => p.theme.colors.darkerGray};
  font-size: ${p => p.theme.fonts.size.small}px;
  font-weight: ${p => p.theme.fonts.weight.thin}px;
  text-align: center;
`

const ButtonAndTextPanel = ({buttonText, onClick, text}) => (
  <ButtonAndTextPanelBase>
    {buttonText && (
      <Button onClick={onClick} primary fluid huge>
        {buttonText}
      </Button>
    )}
    {text && (
      <Text center info>
        {text}
      </Text>
    )}
  </ButtonAndTextPanelBase>
)

ButtonAndTextPanel.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string
}

export default ButtonAndTextPanel
