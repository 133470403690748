import {
  compose,
  curry,
  filter,
  flatten,
  groupBy,
  head,
  map,
  omit,
  pipe,
  prop,
  reduce,
  sort,
  sortBy,
  toLower,
  values
} from 'ramda'
import {generateDuplicateRecipeData} from '../modules/searchAndAdd/utils/utils'
import {extractComponentTitles} from './compositeUtils'

export const sortByTitle = results =>
  sortBy(
    compose(
      toLower,
      prop('title')
    ),
    results
  )

export const groupAndLabelByFirstLetter = results => {
  if (!results || Object.keys(results).length === 0) {
    return []
  }

  return Object.values(
    results.reduce((p, c) => {
      const label = c.title[0].toLowerCase()
      if (!p[label]) {
        p[label] = {label, results: [c]}
      } else {
        p[label].results = [...p[label].results, c]
      }
      return p
    }, {})
  )
}

export const groupByFirstLetter = groupBy(r => r.title[0].toLowerCase())

export const sortResults = curry((searchInput, searchResults) => {
  const searchInputFirstLetter = searchInput[0]
  const groupedSearchResults = groupByFirstLetter(searchResults)
  const firstLetterGroup = groupedSearchResults[searchInputFirstLetter] || []
  const getResultsWithoutFirstLetterGroup = pipe(
    omit([searchInputFirstLetter]),
    values,
    reduce((p, c) => [...p, ...c], [])
  )
  const searchResultsWithoutFirstLetterGroup = getResultsWithoutFirstLetterGroup(
    groupedSearchResults
  )

  return [
    ...sortByTitle(firstLetterGroup),
    ...sortByTitle(searchResultsWithoutFirstLetterGroup)
  ]
})

export const sortByUpdatedAt = sort(
  (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
)

export const pickUpdatedLatest = pipe(
  sortByUpdatedAt,
  head
)

export const selectPermanentOrLatest = curry((type, searchResults) => {
  const searchResultsOfOtherType = searchResults.filter(r => r.type !== type)
  const typeOnly = searchResults.filter(r => r.type === type)

  const pickLatestPerTitle = pipe(
    groupBy(r => r.title),
    values,
    map(pickUpdatedLatest)
  )

  const typeOnlyGroupedByPermanent = groupBy(r => r.permanent, typeOnly)
  const permanents = typeOnlyGroupedByPermanent['true'] || []
  const nonPermanents = typeOnlyGroupedByPermanent['false'] || []

  const latestPermanents = pickLatestPerTitle(permanents)
  const latestPermanentsTitles = latestPermanents.map(p => p.title)
  const latestNonPermanentsWithoutPermanentCopy = pickLatestPerTitle(
    nonPermanents
  ).filter(r => !latestPermanentsTitles.includes(r.title))

  return [
    ...searchResultsOfOtherType,
    ...latestPermanents,
    ...latestNonPermanentsWithoutPermanentCopy
  ]
})

export const selectPermanentOrLatestRecipes = selectPermanentOrLatest('recipe')

export const selectPermanentOrLatestProducts = selectPermanentOrLatest(
  'product'
)

export const prepareForBasicSearch = (components, searchResults) => {
  const getRealDuplicateRecipes = pipe(
    filter(r => r.type === 'recipe'),
    groupBy(r => r.title),
    values,
    map(generateDuplicateRecipeData),
    map(({duplicateRecipes}) => duplicateRecipes),
    flatten
  )

  const resultsWithoutRecipes = searchResults.filter(r => r.type !== 'recipe')
  const realDuplicateRecipesOnly = getRealDuplicateRecipes(searchResults)

  return map(extractComponentTitles(components), [
    ...resultsWithoutRecipes,
    ...realDuplicateRecipesOnly
  ])
}
