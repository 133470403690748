import PropTypes from 'prop-types'
import React, {createRef} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {IconMinus, IconPlus} from '../../../assets/icons'
import Button from './Button'
import ButtonGroup from './ButtonGroup'

const CountSetterBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  @media print {
    display: none;
  }
`

const CountInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  ${p => p.theme.fonts.family.default};
  border: ${p => p.theme.border.default};
  line-height: 20px;
  padding-top: 9px;
  padding-right: 30px;
  padding-left: 0px;
  margin-left: 0px;
  margin-right: ${p => p.spacing};
  padding-bottom: 9px;
  border-radius: 4px;
  font-size: 18px;
  letter-spacing: 1px;
  width: auto;
  color: ${p => p.theme.colors.darkGray};
  margin-right: 25px;
`

const CountInput = styled.input`
  border: ${p => p.theme.border.default};
  border-color: ${p => p.theme.colors.white};
  outline: none;
  color: ${p => p.theme.colors.darkGray};
  width: 40px;
  padding-left: 10px;
  padding-right: 4px;
  text-align: right;
  font-family: ${p => p.theme.fonts.family.default};
  font-size: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 4px;

  &:focus {
    border: ${p => p.theme.border.default};
    border-color: ${p => p.theme.colors.blue};
    border-radius: ${p => p.theme.border.radius.default}px;
  }
`

const CountIconWrapper = styled.div`
  position: relative;
  width: 22px;
  height: 31px;
`

const CountIcon = styled.img`
  position: relative;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const CountSetterButtonGroup = styled(ButtonGroup)`
  display: flex;
`

const CountSetterButton = styled(Button)`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 14px;
  padding-top: 14px;
`

const CountSetter = ({
  className,
  descriptionTranslateKey,
  onMinusClick,
  onPlusClick,
  count,
  spacing,
  onChange
}) => {
  const [t] = useTranslation()
  const inputToFocus = createRef()

  return (
    <CountSetterBase className={className}>
      <CountInputWrapper
        onClick={() => {
          inputToFocus.current.focus()
          inputToFocus.current.select()
        }}
        spacing={spacing}
      >
        <CountInput
          id="CountSetterInput"
          ref={inputToFocus}
          onChange={({target: {value}}) => onChange(value)}
          readOnly={!onChange}
          value={count}
        />
        {t(`countSetter.${descriptionTranslateKey}`, {count})}
      </CountInputWrapper>
      <CountSetterButtonGroup>
        <CountSetterButton primary big onClick={() => onMinusClick()}>
          <CountIconWrapper id="CountSetterMinus">
            <CountIcon draggable={false} src={IconMinus} />
          </CountIconWrapper>
        </CountSetterButton>
        <CountSetterButton primary big onClick={() => onPlusClick()}>
          <CountIconWrapper id="CountSetterPlus">
            <CountIcon draggable={false} src={IconPlus} />
          </CountIconWrapper>
        </CountSetterButton>
      </CountSetterButtonGroup>
    </CountSetterBase>
  )
}

CountSetter.propTypes = {
  className: PropTypes.string,
  descriptionTranslateKey: PropTypes.string,
  onChange: PropTypes.func,
  onMinusClick: PropTypes.func.isRequired,
  onPlusClick: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  spacing: PropTypes.string
}

export default CountSetter
