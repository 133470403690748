import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {IconConfirm} from '../../../assets/icons'
import {InfoBox} from './InfoBox'
import LinkLine from './LinkLine'
import RegLogBase from './RegLogBase'
import RegLogContent from './RegLogContent'
import RegLogHeader from './RegLogHeader'
import RegLogLogo from './RegLogLogo'
import RegLogText from './RegLogText'

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ConfirmImage = styled.img`
  height: 32px;
  margin: 15px 10px 10px 10px;
`

const ContentWrapper = styled.div``

const Verify = ({redirect, verify}) => {
  const [t] = useTranslation()
  useEffect(() => {
    verify()
  }, [verify])

  return (
    <RegLogBase>
      <ContentWrapper>
        <RegLogContent>
          <RegLogLogo />
          <RegLogHeader>{t('verify.header')}</RegLogHeader>
          <RegLogText width="340">{t('verify.subHeaderText')}</RegLogText>
          <ColumnDiv>
            <InfoBox>
              {t('verify.emailConfirmedText')}
              <br />
              <ConfirmImage src={IconConfirm} />
            </InfoBox>
            <LinkLine
              linkText={t('verify.forwardingLinkText')}
              onClick={redirect}
              text={t('verify.forwardingText')}
            />
          </ColumnDiv>
        </RegLogContent>
      </ContentWrapper>
    </RegLogBase>
  )
}

Verify.propTypes = {
  redirect: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired
}

export default Verify
