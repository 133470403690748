import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {closeAdminDrawer} from '../../../redux/modules/admin'
import {
  closeAllSettingsModals,
  openBillingDetailsModal,
  openLogoutWarningModal,
  openRestaurantSettingsModal,
  openUserSettingsModal,
  setModalType,
  showBuyLicenseModal,
  showExportsModal
} from '../../../redux/modules/modal'
import {saveToLocalStorage} from '../../../utils/localStorage'
import {getLicenseType} from '../../../utils/restaurantUtils'
import AdminDrawer from '../components/AdminDrawer'
import {processAdminItemClick} from '../epics/epics'
import {openSettingsModal} from '../../../redux/modules/modal'

const mapStateToProps = ({
  config: {webEntryDomain},
  admin: {adminDrawerOpen},
  businesses: {selectedBusiness},
  businessPartners: {businessPartnerName},
  restaurants: {editedRestaurant, restaurants, selectedRestaurant},
  users: {user}
}) => ({
  businessPartnerName,
  editedRestaurant,
  isOpen: adminDrawerOpen,
  licensePurchased:
    selectedRestaurant && selectedRestaurant.licenses.licensePurchased
      ? true
      : false,
  licenseType: getLicenseType(webEntryDomain, selectedRestaurant.licenses),
  numberOfRestaurants: Object.values(restaurants).length,
  selectedBusiness,
  selectedRestaurant,
  user
})

const mapDispatchToProps = {
  closeAdminDrawer,
  closeAllSettingsModals,
  onExportsClick: showExportsModal,
  openLogoutWarningModal,
  onBillingDetailsClick: openBillingDetailsModal,
  onRestaurantSettingsClick: openRestaurantSettingsModal,
  onSettingsClick: openSettingsModal,
  onUserSettingsClick: openUserSettingsModal,
  processAdminItemClick,
  setModalType,
  showBuyLicenseModal
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onCloseClick: ({closeAdminDrawer}) => () => {
      saveToLocalStorage('adminDrawerOpen', false)
      closeAdminDrawer()
    },
    onLogoutClick: ({openLogoutWarningModal, setModalType}) => () => {
      setModalType('logoutWarning')
      openLogoutWarningModal()
    }
  })
)(AdminDrawer)
