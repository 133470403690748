import round from 'lodash/round'
import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React, {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import EnergyRow from '../../indicators/components/EnergyRow'
import IndicatorGroupContainer from '../../indicators/containers/IndicatorGroupContainer'
import RainforestRowContainer from '../../indicators/containers/RainforestRowContainer'
import Button from './Button'
import FactRow from './FactRow'
import RowHeader from './RowHeader'

const IndicatorsBase = styled.div`
  ${p => p.theme.fonts.family.default};
`

const DeleteButton = styled(Button)`
  margin-top: 0;
`

const CompositeFactsBody = ({
  onDeleteRecipeClick,
  onFactRowClick,
  composite,
  indicatorData,
  showServings,
  visibleIndicators
}) => {
  const [t] = useTranslation()

  if (!composite) {
    return null
  }

  const cloudIsAvailable =
    String(composite.serviceStatus.cloudStatus)[0] === '2'
  const matchingStatusOk =
    String(composite.serviceStatus.matchingStatus)[0] !== '6'

  const indicatorMap = {
    co2: {
      indicatorType: 'co2',
      labelFirstRow: t('compositeFactsBody.co2Label'),
      nextView: {
        view: 'indicatorDetails',
        indicatorTypeId: 'co2'
      },
      render: function co2ScoreRow() {
        return <IndicatorGroupContainer indicatorType="co2" />
      },
      showIconRight: true
    },
    health: {
      indicatorType: 'health',
      labelFirstRow: t('compositeFactsBody.healthLabel'),
      nextView: {
        view: 'indicatorDetails',
        indicatorTypeId: 'health'
      },
      render: function healthRow() {
        return <IndicatorGroupContainer indicatorType="health" />
      },
      showIconRight: true
    },
    water: {
      indicatorType: 'water',
      labelFirstRow: t('compositeFactsBody.waterLabel'),
      nextView: {
        view: 'indicatorDetails',
        indicatorTypeId: 'water'
      },
      render: function waterRow() {
        return <IndicatorGroupContainer indicatorType="water" />
      },
      showIconRight: true
    },
    rainforestLabel: {
      indicatorType: 'rainforestLabel',
      labelFirstRow: t('compositeFactsBody.rainforestLabel'),
      render: function rainforestRow() {
        return <RainforestRowContainer indicatorType="rainforestLabel" />
      },
      showIconRight: false
    }
  }

  const data = [
    {
      labelFirstRow: t('compositeFactsBody.energyLabel'),
      render: function energyRow() {
        return (
          <EnergyRow
            nutritionalBalanced={
              cloudIsAvailable &&
              matchingStatusOk &&
              composite.nutritionalBalanced
            }
            kcalPerServing={
              cloudIsAvailable && matchingStatusOk && composite.kcalPerServing
                ? String(composite.kcalPerServing)
                : '0'
            }
          />
        )
      },
      showIconRight: false
    },
    ...visibleIndicators.map(indicatorType => indicatorMap[indicatorType]),
    {
      labelFirstRow: upperFirst(t('factRows.weight.label')),
      id: 'ComponentDetailsAmountRow',
      render: function gramsPerServingRow() {
        return `${
          indicatorData.gramsPerServing
            ? round(indicatorData.gramsPerServing, 2)
            : 0
        } g`
      }
    }
  ]

  return (
    <IndicatorsBase>
      <RowHeader>{t('factRows.headerText')}</RowHeader>
      {data.map(
        (
          {indicatorType, labelFirstRow, nextView, render, showIconRight},
          index
        ) => (
          <Fragment key={index}>
            <FactRow
              isLast={index === data.length - 1}
              onClick={() =>
                cloudIsAvailable &&
                matchingStatusOk &&
                onFactRowClick({indicatorType, nextView})
              }
              label={labelFirstRow}
              render={render}
              iconRightType="arrow"
              showIconRight={
                showIconRight &&
                indicatorData[indicatorType].score > 0 &&
                cloudIsAvailable &&
                matchingStatusOk
              }
            />
          </Fragment>
        )
      )}
      <RowHeader>{t('compositeFactsBody.additionalSpecsHeader')}</RowHeader>

      {showServings ? (
        <FactRow
          key={data.length + 1}
          onClick={() => onFactRowClick({nextView: {view: 'menuServingsView'}})}
          label={t('compositeFactsBody.specsForLabel')}
          render={() =>
            t('compositeFactsBody.specsForValue', {count: composite.servings})
          }
          isLast={false}
          iconRightType="arrow"
          showIconRight
        />
      ) : null}
      <FactRow
        key={data.length + 2}
        onClick={() =>
          onFactRowClick({nextView: {view: 'selectCompositeImage'}})
        }
        label="Bild"
        render={() => {
          if (!composite.imageUrl) {
            return null
          }
          const splitImageUrl = composite.imageUrl.split('/')
          const imageName = splitImageUrl[splitImageUrl.length - 1]
          const shortenedImageName =
            imageName.length > 18 ? `${imageName.slice(0, 15)}...` : imageName

          return shortenedImageName
        }}
        isLast={true}
        iconRightType="arrow"
        showIconRight
      />
      {composite.type === 'recipe' && composite.permanent && (
        <DeleteButton
          data-cy="recipeDeleteButton"
          fluid
          onClick={onDeleteRecipeClick}
          primary
          big
        >
          {t('compositeFactsBody.deleteButtonText')}
        </DeleteButton>
      )}
    </IndicatorsBase>
  )
}

CompositeFactsBody.propTypes = {
  composite: PropTypes.object,
  indicatorData: PropTypes.object,
  onDeleteRecipeClick: PropTypes.func.isRequired,
  onFactRowClick: PropTypes.func.isRequired,
  showServings: PropTypes.bool,
  visibleIndicators: PropTypes.array.isRequired
}

export default CompositeFactsBody
