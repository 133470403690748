import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Link from '../../core/components/Link'
import RegLogText from './RegLogText'

const RegLogNotReceivedTextBase = styled(RegLogText)`
  margin-top: 25px;
  margin-bottom: 41px;
`

const RegLogNotReceivedText = ({reset, t}) => (
  <RegLogNotReceivedTextBase>
    {t('logReg.notReceivedText')}
    <Link onClick={reset}>{t('logReg.notReceivedLinkText')}</Link>
  </RegLogNotReceivedTextBase>
)

RegLogNotReceivedText.propTypes = {
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default RegLogNotReceivedText
