import moment from 'moment'
import {createAction, createReducer} from 'redux-act'
import {
  loadFromLocalStorage,
  saveToLocalStorage
} from '../../utils/localStorage'

const persistedDate = loadFromLocalStorage('selectedDate')

const _moment = persistedDate ? moment(persistedDate) : moment()

const currentDate = _moment
  .utc()
  .startOf('day')
  .toDate()

export const initialStateDate = {
  dayPickerToolTipDate: currentDate,
  selectedDate: currentDate
}

export const setSelectedDate = createAction('date/SET_SELECTED_DATE')
export const setDayPickerToolTipDate = createAction(
  'date/SET_DAYPICKER_TOOL_TIP_DATE'
)

const reducer = createReducer(
  {
    [setSelectedDate]: (state, payload) => {
      saveToLocalStorage('selectedDate', payload)
      return {
        ...state,
        selectedDate: payload
      }
    },
    [setDayPickerToolTipDate]: (state, payload) => ({
      ...state,
      dayPickerToolTipDate: payload
    })
  },
  initialStateDate
)

export default reducer
