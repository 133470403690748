import {connect} from 'react-redux'
import {
  getAllRestaurants,
  getBusinessRestaurants,
  getUserRestaurants
} from '../../../redux/modules/restaurants'
import {setSearchTypes, updateSearchInput} from '../../../redux/modules/search'
import RestaurantAdminContent from '../components/RestaurantAdminContent'
import {processAddRestaurantClick} from '../epics/epics'

const mapStateToProps = ({
  businesses: {selectedBusiness},
  network: {getUserRestaurantsSucceeded},
  search: {searchInput},
  users: {
    user: {defaultBusinessId, email: userEmail, isBusinessPartnerAdmin}
  }
}) => ({
  defaultBusinessSelected:
    selectedBusiness !== undefined && selectedBusiness.id === defaultBusinessId,
  getUserRestaurantsSucceeded,
  isBusinessPartnerAdmin: !!isBusinessPartnerAdmin,
  isSuperAdmin: userEmail.endsWith('@eaternity.ch'),
  selectedBusiness,
  searchInput
})

const mapDispatchToProps = {
  getAllRestaurants,
  getBusinessRestaurants,
  getUserRestaurants,
  onAddRestaurantClick: processAddRestaurantClick,
  setSearchTypes,
  updateSearchInput
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestaurantAdminContent)
