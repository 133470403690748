import {replaceAtIndex} from '../../../utils/utils'

export const updateInstructionField = ({
  editedInstructionIndex,
  field,
  newValue,
  recipe
}) => {
  const intructionToUpdate = recipe.instructions[editedInstructionIndex]
  const updatedIntructions = replaceAtIndex(
    recipe.instructions,
    editedInstructionIndex,
    {...intructionToUpdate, [field]: newValue}
  )
  const recipeWithUpdatedInstructions = {
    ...recipe,
    instructions: updatedIntructions
  }

  return recipeWithUpdatedInstructions
}
