import styled from 'styled-components'

export default styled.div`
  height: 16px;
  color: ${p => p.theme.colors.darkGray};
  ${p => p.theme.fonts.family.default};
  font-size: 13.5px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: 3px;
  margin-bottom: 6px;
  border-bottom: ${p => p.underlined && p.theme.border.default};
`
