import {
  startShowBuyLicenseModalInterval,
  stopShowBuyLicenseModalInterval
} from 'eaternity-core/build/modules/modal/epics/epics'
import {showBuyLicenseModal} from 'eaternity-core/build/redux/modules/modal'
import {checkServerStatus} from 'eaternity-core/build/redux/modules/network'
import {setResizing} from 'eaternity-core/build/redux/modules/view'
import {
  startEnrichStream,
  startSyncStream,
  stopEnrichStream,
  stopSyncStream,
  subscribeToAdminSocket,
  unsubscribeFromAdminSocket
} from 'eaternity-core/build/redux/modules/websocket'
import {selectDaysUntilLicenseExpiry} from 'eaternity-core/build/redux/selectors/selectors'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import App from '../components/App'

const mapStateToProps = state => ({
  daysUntilLicenseExpiry: selectDaysUntilLicenseExpiry(state)
})

const mapDispatchToProps = {
  checkServerStatus,
  setResizing,
  showBuyLicenseModal,
  startEnrichStream,
  startShowBuyLicenseModalInterval,
  startSyncStream,
  stopEnrichStream,
  stopShowBuyLicenseModalInterval,
  stopSyncStream,
  subscribeToAdminSocket,
  unsubscribeFromAdminSocket
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App)
