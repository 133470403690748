import {compose, withHandlers, withState} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {deleteMenu} from '../../../redux/modules/menus'
import {
  processComponentAmount,
  processComponentTitleKeyDown,
  processComponentUnit,
  replaceComponent
} from '../epics/epics'
import {
  resetSelectedComponent,
  setSelectedComponent,
  setEditedComponentIndex
} from '../../../redux/modules/components'
import {selectComponentToAdd} from '../../../redux/selectors/componentSelectors'
import {
  selectGenerated,
  selectCompositeByIdFromUrl,
  selectRecipeOrProduct
} from '../../../redux/selectors/selectors'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {setSearchTypes, updateSearchInput} from '../../../redux/modules/search'
import {unitConversionMapForward} from '../../../utils/componentUtils'
import SectionRight from '../components/SectionRight'
import {setEditMode, setPreventBlur} from '../../../redux/modules/dataGrid'

const mapStateToProps = (
  state,
  {componentIndex, composite: passedComposite}
) => {
  const component = passedComposite.components[componentIndex]
  const editedComponentIndex = state.components.editedComponentIndex
  const recipeOrProduct = selectRecipeOrProduct(state, component.component.id)
  const composite = selectCompositeByIdFromUrl(state)
  const isEditedComponent = componentIndex === editedComponentIndex
  const isEditedComposite = composite
    ? composite.id === passedComposite.id
    : false

  return {
    amount: String(component.quantity.amount),
    component,
    componentToAdd: selectComponentToAdd(state),
    composite,
    currentComponent: {
      ...component.component,
      title: recipeOrProduct ? recipeOrProduct.title : '',
      permanent: recipeOrProduct ? recipeOrProduct.permanent : false
    },
    editedComponentIndex,
    editMode: isEditedComposite && isEditedComponent && state.dataGrid.editMode,
    generated: selectGenerated(state),
    isEditedComponent,
    isEditedComposite,
    preventBlur: state.dataGrid.preventBlur,
    tempValue: state.search.searchInput,
    unit: unitConversionMapForward[component.quantity.unit],
    // TODO: There will come default unitChoices from the server per
    // FoodServiceProduct
    unitOptions: ['g', 'kg', 'ml', 'l'],
    value: recipeOrProduct ? recipeOrProduct.title : ''
  }
}

const mapDispatchToProps = {
  deleteMenu,
  processComponentAmount,
  processComponentTitleKeyDown,
  processComponentUnit,
  replaceComponent,
  resetSelectedComponent,
  setDayPlanningPopOverContentType,
  setEditedComponentIndex,
  setEditMode,
  setPreventBlur,
  setSearchTypes,
  setSelectedComponent,
  updateSearchInput
}

const ComponentInputContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('editingAmount', 'setEditingAmount', false),
  withState('tempAmount', 'updateTempAmount', ''),
  withHandlers({
    onFocus: ({
      component: {component},
      componentIndex,
      resetSelectedComponent,
      setEditedComponentIndex,
      setEditMode,
      setDayPlanningPopOverContentType,
      setSelectedComponent,
      setPreventBlur,
      setSearchTypes,
      updateSearchInput,
      value
    }) => () => {
      if (value === '') {
        resetSelectedComponent()
      } else {
        setSelectedComponent(component)
      }
      setPreventBlur(false)
      setEditedComponentIndex(componentIndex)
      updateSearchInput(value)
      setEditMode(true)
      setSearchTypes(['products', 'recipes'])
      setDayPlanningPopOverContentType('search')
    },
    onComponentTitleChange: ({
      setDayPlanningPopOverContentType,
      resetSelectedComponent,
      updateSearchInput
    }) => ({target: {value}}) => {
      resetSelectedComponent()
      setDayPlanningPopOverContentType('search')
      updateSearchInput(value)
    },
    onComponentTitleBlur: ({
      componentToAdd,
      composite,
      currentComponent,
      editedComponentIndex,
      replaceComponent,
      setEditMode,
      preventBlur
    }) => event => {
      if (preventBlur) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        if (editedComponentIndex !== undefined) {
          // in some strange cases editedComponentIndex is undefined (not sure why), and below code would fail
          replaceComponent({
            addRow: false,
            componentToAdd,
            currentComponent,
            componentIndex: editedComponentIndex,
            composite
          })
        }
        setEditMode(false)
      }
    },
    onComponentTitleKeyDown: ({
      currentComponent,
      componentIndex,
      componentToAdd,
      composite,
      processComponentTitleKeyDown,
      setEditMode
    }) => ({keyCode}) => {
      if (keyCode === 13) {
        processComponentTitleKeyDown({
          addRow: componentIndex === composite.components.length - 1,
          componentToAdd,
          currentComponent,
          componentIndex,
          composite
        })
        setEditMode(false)
      }
    },
    onAmountBlur: ({
      amount,
      componentIndex,
      composite,
      processComponentAmount,
      setEditingAmount
    }) => ({target: {value}}) => {
      processComponentAmount({
        componentIndex,
        oldValue: amount,
        newValue: value,
        composite
      })
      setEditingAmount(false)
    },
    onAmountChange: ({updateTempAmount}) => ({target: {value}}) => {
      updateTempAmount(value)
    },
    onAmountFocus: ({
      amount,
      componentIndex,
      setEditedComponentIndex,
      setDayPlanningPopOverContentType,
      setEditingAmount,
      updateTempAmount
    }) => ({target}) => {
      target.select()
      updateTempAmount(amount)
      setEditingAmount(true)
      setDayPlanningPopOverContentType('compositeDetails')
      setEditedComponentIndex(componentIndex)
    },
    onUnitFocus: ({
      componentIndex,
      setEditedComponentIndex,
      setDayPlanningPopOverContentType
    }) => () => {
      setDayPlanningPopOverContentType('compositeDetails')
      setEditedComponentIndex(componentIndex)
    },
    onUnitChange: ({componentIndex, composite, processComponentUnit}) => ({
      target: {value}
    }) => {
      processComponentUnit({
        componentIndex,
        value,
        composite
      })
    }
  })
)(SectionRight)

export default ComponentInputContainer
