import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

import {
  selectCompositeByIdFromUrl,
  selectProductByIdFromUrl
} from '../../../redux/selectors/selectors'
import {selectSelectedComponent} from '../../../redux/selectors/componentSelectors'
import HeaderRows from '../components/HeaderRows'

const mapStateToProps = state => {
  const composite = selectCompositeByIdFromUrl(state)
  const selectedComponent = selectSelectedComponent(state)
  const product = selectProductByIdFromUrl(state)

  return {
    selectedComponent,
    compositeTitle: composite ? composite.title : product ? product.title : '',
    compositeType: composite ? composite.type : 'recipe'
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(HeaderRows)
