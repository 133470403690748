import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Link from '../../core/components/Link'
import {TextStyle} from '../../core/components/Text'

const HavingTroubleTextBase = styled.div`
  ${() => TextStyle};
  font-size: ${p => p.theme.fonts.size.small}px;
  font-weight: ${p => p.theme.fonts.weight.thin};
  line-height: 1.5em;
  text-align: center;
  height: 20px;
`

const HavingTroubleText = ({t, webEntryDomain}) => {
  const mailToMap = {
    eaternity: 'info@eaternity.ch',
    klimateller: 'hallo@klimateller.de'
  }

  return (
    <HavingTroubleTextBase>
      {t('logReg.havingTroubleText')}
      <br />
      <Link href={`mailto:${mailToMap[webEntryDomain]}`}>
        {t('logReg.havingTroubleLinkText')}
      </Link>
    </HavingTroubleTextBase>
  )
}

HavingTroubleText.propTypes = {
  t: PropTypes.func.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default HavingTroubleText
