import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {setSelectedIndicatorType} from '../../../redux/modules/indicators'
import {goToView} from '../../../redux/modules/viewPagerNavigator'
import {selectIndicatorData} from '../../../redux/selectors/indicatorSelectors'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import CompositeFactsBody from '../components/CompositeFactsBody'
import {processDeleteRecipeClick} from '../epics/epics'

const mapStateToProps = (state, {showServings}) => {
  const composite = selectCompositeByIdFromUrl(state)

  return {
    composite,
    indicatorData: selectIndicatorData(state),
    showServings:
      composite && composite.type !== 'recipe' && showServings ? true : false,
    visibleIndicators: state.config.visibleIndicators
  }
}

const mapDispatchToProps = {
  goToView,
  processDeleteRecipeClick,
  setSelectedIndicatorType
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDeleteRecipeClick: ({composite, processDeleteRecipeClick}) => () => {
      processDeleteRecipeClick(composite)
    },
    onFactRowClick: ({goToView, setSelectedIndicatorType}) => ({
      indicatorType,
      nextView
    }) => {
      setSelectedIndicatorType(indicatorType)
      if (nextView) {
        goToView(nextView)
      }
    }
  })
)(CompositeFactsBody)
