import {v4} from 'uuid'
import {
  loadFromLocalStorage,
  loadSelectedRestaurant,
  loadUserInfo
} from '../utils/localStorage'

let windowId = sessionStorage.getItem('windowId')

if (!windowId) {
  const _windowId = v4()
  sessionStorage.setItem('windowId', _windowId)
  windowId = _windowId
}

const generateHeaders = ({
  selectedBusinessId,
  selectedRestaurantId,
  webEntryDomain
}) => {
  const persistedUserInfo = loadUserInfo()

  return {
    'Content-Type': 'application/json',
    jwt: persistedUserInfo ? persistedUserInfo.authToken : undefined,
    locale: window.__localeId__ ? window.__localeId__.split('-')[0] : 'en',
    selectedbusinessid: selectedBusinessId
      ? selectedBusinessId
      : loadFromLocalStorage('selectedBusiness')
      ? loadFromLocalStorage('selectedBusiness').id
      : undefined,
    selectedrestaurantid: selectedRestaurantId
      ? selectedRestaurantId
      : loadSelectedRestaurant()
      ? loadSelectedRestaurant().id
      : undefined,
    webentrydomain: webEntryDomain,
    windowid: windowId
  }
}

export {generateHeaders}
