import React from 'react'
import styled from 'styled-components'

import CompositeFactsBodyContainer from '../../core/containers/CompositeFactsBodyContainer'
import CompositeFactsHeaderContainer from '../../core/containers/CompositeFactsHeaderContainer'
import PopOverBody from '../../popover/components/PopOverBody'

const CompositeDetailsBase = styled.div``

const CompositeDetails = () => (
  <CompositeDetailsBase>
    <CompositeFactsHeaderContainer showEditButton={false} />
    <PopOverBody>
      <CompositeFactsBodyContainer showServings />
    </PopOverBody>
  </CompositeDetailsBase>
)

export default CompositeDetails
