import React from 'react'
import LayoutContentRight from '../../core/components/LayoutContentRight'
import FooterContainer from '../../core/containers/FooterContainer'
import LayoutWithAdminDrawerContainer from '../../core/containers/LayoutWithAdminDrawerContainer'
import AdminHeaderContainer from '../containers/AdminHeaderContainer'
import UserAdminContentContainer from '../containers/UserAdminContentContainer'

const RestaurantAdmin = () => (
  <LayoutWithAdminDrawerContainer
    renderContentRight={() => (
      <LayoutContentRight
        renderFooter={() => <FooterContainer />}
        renderHeader={() => <AdminHeaderContainer />}
        renderMain={() => <UserAdminContentContainer />}
      />
    )}
  />
)

export default RestaurantAdmin
