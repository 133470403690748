import BusinessPartnerAdmin from 'eaternity-core/build/modules/admin/components/BusinessPartnerAdmin'
import OrganisationAdmin from 'eaternity-core/build/modules/admin/components/OrganisationAdmin'
import RestaurantAdmin from 'eaternity-core/build/modules/admin/components/RestaurantAdmin'
import UserAdmin from 'eaternity-core/build/modules/admin/components/UserAdmin'
import NotFound from 'eaternity-core/build/modules/core/components/NotFound'
import UnlicensedContainer from 'eaternity-core/build/modules/core/containers/UnlicensedContainer'
import DayPlanningContainer from 'eaternity-core/build/modules/dayPlanningView/containers/DayPlanningContainer'
import LoginContainer from 'eaternity-core/build/modules/login/containers/LoginContainer'
import RegisterContainer from 'eaternity-core/build/modules/login/containers/RegisterContainer'
import VerifyContainer from 'eaternity-core/build/modules/login/containers/VerifyContainer'
import RecipeEditContainer from 'eaternity-core/build/modules/recipeEditView/containers/RecipeEditContainer'
import RecipeOrProductContainer from 'eaternity-core/build/modules/recipeOrProduct/containers/RecipeOrProductContainer'
import SearchViewContainer from 'eaternity-core/build/modules/search/containers/SearchViewContainer'
import WeekPlanningContainer from 'eaternity-core/build/modules/weekPlanningView/containers/WeekPlanningContainer'
import {restaurantLicenseCheck} from 'eaternity-core/build/utils/dateUtils'
import {getLicenseType as _getLicenseType} from 'eaternity-core/build/utils/restaurantUtils'
import PropTypes from 'prop-types'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Routes = ({
  isAuthenticated,
  isBusinessPartnerAdmin,
  selectedRestaurant,
  webEntryDomain
}) => {
  const isLicensed =
    selectedRestaurant !== undefined &&
    restaurantLicenseCheck(webEntryDomain, selectedRestaurant.licenses)
  const getLicenseType = _getLicenseType(webEntryDomain)

  return (
    <Switch>
      {!selectedRestaurant && null}

      <Route exact path="/login" render={() => <LoginContainer />} />
      <Route exact path="/register" render={() => <RegisterContainer />} />
      <Route exact path="/verify/:token" render={() => <VerifyContainer />} />

      {!isAuthenticated && <Route render={() => <Redirect to="/login" />} />}
      {!isLicensed && <Route render={() => <UnlicensedContainer />} />}

      <Route
        exact
        path="/"
        render={() => {
          const licenseTypeRedirectPathMap = {
            chef: '/search',
            eaternity: '/weekplanning'
          }
          const licenseType = getLicenseType(selectedRestaurant.licenses)
          const redirectPath = licenseTypeRedirectPathMap[licenseType]

          return <Redirect to={redirectPath} />
        }}
      />

      <Route
        exact
        path="/search"
        render={() => {
          const licenseType = getLicenseType(selectedRestaurant.licenses)
          return licenseType === 'eaternity' ? (
            <Redirect to="/" />
          ) : (
            <SearchViewContainer />
          )
        }}
      />
      <Route
        exact
        path="/dayplanning"
        render={() => {
          const licenseType = getLicenseType(selectedRestaurant.licenses)

          return licenseType === 'eaternity' ? (
            <DayPlanningContainer />
          ) : (
            <Redirect to="/" />
          )
        }}
      />
      <Route
        exact
        path="/weekplanning"
        render={() => {
          const licenseType = getLicenseType(selectedRestaurant.licenses)
          return licenseType === 'eaternity' ? (
            <WeekPlanningContainer />
          ) : (
            <Redirect to="/" />
          )
        }}
      />
      <Route exact path="/select" render={() => <RecipeOrProductContainer />} />
      <Route exact path="/recipes" render={() => <RecipeEditContainer />} />
      <Route
        exact
        path="/businesses"
        render={() => {
          return isBusinessPartnerAdmin ? <OrganisationAdmin /> : <NotFound />
        }}
      />
      <Route
        exact
        path="/business-partners"
        render={() => <BusinessPartnerAdmin />}
      />

      <Route exact path="/restaurants" render={() => <RestaurantAdmin />} />
      <Route exact path="/settings" render={() => <UserAdmin />} />
      <Route exact path="/users" render={() => <UserAdmin />} />

      <Route render={() => <NotFound />} />
    </Switch>
  )
}

Routes.propTypes = {
  isBusinessPartnerAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  selectedRestaurant: PropTypes.object,
  webEntryDomain: PropTypes.string.isRequired
}

export default Routes
