import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers, withState} from 'recompose'
import {
  resetSelectedComponent,
  setSelectedComponent
} from '../../../redux/modules/components'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import RecipeOrProductEditMain from '../components/RecipeOrProductEditMain'
import {openConfigureProductModal} from '../../../redux/modules/products'
import {selectProductByIdFromUrl} from '../../../redux/selectors/selectors'

const mapStateToPros = (state, {t}) => ({
  addOrConfigureButtonText:
    state.search.searchInput === ''
      ? t('recipeOrProductEditMain.specifyText')
      : t('recipeOrProductEditMain.addText'),
  location: state.router.location,
  product: selectProductByIdFromUrl(state)
})

const mapDispatchToProps = {
  openConfigureProductModal,
  resetSelectedComponent,
  setSelectedComponent,
  setDayPlanningPopOverContentType
}

export default compose(
  withRouter,
  withTranslation(),
  connect(
    mapStateToPros,
    mapDispatchToProps
  ),
  withState('editMode', 'setEditMode', false),
  withHandlers({
    onConfigureButtonClick: ({
      openConfigureProductModal,
      product,
      setSelectedComponent
    }) => () => {
      setSelectedComponent(product)
      openConfigureProductModal()
    }
  })
)(RecipeOrProductEditMain)
