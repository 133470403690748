import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import LayoutContentRight from '../../core/components/LayoutContentRight'
import PlaceHolder from '../../core/components/PlaceHolder'
import LayoutWithAdminDrawerContainer from '../../core/containers/LayoutWithAdminDrawerContainer'
import LoadingIndicatorModalContainer from '../../modal/containers/LoadingIndicatorModalContainer'
import WeekPlanningMain from '../../weekPlanningView/components/WeekPlanningMain'
import NavigationContainer from '../../core/containers/NavigationContainer'
import FooterContainer from '../../core/containers/FooterContainer'

const WeekPlanning = ({
  adminDrawerOpen,
  getAllDataSucceeded,
  refetchIfNeeded
}) => {
  useEffect(() => {
    refetchIfNeeded()
  }, [refetchIfNeeded])

  return getAllDataSucceeded ? (
    <LayoutWithAdminDrawerContainer
      renderContentRight={() => (
        <LayoutContentRight
          renderHeader={() => <NavigationContainer />}
          renderMain={() => (
            <WeekPlanningMain adminDrawerOpen={adminDrawerOpen} />
          )}
          renderPlaceHolderLeft={() => <PlaceHolder />}
          renderPlaceHolderRight={() => <PlaceHolder />}
          renderFooter={() => <FooterContainer />}
        />
      )}
    />
  ) : (
    <LoadingIndicatorModalContainer isOpen />
  )
}

WeekPlanning.propTypes = {
  adminDrawerOpen: PropTypes.bool.isRequired,
  getAllDataSucceeded: PropTypes.bool.isRequired,
  refetchIfNeeded: PropTypes.func.isRequired
}

export default WeekPlanning
