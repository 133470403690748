import {connect} from 'react-redux'
import {selectSearchResults} from '../../../redux/selectors/search'
import AdminSearchResults from '../components/AdminSearchResults'
import {processBusinessPartnerSearchItemClick} from '../epics/epics'

const mapStateToProps = state => {
  const {
    businessPartners: {businessPartners},
    search: {searchInput}
  } = state
  const {groupedSearchResults} = selectSearchResults(state)
  const allBusinessPartners = Object.values(businessPartners)

  return {
    results:
      searchInput === ''
        ? allBusinessPartners
        : groupedSearchResults.businessPartners
  }
}

const mapDispatchToProps = {
  onSearchHitRowClick: processBusinessPartnerSearchItemClick
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearchResults)
