import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {hideSearchModal} from '../../../redux/modules/modal'
import {
  hideWelcomeBoxAndRecipePreview,
  resetSearchInput,
  setSearchTypes,
  showWelcomeBoxAndRecipePreview,
  updateSearchInput
} from '../../../redux/modules/search'
import {getLicenseType} from '../../../utils/restaurantUtils'
import SearchBar from '../components/SearchBar'
import {processAddButtonClick} from '../epics/epics'

const mapStateToProps = ({
  config: {webEntryDomain},
  restaurants: {selectedRestaurant},
  router: {
    location: {pathname}
  },
  search: {searchInput, searchTypes}
}) => ({
  licenseType:
    selectedRestaurant !== undefined
      ? getLicenseType(webEntryDomain, selectedRestaurant.licenses)
      : '',
  recipeCollectionId:
    selectedRestaurant !== undefined && selectedRestaurant.recipeCollectionId,
  searchInput,
  searchTypes,
  pathname
})

const mapDispatchToProps = {
  hideSearchModal,
  hideWelcomeBoxAndRecipePreview,
  processAddButtonClick,
  resetSearchInput,
  setSearchTypes,
  showWelcomeBoxAndRecipePreview,
  updateSearchInput
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onKeyDown: ({
      processAddButtonClick,
      recipeCollectionId,
      searchInput,
      searchTypes,
      t
    }) => ({clearInputWithFilterState, inputToFocusRef, keyCode}) => {
      const searchTypeSelected = searchTypes.length === 1
      const selectedSearchType = searchTypeSelected ? searchTypes[0] : undefined

      if (keyCode === 27) {
        inputToFocusRef.current.blur()
      } else if (keyCode === 13 && selectedSearchType === 'recipes') {
        processAddButtonClick({
          recipeCollectionId,
          searchInput,
          compositeType: 'recipe',
          t
        })
      } else if (keyCode === 8 && searchInput === '') {
        clearInputWithFilterState()
      }
    }
  })
)(SearchBar)
