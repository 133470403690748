import {css} from 'styled-components'

const theme = {
  border: {
    default: '1px solid #C3C8CD',
    light: '1px solid #EAEFF3',
    thin: '0.5px solid #C3C8CD',
    thin_transparent: '0.5px solid rgba(0, 0, 0, 0)',
    big: '3px solid #C3C8CD',
    color: {
      default: '#C3C8CD',
      dark: '#C2C7CC',
      darkerGray: '#787C80'
    },
    style: {
      default: 'solid'
    },
    width: {
      default: 1,
      thin: 0.5,
      thick: 3
    },
    radius: {
      default: 4
    }
  },
  colors: {
    almostBlack: '#2f3b4f',
    background: '#f2f4f5',
    black: '#1f2226',
    blue: '#39A0D3',
    blueGray: '#b1c2d0',
    boxGray: '#90959A',
    boxLighterGray: '#d8d8d8',
    darkerBlue: '#2996cc',
    darkerGray: '#a3adb9',
    darkGray: '#3D454D',
    gray: '#C3C8CD',
    grayButton: '#979FAD',
    grayFont: '#616264',
    green: '#00B30E',
    klimatellerBlue: '#2b99ca',
    lightBlue: '#4da0d0',
    lightestGray2: '#F4F4F4',
    mediumGray2: '#EBEFF3',
    lightestGray: '#eceeef',
    lightGray: '#FAFBFC',
    mediumGray: '#E5E5E5',
    co2Orange: '#FF9500',
    red: '#D45444',
    white: '#FDFEFE',
    waterScarcityBlue: '#296EC6',
    vitaGreen: '#27BF5B'
  },
  fonts: {
    family: {
      default: css`
        font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
          'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          'Helvetica Neue', 'sans-serif';
      `,
      tall: css`
        font-family: '-apple-system-tall-body', 'BlinkMacSystemFont', 'Segoe UI',
          'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          'Helvetica Neue', 'sans-serif';
      `
    },
    size: {
      small: 13.5,
      normal: 17,
      big: 21,
      bigger: 24,
      huge: 36,
      biggerThanHuge: 42,
      bombing: 60
    },
    weight: {
      thin: 300,
      normal: 400,
      semibold: 500,
      bold: 600
    }
  },
  sizes: {
    adminButtonBoxHeigth: 148,
    adminDrawerWidth: 300,
    adminLeftColumnWidth: 300,
    fixedWidth: 1024,
    maxWidth: 1024,
    minWidth: 1024,
    spacerBarHeight: 86,
    calendarGutter: 16,
    calendarLeftColumnWidth: 174,
    /* use this for maximum responsive power: */
    /* width: calc(100vw - ${p => p.theme.sizes.calendarLeftColumnWidth}px); */
    calendarContentWidth: css`calc(100vw - ${p =>
      p.theme.sizes.calendarLeftColumnWidth}px);`,
    calendarContentMaxWidth: css`
      ${p => p.theme.sizes.maxWidth - p.theme.sizes.calendarLeftColumnWidth}px;
    `,
    calendarContentMinWidth: css`
      ${p => p.theme.sizes.minWidth - p.theme.sizes.calendarLeftColumnWidth}px;
    `,
    dataGridLeftColumnWidth: 190,
    dataGridRightColumnMaxWidth: css`calc(100vw - ${p =>
      p.theme.sizes.popoverWidth +
      p.theme.sizes.popOverMargin +
      p.theme.sizes.dayPlanningContentMarginRight +
      p.theme.sizes.editComponentSpacingRight +
      p.theme.sizes.dataGridLeftColumnWidth}px)
      ;`,
    dayListHeight: 40,
    dayPlanningContentMarginRight: 6,
    duplicateRecipeListPadding: 15,
    duplicateRecipeListWidth: css`
      ${p =>
        p.theme.sizes.minWidth -
        (p.theme.sizes.popoverWidth +
          p.theme.sizes.popOverMargin +
          p.theme.sizes.dayPlanningContentMarginRight +
          p.theme.sizes.duplicateRecipeListPadding)}px;
    `,
    headerHeight: 65,
    footerHeight: 65,
    instuctionCellMinHeight: 200,
    instuctionPadding: 15,
    dataGridHeightRecipe: css`calc(100vh - ${p =>
      p.theme.sizes.headerHeight +
      p.theme.sizes.footerHeight +
      p.theme.sizes.spacerBarHeight +
      p.theme.sizes.dataGridRowHeight}px);`,
    dataGridHeightMenu: css`calc(100vh - ${p =>
      p.theme.sizes.headerHeight +
      p.theme.sizes.footerHeight +
      p.theme.sizes.spacerBarHeight}px);`,
    popOverArrowWidth: 13,
    popOverMargin: 20,
    popOverContentMargin: 18,
    popOverBorderRadius: 8,
    popOverHeaderRowHeight: 60,
    popOverMinHeight: 656,
    popOverMaxHeight: css`calc(100vh - ${p =>
      p.theme.sizes.popOverMargin * 2}px);`,
    popoverWidth: 374,
    componentRowOuterColumsWidth: 60,
    dataGridRowHeight: 60,
    searchBarHeight: 60,
    smallModalWidth: 600,
    fullScreenModalWidth: 768,
    fullScreenModalMargin: 84,
    searchModalContentMarginBottom: 0,
    modalHeaderHeight: 64,
    searchModalContentMarginTop: 22
  }
}

export default theme
