import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {updateSearchInput} from '../../../redux/modules/search'
import {selectComponentToAdd} from '../../../redux/selectors/componentSelectors'
import {
  selectParentByIdFromUrl,
  selectProductByIdFromUrl
} from '../../../redux/selectors/selectors'
import {generateRecipeOrProduct} from '../../../utils/recipeOrProductUtils'
import RecipeOrProductGrid from '../components/RecipeOrProductGrid'
import {convertProductToRecipe} from '../epics/epics'
import {setEditMode, setPreventBlur} from '../../../redux/modules/dataGrid'

const mapStateToPros = state => {
  const currentComponent = selectProductByIdFromUrl(state)
  const componentToAdd = selectComponentToAdd(state)

  return {
    componentToAdd,
    currentComponent,
    parent: selectParentByIdFromUrl(state),
    preventBlur: state.dataGrid.preventBlur,
    recipeOrProduct:
      currentComponent && generateRecipeOrProduct(currentComponent.title),
    searchInput: state.search.searchInput
  }
}

const mapDispatchToProps = {
  convertProductToRecipe,
  setEditMode,
  setPreventBlur,
  updateSearchInput
}

export default compose(
  connect(mapStateToPros, mapDispatchToProps),
  withHandlers({
    onComponentFocus: ({
      setEditMode,
      setPreventBlur,
      updateSearchInput
    }) => () => {
      /*
        this is needed because the CellInput will show the searchInput as
        value in editMode!
      */
      setPreventBlur(false)
      updateSearchInput('')
      setEditMode(true)
    },
    onComponentTitleBlurOrKeyDown: ({
      componentToAdd,
      currentComponent,
      parent,
      preventBlur,
      convertProductToRecipe,
      recipeOrProduct,
      searchInput,
      t
    }) => event => {
      if (preventBlur) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        convertProductToRecipe({
          componentToAdd,
          currentComponent,
          componentIndex: 0,
          parent,
          recipeOrProduct,
          searchInput,
          t
        })
      }
    },
    onComponentChange: ({updateSearchInput}) => ({target: {value}}) => {
      updateSearchInput(value)
    }
  })
)(RecipeOrProductGrid)
