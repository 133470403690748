import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const AdminOptionsListBase = styled.div`
  background-color: ${p => p.theme.colors.white};
  border-right: ${p => p.theme.border.default};
`
const AdminOptionsListItem = styled.div`
  ${p => p.theme.fonts.family.default};
  align-items: center;
  background-color: ${p =>
    p.selected ? p.theme.colors.lightBlue : p.theme.colors.white};
  border-bottom: ${p =>
    p.selected ? p.theme.border.thin_transparent : p.theme.border.thin};
  box-sizing: border-box;
  color: ${p => (p.selected ? p.theme.colors.white : p.theme.colors.darkGray)};
  cursor: pointer;
  display: flex;
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  height: 60px;
  letter-spacing: 0.35px;
  padding-left: 20px;
  text-align: center;
`

const AdminOptionsList = ({options}) => (
  <AdminOptionsListBase>
    {options.map(({onClick, text}, index) => (
      <AdminOptionsListItem key={text} onClick={onClick} selected={index === 0}>
        {text}
      </AdminOptionsListItem>
    ))}
  </AdminOptionsListBase>
)

AdminOptionsList.propTypes = {
  options: PropTypes.array.isRequired
}

export default AdminOptionsList
