import PropTypes from 'prop-types'
import React, {Component} from 'react'
import LayoutContentRight from '../../core/components/LayoutContentRight'
import LayoutWithAdminDrawerContainer from '../../core/containers/LayoutWithAdminDrawerContainer'
import LoadingIndicatorModalContainer from '../../modal/containers/LoadingIndicatorModalContainer'
import RecipeEditMainContainer from '../containers/RecipeEditMainContainer'
import RecipeEditNavigationContainer from '../containers/RecipeEditNavigationContainer'
import FooterContainer from '../../core/containers/FooterContainer'

class RecipeEdit extends Component {
  static propTypes = {
    getAllDataSucceeded: PropTypes.bool.isRequired,
    refetchIfNeeded: PropTypes.func.isRequired
  }

  componentDidMount = () => {
    const {refetchIfNeeded} = this.props
    refetchIfNeeded()
  }

  render() {
    const {getAllDataSucceeded} = this.props

    return getAllDataSucceeded ? (
      <LayoutWithAdminDrawerContainer
        renderContentRight={() => (
          <LayoutContentRight
            renderHeader={() => <RecipeEditNavigationContainer />}
            renderMain={() => <RecipeEditMainContainer />}
            renderFooter={() => <FooterContainer />}
          />
        )}
      />
    ) : (
      <LoadingIndicatorModalContainer isOpen />
    )
  }
}

export default RecipeEdit
