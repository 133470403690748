import React from 'react'
import {pure} from 'recompose'
import styled from 'styled-components'
import BoxItem from '../../core/components/BoxItem'
import {productionDateToMoment} from '../../../utils/dateUtils'

const CalendarWeekBase = styled.div`
  width: ${p => p.theme.sizes.calendarContentWidth};
  max-width: ${p => p.theme.sizes.calendarContentMaxWidth};
  min-width: ${p => p.theme.sizes.calendarContentMinWidth};
  display: flex;
  flex-direction: column;
`
const CalendarGridWrapper = styled.div`
  display: grid;
  grid-gap: ${p => p.theme.sizes.calendarGutter}px;
  font-weight: ${p => p.theme.fonts.weight.normal};
  width: 100%;
  box-sizing: border-box;
  padding: 17px;
`

const CalendarItem = styled.div`
  grid-column: ${props => props.day} / ${props => props.day};
  grid-row: ${props => props.menuLineId} / ${props => props.menuLineId};
  align-self: center;
  justify-self: center;

  & > * {
    pointer-events: none;
  }
`

const CalendarGrid = pure(({handleCalendarItemClick, grid}) => (
  <CalendarGridWrapper id="CalendarGridWrapper" numRows={grid.length}>
    {grid
      .reduce((previous, current) => previous.concat(current))
      .map((menu, index) => {
        const {
          id,
          productionDate,
          menuLineId,
          imageUrl,
          title,
          generated
        } = menu

        const isoWeekday = productionDateToMoment(productionDate).isoWeekday()

        return (
          <CalendarItem
            data-cy={`CalendarItem${menuLineId}/${index}`}
            id={`CalendarItem${id}`}
            onClick={({target: calendarItem}) =>
              handleCalendarItemClick(menu, calendarItem)
            }
            key={id}
            day={isoWeekday}
            menuLineId={menuLineId}
          >
            <BoxItem
              imageUrl={imageUrl}
              title={title}
              showPlusIcon={generated}
            />
          </CalendarItem>
        )
      })}
  </CalendarGridWrapper>
))

const CalendarWeek = pure(
  ({handleCalendarItemClick, weekData: {grid}, style}) => (
    <CalendarWeekBase style={style}>
      <CalendarGrid
        handleCalendarItemClick={handleCalendarItemClick}
        grid={grid}
      />
    </CalendarWeekBase>
  )
)

export default CalendarWeek
