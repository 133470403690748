import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CellInput from '../../dataGrid/components/CellInput'

const HeaderLeftCellInput = styled(CellInput)`
  text-align: center;
  font-weight: ${p => p.theme.fonts.weight.bold};
`

const HeaderLeft = ({handleChange, handleFocus, value}) => (
  <HeaderLeftCellInput
    handleChange={handleChange}
    handleFocus={handleFocus}
    readOnly
    value={value}
    width="140px"
  />
)

HeaderLeft.propTypes = {
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func,
  value: PropTypes.string
}

export default HeaderLeft
