import {push} from 'connected-react-router'
import moment from 'moment'
import {flatten} from 'ramda'
import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {interval} from 'rxjs'
import {filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators'
import {openAdminDrawer, setEditedUser} from '../../../redux/modules/admin'
import {
  deleteMenu,
  deleteMenuOptimistic,
  getAllMenus
} from '../../../redux/modules/menus'
import {
  hideAddOrEditOrganisationModal,
  hideAddOrEditRestaurantModal,
  hideAddOrEditUserModal,
  hideConfirmModal,
  showBuyLicenseModal,
  showRemoveBusinessModal,
  hideRemoveBusinessModal
} from '../../../redux/modules/modal'
import {getAllProducts} from '../../../redux/modules/products'
import {
  deleteRecipe,
  deleteRecipeOptimistic,
  getAllRecipes
} from '../../../redux/modules/recipes'
import {
  deleteRestaurantOptimistic,
  setEditedRestaurant,
  setSelectedRestaurant,
  deleteRestaurant
} from '../../../redux/modules/restaurants'
import {showWelcomeBoxAndRecipePreview} from '../../../redux/modules/search'
import {deleteUser, deleteUserOptimistic} from '../../../redux/modules/users'
import {hideCalendarPopOver} from '../../../redux/modules/view'
import {handleNetworkError} from '../../../redux/observables/pipes'
import {getDaysUntilLicenseExpiry} from '../../../utils/dateUtils'
import {
  saveSelectedRestaurant,
  saveToLocalStorage
} from '../../../utils/localStorage'
import {getLicenseType} from '../../../utils/restaurantUtils'
import {
  deleteProductIfNotLinkedOrPermanent,
  deleteRecipeAndLinkedComponents
} from '../../dataGrid/epics/epics'
import {collectLinkedComponentData} from '../../dataGrid/utils/utils'
import {showConfirmModal, setModalType} from '../../../redux/modules/modal'
import {
  deleteBusinessOptimistic,
  setSelectedBusiness,
  deleteBusiness
} from '../../../redux/modules/businesses'
import {
  showRemoveRestaurantModal,
  hideRemoveRestaurantModal
} from '../../../redux/modules/modal'

export const processCompositeDelete = createAction(
  'modal/epics/PROCESS_COMPOSITE_DELETE'
)

export const startShowBuyLicenseModalInterval = createAction(
  'modal/epics/START_SHOW_BUY_LICENSE_MODAL_INTERVAL'
)
export const stopShowBuyLicenseModalInterval = createAction(
  'modal/epics/STOP_SHOW_BUY_LICENSE_MODAL_INTERVAL'
)
export const processSwitchToBusinessClick = createAction(
  'modal/epics/PROCESS_SWITCH_TO_BUSINESS_CLICK'
)

export const processSwitchToRestaurantClick = createAction(
  'modal/epics/PROCESS_SWITCH_TO_RESTAURANT_CLICK'
)

export const processRemoveRestaurantClick = createAction(
  'modal/epics/PROCESS_REMOVE_RESTAURANT_CLICK'
)

export const getMenusProductsAndRecipesOfRestaurant = createAction(
  'modal/epics/GET_MENUS_PRODUCTS_AND_RECIPES_OF_RESTAURANT'
)

export const processRemoveUserClick = createAction(
  'admin/PROCESS_REMOVE_USER_CLICK'
)

export const processRemoveUserConfirmClick = createAction(
  'admin/PROCESS_REMOVE_CONFIRM_CLICK'
)

export const processRemoveBusinessClick = createAction(
  'admin/PROCESS_REMOVE_BUSINESS_CLICK'
)

export const processRemoveBusinessConfirmClick = createAction(
  'admin/PROCESS_REMOVE_BUSINESS_CONFIRM_CLICK'
)

export const processRemoveRestaurantConfirmClick = createAction(
  'admin/PROCESS_REMOVE_RESTAURANT_CONFIRM_CLICK'
)

const processCompositeDeleteEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processCompositeDelete().type),
    mergeMap(({payload: composite}) => {
      let actions
      if (composite.type === 'menu') {
        const {
          recipes: {recipes}
        } = state$.value

        const ignoreIds = {}
        const componentsToDelete = flatten(
          collectLinkedComponentData({recipes, composite, ignoreIds})
        )

        const recipesToDelete = componentsToDelete.filter(
          c => c.type === 'recipe'
        )

        const productsToDelete = componentsToDelete.filter(
          c => c.type === 'product' && c.id !== '0'
        )

        /*
       deleteProductIfNotLinkedOrPermanent for all collected products, recipes
       can be deleted just like that cause they are deep copies
      */
        actions = [
          ...flatten(
            recipesToDelete.map(recipe => [
              deleteRecipeOptimistic({recipe}),
              deleteRecipe({recipe})
            ])
          ),
          ...productsToDelete.map(product =>
            deleteProductIfNotLinkedOrPermanent({composite, product})
          ),
          deleteMenuOptimistic({menu: composite}),
          deleteMenu({menu: composite})
        ]
      } else {
        const nonPermanentRecipe = {...composite, permanent: false}
        actions = [
          deleteRecipeAndLinkedComponents({
            composite: nonPermanentRecipe,
            recipe: nonPermanentRecipe
          }),
          showWelcomeBoxAndRecipePreview(),
          push('/')
        ]
      }

      return of(...actions, hideConfirmModal(), hideCalendarPopOver())
    })
  )

const showBuyLicenseModalIntervalEpic = (action$, state$) =>
  action$.pipe(
    ofType(startShowBuyLicenseModalInterval().type),
    mergeMap(() =>
      // TODO: open the modal ever 24 hours 24 * 60* 60 * 1000
      interval(24 * 60 * 60 * 1000).pipe(
        filter(() => {
          const {
            restaurants: {selectedRestaurant}
          } = state$.value

          return selectedRestaurant || false
        }),
        filter(() => {
          const {
            config: {webEntryDomain},
            restaurants: {selectedRestaurant}
          } = state$.value
          const daysUntilLicenseExpiry = getDaysUntilLicenseExpiry(
            moment,
            webEntryDomain,
            selectedRestaurant
          )

          return daysUntilLicenseExpiry <= 31
        }),
        map(() => {
          const {
            modal: {billingModalOpen, letsAddModalopen, pricingModalOpen}
          } = state$.value

          if (!billingModalOpen && !letsAddModalopen && !pricingModalOpen) {
            return showBuyLicenseModal()
          }
        }),
        takeUntil(action$.ofType(stopShowBuyLicenseModalInterval().type))
      )
    )
  )

const processSwitchToBusinessClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processSwitchToBusinessClick().type),
    mergeMap(() => {
      const {
        businesses: {selectedBusiness},
        users: {
          user: {defaultRestaurantId}
        },
        restaurants: {restaurants}
      } = state$.value

      const defaultRestaurant = restaurants[defaultRestaurantId]
      saveToLocalStorage('selectedBusiness', selectedBusiness)
      saveSelectedRestaurant(defaultRestaurant)

      return of(
        hideAddOrEditOrganisationModal(),
        openAdminDrawer(),
        setSelectedRestaurant(defaultRestaurant),
        setEditedRestaurant(undefined),
        push('/restaurants')
      )
    })
  )

const processSwitchToRestaurantClickEpic = (action$, state$, {api, of}) =>
  action$.pipe(
    ofType(processSwitchToRestaurantClick().type),
    switchMap(() => {
      const {
        restaurants: {
          editedRestaurant: clientRestaurant,
          selectedRestaurant: adminRestaurant
        },
        users: {
          user: {email, isBusinessPartnerAdmin}
        }
      } = state$.value

      const isSuperAdmin = email.endsWith('@eaternity.ch')

      if (isSuperAdmin || isBusinessPartnerAdmin) {
        return api.getCollectionIds(clientRestaurant.id).pipe(
          mergeMap(({response: collectionIds}) => {
            /**
             * the following lines are crucial and can potentially cause a lot
             * ofugly bugs. the selectedRestaurant(Id) is send to the server in
             * the headers with every request and the server checks the license
             * of that restaurant. the idea is that the superadmin can transfer
             * his own license to the selected client restaurant and look at its
             * menus an recipes even if the client restaurant has no valid
             * license.
             *
             * The collectionIds need to be transfered to the selectedRestaurant
             * as well to make sure that menus, products and recipes created by
             * the superadmin in the name of the selected client are put into
             * the correct collections. e.g. look at NavigationContainer.js
             *
             * Note that the created "chimera" selectedRestaurant is never
             * saved on the server side.
             */

            const clientRestaurantWithCollectionIdsAndAdminLicenses = {
              ...clientRestaurant,
              ...collectionIds,
              licenses: adminRestaurant.licenses
            }

            saveSelectedRestaurant(
              clientRestaurantWithCollectionIdsAndAdminLicenses
            )

            let _actions = [
              getMenusProductsAndRecipesOfRestaurant(
                clientRestaurantWithCollectionIdsAndAdminLicenses
              )
            ]

            const actions = isSuperAdmin
              ? _actions
              : [..._actions, push('/users')]

            return of(...actions)
          }),
          handleNetworkError
        )
      } else {
        saveSelectedRestaurant(clientRestaurant)
        return of(getMenusProductsAndRecipesOfRestaurant(clientRestaurant))
      }
    })
  )

const getMenusProductsAndRecipesOfRestaurantEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(getMenusProductsAndRecipesOfRestaurant().type),
    mergeMap(({payload: restaurant}) => {
      const {
        config: {webEntryDomain}
      } = state$.value

      const licenseType = getLicenseType(webEntryDomain, restaurant.licenses)

      let actions = [
        setSelectedRestaurant(restaurant),
        getAllRecipes(restaurant.id),
        getAllProducts(restaurant.id),
        hideAddOrEditRestaurantModal()
      ]
      if (licenseType === 'eaternity') {
        actions = [...actions, getAllMenus(restaurant.id)]
      }

      return of(...actions)
    })
  )

const processRemoveUserClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processRemoveUserClick().type),
    mergeMap(() => {
      const modalType = 'userRemove'
      saveToLocalStorage('modalType', modalType)

      return of(
        hideAddOrEditUserModal(),
        setModalType(modalType),
        showConfirmModal()
      )
    })
  )

const processRemoveUserConfirmClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processRemoveUserConfirmClick().type),
    mergeMap(() => {
      const {
        admin: {editedUser},
        restaurants: {selectedRestaurant}
      } = state$.value

      saveToLocalStorage('modalType', undefined)

      return of(
        deleteUserOptimistic(editedUser.email),
        deleteUser({
          restaurantId: selectedRestaurant.id,
          user: editedUser
        }),
        setEditedUser(undefined),
        hideConfirmModal()
      )
    })
  )

const processRemoveBusinessClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processRemoveBusinessClick().type),
    mergeMap(() => {
      return of(setModalType('businessRemove'), showRemoveBusinessModal())
    })
  )

const processRemoveBusinessConfirmClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processRemoveBusinessConfirmClick().type),
    mergeMap(() => {
      const {
        businesses: {
          selectedBusiness: {id: businessId}
        }
      } = state$.value

      saveToLocalStorage('selectedBusiness', undefined)

      return of(
        hideRemoveBusinessModal(),
        hideAddOrEditOrganisationModal(),
        setSelectedBusiness(undefined),
        deleteBusinessOptimistic(businessId),
        deleteBusiness(businessId)
      )
    })
  )

const processRemoveRestaurantClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processRemoveRestaurantClick().type),
    mergeMap(() => {
      return of(setModalType('restaurantRemove'), showRemoveRestaurantModal())
    })
  )

const processRemoveRestaurantConfirmClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processRemoveRestaurantConfirmClick().type),
    mergeMap(() => {
      const {
        businesses: {
          selectedBusiness: {id: businessId}
        },
        restaurants: {
          editedRestaurant: {id: restaurantId}
        }
      } = state$.value

      return of(
        hideRemoveRestaurantModal(),
        hideAddOrEditRestaurantModal(),
        deleteRestaurantOptimistic(restaurantId),
        deleteRestaurant({businessId, restaurantId})
      )
    })
  )

export const modalEpics = combineEpics(
  getMenusProductsAndRecipesOfRestaurantEpic,
  processCompositeDeleteEpic,
  processRemoveBusinessClickEpic,
  processRemoveBusinessConfirmClickEpic,
  processRemoveRestaurantConfirmClickEpic,
  processRemoveUserClickEpic,
  processRemoveRestaurantClickEpic,
  processRemoveUserConfirmClickEpic,
  processSwitchToBusinessClickEpic,
  processSwitchToRestaurantClickEpic,
  showBuyLicenseModalIntervalEpic
)
