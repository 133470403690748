import {saveToLocalStorage} from '../../utils/localStorage'
import {of} from 'rxjs'
import {
  setModalType,
  showLoginAgainModal,
  showLinkExpiredModal,
  showAuthTokenExpiredModal
} from '../modules/modal'
import {catchError} from 'rxjs/operators'
import {setLinkExpired} from '../modules/error'

export const handleNetworkError = catchError(({response}) => {
  let modalType
  if (!response) {
    modalType = 'networkError'
  } else {
    const modalTypeMap = {
      'user exists aka link expired': 'linkExpired',
      'login or register token expired': 'linkExpired',
      'jwt expired': 'authTokenExpired'
    }
    modalType = modalTypeMap[response.message] || 'networkError'
  }

  saveToLocalStorage('modalType', modalType)

  let actions = [setModalType(modalType)]
  switch (modalType) {
    case 'networkError':
      saveToLocalStorage('loginAgainModalOpen', true)
      actions = [...actions, showLoginAgainModal()]
      break

    case 'linkExpired':
      saveToLocalStorage('linkExpiredModalOpen', true)
      actions = [...actions, setLinkExpired(true), showLinkExpiredModal()]
      break

    case 'authTokenExpired':
      saveToLocalStorage('authTokenExpiredModalOpen', true)
      actions = [...actions, showAuthTokenExpiredModal()]
      break

    default:
      break
  }

  return of(...actions)
})
