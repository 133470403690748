import styled from 'styled-components'

const RegLogText = styled.div`
  ${p => p.theme.fonts.family.default};
  color: ${p => p.theme.colors.grayFont};
  float: ${p => p.position};
  font-size: ${p => p.theme.fonts.size.small}px;
  font-weight: ${p => p.theme.fonts.weight.thin};
  line-height: 1.5em;
  text-align: ${p => (p.position ? p.position : 'center')};
  width: ${p => p.width}px;
`

export default RegLogText
