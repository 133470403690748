import emailValidator from 'email-validator'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Blink from '../../core/components/Blink'
import {BelowTheBoxGroup} from './BelowTheBoxGroup'
import HavingTroubleText from './HavingTroubleText'
import ChooseLanguage from './ChooseLanguage'
import {InfoBox} from './InfoBox'
import LinkLine from './LinkLine'
import {NavigateButton} from './NavigateButton'
import RegLogBase from './RegLogBase'
import {RegLogButton} from './RegLogButton'
import RegLogContent from './RegLogContent'
import RegLogHeader from './RegLogHeader'
import RegLogInput from './RegLogInput'
import RegLogLogo from './RegLogLogo'
import RegLogNotReceivedText from './RegLogNotReceivedText'
import RegLogText from './RegLogText'

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ContentWrapper = styled.div``

const Login = ({
  email,
  goToRegister,
  loggingIn,
  login,
  loginEmailSend,
  onChange,
  resetLogin,
  userDoesNotExistWarning,
  webEntryDomain
}) => {
  const [t] = useTranslation()

  const isEmail = emailValidator.validate(email)
  return (
    <RegLogBase
      tabIndex={0}
      onKeyDown={({keyCode}) => {
        keyCode === 13 && login()
      }}
    >
      <ContentWrapper>
        <RegLogContent>
          <RegLogLogo />
          <RegLogHeader>{t('login.header')}</RegLogHeader>
          <RegLogText width="340">{t('login.subHeaderText')}</RegLogText>
          {userDoesNotExistWarning ? (
            <ColumnDiv>
              <InfoBox>{t('login.emailNotRegisteredMessage')}</InfoBox>
              <NavigateButton big full blue onClick={goToRegister}>
                {t('login.emailNotRegisteredButtonText')}
              </NavigateButton>
            </ColumnDiv>
          ) : loginEmailSend ? (
            <ColumnDiv>
              <InfoBox>{t('login.emailSendMessage')}</InfoBox>
              <RegLogNotReceivedText t={t} reset={resetLogin} />
            </ColumnDiv>
          ) : (
            <ColumnDiv>
              <RegLogInput
                disabled={loggingIn || userDoesNotExistWarning}
                onChange={onChange}
                placeholder={t('logReg.emailPlaceholder')}
                value={email}
              />
              <RegLogButton
                disabled={!isEmail || loggingIn}
                full
                blue
                fluid
                onClick={login}
              >
                {loggingIn ? (
                  <RowDiv>
                    {t('logReg.sendingEmail')}
                    <Blink />
                  </RowDiv>
                ) : (
                  t('login.buttonText')
                )}
              </RegLogButton>
              <LinkLine
                linkText={t('login.registerLinkText')}
                onClick={goToRegister}
                text={t('login.noAccountText')}
              />
            </ColumnDiv>
          )}
        </RegLogContent>
        <BelowTheBoxGroup>
          <HavingTroubleText t={t} webEntryDomain={webEntryDomain} />
        </BelowTheBoxGroup>
        <BelowTheBoxGroup>
          <ChooseLanguage t={t} />
        </BelowTheBoxGroup>
      </ContentWrapper>
    </RegLogBase>
  )
}

Login.propTypes = {
  email: PropTypes.string,
  login: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loginEmailSend: PropTypes.bool.isRequired,
  goToRegister: PropTypes.func.isRequired,
  resetLogin: PropTypes.func.isRequired,
  userDoesNotExistWarning: PropTypes.bool.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default Login
