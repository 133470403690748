import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {setModalType, showConfirmModal} from '../../../redux/modules/modal'
import {hideCalendarPopOver} from '../../../redux/modules/view'
import {selectEditedMenu} from '../../../redux/selectors/selectors'
import EditableMenuDetails from '../components/EditableMenuDetails'

const mapStateToProps = state => {
  return {
    menuTitle: selectEditedMenu(state).title
  }
}

const mapDispatchToProps = {
  hideCalendarPopOver,
  setModalType,
  showConfirmModal
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    deleteMenu: ({hideCalendarPopOver, showConfirmModal}) => () => {
      showConfirmModal()
      hideCalendarPopOver()
    },
    goToDayPlanning: ({history: {push}}) => () => {
      push('/dayplanning')
    },
    onDeleteButtonClick: ({setModalType, showConfirmModal}) => () => {
      setModalType('compositeDelete')
      showConfirmModal()
    }
  })
)(EditableMenuDetails)
