import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Routes from '../components/Routes'

const mapStateToProps = ({
  config: {webEntryDomain},
  restaurants: {selectedRestaurant},
  users: {
    isAuthenticated,
    user: {email, isBusinessPartnerAdmin}
  }
}) => ({
  isAuthenticated,
  isSuperAdmin: email ? email.endsWith('@eaternity.ch') : false,
  isBusinessPartnerAdmin,
  selectedRestaurant,
  webEntryDomain
})

export default compose(withRouter, connect(mapStateToProps))(Routes)
