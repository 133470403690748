import {omit} from 'ramda'
import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {map, mergeMap} from 'rxjs/operators'
import {
  loadSelectedRestaurant,
  saveSelectedRestaurant
} from '../../utils/localStorage'
import {normalizeById} from '../../utils/utils'
import {handleNetworkError} from '../observables/pipes'
import {closeRestaurantSettingsModal} from './modal'

export const initialStateRestaurants = {
  creatingOrUpdatingRestaurant: false,
  editedRestaurant: loadSelectedRestaurant() || undefined,
  selectedRestaurant: loadSelectedRestaurant() || undefined,
  addRestaurantOpen: false,
  restaurantTypes: {
    klimateller: [
      'caterer',
      'communalFeeding',
      'eventCaterer',
      'chef',
      'privatePerson'
    ],
    eaternity: [
      'seatedRestaurant',
      'caterer',
      'quickServiceRestaurant',
      'coffeeOrTeaHouse',
      'barClubLounge',
      'foodTruck',
      'privatePerson',
      'other'
    ]
  },
  dailyGuestChoices: ['< 20', '20-100', '100-200', '200-1000', '> 1000'],
  restaurants: {}
}

export const setCreatingOrEditingRestaurant = createAction(
  'restaurants/SET_CREATING_OR_EDITING_RESTAURANT'
)

export const setEditedRestaurant = createAction(
  'restaurants/SET_EDITED_RESTAURANT'
)

export const setSelectedRestaurant = createAction(
  'restaurants/SET_SELECTED_RESTAURANT'
)

export const getAllRestaurants = createAction(
  'restaurants/GET_ALL_RESTAURANTS_REQUESTED'
)

export const getAllRestaurantsSucceeded = createAction(
  'restaurants/GET_ALL_RESTAURANTS_SUCCEEDED'
)

export const getBusinessRestaurants = createAction(
  'restaurants/GET_BUSINESS_RESTAURANTS_REQUESTED'
)

export const getBusinessRestaurantsSucceeded = createAction(
  'restaurants/GET_BUSINESS_RESTAURANTS_SUCCEEDED'
)

export const getUserRestaurants = createAction(
  'restaurants/GET_USER_RESTAURANTS_REQUESTED'
)

export const getUserRestaurantsSucceeded = createAction(
  'restaurants/GET_USER_RESTAURANTS_SUCCEEDED'
)

export const postRestaurant = createAction(
  'restaurants/POST_RESTAURANT_REQUESTED'
)

export const postRestaurantSuceeded = createAction(
  'restaurants/POST_RESTAURANT_SUCCEEDED'
)

export const putRestaurant = createAction(
  'restaurants/PUT_RESTAURANT_REQUESTED'
)

export const putRestaurantSuceeded = createAction(
  'restaurants/PUT_RESTAURANT_SUCEEDED'
)

export const deleteRestaurant = createAction(
  'restaurants/DELETE_RESTAURANT_REQUESTED'
)

export const deleteRestaurantSucceeded = createAction(
  'restaurants/DELETE_RESTAURANT_SUCCEEDED'
)

export const deleteRestaurantOptimistic = createAction(
  'restaurants/DELETE_RESTAURANT_OPTIMISTIC'
)

export const resetSelectedRestaurant = createAction(
  'restaurants/RESET_SELECTED_RESTAURANT'
)

export const updateRestaurantsOptimistic = createAction(
  'restaurants/UPDATE_RESTAURANTS_OPTIMISTIC'
)

export const getRestaurantCollectionIds = createAction(
  'restaurants/GET_RESTAURANT_COLLECTION_IDS_REQUESTED'
)

export const getRestaurantCollectionIdsSucceeded = createAction(
  'restaurants/GET_RESTAURANT_COLLECTION_IDS_SUCCEEDED'
)

const reducer = createReducer(
  {
    [updateRestaurantsOptimistic]: (state, payload) => ({
      ...state,
      restaurants: {
        ...state.restaurants,
        [payload.id]: payload
      }
    }),
    [getAllRestaurantsSucceeded]: (state, payload) => ({
      ...state,
      restaurants: payload
    }),
    [getUserRestaurantsSucceeded]: (state, payload) => ({
      ...state,
      restaurants: payload
    }),
    [getBusinessRestaurantsSucceeded]: (state, payload) => ({
      ...state,
      restaurants: payload
    }),
    [deleteRestaurantOptimistic]: (state, payload) => ({
      ...state,
      restaurants: omit([payload], state.restaurants)
    }),
    [resetSelectedRestaurant]: state => ({
      ...state,
      selectedRestaurant: {}
    }),
    [setEditedRestaurant]: (state, payload) => {
      return {
        ...state,
        editedRestaurant: payload
      }
    },
    [setSelectedRestaurant]: (state, payload) => {
      return {
        ...state,
        selectedRestaurant: payload
      }
    },
    [putRestaurant]: state => ({...state, creatingOrUpdatingRestaurant: true}),
    [putRestaurantSuceeded]: (state, payload) => ({
      ...state,
      restaurants: {...state.restaurants, [payload.id]: payload},
      creatingOrUpdatingRestaurant: false
    }),
    [setCreatingOrEditingRestaurant]: (state, payload) => ({
      ...state,
      creatingOrUpdatingRestaurant: payload
    })
  },
  initialStateRestaurants
)

const getAllRestaurantsEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(getAllRestaurants().type),
    mergeMap(() =>
      api.getAllRestaurants().pipe(
        map(({response: restaurants}) => {
          const restaurantsById = normalizeById(restaurants)
          return getAllRestaurantsSucceeded(restaurantsById)
        }),
        handleNetworkError
      )
    )
  )

const getBusinessRestaurantsEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(getBusinessRestaurants().type),
    mergeMap(({payload: businessId}) =>
      api.getBusinessRestaurants({businessId}).pipe(
        map(({response: restaurants}) => {
          const restaurantsById = normalizeById(restaurants)
          return getBusinessRestaurantsSucceeded(restaurantsById)
        }),
        handleNetworkError
      )
    )
  )

const getUserRestaurantsEpic = (action$, state$, {api, of}) =>
  action$.pipe(
    ofType(getUserRestaurants().type),
    mergeMap(() =>
      api.getUserRestaurants().pipe(
        mergeMap(({response: restaurants}) => {
          const {
            restaurants: {selectedRestaurant}
          } = state$.value

          const restaurantsById = normalizeById(restaurants)
          const updatedRestaurantFromResponse =
            restaurantsById[selectedRestaurant.id]

          let actions = [getUserRestaurantsSucceeded(restaurantsById)]
          if (updatedRestaurantFromResponse) {
            saveSelectedRestaurant(updatedRestaurantFromResponse)
            actions = [
              ...actions,
              setSelectedRestaurant(updatedRestaurantFromResponse)
            ]
          }

          return of(...actions)
        }),
        handleNetworkError
      )
    )
  )

const postRestaurantEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(postRestaurant().type),
    mergeMap(({payload: {businessId, restaurant}}) =>
      api.postRestaurant({businessId, restaurant}).pipe(
        map(() => postRestaurantSuceeded()),
        handleNetworkError
      )
    )
  )

const putRestaurantEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(putRestaurant().type),
    mergeMap(({payload: {restaurant, emailData}}) => {
      return api.putRestaurant({restaurant, emailData}).pipe(
        mergeMap(({response: restaurant}) => {
          saveSelectedRestaurant(restaurant)

          return of(
            setSelectedRestaurant(restaurant),
            putRestaurantSuceeded(restaurant),
            setCreatingOrEditingRestaurant(false),
            closeRestaurantSettingsModal()
          )
        }),
        handleNetworkError
      )
    })
  )

const deleteRestaurantEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(deleteRestaurant().type),
    mergeMap(({payload}) => {
      return api.deleteRestaurant(payload).pipe(
        map(() => deleteRestaurantSucceeded()),
        handleNetworkError
      )
    })
  )

export const restaurantEpics = combineEpics(
  deleteRestaurantEpic,
  getAllRestaurantsEpic,
  getBusinessRestaurantsEpic,
  getUserRestaurantsEpic,
  postRestaurantEpic,
  putRestaurantEpic
)

export default reducer
