import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import CalendarContainer from '../../calendar/containers/CalendarContainer'
import CalendarPopOverContainer from '../../calendar/containers/CalendarPopOverContainer'
import DatePickerContainer from '../../calendar/containers/DatePickerContainer'
import SpacerBar from '../../core/components/SpacerBar'
import MenuLineBoxesContainer from '../containers/MenuLineBoxesContainer'
import CalendarDaySelectorContainer from '../../calendar/containers/CalendarDaySelectorContainer'
import LastWeekButtonContainer from '../../calendar/containers/LastWeekButtonContainer'
import NextWeekButtonContainer from '../../calendar/containers/NextWeekButtonContainer'

const WeekPlanningBase = styled.div``

const Content = styled.div`
  display: flex;
  max-height: calc(
    100vh -
      ${p =>
        p.theme.sizes.headerHeight +
        p.theme.sizes.footerHeight +
        p.theme.sizes.spacerBarHeight}px
  );
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const WeekPlanningDatePickerContainer = styled(DatePickerContainer)`
  position: absolute;
  left: ${p => (p.adminDrawerOpen ? p.theme.sizes.adminDrawerWidth : 0)}px;
`

const Wrapper = styled.div`
  max-width: ${p =>
    p.theme.sizes.maxWidth -
    p.theme.sizes.calendarLeftColumnWidth -
    p.theme.sizes.calendarGutter * 2}px;
  min-width: ${p =>
    p.theme.sizes.minWidth -
    p.theme.sizes.calendarLeftColumnWidth -
    p.theme.sizes.calendarGutter * 2}px;
  align-self: flex-end;
  padding: 0 ${p => p.theme.sizes.calendarGutter}px;
  margin-left: ${p => p.theme.sizes.calendarLeftColumnWidth}px;
`

const WeekPlanningMain = ({adminDrawerOpen}) => (
  <WeekPlanningBase>
    <SpacerBar>
      <WeekPlanningDatePickerContainer adminDrawerOpen={adminDrawerOpen} />
      <Wrapper>
        <CalendarDaySelectorContainer
          leftArrowContainer={() => <LastWeekButtonContainer />}
          rightArrowContainer={() => <NextWeekButtonContainer />}
        />
      </Wrapper>
    </SpacerBar>
    <Content>
      <MenuLineBoxesContainer />
      <CalendarContainer />
    </Content>
    <CalendarPopOverContainer />
  </WeekPlanningBase>
)

WeekPlanningMain.propTypes = {
  adminDrawerOpen: PropTypes.bool.isRequired
}

export default WeekPlanningMain
