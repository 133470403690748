export const getScore = rating => {
  if (!rating) {
    return 0
  }

  const ABCRatingToScoreMap = {
    a: 5,
    b: 4,
    c: 3,
    d: 2,
    e: 1
  }

  return ABCRatingToScoreMap[rating.toLowerCase()]
}
