// every function exposed by the api does return an Observable and can
// thus be easily integrated into any epic. Most often this will be as an inner
// Observable aka a metaStream. See documentation here: http://bit.ly/2nXj1q1
import {ajax as request} from 'rxjs/ajax'

import {generateHeaders} from './utils'

const BASE_URL = '/api'

export const createApi = webEntryDomain => ({
  /**
    menus
  */
  getAllMenus: restaurantId =>
    request({
      url: `${BASE_URL}/menus`,
      method: 'GET',
      headers: generateHeaders({restaurantId, webEntryDomain})
    }),

  postMenu: menu =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/menus`,
      body: menu,
      method: 'POST'
    }),

  putMenu: menu => {
    const {id} = menu
    return request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/menus/${id}`,
      method: 'PUT',
      body: menu
    })
  },

  deleteMenu: id => {
    return request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/menus/${id}`,
      method: 'DELETE'
    })
  },

  /**
    recipes
  */
  getAllRecipes: ({selectedBusinessId, selectedRestaurantId}) =>
    request({
      url: `${BASE_URL}/recipes`,
      headers: generateHeaders({
        selectedBusinessId,
        selectedRestaurantId,
        webEntryDomain
      }),
      method: 'GET'
    }),

  postRecipe: recipe => {
    return request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/recipes`,
      body: recipe,
      method: 'POST'
    })
  },

  putRecipe: recipe => {
    const {id} = recipe
    return request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/recipes/${id}`,
      body: recipe,
      method: 'PUT'
    })
  },

  deleteRecipe: id =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/recipes/${id}`,
      method: 'DELETE'
    }),

  /**
    products
  */
  getAllProducts: restaurantId =>
    request({
      url: `${BASE_URL}/products`,
      headers: generateHeaders({restaurantId, webEntryDomain}),
      method: 'GET'
    }),

  postProduct: product =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/products`,
      body: product,
      method: 'POST'
    }),

  deleteProduct: ({id}) =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/products/${id}`,
      method: 'DELETE'
    }),

  /**
    user
  */
  createUser: registerData =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/users/register`,
      body: registerData,
      method: 'POST'
    }),

  putUser: ({data, resendInvitationEmail = false}) =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/users/${
        data.user.id
      }?resendInvitationEmail=${resendInvitationEmail || false}`,
      body: data,
      method: 'PUT'
    }),

  verifyUser: (token, isLogin) => {
    const suffix = isLogin ? 'verify-login' : 'verify'

    return request({
      headers: {
        ...generateHeaders({webEntryDomain}),
        jwt: token
      },
      url: `${BASE_URL}/users/${suffix}`,
      method: 'PUT'
    })
  },

  loginUser: loginData => {
    return request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/users/login`,
      body: loginData,
      method: 'PUT'
    })
  },

  getRestaurantUsers: restaurantId =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/users?restaurantId=${restaurantId}`,
      method: 'GET'
    }),

  postDefaultRestaurant: restaurantId =>
    request({
      url: `${BASE_URL}/users/set-default-restaurant`,
      headers: generateHeaders({webEntryDomain}),
      method: 'POST',
      body: {restaurantId}
    }),

  /**
    restaurants
  */
  getUserRestaurants: () =>
    request({
      url: `${BASE_URL}/restaurants`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }),

  putRestaurant: ({restaurant, emailData = {}}) => {
    const {
      sendLicenseRequestEmail,
      email,
      firstName,
      lastName,
      selectedPricingScheme
    } = emailData
    const query = !!sendLicenseRequestEmail
      ? `?sendLicenseRequestEmail=true&email=${email}&firstName=${firstName}&lastName=${lastName}&selectedPricingScheme=${selectedPricingScheme}`
      : '?sendLicenseRequestEmail=false'

    return request({
      url: `${BASE_URL}/restaurants${query}`,
      headers: generateHeaders({webEntryDomain}),
      method: 'PUT',
      body: restaurant
    })
  },

  // businesses
  putBusiness: business =>
    request({
      url: `${BASE_URL}/businesses`,
      headers: generateHeaders({webEntryDomain}),
      method: 'PUT',
      body: business
    }),

  // server status
  checkServerStatus: () =>
    request({
      url: `${BASE_URL}/status`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }),

  /*
  superadmin --> eaternity email address
  */
  getAllRestaurants: () =>
    request({
      url: `${BASE_URL}/superadmin/restaurants`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }),

  getUsers: query =>
    request({
      url: `${BASE_URL}/superadmin/users?query=${query}`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }).toPromise(),

  getBusinessPartners: () =>
    request({
      url: `${BASE_URL}/superadmin/business-partners`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }),

  postBusinessPartner: businessPartner =>
    request({
      url: `${BASE_URL}/superadmin/business-partners`,
      headers: generateHeaders({webEntryDomain}),
      method: 'POST',
      body: businessPartner
    }),

  putBusinessPartner: businessPartner =>
    request({
      url: `${BASE_URL}/superadmin/business-partners`,
      headers: generateHeaders({webEntryDomain}),
      method: 'PUT',
      body: businessPartner
    }),

  /*
  admin (businessPartners are admins...)
  */

  getBusinesses: () =>
    request({
      url: `${BASE_URL}/admin/businesses`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }),

  postBusiness: business =>
    request({
      url: `${BASE_URL}/admin/businesses`,
      headers: generateHeaders({webEntryDomain}),
      method: 'POST',
      body: business
    }),

  deleteBusiness: businessId =>
    request({
      url: `${BASE_URL}/admin/businesses/${businessId}`,
      headers: generateHeaders({webEntryDomain}),
      method: 'DELETE'
    }),

  getBusinessRestaurants: ({businessId}) =>
    request({
      url: `${BASE_URL}/admin/restaurants?businessId=${businessId}`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }),

  getCollectionIds: restaurantId =>
    request({
      url: `${BASE_URL}/collections/ids?restaurantId=${restaurantId}`,
      headers: generateHeaders({webEntryDomain}),
      method: 'GET'
    }),

  postRestaurant: ({businessId, restaurant}) =>
    request({
      url: `${BASE_URL}/admin/restaurants?businessId=${businessId}`,
      headers: generateHeaders({webEntryDomain}),
      method: 'POST',
      body: restaurant
    }),

  deleteRestaurant: ({businessId, restaurantId}) =>
    request({
      url: `${BASE_URL}/admin/restaurants?restaurantId=${restaurantId}&businessId=${businessId}`,
      headers: generateHeaders({webEntryDomain}),
      method: 'DELETE'
    }),

  postUser: ({businessId, restaurantId, data}) =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/admin/users?businessId=${businessId}&restaurantId=${restaurantId}`,
      body: data,
      method: 'POST'
    }),

  deleteUser: ({user, restaurantId}) =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/admin/users?restaurantId=${restaurantId}`,
      body: user,
      method: 'DELETE'
    }),

  /*
  images (talk to uploadcare from te backend)
  */
  deleteImage: uuid =>
    request({
      headers: generateHeaders({webEntryDomain}),
      url: `${BASE_URL}/images/${uuid}`,
      method: 'DELETE'
    }),

  /**
   * exports
   */
  getRecipeCsv: recipeId =>
    request({
      headers: generateHeaders({webEntryDomain}),
      responseType: 'text',
      url: `${BASE_URL}/exports/recipes/${recipeId}`,
      method: 'GET'
    }),

  getRecipesCsv: ({recipesOrIngredients, from, to}) =>
    request({
      headers: generateHeaders({webEntryDomain}),
      responseType: 'text',
      url: `${BASE_URL}/exports/recipes?recipesOrIngredients=${recipesOrIngredients}&to=${to}&from=${from}`,
      method: 'GET'
    }),

  getMenusCsv: ({recipesOrIngredients, from, to}) =>
    request({
      headers: generateHeaders({webEntryDomain}),
      responseType: 'text',
      url: `${BASE_URL}/exports/menus?recipesOrIngredients=${recipesOrIngredients}&to=${to}&from=${from}`,
      method: 'GET'
    })
})
