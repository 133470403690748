import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import CountSetter from '../components/CountSetter'
import {processPlannedPortionsChange} from '../epics/epics'

const mapStateToProps = state => {
  const composite = selectCompositeByIdFromUrl(state)
  return {
    composite,
    count: composite && composite.plannedPortions,
    descriptionTranslateKey: 'portions'
  }
}

const mapDispatchToProps = {
  processPlannedPortionsChange
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onMinusClick: ({composite, processPlannedPortionsChange}) => () => {
      processPlannedPortionsChange({composite, type: 'decrease'})
    },
    onPlusClick: ({composite, processPlannedPortionsChange}) => () => {
      processPlannedPortionsChange({composite, type: 'increase'})
    },
    onChange: ({composite, processPlannedPortionsChange}) => value => {
      processPlannedPortionsChange({composite, type: 'update', value})
    }
  })
)(CountSetter)
