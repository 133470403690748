import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import LayoutContentRight from '../../core/components/LayoutContentRight'
import LayoutWithAdminDrawerContainer from '../../core/containers/LayoutWithAdminDrawerContainer'
import LoadingIndicatorModalContainer from '../../modal/containers/LoadingIndicatorModalContainer'
import RecipeEditNavigationContainer from '../../recipeEditView/containers/RecipeEditNavigationContainer'
import RecipeOrProductEditMainContainer from '../../recipeOrProduct/containers/RecipeOrProductEditMainContainer'
import FooterContainer from '../../core/containers/FooterContainer'

const RecipeOrProduct = ({
  getAllDataSucceeded,
  refetchIfNeeded,
  setSearchTypes
}) => {
  useEffect(() => {
    refetchIfNeeded()
    setSearchTypes(['products', 'recipes'])
  }, [])

  return getAllDataSucceeded ? (
    <LayoutWithAdminDrawerContainer
      renderContentRight={() => (
        <LayoutContentRight
          renderHeader={() => <RecipeEditNavigationContainer />}
          renderMain={() => <RecipeOrProductEditMainContainer />}
          renderFooter={() => <FooterContainer />}
        />
      )}
    />
  ) : (
    <LoadingIndicatorModalContainer isOpen />
  )
}

RecipeOrProduct.propTypes = {
  getAllDataSucceeded: PropTypes.bool.isRequired,
  refetchIfNeeded: PropTypes.func.isRequired,
  setSearchTypes: PropTypes.func.isRequired
}

export default RecipeOrProduct
