import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Select, Option} from 'informed'
import {TextStyle} from '../../core/components/Text'
import React, {Component} from 'react'
import i18n from 'i18next'

const ChooseLanguageBase = styled.div`
  ${() => TextStyle};
  font-size: ${p => p.theme.fonts.size.small}px;
  font-weight: ${p => p.theme.fonts.weight.thin};
  line-height: 1.5em;
  text-align: center;
  height: 20px;
`

class ChooseLanguage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    i18n.changeLanguage(event.target.value, (err, t) => {
      if (err) return console.log('something went wrong loading', err)
    })
  }

  render() {
    const {t} = this.props

    return (
      <ChooseLanguageBase>
        <br />
        <label>
          {t('logReg.selectLanguage')}:{' '}
          <Select field="status" onChange={this.handleChange}>
            <Option value="" disabled>
              Select Language...
            </Option>
            <Option value="en">English</Option>
            <Option value="de">Deutsch</Option>
          </Select>
        </label>
      </ChooseLanguageBase>
    )
  }
}

export default ChooseLanguage
