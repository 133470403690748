import {createAction, createReducer} from 'redux-act'
import {loadFromLocalStorage} from '../../utils/localStorage'

export const initialStateAdmin = {
  adminDrawerOpen: loadFromLocalStorage('adminDrawerOpen') || false,
  addRestaurantOpen: false,
  editRestaurantOpen: false,
  editedUser: undefined
}

export const setEditedUser = createAction('admin/SET_EDITED_USER')
export const openAddRestaurant = createAction('admin/OPEN_ADD_RESTAURANT')
export const closeAddRestaurant = createAction('admin/CLOSE_ADD_RESTAURANT')
export const closeEditRestaurant = createAction('admin/CLOSE_EDIT_RESTAURANT')
export const closeAdminDrawer = createAction('admin/CLOSE_ADMIN_DRAWER')
export const openAdminDrawer = createAction('admin/OPEN_ADMIN_DRAWER')

const reducer = createReducer(
  {
    [openAdminDrawer]: state => ({
      ...state,
      adminDrawerOpen: true
    }),
    [closeAdminDrawer]: state => ({
      ...state,
      adminDrawerOpen: false
    }),
    [openAddRestaurant]: state => ({
      ...state,
      addRestaurantOpen: true
    }),
    [closeAddRestaurant]: state => ({
      ...state,
      addRestaurantOpen: false
    }),
    [closeEditRestaurant]: state => ({
      ...state,
      editRestaurantOpen: false
    }),
    [setEditedUser]: (state, payload) => ({
      ...state,
      editedUser: payload
    })
  },
  initialStateAdmin
)

export default reducer
