import PropTypes from 'prop-types'
import React, {useState} from 'react'
import styled from 'styled-components'

const SearchResultsBase = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-sizing: border-box;
  padding: 0px 60px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const SearchHitRow = styled.div`
  border-bottom: ${p => p.theme.border.thin};
  border-left: ${p => p.theme.border.thin};
  border-top: ${p => p.isFirst && p.theme.border.thin};
  box-sizing: border-box;
  color: ${p => p.theme.colors.darkGray};
  cursor: pointer;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: 60px;
  background-color: ${p => p.hovering && p.theme.colors.lightestGray};
`
const SmallBox = styled.div`
  background-color: ${p => p.theme.colors.boxLighterGray};
  box-sizing: border-box;
  height: 59.5px;
`
const Name = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-self: start;
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.semibold};
  letter-spacing: 0.35px;
  padding: 0 20px;
  width: 100%;
`

const ClickBoxRow = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr auto auto;
`

const AdminSearchResults = ({results = [], onSearchHitRowClick}) => {
  const [hoveringIndex, setHoveringIndex] = useState(false)

  return (
    <SearchResultsBase>
      {results.map((result, index) => (
        <SearchHitRow
          hovering={hoveringIndex === index}
          isFirst={index === 0}
          key={`${result.short}${index}`}
        >
          <ClickBoxRow
            showButtons={result.type === 'user'}
            onMouseEnter={() => setHoveringIndex(index)}
            onMouseLeave={() => setHoveringIndex(null)}
            onClick={() => onSearchHitRowClick(result)}
          >
            <SmallBox />
            <Name>{result.type === 'user' ? result.email : result.name}</Name>
          </ClickBoxRow>
        </SearchHitRow>
      ))}
    </SearchResultsBase>
  )
}

AdminSearchResults.propTypes = {
  results: PropTypes.array,
  onSearchHitRowClick: PropTypes.func
}

export default AdminSearchResults
