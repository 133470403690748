import 'react-app-polyfill/ie9'
import 'core-js'
import TWEEN from '@tweenjs/tween.js'
import {ConnectedRouter} from 'connected-react-router'
import 'eaternity-core/build/i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {ThemeProvider} from 'styled-components'
import './index.css'
import AppContainer from './modules/core/containers/AppContainer'
import {history, store} from './redux/configureStore'
import * as serviceWorker from './serviceWorker'
import theme from 'eaternity-core/build/theme'

// Setup the animation loop.
function animate(time) {
  /* global window */
  window.requestAnimationFrame(animate)
  TWEEN.update(time)
}
requestAnimationFrame(animate)

if (window.Cypress) {
  window.__store__ = store
}

ReactDOM.render(
  <Provider store={window.Cypress ? window.__store__ : store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <AppContainer />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
