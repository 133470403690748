import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

class Portal extends React.Component {
  constructor(...args) {
    super(...args)
    this.portalNode = document.createElement('div')
    this.portalNode.dataset.testid = 'portal'
  }
  componentDidMount() {
    document.body.appendChild(this.portalNode)
  }
  componentWillUnmount() {
    this.portalNode.parentNode.removeChild(this.portalNode)
  }

  render() {
    const {isOpen} = this.props
    if (!isOpen) {
      return null
    }
    return ReactDOM.createPortal(this.props.children, this.portalNode)
  }
}

Portal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default Portal
