import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import upperFirst from 'lodash/upperFirst'

import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import HeaderLeft from '../../dataGrid/components/HeaderLeft'

const mapStateToProps = (state, {menuLineId, t}) => {
  const editedMenu = state.menus.menus[state.menus.editedMenuId]
  return {
    hasTitle: editedMenu ? editedMenu.title !== '' : false,
    value: `${upperFirst(t('compositeType.menu'))} ${menuLineId}`
  }
}

const mapDispatchToProps = {
  setDayPlanningPopOverContentType
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    handleFocus: ({hasTitle, setDayPlanningPopOverContentType}) => () => {
      if (hasTitle) {
        setDayPlanningPopOverContentType('compositeDetails')
      }
    }
  })
)(HeaderLeft)
