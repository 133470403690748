import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {IconForestActive, IconForestInactive} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconRainforest = styled(Icon)`
  margin-right: -12px;
`

const RainforestRow = ({rainforestLabel}) => (
  <RowContainer>
    <IconRainforest
      src={rainforestLabel ? IconForestActive : IconForestInactive}
      width="50px"
      height="25px"
    />
  </RowContainer>
)

RainforestRow.propTypes = {
  rainforestLabel: PropTypes.bool
}

export default RainforestRow
