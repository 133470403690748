import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {Content, IndicatorBannerWrapper} from './IndicatorDetails'
import EnvironmentIconGroup from './EnvironmentIconGroup'
import FactRow from '../../core/components/FactRow'
import PopoverHeaderBar from '../../core/components/PopoverHeaderBar'
import IndicatorGroupContainer from '../containers/IndicatorGroupContainer'
import RowHeader from '../../core/components/RowHeader'
import RowValue from '../../core/components/RowValue'

const IndicatorsBase = styled.div`
  ${p => p.theme.fonts.family.default};
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EnvironmentOverviewBase = styled.div`
  height: ${p => p.theme.sizes.popOverMinHeight}px;
`

const ClassificationText = styled.div`
  width: 290px;
  margin-left: 20px;
  height: 57px;
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  text-align: center;
  color: ${p => p.theme.colors.darkGray};
`

const data = [
  {
    label: 'Rating',
    render: function ratingRow() {
      return <RowValue>4 out of 5</RowValue>
    },
    nextView: {
      view: 'rating'
    }
  },
  {
    label: 'Water Footprint',
    render: function waterFootprintRowRow() {
      return <IndicatorGroupContainer />
    },
    nextView: {
      view: 'indicatorDetails',
      indicatorTypeId: 'water'
    }
  },
  {
    label: 'Deforestation',
    render: function deforestationRow() {
      return <RowValue>Soya Label (RSPO)</RowValue>
    },
    nextView: {
      view: 'environmentDetails',
      environmentTypeId: 'deforestation'
    }
  },
  {
    label: 'Animal Welfare',
    render: function animalWelfareRow() {
      return <RowValue>RAUS</RowValue>
    },
    nextView: {
      view: 'environmentDetails',
      environmentTypeId: 'animalWelfare'
    }
  },
  {
    label: 'Season',
    render: function seasonRow() {
      return <RowValue>March-June</RowValue>
    },
    nextView: {
      view: 'environmentDetails',
      environmentTypeId: 'season'
    }
  }
]

const EnvironmentOverview = ({
  classificationText,
  goBack,
  goToView,
  awards
}) => (
  <EnvironmentOverviewBase>
    <PopoverHeaderBar title={'Environment'} onArrowClick={goBack} />
    <Content>
      <IndicatorBannerWrapper>
        <RowContainer>
          <EnvironmentIconGroup
            awards={awards}
            size={100}
            iconHeight="50px"
            iconWidth="50px"
          />
        </RowContainer>
      </IndicatorBannerWrapper>
      <ClassificationText>{classificationText}</ClassificationText>
      <IndicatorsBase>
        <RowHeader />
        {data.map(({label, render, nextView}, index) => (
          <FactRow
            isFirst={index === 0}
            isLast={index === data.length - 1}
            key={index}
            onClick={() => nextView && goToView(nextView)}
            label={label}
            render={render}
            iconRightType="arrow"
            showIconRight
          />
        ))}
      </IndicatorsBase>
    </Content>
  </EnvironmentOverviewBase>
)

EnvironmentOverview.propTypes = {
  classificationText: PropTypes.string.isRequired,
  awards: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  goToView: PropTypes.func.isRequired,
  sliceType: PropTypes.string.isRequired
}

export default EnvironmentOverview
