import {connect} from 'react-redux'
import {
  getBusinesses,
  setSelectedBusiness
} from '../../../redux/modules/businesses'
import {showAddOrEditOrganisationModal} from '../../../redux/modules/modal'
import {getUserRestaurants} from '../../../redux/modules/restaurants'
import {setSearchTypes, updateSearchInput} from '../../../redux/modules/search'
import OrganisationAdminContent from '../components/OrganisationAdminContent'

const mapStateToProps = state => ({
  getUserRestaurantsSucceeded: state.network.getUserRestaurantsSucceeded,
  searchInput: state.search.searchInput
})

const mapDispatchToProps = {
  getBusinesses,
  getUserRestaurants,
  setSearchTypes,
  showAddOrEditOrganisationModal,
  setSelectedBusiness,
  updateSearchInput
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationAdminContent)
