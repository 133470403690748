import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {addComponentWithDefaultProduct} from '../../dataGrid/epics/epics'
import {addRowToInstructions} from '../epics/epics'
import {
  decreaseRecipeServings,
  increaseRecipeServings
} from '../../../redux/modules/recipes'
import {
  selectCompositeByIdFromUrl,
  selectImageUrls
} from '../../../redux/selectors/selectors'
import {selectDuplicateRecipeData} from '../../../redux/selectors/search'
import RecipeEditMain from '../components/RecipeEditMain'

const mapStateToPros = state => {
  const recipe = selectCompositeByIdFromUrl(state)
  const {hasDuplicateRecipes} = selectDuplicateRecipeData(state, recipe)

  return {
    hasDuplicateRecipes,
    imageUrls: selectImageUrls(state),
    recipe
  }
}

const mapDispatchToProps = {
  addComponentWithDefaultProduct,
  addRowToInstructions,
  increaseRecipeServings,
  decreaseRecipeServings
}

const RecipeEditMainContainer = compose(
  withRouter,
  connect(
    mapStateToPros,
    mapDispatchToProps
  ),
  withHandlers({
    onAddRowClick: ({addComponentWithDefaultProduct, recipe}) => () => {
      addComponentWithDefaultProduct({composite: recipe})
    }
  })
)(RecipeEditMain)

export default RecipeEditMainContainer
