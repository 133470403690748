import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {IconSettings} from '../../../assets/icons'
import Link from '../../core/components/Link'
import {TextStyle} from '../../core/components/Text'
import Icon from '../../icons/components/Icon'
import IconSuperManu from '../../icons/components/IconSuperManu'

const FooterBase = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${p =>
    p.isSuperAdmin ? '65px 90px auto 1fr 120px' : '65px auto 1fr 120px'};
  grid-template-rows: ${p => p.theme.sizes.footerHeight}px;
  background-color: ${p => p.theme.colors.darkGray};
  @media print {
    display: none;
  }
  z-index: 0;
`

const FooterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  color: ${p => p.theme.colors.boxGray};
`

const AdminDrawerToggle = styled(FooterItem)`
  background-color: ${p => p.theme.colors.blue};
  cursor: pointer;
`

const UserAndRestaurantName = styled(FooterItem)`
  padding-left: 10px;
`

const FooterVersionBadge = styled(FooterItem)`
  grid-column: 5;
`

const ProvidedByText = styled(FooterItem)`
  ${() => TextStyle};
  font-size: ${p => p.theme.fonts.size.small}px;
  text-align: center;
  grid-column: 4;
`

const Footer = ({
  backendVersion,
  initials,
  isSuperAdmin,
  location: {pathname},
  onAdminDrawerToggleClick,
  restaurantName,
  userName,
  webEntryDomain
}) => {
  const _userAndRestaurantName = `${userName && userName} ${userName &&
    restaurantName &&
    '-'} ${restaurantName && restaurantName}`
  const userAndRestaurantNameOverLong = _userAndRestaurantName.length > 43
  const userAndRestaurantName = userAndRestaurantNameOverLong
    ? `${_userAndRestaurantName.slice(0, 60)}...`
    : _userAndRestaurantName

  return (
    <FooterBase isSuperAdmin={isSuperAdmin}>
      <AdminDrawerToggle
        data-testid="adminDrawerToggle"
        onClick={onAdminDrawerToggleClick}
      >
        <Icon src={IconSettings} width="64px" height="64px" />
      </AdminDrawerToggle>
      {isSuperAdmin && webEntryDomain === 'eaternity' && (
        <FooterItem id="footerSuperAdminIcon">
          <IconSuperManu initials={initials} />
        </FooterItem>
      )}
      {pathname !== '/search' && (
        <UserAndRestaurantName>{userAndRestaurantName}</UserAndRestaurantName>
      )}
      {isSuperAdmin && (
        <FooterVersionBadge>{backendVersion}</FooterVersionBadge>
      )}
      {webEntryDomain === 'klimateller' && pathname === '/search' && (
        <ProvidedByText>
          <Link href="https://eaternity.org">Eaternity</Link>
        </ProvidedByText>
      )}
    </FooterBase>
  )
}

Footer.propTypes = {
  backendVersion: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onAdminDrawerToggleClick: PropTypes.func.isRequired,
  restaurantName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default Footer
