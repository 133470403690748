import {curry, equals, omit, partial, pipe, sort, uniqBy} from 'ramda'
import {pickStandardComponentFields} from './componentUtils'
import {diffByProductionDate} from './dateUtils'
import {getScore} from './indicatorUtils'
import {putMenu, updateMenuOptimistic} from '../redux/modules/menus'
import {putRecipe, updateRecipeOptimistic} from '../redux/modules/recipes'
import {sortByUpdatedAt} from './searchUtils'

export const sortByUpdateOrProductionDate = compositeArray => {
  const allHaveUpdateDates = compositeArray.every(
    c => c.updatedAt !== undefined
  )

  const allProductionDatesUniq =
    uniqBy(c => c.productionDate, compositeArray).length ===
    compositeArray.length

  if (allHaveUpdateDates) {
    return sortByUpdatedAt(compositeArray)
  } else if (allProductionDatesUniq) {
    return sort(diffByProductionDate, compositeArray)
  } else {
    console.error(
      'Duplicated recipes cannot be sorted by updateDate or prodcutionDate. Returning unsorted search results!'
    )
    return compositeArray
  }
}

export const extractComponentTitles = curry((components, composite) => ({
  ...composite,
  components: composite.components
    .map(c => {
      const fullComponent = components[c.component.id]

      return {
        title: fullComponent ? fullComponent.title : ''
      }
    })
    .filter(c => c.title)
}))

export const omitCompositeCloudData = omit([
  'co2Rating',
  'co2Value',
  'co2ValueImprovementPercentage',
  'foodUnit',
  'gramsPerServing',
  'kcalPerServing',
  'kJPerServing',
  'nutrients',
  'nutritionalBalanced',
  'rainforestLabel',
  'serviceStatus',
  'vitaScore',
  'vitaScoreImprovementPercentage',
  'vitaScoreRating',
  'waterScarcity',
  'waterScarcityImprovementPercentage',
  'waterScarcityRating'
])

export const pickComponentCompareFields = composite => ({
  ...composite,
  components: composite.components.map(pickStandardComponentFields)
})

export const deepCompareComposites = curry(
  (recipes, composite1, composite2) => {
    if (composite1 === undefined || composite2 === undefined) {
      return true
    }

    const omitId = omit(['id'])
    const removeIds = composite => ({
      ...omitId(composite),
      components: composite.components.map(component => ({
        ...component,
        component: omitId(component.component)
      }))
    })

    const removeIrrelevantFields = omit([
      'authorId',
      'permanent',
      'menuCollectionId',
      'recipeCollectionId',
      'productionDate',
      'createdAt',
      'deletedAt',
      'updatedAt'
    ])

    const prepareForCompare = pipe(
      removeIds,
      removeIrrelevantFields,
      omitCompositeCloudData,
      pickComponentCompareFields
    )

    const comparableComposite1 = prepareForCompare(composite1)
    const comparableComposite2 = prepareForCompare(composite2)

    const compositesAreEqual = equals(
      comparableComposite1,
      comparableComposite2
    )

    if (!compositesAreEqual) {
      return false
    } else {
      /*
     composites are identical here so it's ok to just filter for recipes and
     assume that recipes[component.id] exists for the components of composite1
     and composite2
     */
      return composite1.components
        .filter(({component}) => component.type === 'recipe')
        .map(({component}, index) => {
          return deepCompareComposites(
            recipes,
            recipes[component.id],
            recipes[composite2.components[index].component.id]
          )
        })
        .reduce((p, c) => p && c, true)
    }
  }
)

export const getCompositeCopy = (composites, composite) =>
  Object.values(composites)
    .filter(c => c.title === composite.title)
    .filter(c => deepCompareComposites(composites, composite, c))[0]

export const getPermanentCompositeCopy = (composites, composite) =>
  Object.values(composites)
    .filter(c => c.title === composite.title && c.permanent)
    .filter(c => deepCompareComposites(composites, composite, c))[0]

export const extractIndicatorData = (webEntryDomain, composite) => {
  if (!composite) {
    return undefined
  }

  const {
    co2Rating,
    co2Value,
    co2ValueImprovementPercentage,
    gramsPerServing,
    rainforestLabel,
    vitaScore,
    vitaScoreImprovementPercentage,
    vitaScoreRating,
    waterScarcity,
    waterScarcityImprovementPercentage,
    waterScarcityRating
  } = composite

  const hasAward = rating => rating === 'A'

  const co2 = {
    hasAward: hasAward(co2Rating),
    improvementPercentage: co2ValueImprovementPercentage,
    score: getScore(co2Rating),
    value: co2Value
  }

  const health = {
    hasAward: hasAward(vitaScoreRating),
    improvementPercentage: vitaScoreImprovementPercentage,
    score: getScore(vitaScoreRating),
    value: vitaScore
  }

  const water = {
    hasAward: hasAward(waterScarcityRating),
    improvementPercentage: waterScarcityImprovementPercentage,
    score: getScore(waterScarcityRating),
    value: waterScarcity
  }

  return {
    co2,
    gramsPerServing,
    health,
    rainforestLabel: {
      hasAward: rainforestLabel
    },
    water
  }
}

export const createCompositeUpdateActions = composite => {
  const optimisticUpdateFns = {
    menu: partial(updateMenuOptimistic, [{menu: composite}]),
    recipe: partial(updateRecipeOptimistic, [{recipe: composite}])
  }

  const updateFunctions = {
    menu: partial(putMenu, [{menu: composite}]),
    recipe: partial(putRecipe, [{recipe: composite}])
  }

  return [
    optimisticUpdateFns[composite.type](),
    updateFunctions[composite.type]()
  ]
}

export const updateProductionDate = curry((productionDate, composite) => ({
  ...composite,
  productionDate
}))

export const getSubrecipes = (recipes, recipe) => {
  const subrecipes = recipe.components.flatMap(({component}) =>
    component.type === 'recipe' ? recipes[component.id] : []
  )

  if (subrecipes.length < 0) {
    return []
  } else {
    return [
      ...subrecipes,
      ...subrecipes.flatMap(subrecipe => getSubrecipes(recipes, subrecipe))
    ]
  }
}
