import {useEffect, useState} from 'react'

export const useWindowSize = () => {
  const getSize = () => ({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight
  })

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
