import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import {filter, pipe, slice} from 'ramda'
import React from 'react'
import styled from 'styled-components'
import {
  groupAndLabelByFirstLetter,
  sortByTitle,
  sortResults
} from '../../../utils/searchUtils'
import HitRowBig from './HitRowBig'
import LabelAndLinkHeader from './LabelAndLinkHeader'
import SearchHitsList from './SearchHitsList'
import SearchModalAddButton from './SearchModalAddButton'
import {useTranslation} from 'react-i18next'

const HeaderSearchHitsListWrapper = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
`

const SearchResults = ({
  onAddButtonMouseDown,
  onHitRowMouseDown,
  onLinkClick,
  searchInput,
  searchResults,
  searchTypes,
  webEntryDomain
}) => {
  const [t] = useTranslation()
  const searchTypeToCompositeTypeMap = {
    menus: 'menu',
    recipes: 'recipe'
  }
  const searchTypeSelected = searchTypes.length === 1
  const groupSearchResults = pipe(sortByTitle, groupAndLabelByFirstLetter)
  const searchResultsGroupedByFirstLetter = groupSearchResults(searchResults)

  return searchTypeSelected ? (
    searchResultsGroupedByFirstLetter.length === 0 ? (
      <HitRowBig
        hasAward={false}
        isLast
        marginTop
        isNoResultsPlaceholder
        result={{title: t('searchHitsList.noResultsText')}}
      />
    ) : (
      searchResultsGroupedByFirstLetter.map(({label, results}, index) => (
        <HeaderSearchHitsListWrapper
          id={`HeaderSearchHitsListWrapper${index}`}
          key={label}
        >
          <LabelAndLinkHeader label={label.toUpperCase()} />
          <SearchHitsList
            onHitRowMouseDown={onHitRowMouseDown}
            results={results}
            t={t}
            webEntryDomain={webEntryDomain}
          />
        </HeaderSearchHitsListWrapper>
      ))
    )
  ) : (
    searchTypes.map((searchType, index) => {
      const compositeType = searchTypeToCompositeTypeMap[searchType]

      const prepareSearchResults = pipe(
        sortResults(searchInput),
        slice(0, 25),
        filter(r => r.type === compositeType)
      )

      const preparedSearchResults = prepareSearchResults(searchResults)

      return (
        <HeaderSearchHitsListWrapper key={searchType} isFirst={index === 0}>
          <LabelAndLinkHeader
            linkText={t('searchResults.linkText', {
              compositeType: t(`compositeType.${compositeType}`, {count: 2})
            })}
            label={upperFirst(t(`compositeType.${compositeType}`, {count: 2}))}
            onLinkClick={() => onLinkClick(searchType)}
          />
          <SearchHitsList
            onHitRowMouseDown={onHitRowMouseDown}
            results={preparedSearchResults}
            webEntryDomain={webEntryDomain}
          />
          {compositeType === 'recipe' &&
            !preparedSearchResults.map(r => r.title).includes(searchInput) && (
              <SearchModalAddButton
                id="SearchModalAddButton"
                onMouseDown={onAddButtonMouseDown}
                searchInput={searchInput}
                t={t}
              />
            )}
        </HeaderSearchHitsListWrapper>
      )
    })
  )
}

SearchResults.propTypes = {
  className: PropTypes.string,
  onAddButtonMouseDown: PropTypes.func.isRequired,
  onHitRowMouseDown: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  searchTypes: PropTypes.array.isRequired,
  style: PropTypes.object,
  webEntryDomain: PropTypes.string.isRequired
}

export default SearchResults
