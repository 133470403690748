import {createAction, createReducer} from 'redux-act'

export const initialStateDataGrid = {
  editMode: false,
  preventBlur: false
}

export const setEditMode = createAction('dataGrid/SET_EDIT_MODE')
export const setPreventBlur = createAction('dataGrid/SET_PREVENT_BLUR')

const reducer = createReducer(
  {
    [setEditMode]: (state, payload) => ({
      ...state,
      editMode: payload
    }),
    [setPreventBlur]: (state, payload) => ({
      ...state,
      preventBlur: payload
    })
  },
  initialStateDataGrid
)

export default reducer
