import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import AmountSetter from './AmountSetter'
import CellInput from '../../dataGrid/components/CellInput'

const SectionRightBase = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1;
`

const SectionRightCellInput = styled(CellInput)`
  padding-left: ${p => p.paddingLeft};
`

const SectionRight = ({
  amount,
  autoFocus,
  componentIndex,
  editingAmount,
  editMode,
  hasComponent,
  isEditedComponent,
  isEditedComposite,
  onFocus,
  onComponentTitleChange,
  onComponentTitleBlur,
  onComponentTitleKeyDown,
  onAmountFocus,
  onAmountChange,
  onAmountBlur,
  onUnitFocus,
  onUnitChange,
  onUnitBlur,
  tempAmount,
  tempValue,
  unitOptions,
  unit,
  value
}) => (
  <SectionRightBase id={`SectionRightBase${componentIndex}`}>
    <SectionRightCellInput
      autoFocus={autoFocus}
      editMode={editMode}
      paddingLeft={hasComponent ? '10px' : '70px'}
      onFocus={onFocus}
      onChange={onComponentTitleChange}
      onBlur={onComponentTitleBlur}
      onKeyDown={onComponentTitleKeyDown}
      tempValue={tempValue}
      value={value}
    />
    {(value !== '' ||
      (isEditedComponent && isEditedComposite && tempValue !== '')) && (
      <AmountSetter
        editingAmount={editingAmount}
        amount={amount}
        onAmountChange={onAmountChange}
        onAmountFocus={onAmountFocus}
        onAmountBlur={onAmountBlur}
        onUnitFocus={onUnitFocus}
        onUnitChange={onUnitChange}
        onUnitBlur={onUnitBlur}
        tempAmount={tempAmount}
        unit={unit}
        unitOptions={unitOptions}
      />
    )}
  </SectionRightBase>
)

SectionRight.propTypes = {
  amount: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  componentIndex: PropTypes.number,
  editMode: PropTypes.bool.isRequired,
  editingAmount: PropTypes.bool.isRequired,
  hasComponent: PropTypes.bool.isRequired,
  isEditedComponent: PropTypes.bool.isRequired,
  isEditedComposite: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  onComponentTitleChange: PropTypes.func.isRequired,
  onComponentTitleBlur: PropTypes.func.isRequired,
  onComponentTitleKeyDown: PropTypes.func.isRequired,
  onAmountFocus: PropTypes.func,
  onAmountChange: PropTypes.func.isRequired,
  onAmountBlur: PropTypes.func,
  onUnitFocus: PropTypes.func,
  onUnitChange: PropTypes.func.isRequired,
  onUnitBlur: PropTypes.func,
  tempAmount: PropTypes.string,
  tempValue: PropTypes.string,
  unitOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  unit: PropTypes.string,
  value: PropTypes.string
}

export default SectionRight
