import {connect} from 'react-redux'
import {goBack} from '../../../redux/modules/viewPagerNavigator'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import CompositeImageSelector from '../components/CompositeImageSelector'
import {processImageChanged, removeImage} from '../epics/epics'

const mapStateToProps = state => {
  const composite = selectCompositeByIdFromUrl(state)
  return {
    composite
  }
}

const mapDispatchToProps = {
  goBack,
  processImageChanged,
  removeImage
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompositeImageSelector)
