/* eslint import/no-named-as-default-member: 0 */
import {initReactI18next} from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',

    // allowed languages
    whitelist: ['de', 'en'],

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,
    lowerCaseLng: true,

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    react: {
      useSuspense: false,
      wait: true
    }
  })

i18n.on('languageChanged', lng => {
  window.__localeId__ = lng
})

export {i18n}
