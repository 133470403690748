import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {map, mergeMap} from 'rxjs/operators'
import {loadFromLocalStorage} from '../../utils/localStorage'
import {getAllMenus, getAllMenusSucceeded} from './menus'
import {getAllProducts, getAllProductsSucceeded} from './products'
import {getAllRecipes, getAllRecipesSucceeded} from './recipes'
import {getUserRestaurants, getUserRestaurantsSucceeded} from './restaurants'
import {handleNetworkError} from '../../../build/redux/observables/pipes'

export const initialStateNetWork = {
  backendVersion: loadFromLocalStorage('backendVersion'),
  incomingBackendVersion: undefined,
  getMenusSucceeded: false,
  getProductsSucceeded: false,
  getRecipesSucceeded: false,
  getUserRestaurantsSucceeded: false
}

export const checkServerStatus = createAction(
  'network/CHECK_SERVER_STATUS_REQUESTED'
)
export const checkServerStatusSucceeded = createAction(
  'network/CHECK_SERVER_STATUS_SUCCEEDED'
)
export const resetNetworkState = createAction('network/RESET_NETWORK_STATE')
export const setBackendVersion = createAction('network/SET_BACKEND_VERSION')
export const setIncomingBackendVersion = createAction(
  'network/SET_INCOMING_BACKEND_VERSION'
)

const reducer = createReducer(
  {
    [getAllMenus]: state => ({...state, getMenusSucceeded: false}),
    [getAllProducts]: state => ({
      ...state,
      getProductsSucceeded: false
    }),
    [getAllRecipes]: state => ({
      ...state,
      getRecipesSucceeded: false
    }),
    [getUserRestaurants]: state => ({
      ...state,
      getUserRestaurantsSucceeded: false
    }),
    [getAllMenusSucceeded]: state => ({...state, getMenusSucceeded: true}),
    [getAllProductsSucceeded]: state => ({
      ...state,
      getProductsSucceeded: true
    }),
    [getAllRecipesSucceeded]: state => ({
      ...state,
      getRecipesSucceeded: true
    }),
    [getUserRestaurantsSucceeded]: state => ({
      ...state,
      getUserRestaurantsSucceeded: true
    }),
    [resetNetworkState]: state => ({
      ...state,
      getMenusSucceeded: false,
      getProductsSucceeded: false,
      getRecipesSucceeded: false,
      getUserRestaurantsSucceeded: false
    }),
    [setBackendVersion]: (state, payload) => ({
      ...state,
      backendVersion: payload
    }),
    [setIncomingBackendVersion]: (state, payload) => ({
      ...state,
      incomingBackendVersion: payload
    })
  },
  initialStateNetWork
)

const checkServerStatusEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(checkServerStatus().type),
    mergeMap(() =>
      api.checkServerStatus().pipe(
        map(() => checkServerStatusSucceeded()),
        handleNetworkError
      )
    )
  )

export const networkEpics = combineEpics(checkServerStatusEpic)

export default reducer
