import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import PopOverDialog from '../../popover/components/PopOverDialog'
import SearchAndAddContainer from '../../searchAndAdd/containers/SearchAndAddContainer'
import ViewPagerNavigatorContainer from '../../core/containers/ViewPagerNavigatorContainer'

const DayPlanningPopBase = styled(PopOverDialog)`
  height: 100%;
  margin-right: ${p => p.theme.sizes.popOverMargin}px;
`

const InfoPopOver = ({contentType = 'compositeDetails', sliceType}) => {
  const COMPONENTS = {
    compositeDetails: ViewPagerNavigatorContainer,
    search: SearchAndAddContainer
  }
  const Component = COMPONENTS[contentType]

  if (!Component) {
    return null
  }

  return (
    <DayPlanningPopBase>
      <Component sliceType={sliceType} />
    </DayPlanningPopBase>
  )
}

InfoPopOver.propTypes = {
  contentType: PropTypes.string,
  sliceType: PropTypes.string
}

export default InfoPopOver
