import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {ButtonGroup, GroupButton} from '../../core/components/ScopeChooser'
import Button from '../../core/components/Button'

const SearchTypeChooserButtonGroup = styled(ButtonGroup)`
  width: auto;
`

const SearchTypeChooserGroupButton = styled(GroupButton)`
  padding-left: 15px;
  padding-right: 15px;
`

const ExpandButton = styled(Button)`
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
`

const SearchTypeChooser = ({
  expanded,
  onExpandButtonClick,
  onSearchTypeChooserItemClick,
  searchTypes,
  t
}) => {
  const searchTypeToCompositeTypeMap = {
    menus: 'menu',
    recipes: 'recipe'
  }

  return expanded ? (
    <SearchTypeChooserButtonGroup>
      {searchTypes.map(searchType => (
        <SearchTypeChooserGroupButton
          key={searchType}
          onClick={() => onSearchTypeChooserItemClick(searchType)}
          primary
        >
          {t(`compositeType.${searchTypeToCompositeTypeMap[searchType]}`, {
            count: 2
          }).toUpperCase()}
        </SearchTypeChooserGroupButton>
      ))}
    </SearchTypeChooserButtonGroup>
  ) : (
    <ExpandButton
      id="SearchTypeChooserToggleButton"
      full
      blue
      onClick={onExpandButtonClick}
    >
      {t('inputWithFilter.filterToggelButtonText')}
    </ExpandButton>
  )
}

SearchTypeChooser.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpandButtonClick: PropTypes.func.isRequired,
  onSearchTypeChooserItemClick: PropTypes.func.isRequired,
  searchTypes: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
}

export default SearchTypeChooser
