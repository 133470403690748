import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, mergeMap} from 'rxjs/operators'
import {exportRecipeCsv} from '../../../redux/modules/exports'
import {putRecipe, updateRecipeOptimistic} from '../../../redux/modules/recipes'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import {generateEmptyInstruction} from '../../../utils/recipeUtils'
import {updateInstructionField} from '../utils/utils'

export const addRowToInstructions = createAction(
  'recipes/ADD_ROW_TO_INSTRUCTIONS'
)

export const updateInstruction = createAction(
  'recipeEditView/epics/UPDATE_INSTRUCTION'
)

export const updateInstructionTitle = createAction(
  'recipeEditView/epics/UPDATE_INSTRUCTION_TITLE'
)

export const processRecipeTitle = createAction(
  'recipeEditView/epics/PROCESS_RECIPE_TITLE'
)

export const processDoneButtonClick = createAction(
  'recipeEditView/epics/PROCESS_DONE_BUTTON_CLICK'
)

export const processSharrowClick = createAction(
  'recipeEditView/epics/PROCESS_SHARROW_CLICK'
)

const addRowToInstructionsEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(addRowToInstructions().type),
    mergeMap(({payload: recipe}) => {
      const updatedRecipe = {
        ...recipe,
        instructions: [...recipe.instructions, generateEmptyInstruction()]
      }

      return of(
        updateRecipeOptimistic({recipe: updatedRecipe}),
        putRecipe({recipe: updatedRecipe})
      )
    })
  )

const processRecipeTitleEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processRecipeTitle().type),
    filter(({payload: {newValue, oldValue}}) => newValue !== oldValue),
    mergeMap(({payload: {newValue, recipe}}) => {
      const updatedRecipe = {
        ...recipe,
        title: newValue
      }

      return of(
        updateRecipeOptimistic({recipe: updatedRecipe}),
        putRecipe({recipe: updatedRecipe})
      )
    })
  )

const updateInstructionTitleEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(updateInstructionTitle().type),
    filter(({payload: {oldValue, newValue}}) => oldValue !== newValue),
    mergeMap(({payload: {editedInstructionIndex, newValue, recipe}}) => {
      const updatedRecipe = updateInstructionField({
        editedInstructionIndex,
        field: 'title',
        newValue,
        recipe
      })

      return of(
        updateRecipeOptimistic({recipe: updatedRecipe}),
        putRecipe({recipe: updatedRecipe})
      )
    })
  )

const updateInstructionEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(updateInstruction().type),
    filter(({payload: {contentStateHasChanged}}) => contentStateHasChanged),
    mergeMap(({payload: {editedInstructionIndex, rawInstructions, recipe}}) => {
      const updatedRecipe = updateInstructionField({
        editedInstructionIndex,
        field: 'instruction',
        newValue: rawInstructions,
        recipe
      })

      return of(
        updateRecipeOptimistic({recipe: updatedRecipe}),
        putRecipe({recipe: updatedRecipe})
      )
    })
  )

const processSharrowClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processSharrowClick().type),
    mergeMap(() => {
      const recipe = selectCompositeByIdFromUrl(state$.value)

      return of(exportRecipeCsv(recipe))
    })
  )

export const recipeEditViewEpics = combineEpics(
  addRowToInstructionsEpic,
  processRecipeTitleEpic,
  processSharrowClickEpic,
  updateInstructionEpic,
  updateInstructionTitleEpic
)
