import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import HitRowBig from './HitRowBig'

const SearchHitsListBase = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

const SearchHitsList = ({
  className,
  onHitRowMouseDown,
  results,
  style,
  webEntryDomain
}) => {
  const [t] = useTranslation()

  return (
    <SearchHitsListBase
      id="SearchHitsListBase"
      className={className}
      numRows={results.length}
      style={style}
    >
      {results.length === 0 ? (
        <HitRowBig
          hasAward={false}
          isLast
          isNoResultsPlaceholder
          result={{title: t('searchHitsList.noResultsText')}}
        />
      ) : (
        results.map((result, index) => (
          <HitRowBig
            id={`HitRowBig${index}`}
            hasAward={result.hasAward}
            isLast={index === results.length - 1}
            key={result.id}
            onMouseDown={onHitRowMouseDown}
            result={result}
            webEntryDomain={webEntryDomain}
          />
        ))
      )}
    </SearchHitsListBase>
  )
}

SearchHitsList.propTypes = {
  className: PropTypes.string,
  onHitRowMouseDown: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
  webEntryDomain: PropTypes.string.isRequired
}

export default SearchHitsList
