import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Link from '../../core/components/Link'

const LabelAndLinkHeaderBase = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`
const Label = styled.span`
  ${p => p.theme.fonts.family.default};
  color: ${p => p.theme.colors.darkGray};
  font-weight: ${p => p.theme.fonts.weight.bold};
  font-size: ${p => p.theme.fonts.size.small}px;
`

const LabelAndLinkHeaderLink = styled(Link)`
  font-weight: ${p => p.theme.fonts.weight.bold};
  font-size: ${p => p.theme.fonts.size.small}px;
`

const LabelAndLinkHeader = ({label, linkText, onLinkClick}) => (
  <LabelAndLinkHeaderBase>
    <Label id="LabelAndLinkHeaderLabel">{label}</Label>
    {linkText && (
      <LabelAndLinkHeaderLink onClick={onLinkClick}>
        {linkText}
      </LabelAndLinkHeaderLink>
    )}
  </LabelAndLinkHeaderBase>
)

LabelAndLinkHeader.propTypes = {
  label: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  onLinkClick: PropTypes.func
}

export default LabelAndLinkHeader
