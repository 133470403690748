import PropTypes from 'prop-types'
import React from 'react'

import {withSliceType} from '../../../utils/utils'
import CompositeDetails from '../../dayPlanningView/components/CompositeDetails'
import EditableMenuDetailsContainer from '../../weekPlanningView/containers/EditableMenuDetailsContainer'
import EnvironmentOverviewContainer from '../../indicators/containers/EnvironmentOverviewContainer'
import IndicatorDetailsContainer from '../../indicators/containers/IndicatorDetailsContainer'
import PopOverServingsSetterContainer from '../containers/PopOverServingsSetterContainer'
import ViewPager from './ViewPager'
import CompositeImageSelectorContainer from '../containers/CompositeImageSelectorContainer'

const ViewPagerNavigator = props => {
  const {
    hierarchy,
    match: {path},
    sliceType
  } = props

  const viewsMap = {
    root: path.includes('weekplanning')
      ? function renderEditableMenuDetails() {
          return <EditableMenuDetailsContainer />
        }
      : CompositeDetails,
    indicatorDetails: withSliceType(IndicatorDetailsContainer, sliceType),
    environmentOverview: withSliceType(EnvironmentOverviewContainer, sliceType),
    menuServingsView: function servingSetter() {
      return <PopOverServingsSetterContainer />
    },
    selectCompositeImage: function compositeImageSelector() {
      return <CompositeImageSelectorContainer />
    }
  }

  // just always the last in the hierarchy
  const currentViewIndex = hierarchy.length - 1
  const views = hierarchy.map(({view}) => viewsMap[view])

  return <ViewPager views={views} currentViewIndex={currentViewIndex} />
}

ViewPagerNavigator.propTypes = {
  sliceType: PropTypes.string.isRequired,
  hierarchy: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired
}

export default ViewPagerNavigator
