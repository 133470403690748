import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CellInput from '../../dataGrid/components/CellInput'
import PopOverArrow from '../../popover/components/PopOverArrow'

const HeaderRightBase = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1;
`

const HeaderRightCellInput = styled(CellInput)`
  text-align: center;
  font-weight: ${p => p.theme.fonts.weight.bold};
`

// This component only exist to smuggle in the PopOverArrow...
const HeaderRight = ({
  autoFocus,
  className,
  disabled,
  editMode,
  onBlurOrKeyDown,
  onChange,
  onFocus,
  showArrow,
  style,
  tempValue,
  value
}) => (
  <HeaderRightBase className={className} style={style}>
    <HeaderRightCellInput
      autoFocus={autoFocus}
      disabled={disabled}
      editMode={editMode}
      onBlur={onBlurOrKeyDown}
      onChange={onChange}
      onDoubleClick
      onFocus={onFocus}
      onKeyDown={onBlurOrKeyDown}
      tempValue={tempValue}
      value={value}
    />
    <PopOverArrow show={showArrow} border left />
  </HeaderRightBase>
)

HeaderRight.propTypes = {
  autoFocus: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  onBlurOrKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  showArrow: PropTypes.bool.isRequired,
  style: PropTypes.object,
  tempValue: PropTypes.string,
  value: PropTypes.string
}

export default HeaderRight
