import {goBack, push} from 'connected-react-router'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import {
  resetSearchInput,
  showWelcomeBoxAndRecipePreview
} from '../../../redux/modules/search'
import {
  selectLicenseType,
  selectParentByIdFromUrl,
  selectCompositeByIdFromUrl
} from '../../../redux/selectors/selectors'
import RecipeEditNavigation from '../components/RecipeEditNavigation'
import {processSharrowClick} from '../epics/epics'

const mapStateToPros = state => ({
  licenseType: selectLicenseType(state),
  parent: selectParentByIdFromUrl(state),
  recipe: selectCompositeByIdFromUrl(state)
})

const mapDispatchToProps = {
  goBack,
  onSharrowClick: processSharrowClick,
  push,
  resetSearchInput,
  showWelcomeBoxAndRecipePreview
}

export default compose(
  withRouter,
  connect(mapStateToPros, mapDispatchToProps)
)(RecipeEditNavigation)
