import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {
  selectCompositeByIdFromUrl,
  selectQueryParams,
  selectRecipeIsPinned,
  selectIsPreviewRecipeCopy
} from '../../../redux/selectors/selectors'
import CompositeFactsHeader from '../components/CompositeFactsHeader'
import {
  processMakePreviewRecipeClick,
  processShareRecipeClick
} from '../epics/epics'

const mapStateToProps = state => {
  const composite = selectCompositeByIdFromUrl(state)

  return {
    composite: selectCompositeByIdFromUrl(state),
    isSuperAdmin: state.users.user.email.endsWith('@eaternity.ch'),
    isPreviewRecipeCopy: selectIsPreviewRecipeCopy(state),
    queryParams: selectQueryParams(state),
    selectedDate: state.date.selectedDate,
    showShareIcon: composite && composite.type === 'recipe',
    recipeIsPinned: selectRecipeIsPinned(state)
  }
}

const mapDispatchToProps = {
  onMakePrevievRecipeClick: processMakePreviewRecipeClick,
  onShareRecipeClick: processShareRecipeClick,
  push
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    goToDayPlanning: ({push, queryParams}) => () => {
      const {menuId} = queryParams
      push(`/dayplanning?menuId=${menuId}`)
    }
  })
)(CompositeFactsHeader)
