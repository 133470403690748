import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import {setSelectedDate} from '../../../redux/modules/date'
import {subtractAWeek} from '../utils/calendarUtils'
import CalendarDayListBackButton from '../components/CalendarDayListBackButton'

const mapStateToProps = state => ({
  selectedDate: state.date.selectedDate
})

const mapDispatchToProps = {
  setSelectedDate
}

const LastWeekButtonContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClick: ({selectedDate, setSelectedDate}) => () =>
      setSelectedDate(subtractAWeek(selectedDate))
  })
)(CalendarDayListBackButton)

export default LastWeekButtonContainer
