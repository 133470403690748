import styled from 'styled-components'

const AddRowWrapper = styled.div`
  box-sizing: border-box;
  margin-right: ${p => p.theme.sizes.componentRowOuterColumsWidth}px;
  margin-left: ${p => p.theme.sizes.componentRowOuterColumsWidth}px;
  height: ${props => props.theme.sizes.dataGridRowHeight};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  padding-right: 20px;

  &:hover {
    border-bottom: ${p => p.theme.border.thin};
  }
`
export default AddRowWrapper
