import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {IconRightCalendarArrow} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'

const IconNextWeek = styled(Icon)`
  z-index: 1;
  margin-right: -15px;
  @media print {
    display: none;
  }
`

const CalendarDayListNextButton = ({className, onClick, style}) => (
  <IconNextWeek
    className={className}
    height="40px"
    onClick={onClick}
    style={style}
    src={IconRightCalendarArrow}
    width="40px"
  />
)

CalendarDayListNextButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
}

export default CalendarDayListNextButton
