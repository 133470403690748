import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {showSearchModal} from '../../../redux/modules/modal'
import {resetSearchInput, setSearchTypes} from '../../../redux/modules/search'
import Navigation from '../../core/components/Navigation'
import {processAddButtonClick} from '../../search/epics/epics'
import {processNavIconPlusClick} from '../epics/epics'

const mapStateToProps = ({
  config: {visibleNavIcons},
  restaurants: {selectedRestaurant}
}) => ({
  visibleNavIcons,
  recipeCollectionId:
    selectedRestaurant && selectedRestaurant.recipeCollectionId
})

const mapDispatchToProps = {
  processAddButtonClick,
  processNavIconPlusClick,
  resetSearchInput,
  setSearchTypes,
  showSearchModal
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withHandlers({
    onPlusClick: ({processNavIconPlusClick, recipeCollectionId, t}) => () => {
      processNavIconPlusClick({
        recipeCollectionId,
        newRecipeTitle: t('global.newRecipeTitle'),
        t
      })
    },
    onSearchClick: ({
      resetSearchInput,
      setSearchTypes,
      showSearchModal
    }) => () => {
      resetSearchInput()
      setSearchTypes(['menus', 'recipes'])
      showSearchModal()
    }
  })
)(Navigation)
