import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Button from '../../core/components/Button'
import RowHeader from '../../core/components/RowHeader'
import CompositeFactsBodyContainer from '../../core/containers/CompositeFactsBodyContainer'
import CompositeFactsHeaderContainer from '../../core/containers/CompositeFactsHeaderContainer'
import PlannedPortionsSetterContainer from '../../core/containers/PlannedPortionsSetterContainer'
import PopOverBody from '../../popover/components/PopOverBody'

const DeleteRepeat = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'repeat . delete';
  border-top: ${p => p.theme.border.default};
`

const DeleteRepeatItem = styled.div`
  grid-area: ${p => p.area};
`

const Spacer = styled.div`
  margin-top: ${p => p.size};
`

const EditableMenuBase = styled.div``

const PlannedPortionsRowHeader = styled(RowHeader)`
  margin-bottom: 0;
`

const EditableMenuDetails = ({onDeleteButtonClick}) => {
  const [t] = useTranslation()

  return (
    <EditableMenuBase>
      <CompositeFactsHeaderContainer showEditButton />
      <PopOverBody>
        <DeleteRepeat>
          <DeleteRepeatItem area="delete">
            <Button
              data-cy="compositeDeleteButton"
              primary
              wide
              onClick={onDeleteButtonClick}
            >
              {t('editableMenuDetails.deleteButtonText')}
            </Button>
          </DeleteRepeatItem>
        </DeleteRepeat>
        <PlannedPortionsRowHeader>
          {t('editableMenuDetails.plannedPortionsHeader')}
        </PlannedPortionsRowHeader>
        <PlannedPortionsSetterContainer />
        <Spacer size="30px" />
        <CompositeFactsBodyContainer showServings={false} />
        <Spacer size="20px" />
      </PopOverBody>
    </EditableMenuBase>
  )
}

EditableMenuDetails.propTypes = {
  onDeleteButtonClick: PropTypes.func.isRequired
}

export default EditableMenuDetails
