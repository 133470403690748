import styled from 'styled-components'

const Row = styled.div`
  display: grid;
  grid-template-columns:
    minmax(${p => p.theme.sizes.dataGridLeftColumnWidth}px, 2fr)
    5fr;
`

export default Row
