import styled from 'styled-components'

const Header = styled.div`
  background-color: ${p => p.theme.colors.background};
  border-bottom: ${p => p.theme.border.default};
  box-sizing: border-box;
  height: ${p => p.theme.sizes.headerHeight}px;
  position: relative;
  z-index: 0;
`

export default Header
