import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import ViewPagerNavigator from '../components/ViewPagerNavigator'

const mapStateToProps = state => ({
  hierarchy: state.viewPagerNavigator.hierarchy
})

export default withRouter(connect(mapStateToProps)(ViewPagerNavigator))
