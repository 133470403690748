import {compose, withHandlers, withState} from 'recompose'
import {connect} from 'react-redux'

import {processRecipeTitle} from '../epics/epics'
import {
  resetEditedComponentIndex,
  resetSelectedComponent
} from '../../../redux/modules/components'
import {
  selectCompositeByIdFromUrl,
  selectProductByIdFromUrl
} from '../../../redux/selectors/selectors'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {setSearchTypes, updateSearchInput} from '../../../redux/modules/search'
import HeaderRight from '../../dataGrid/components/HeaderRight'

const mapStateToProps = state => {
  const product = selectProductByIdFromUrl(state)
  const recipe = selectCompositeByIdFromUrl(state)
  const component = recipe || product

  return {
    value: component.title,
    showArrow: state.components.editedComponentIndex === undefined,
    autoFocus: true,
    recipe,
    tempValue: state.search.searchInput
  }
}

const mapDispatchToProps = {
  processRecipeTitle,
  resetEditedComponentIndex,
  resetSelectedComponent,
  setDayPlanningPopOverContentType,
  setSearchTypes,
  updateSearchInput
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('editMode', 'setEditMode', false),
  withHandlers({
    onFocus: ({
      resetEditedComponentIndex,
      resetSelectedComponent,
      setDayPlanningPopOverContentType,
      setEditMode,
      setSearchTypes,
      updateSearchInput,
      value
    }) => () => {
      resetEditedComponentIndex()
      resetSelectedComponent()
      setSearchTypes(['recipes'])
      setEditMode(true)
      updateSearchInput(value)
      setDayPlanningPopOverContentType('compositeDetails')
    },
    onChange: ({setEditMode, updateSearchInput}) => ({target: {value}}) => {
      setEditMode(true)
      updateSearchInput(value)
    },
    onBlurOrKeyDown: ({
      processRecipeTitle,
      recipe,
      tempValue,
      setEditMode,
      setDayPlanningPopOverContentType,
      value
    }) => () => {
      processRecipeTitle({
        newValue: tempValue === '' ? value : tempValue,
        oldValue: value,
        recipe
      })
      setEditMode(false)
      setDayPlanningPopOverContentType('compositeDetails')
    }
  })
)(HeaderRight)
