import {compose, withHandlers, withProps} from 'recompose'
import {connect} from 'react-redux'
import {replace} from 'connected-react-router'
import {withTranslation} from 'react-i18next'

import {addMenu, setEditedMenuId} from '../../../redux/modules/menus'
import {
  calculateArrowPosition,
  calculatePopoverPosition,
  generateWeekData
} from '../utils/calendarUtils'
import {productionDateToDate} from '../../../utils/dateUtils'
import {
  selectMenusOfSelectedRestaurant,
  selectMenuLines
} from '../../../redux/selectors/selectors'
import {
  setCalendarPopOverArrowTop,
  setCalendarPopOverContentType,
  setCalendarPopOverPosition,
  setMenuLineId,
  showCalendarPopOver
} from '../../../redux/modules/view'
import {setSelectedDate} from '../../../redux/modules/date'
import CalendarWeek from '../components/CalendarWeek'
import theme from '../../../theme'

const mapStateToProps = state => ({
  menus: selectMenusOfSelectedRestaurant(state),
  menuLines: selectMenuLines(state),
  calendarScrollInProgress: state.view.calendarScrollInProgress,
  menuCollectionId: state.restaurants.selectedRestaurant.menuCollectionId,
  selectedDate: state.date.selectedDate
})

const mapDispatchToProps = {
  addMenu,
  replace,
  setCalendarPopOverArrowTop,
  setCalendarPopOverPosition,
  showCalendarPopOver,
  setCalendarPopOverContentType,
  setEditedMenuId,
  setMenuLineId,
  setSelectedDate
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({menus, menuLines, startOfWeek, t}) => ({
    weekData: generateWeekData(menus, menuLines, startOfWeek, t)
  })),
  withHandlers({
    handleCalendarItemClick: ({
      addMenu,
      calendarScrollInProgress,
      menuCollectionId,
      replace,
      setCalendarPopOverArrowTop,
      setCalendarPopOverPosition,
      showCalendarPopOver,
      setCalendarPopOverContentType,
      setEditedMenuId,
      setMenuLineId,
      setSelectedDate
    }) => (menu, calendarItem) => {
      if (calendarScrollInProgress) {
        return
      }
      const {id, generated, menuLineId, productionDate} = menu
      const elementBoundingRect = calendarItem.getBoundingClientRect()
      const date = productionDateToDate(productionDate)

      if (generated) {
        addMenu({...menu, menuCollectionId})
        setCalendarPopOverContentType('generatedMenu')
      } else {
        setCalendarPopOverContentType('editableMenuDetails')
      }
      setEditedMenuId(id)
      setSelectedDate(date)
      setMenuLineId(menuLineId)
      setCalendarPopOverPosition(calculatePopoverPosition(id, generated, theme))
      setCalendarPopOverArrowTop(calculateArrowPosition(elementBoundingRect))
      showCalendarPopOver()
      replace(`/weekplanning?menuId=${id}`)
    }
  })
)(CalendarWeek)
