import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import upperFirst from 'lodash/upperFirst'

import {getDateDetails} from '../../../utils/dateUtils'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import GeneratedMenu from '../components/GeneratedMenu'

const mapStateToProps = (state, {t}) => {
  const {
    date: {selectedDate}
  } = state
  const menu = selectCompositeByIdFromUrl(state)
  const {dayOfMonth, dayOfWeek, month} = getDateDetails(selectedDate)

  return {
    menu,
    titleText: menu
      ? `${upperFirst(t('compositeType.menu'))} ${
          menu.menuLineId
        } - ${dayOfWeek} ${dayOfMonth}. ${month}`
      : ''
  }
}

const mapDispatchToProps = {push}

export default compose(
  withRouter,
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    goToDayPlanning: ({menu, push}) => () => {
      push(`/dayplanning?menuId=${menu.id}`)
    }
  })
)(GeneratedMenu)
