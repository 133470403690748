import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import {
  deleteMenuOptimistic,
  setEditedMenuId
} from '../../../redux/modules/menus'
import {goToRoot} from '../../../redux/modules/viewPagerNavigator'
import {hideCalendarPopOver} from '../../../redux/modules/view'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import CalendarPopOver from '../components/CalendarPopOver'

const mapStateToProps = state => ({
  calendarPopOverOpen: state.view.calendarPopOverOpen,
  calendarPopOverPosition: state.view.calendarPopOverPosition,
  calendarPopOverArrowTop: state.view.calendarPopOverArrowTop,
  calendarPopOverContentType: state.view.calendarPopOverContentType,
  menu: selectCompositeByIdFromUrl(state)
})

const mapDispatchToProps = {
  deleteMenuOptimistic,
  hideCalendarPopOver,
  setEditedMenuId,
  goToRoot
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClickOutside: ({
      deleteMenuOptimistic,
      menu,
      goToRoot,
      hideCalendarPopOver,
      setEditedMenuId
    }) => () => {
      hideCalendarPopOver()
      goToRoot()

      if (menu && menu.generated) {
        deleteMenuOptimistic({menu})
        setEditedMenuId(undefined)
      }
    }
  })
)(CalendarPopOver)
