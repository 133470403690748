import {createAction, createReducer} from 'redux-act'
import {loadFromLocalStorage} from '../../../build/utils/localStorage'

export const decreaseNumberOfMenuLines = createAction(
  'menuLines/DECREASE_NUMBER_OF_MENULINES'
)
export const increaseNumberOfMenuLines = createAction(
  'menuLines/DECREASE_NUMBER_OF_MENULINES'
)

export const initialStateMenuLines = {
  numberOfMenuLines: loadFromLocalStorage('numberOfMenuLines') || 3
}

const reducer = createReducer(
  {
    [decreaseNumberOfMenuLines]: state => ({
      ...state,
      numberOfMenuLines: state.numberOfMenuLines - 1
    }),
    [increaseNumberOfMenuLines]: state => ({
      ...state,
      numberOfMenuLines: state.numberOfMenuLines + 1
    })
  },
  initialStateMenuLines
)

export default reducer
