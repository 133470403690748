import {v4} from 'uuid'

export const generateRecipeOrProduct = title => ({
  id: v4(),
  title,
  imageUrl: '',
  components: [
    {
      section: '',
      component: {
        id: '0',
        title: '',
        type: 'product'
      },
      quantity: {
        amount: 100,
        unit: 'GRAM'
      }
    }
  ]
})
