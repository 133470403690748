import {compose, withHandlers, withState} from 'recompose'
import {connect} from 'react-redux'

import {processComponentSection} from '../../dataGrid/epics/epics'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {setEditedComponentIndex} from '../../../redux/modules/components'
import {updateSearchInput} from '../../../redux/modules/search'
import SectionLeft from '../../dataGrid/components/SectionLeft'

const mapStateToProps = (state, {composite, componentIndex}) => ({
  value: composite.components[componentIndex].section || '',
  tempValue: state.search.searchInput,
  composite: composite
})

const mapDispatchToProps = {
  updateSearchInput,
  setEditedComponentIndex,
  processComponentSection,
  setDayPlanningPopOverContentType
}

const SectionInputContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('editMode', 'setEditMode', false),
  withState('tempValue', 'updateTempValue', ''),
  withHandlers({
    onFocus: ({
      componentIndex,
      setEditedComponentIndex,
      setDayPlanningPopOverContentType,
      setEditMode,
      updateTempValue,
      value
    }) => () => {
      setEditedComponentIndex(componentIndex)
      updateTempValue(value)
      setDayPlanningPopOverContentType('compositeDetails')
      setEditMode(true)
    },
    onChange: ({updateTempValue}) => ({target: {value}}) => {
      updateTempValue(value)
    },
    onBlur: ({
      componentIndex,
      processComponentSection,
      tempValue,
      setEditMode,
      composite,
      value
    }) => () => {
      processComponentSection({
        componentIndex,
        oldValue: value,
        newValue: tempValue,
        composite
      })
      setEditMode(false)
    }
  })
)(SectionLeft)

export default SectionInputContainer
