import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React, {createRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Button from '../../core/components/Button'
import OranisationSearchResultsContainer from '../containers/OranisationSearchResultsContainer'
import AdminOptionsList from './AdminOptionsList'
import AdminSearchBar from './AdminSearchBar'
import AdminViewLeftColumn from './AdminViewLeftColumn'
import AdminViewRightColumnGrid from './AdminViewRightColumnGrid'
import ButtonAndTextPanel from './ButtonAndTextPanel'

const OrganisationAdminContentBase = styled.div`
  display: grid;
  grid-template-columns:
    ${p => p.theme.sizes.adminLeftColumnWidth}px
    minmax(
      300px,
      ${p => p.theme.sizes.maxWidth - p.theme.sizes.adminLeftColumnWidth}px
    );
  grid-template-rows: calc(
    100vh - ${p => p.theme.sizes.headerHeight + p.theme.sizes.footerHeight}px
  );
`

const SearchList = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-sizing: border-box;
  padding: 25px 60px;
`

const OrganisationAdminContent = ({
  getBusinesses,
  getUserRestaurants,
  getUserRestaurantsSucceeded,
  searchInput,
  setSearchTypes,
  setSelectedBusiness,
  showAddOrEditOrganisationModal,
  updateSearchInput
}) => {
  const [t] = useTranslation()
  useEffect(() => {
    if (!getUserRestaurantsSucceeded) {
      getUserRestaurants()
    }
    getBusinesses()
    setSearchTypes(['businesses'])
  }, [
    getBusinesses,
    getUserRestaurants,
    getUserRestaurantsSucceeded,
    setSearchTypes
  ])

  const textInputRef = createRef()

  const adminOptions = [
    {text: t('organisationAdmin.organisationAdministration')}
  ]

  return (
    <OrganisationAdminContentBase>
      <AdminViewLeftColumn>
        <ButtonAndTextPanel />
        <AdminOptionsList options={adminOptions} />
      </AdminViewLeftColumn>
      <AdminViewRightColumnGrid>
        <AdminSearchBar
          placeholderText={upperFirst(t('organisationAdmin.searchPlaceholder'))}
          searchInput={searchInput}
          textInputRef={textInputRef}
          updateSearchInput={updateSearchInput}
        />
        <SearchList>
          <Button
            primary
            huge
            fluid
            onMouseDown={() => {
              showAddOrEditOrganisationModal()
              setSelectedBusiness(undefined)
            }}
          >
            {upperFirst(t('organisationAdmin.addOrganisationButtonText'))}
          </Button>
        </SearchList>
        <OranisationSearchResultsContainer />
      </AdminViewRightColumnGrid>{' '}
    </OrganisationAdminContentBase>
  )
}

OrganisationAdminContent.propTypes = {
  getBusinesses: PropTypes.func.isRequired,
  getUserRestaurants: PropTypes.func.isRequired,
  getUserRestaurantsSucceeded: PropTypes.bool.isRequired,
  searchInput: PropTypes.string.isRequired,
  setSearchTypes: PropTypes.func.isRequired,
  setSelectedBusiness: PropTypes.func.isRequired,
  showAddOrEditOrganisationModal: PropTypes.func.isRequired,
  updateSearchInput: PropTypes.func.isRequired
}

export default OrganisationAdminContent
