import moment from 'moment'
import {createSelector} from 'reselect'
import {
  dateToProductionDateString,
  getDaysUntilLicenseExpiry
} from '../../utils/dateUtils'
import {loadSelectedRestaurant} from '../../utils/localStorage'
import {completeMenusOfDay} from '../../utils/menuUtils'
import {getLicenseType} from '../../utils/restaurantUtils'
import {normalizeById, parseQueryString} from '../../utils/utils'
import {getPermanentCompositeCopy} from '../../utils/compositeUtils'

export const getBusinesses = state => state.businesses.businesses
export const getBusinessPartners = state =>
  state.businessPartners.businessPartners
export const getUser = state => state.users.user
export const getUsers = state => state.users.users
export const getSelectedBusiness = state => state.businesses.selectedBusiness
export const getSelectedDate = state => state.date.selectedDate
export const getEditedMenuId = state => state.menus.editedMenuId
export const getMenus = state => state.menus.menus
export const getHierarchy = state => state.viewPagerNavigator.hierarchy
export const getNumResultsToShow = state => state.search.numResultsToShow
export const getSearchInput = state => state.search.searchInput
export const getProducts = state => state.products.products
export const getRecipes = state => state.recipes.recipes
export const getRestaurants = state => state.restaurants.restaurants
export const getSelectedRestaurant = state =>
  state.restaurants.selectedRestaurant || loadSelectedRestaurant()
export const getSearchTypes = state => state.search.searchTypes
export const getEditedComponentIndex = state =>
  state.components.editedComponentIndex
export const getEditedRecipeId = state => state.recipes.editedRecipeId
export const getSelectedComponentType = state =>
  state.components.selectedComponentType
export const getRecipeOrProductId = (_, id) => id
export const getMenuOrRecipeId = (_, id) => id
export const getSelectedComponent = state => state.components.selectedComponent
export const getQueryParams = state =>
  parseQueryString(state.router.location.search)
export const getCollectionIds = ({restaurants: {selectedRestaurant}}) => ({
  menuCollectionId: selectedRestaurant.menuCollectionId,
  recipeCollectionId: selectedRestaurant.recipeCollectionId,
  productCollectionId: selectedRestaurant.productCollectionId
})
export const getWebEntryDomain = state => state.config.webEntryDomain
export const getRestaurantTypes = state => state.restaurants.restaurantTypes
export const getRecipeCollections = state => state.recipes.recipeCollections
export const getModalType = state => state.modal.modalType
export const getRemovedFromRestaurantName = state =>
  state.websocket.removedFromRestaurantName
export const getNumberOfMenuLines = state => state.menuLines.numberOfMenuLines

export const selectEditedMenu = createSelector(
  [getEditedMenuId, getMenus],
  (editedMenuId, menus) => {
    if (editedMenuId !== undefined) {
      return menus[editedMenuId]
    }
  }
)

export const selectGenerated = createSelector(
  [selectEditedMenu],
  editedMenu => editedMenu && editedMenu.generated
)

export const selectEditedRecipe = createSelector(
  [getEditedRecipeId, getRecipes],
  (editedRecipeId, recipes) => recipes[editedRecipeId]
)

export const selectEditedMenuOrRecipe = (state, sliceType) => {
  const selectFunctionsMap = {
    menu: selectEditedMenu,
    recipe: selectEditedRecipe
  }
  return selectFunctionsMap[sliceType](state)
}

export const selectMenusOfSelectedRestaurant = createSelector(
  [getSelectedRestaurant, getMenus],
  (selectedRestaurant, menus) => {
    if (!selectedRestaurant) {
      return {}
    }

    return normalizeById(
      Object.values(menus).filter(
        menu => menu.menuCollectionId === selectedRestaurant.menuCollectionId
      )
    )
  }
)

export const selectRecipesOfSelectedBusiness = createSelector(
  [getSelectedBusiness, getRecipes],
  (selectedBusiness, recipes) => {
    if (!selectedBusiness) {
      return {}
    }

    return normalizeById(
      Object.values(recipes).filter(
        recipe =>
          recipe.recipeCollectionId === selectedBusiness.recipeCollectionId
      )
    )
  }
)

export const selectRecipesOfSelectedRestaurant = createSelector(
  [getSelectedRestaurant, getRecipes],
  (selectedRestaurant, recipes) => {
    return normalizeById(
      Object.values(recipes).filter(
        recipe =>
          recipe.recipeCollectionId === selectedRestaurant.recipeCollectionId
      )
    )
  }
)

export const selectRecipes = createSelector([getRecipes], recipes => recipes)

export const selectProducts = createSelector(
  [getProducts],
  products => products
)

export const selectRecipeOrProduct = createSelector(
  [getRecipeOrProductId, selectRecipes, selectProducts],
  (id, recipes, products) => {
    return recipes[id] || products[id]
  }
)

export const selectProductByIdFromUrl = createSelector(
  [getQueryParams, selectProducts],
  (queryParams, products) => {
    return products[queryParams.productId]
  }
)

export const selectCompositeByIdFromUrl = createSelector(
  [
    getQueryParams,
    selectMenusOfSelectedRestaurant,
    selectRecipesOfSelectedBusiness,
    selectRecipesOfSelectedRestaurant
  ],
  (queryParams, menus, businessRecipes, restaurantRecipes) => {
    const {menuId, recipeId} = queryParams
    const recipes = {
      ...businessRecipes,
      ...restaurantRecipes
    }
    return menus[menuId] || recipes[recipeId]
  }
)

export const selectParentByIdFromUrl = createSelector(
  [getQueryParams, getMenus, getRecipes],
  (queryParams, menus, recipes) => {
    const {parentId} = queryParams
    return menus[parentId] || recipes[parentId]
  }
)

export const selectMenuLines = createSelector(
  [getNumberOfMenuLines],
  numberOfMenuLines =>
    [...Array(numberOfMenuLines + 1).keys()]
      .slice(1)
      .map(menuLineNumber => ({id: menuLineNumber}))
)

export const selectMenusOfDay = createSelector(
  [
    getSelectedDate,
    selectMenusOfSelectedRestaurant,
    selectMenuLines,
    (_, t) => t
  ],
  (selectedDate, menus, menuLines, t) => {
    const formattedDate = dateToProductionDateString(selectedDate)
    const _menusOfDay = Object.values(menus).filter(
      menu => menu.productionDate === formattedDate
    )

    return completeMenusOfDay(formattedDate, _menusOfDay, menuLines, t)
  }
)

export const selectCollectionIds = createSelector(
  [getCollectionIds],
  collectionIds => collectionIds
)

export const selectQueryParams = createSelector(
  [getQueryParams],
  queryParams => queryParams
)

export const selectParentComponentIndexFromUrl = createSelector(
  [getQueryParams],
  queryParams => queryParams.parentComponentIndex
)

export const selectDaysUntilLicenseExpiry = createSelector(
  [getWebEntryDomain, getSelectedRestaurant],
  (webEntryDomain, selectedRestaurant) =>
    selectedRestaurant &&
    getDaysUntilLicenseExpiry(moment, webEntryDomain, selectedRestaurant)
)

export const selectLicenseType = createSelector(
  [getWebEntryDomain, getSelectedRestaurant],
  (webEntryDomain, selectedRestaurant) =>
    selectedRestaurant &&
    getLicenseType(webEntryDomain, selectedRestaurant.licenses)
)

export const selectPrevievRecipeCollectionId = createSelector(
  [getRecipeCollections, selectLicenseType],
  (_recipeCollections, licenseType) => {
    const recipeCollections = Object.values(_recipeCollections)
    const chefsRecipeCollectionIdsByLocale = Object.values(recipeCollections)
      .filter(c => c.name === 'chef')
      .reduce((p, c) => ({...p, [c.locale]: c.id}), {})
    const [klimatellerRecipeCollection] = recipeCollections.filter(
      c => c.name === 'klimateller'
    )
    const locale = window.__localeId__
      ? window.__localeId__.split('-')[0]
      : 'en'

    const previewRecipeCollectionId =
      licenseType === 'klimateller' && klimatellerRecipeCollection
        ? klimatellerRecipeCollection.id
        : licenseType === 'chef'
        ? chefsRecipeCollectionIdsByLocale[locale]
        : undefined

    return previewRecipeCollectionId
  }
)

export const selectPrevievRecipes = createSelector(
  [selectPrevievRecipeCollectionId, getRecipes],
  (previewRecipeCollectionId, recipes) => {
    return previewRecipeCollectionId
      ? Object.values(recipes).filter(
          r => r.recipeCollectionId === previewRecipeCollectionId
        )
      : []
  }
)

export const selectRestaurantTypes = createSelector(
  [getWebEntryDomain, getRestaurantTypes],
  (webEntryDomain, restaurantTypes) => restaurantTypes[webEntryDomain]
)

export const selectSelectedBusiness = createSelector(
  [getSelectedBusiness],
  selectedBusiness => selectedBusiness
)

export const selectImageUrls = createSelector(
  [
    selectMenusOfSelectedRestaurant,
    selectProducts,
    selectRecipesOfSelectedRestaurant
  ],
  (menus, products, recipes) =>
    Object.values({...menus, ...products, ...recipes})
      .flatMap(({id, imageUrl}) => (imageUrl ? [{[id]: imageUrl}] : []))
      .reduce((p, c) => ({...p, ...c}), {})
)

export const selectWebEntryDomain = createSelector(
  getWebEntryDomain,
  webEntryDomain => webEntryDomain
)

export const selectBrandname = createSelector(
  selectWebEntryDomain,
  webEntryDomain => {
    const brandNameMap = {
      eaternity: 'Eaternity',
      klimateller: 'KlimaTeller'
    }

    return brandNameMap[webEntryDomain]
  }
)

export const selectHighlightedText = createSelector(
  [getRemovedFromRestaurantName, getModalType],
  (removedFromRestaurantName, modalType) => {
    const highlightedTextMap = {
      removedFromRestaurant: removedFromRestaurantName
    }

    return highlightedTextMap[modalType]
  }
)

export const selectRecipeCopyInBusinessCollection = createSelector(
  [selectRecipesOfSelectedBusiness, selectCompositeByIdFromUrl],
  (recipesOfSelectedBusiness, composite) => {
    if (!composite || composite.type !== 'recipe') {
      return undefined
    }

    const permanentRecipeCopyInBusinessCollection = getPermanentCompositeCopy(
      recipesOfSelectedBusiness,
      composite
    )

    return permanentRecipeCopyInBusinessCollection
  }
)

export const selectRecipeIsPinned = createSelector(
  [selectRecipeCopyInBusinessCollection],
  permanentRecipeCopyInBusinessCollection => {
    return permanentRecipeCopyInBusinessCollection !== undefined
  }
)

export const selectAllPreviewRecipes = createSelector(
  [getRecipeCollections, getRecipes],
  (recipeCollections, recipes) => {
    const previewRecipeCollectionsIds = Object.values(
      recipeCollections
    ).flatMap(recipeCollection => {
      return recipeCollection.name ? [recipeCollection.id] : []
    })

    return Object.values(recipes).flatMap(recipe =>
      previewRecipeCollectionsIds.includes(recipe.recipeCollectionId)
        ? [recipe]
        : []
    )
  }
)

export const selectIsPreviewRecipeCopy = createSelector(
  [selectAllPreviewRecipes, selectCompositeByIdFromUrl],
  (previewRecipes, composite) => {
    if (
      !composite ||
      composite.type !== 'recipe' ||
      previewRecipes.length === 0
    ) {
      return false
    }

    const previewRecipeCopy = getPermanentCompositeCopy(
      previewRecipes,
      composite
    )

    return previewRecipeCopy !== undefined
  }
)

export const selectUserIsSharedRecipeAuthor = createSelector(
  [getUser, selectRecipeCopyInBusinessCollection],
  (user, recipeCopyInBusinessCollection) => {
    if (!recipeCopyInBusinessCollection) {
      return false
    }

    const userIsSharedRecipeAuthor =
      user.id === recipeCopyInBusinessCollection.authorId

    return userIsSharedRecipeAuthor
  }
)
