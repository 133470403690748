import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import {showAddOrEditUserModal} from '../../../redux/modules/modal'
import {setSearchTypes, updateSearchInput} from '../../../redux/modules/search'
import {getRestaurantUsers} from '../../../redux/modules/users'
import UserAdminContent from '../components/UserAdminContent'

const mapStateToProps = ({
  restaurants: {editedRestaurant, selectedRestaurant},
  search: {searchInput},
  users: {
    user: {defaultRestaurantId}
  }
}) => ({
  defaultRestaurantSelected: selectedRestaurant.id === defaultRestaurantId,
  editedRestaurant,
  searchInput,
  selectedRestaurant
})

const mapDispatchToProps = {
  getRestaurantUsers,
  showAddOrEditUserModal,
  setSearchTypes,
  updateSearchInput
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserAdminContent)
