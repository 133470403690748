/*
search logic is kept in a selector for now but will most likely move to an epic
or something similar as sson as we start moving search logic to the serfver side
 */
import {curry, groupBy, partial} from 'ramda'
import {createSelector} from 'reselect'
import {generateDuplicateRecipeData} from '../../modules/searchAndAdd/utils/utils'
import {
  selectPermanentOrLatestRecipes,
  sortByUpdatedAt
} from '../../utils/searchUtils'
import {
  getBusinesses,
  getBusinessPartners,
  getRestaurants,
  getSearchInput,
  getSearchTypes,
  getUsers,
  selectMenusOfSelectedRestaurant,
  selectProducts,
  selectRecipesOfSelectedRestaurant,
  selectRecipesOfSelectedBusiness
} from './selectors'

export const selectSearchResults = createSelector(
  [
    getBusinesses,
    getBusinessPartners,
    selectMenusOfSelectedRestaurant,
    selectProducts,
    selectRecipesOfSelectedBusiness,
    selectRecipesOfSelectedRestaurant,
    getRestaurants,
    getUsers,
    getSearchInput,
    getSearchTypes
  ],
  (
    businesses,
    businessPartners,
    menus,
    products,
    businessRecipes,
    restaurantRecipes,
    restaurants,
    users,
    searchInput,
    searchTypes
  ) => {
    const sanitize = string => string.toLowerCase().trim()
    const sanitizedSearchInput = sanitize(searchInput)

    const searchBy = curry((by, searchInput, dataToFilter) =>
      searchInput === ''
        ? []
        : Object.values(dataToFilter).filter(x =>
            sanitize(x[by]).includes(searchInput)
          )
    )

    const searchByTitle = searchBy('title')(sanitizedSearchInput)
    const searchByName = searchBy('name')(sanitizedSearchInput)
    const searchByEmail = searchBy('email')(sanitizedSearchInput)

    const searchBusinesses = partial(searchByName, [businesses])
    const searchBusinessPartners = partial(searchByName, [businessPartners])
    const searchMenus = partial(searchByTitle, [menus])
    const searchProducts = partial(searchByTitle, [products])
    const searchRecipes = partial(searchByTitle, [
      {...businessRecipes, ...restaurantRecipes}
    ])
    const searchUsers = partial(searchByEmail, [users])
    const searchRestaurants = partial(searchByName, [restaurants])

    const searchFunctionMap = {
      businesses: searchBusinesses,
      businessPartners: searchBusinessPartners,
      menus: searchMenus,
      recipes: searchRecipes,
      products: searchProducts,
      restaurants: searchRestaurants,
      users: searchUsers
    }

    const groupedSearchResults = searchTypes
      .map(searchType => ({
        [searchType]: searchFunctionMap[searchType]
          ? searchFunctionMap[searchType]()
          : {}
      }))
      .reduce((prev, curr) => ({...prev, ...curr}), {})

    const combinedSearchResults = Object.values(groupedSearchResults).reduce(
      (prev, curr) => [...prev, ...curr],
      []
    )

    const suggestions = combinedSearchResults.filter(result => {
      // a perfect match can only be a recipe or product
      if (result.type === 'recipe' || result.type === 'product') {
        return sanitize(result.title) === sanitizedSearchInput
      } else {
        return false
      }
    })

    const noResults = combinedSearchResults.length === 0

    const hasPerfectMatch = suggestions.length > 0
    const hasMultiplePerfectMatches =
      selectPermanentOrLatestRecipes(suggestions).length > 1

    const searchResults = {
      groupedSearchResults,
      hasPerfectMatch,
      hasMultiplePerfectMatches,
      noResults,
      searchResults: combinedSearchResults,
      suggestions
    }

    return searchResults
  }
)

export const selectTopSuggestion = createSelector(
  [selectSearchResults],
  ({suggestions}) => {
    const suggestionsGroupedByPermanent = groupBy(s => s.permanent, suggestions)
    const permanentSuggestions = suggestionsGroupedByPermanent['true'] || []
    const nonPermanentSuggestions = suggestionsGroupedByPermanent['false'] || []
    const [topPermanentSuggestion] = sortByUpdatedAt(permanentSuggestions)
    const [topNonPermanentSuggestion] = sortByUpdatedAt(nonPermanentSuggestions)
    const topSuggestion =
      suggestions.length === 2 &&
      suggestions.find(s => s.type === 'product') &&
      suggestions.find(s => s.type === 'recipe')
        ? suggestions.find(s => s.type === 'product')
        : topPermanentSuggestion || topNonPermanentSuggestion

    return topSuggestion && topSuggestion.type === 'recipe'
      ? {...topSuggestion, permanent: false}
      : topSuggestion
  }
)

export const selectDuplicateRecipeData = createSelector(
  [selectRecipesOfSelectedRestaurant, (_, result) => result],
  (recipes, result) => {
    if (!result) {
      return {hasDuplicateRecipes: false}
    }

    const sanitize = string => string.toLowerCase().trim()

    let recipesWithSameTitle
    if (!recipes || !result) {
      recipesWithSameTitle = []
    } else {
      recipesWithSameTitle = Object.values(recipes).filter(
        r => sanitize(r.title) === sanitize(result.title)
      )
    }

    return generateDuplicateRecipeData(recipesWithSameTitle)
  }
)
