import {createAction, createReducer} from 'redux-act'
import {ofType} from 'redux-observable'
import {concat, delay, filter, map, mergeMap, take} from 'rxjs/operators'
import {calculatePopoverPosition} from '../../modules/calendar/utils/calendarUtils'
import theme from '../../theme'

// TODO: eliminaate this unspecific state slice
export const initialStateView = {
  calendarPopOverContentType: 'generatedMenu',
  calendarPopOverOpen: false,
  calendarPopOverPosition: {
    top: 0,
    left: 0,
    offsetLeft: 0
  },
  calendarPopOverArrowTop: 0,
  calendarScrollInProgress: false,
  currentMenuLineId: 1,
  datePickerOpen: false,
  dayplanningPopOverContentType: undefined,
  hoveringOtherRow: false,
  numWeeksRendered: 7,
  resizing: false
}

export const hideCalendarPopOver = createAction('view/HIDE_CALENDAR_POP_OVER')
export const showCalendarPopOver = createAction('view/SHOW_CALENDAR_POP_OVER')
export const setMenuLineId = createAction('view/SET_MENU_LINE_ID')
export const setCalendarPopOverContentType = createAction(
  'view/SET_CALENDAR_POP_OVER_CONTENT_TYPE'
)
export const setCalendarPopOverPosition = createAction(
  'view/SET_CALENDAR_POP_OVER_POSITION'
)
export const setCalendarPopOverArrowTop = createAction(
  'view/SET_CALENDAR_POP_OVER_ARROW_TOP'
)
export const toggleDatePicker = createAction('view/TOGGLE_DATE_PICKER')
export const setCalendarScrollInProgress = createAction(
  'view/SET_CALENDAR_SCORLL_IN_PROGRESS'
)
export const setDayPlanningPopOverContentType = createAction(
  'view/SET_DAYPLANNING_POP_OVER_CONTENT_TYPE'
)
export const setHoveringOtherRow = createAction('view/SET_HOVERING_OTHER_ROW')
export const hideDayPlanningPopOver = createAction(
  'view/HIDE_DAYPLANNING_POP_OVER'
)
export const setResizing = createAction('view/SET_RESIZING')

const reducer = createReducer(
  {
    [hideCalendarPopOver]: state => ({...state, calendarPopOverOpen: false}),
    [setMenuLineId]: (state, payload) => ({
      ...state,
      currentMenuLineId: payload
    }),
    [setHoveringOtherRow]: (state, payload) => ({
      ...state,
      hoveringOtherRow: payload
    }),
    [setCalendarPopOverContentType]: (state, payload) => ({
      ...state,
      calendarPopOverContentType: payload
    }),
    [setCalendarPopOverPosition]: (state, payload) => ({
      ...state,
      calendarPopOverPosition: {
        ...state.calendarPopOverPosition,
        ...payload
      }
    }),
    [setDayPlanningPopOverContentType]: (state, payload) => ({
      ...state,
      dayplanningPopOverContentType: payload
    }),
    // TODO: check if we can do the same for the calendar popover
    [hideDayPlanningPopOver]: state => ({
      ...state,
      dayplanningPopOverContentType: undefined
    }),
    [setCalendarPopOverArrowTop]: (state, payload) => ({
      ...state,
      calendarPopOverArrowTop: payload
    }),
    [showCalendarPopOver]: state => ({...state, calendarPopOverOpen: true}),
    [toggleDatePicker]: state => ({
      ...state,
      datePickerOpen: !state.datePickerOpen
    }),
    [setCalendarScrollInProgress]: (state, payload) => ({
      ...state,
      calendarScrollInProgress: payload
    }),
    [setResizing]: (state, payload) => ({...state, resizing: payload})
  },
  initialStateView
)

// epics after reducer cause they see actions AFTER reducer!
export const resizeEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(setResizing().type),
    mergeMap(({payload}) =>
      of(payload).pipe(
        filter(payload => !payload),
        map(() => {
          const {
            menus: {editedMenuId, menus}
          } = state$.value
          const editedMenu = menus[editedMenuId]
          const generated = editedMenu ? editedMenu.generated : false
          const newPopOverPosition = calculatePopoverPosition(
            editedMenuId,
            generated,
            theme
          )

          return setCalendarPopOverPosition(newPopOverPosition)
        }),
        concat(of(setResizing(false)).pipe(delay(750))),
        take(1)
      )
    )
  )

export default reducer
