import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Cell from './Cell'

const HeaderCellRightBase = styled(Cell)`
  border-bottom: ${p => p.theme.border.thin};
`

const HeaderCellRight = ({children, style, className, id}) => (
  <HeaderCellRightBase id={id} style={style} className={className} column={2}>
    {children}
  </HeaderCellRightBase>
)

HeaderCellRight.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string
}

export default HeaderCellRight
