import {connect} from 'react-redux'
import {compose} from 'recompose'
import AdminHeader from '../components/AdminHeader'
import {withRouter} from 'react-router-dom'

const mapStateToProps = ({
  businesses: {selectedBusiness},
  restaurants: {editedRestaurant}
}) => ({editedRestaurant, selectedBusiness})

export default compose(
  withRouter,
  connect(mapStateToProps)
)(AdminHeader)
