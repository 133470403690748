import {connect} from 'react-redux'

import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import SearchAndAdd from '../components/SearchAndAdd'

const mapDispatchToProps = {
  setDayPlanningPopOverContentType
}

export default connect(
  null,
  mapDispatchToProps
)(SearchAndAdd)
