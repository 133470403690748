import PropTypes from 'prop-types'
import React from 'react'
import styled, {css} from 'styled-components'

const Base = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const leftRightStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ViewPage = styled.div`
  ${p => (p.isLeft || p.isRight) && leftRightStyle};
  ${p => p.isLeft && 'transform: translateX(-100%);'} ${p =>
    p.isRight && 'transform: translateX(100%);'} transition: 0.3s;
  width: 100%;
  height: 100%;
`

const ViewPager = ({style, views, currentViewIndex}) => {
  const viewLeftIndex = currentViewIndex - 1
  const viewRightIndex = currentViewIndex + 1
  return (
    <Base style={style}>
      <ViewPage isLeft key={viewLeftIndex}>
        {views[viewLeftIndex] && views[viewLeftIndex]()}
      </ViewPage>
      <ViewPage key={currentViewIndex}>{views[currentViewIndex]()}</ViewPage>
      <ViewPage isRight key={viewRightIndex}>
        {views[viewRightIndex] && views[viewRightIndex]()}
      </ViewPage>
    </Base>
  )
}

ViewPager.propTypes = {
  style: PropTypes.object,
  views: PropTypes.array.isRequired,
  currentViewIndex: PropTypes.number.isRequired
}

export default ViewPager
