import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

import {selectSearchResults} from '../../../redux/selectors/search'
import HitRow from '../components/HitRow'

const mapStateToProps = (state, {t}) => {
  const {searchInput, searchTypes} = state.search
  const {searchResults} = selectSearchResults(state)
  const hitType = searchTypes.includes('menus') ? 'menu' : 'ingredient'

  return {
    hide: searchResults.length > 0 || searchInput.length > 0,
    hoveringOtherRow: false,
    index: 0,
    isFirst: false,
    result: {title: t('hitRow.noResults.title')},
    subRowText: t('hitRow.noResults.subRowText', {
      hitType: t(`hitRow.hitType.${hitType}`)
    })
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(HitRow)
