import React from 'react'
import styled from 'styled-components'

import Portal from '../../core/components/Portal'

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.lightGray};
`
const FourOFour = styled.div`
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.huge}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
`

const Message = styled.div`
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.huge}px;
  font-weight: ${p => p.theme.fonts.weight.normal};
`

const NotFound = () => (
  <Portal isOpen>
    <Background>
      <FourOFour>404</FourOFour>
      <Message>Not found</Message>
    </Background>
  </Portal>
)

export default NotFound
