import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const MenuLineBoxesBase = styled.div`
  width: ${p => p.theme.sizes.calendarLeftColumnWidth}px;
  height: 100%;
`

const MenuLineBox = styled.div`
  height: ${p => (p.isFirst ? 128 : 119)}px;
  border-right: ${p => p.theme.border.default};
  border-bottom: ${p => !p.isLast && p.theme.border.default};
`

const MenuLineTitle = styled.div`
  ${p => p.theme.fonts.family.default};
  color: ${p => p.theme.colors.darkGray};
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  padding-top: 20px;
  text-align: center;
`

const MenuLineBoxes = ({menuLines}) => {
  const [t] = useTranslation()

  const menuLineTitles = menuLines.map(
    ({id}) => `${upperFirst(t('compositeType.menu'))} ${id}`
  )

  return (
    <MenuLineBoxesBase>
      {menuLineTitles.map((title, index, self) => {
        const isLast = index === self.length - 1
        const isFirst = index === 0

        return (
          <MenuLineBox isFirst={isFirst} isLast={isLast} key={title}>
            <MenuLineTitle>{title}</MenuLineTitle>
          </MenuLineBox>
        )
      })}
    </MenuLineBoxesBase>
  )
}

MenuLineBoxes.propTypes = {
  menuLines: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default MenuLineBoxes
