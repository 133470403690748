import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import ProductIcon from '../../icons/components/ProductIcon'
import ComponentInputContainer from '../containers/ComponentInputContainer'
import ComponentRowPopOverArrowContainer from '../containers/ComponentRowPopOverArrowContainer'
import DeleteRowButtonContainer from '../containers/DeleteRowButtonContainer'
import SectionInputContainer from '../containers/SectionInputContainer'
import CellLeft from './CellLeft'
import CellRight from './CellRight'
import HeaderCellLeft from './HeaderCellLeft'
import HeaderCellRight from './HeaderCellRight'
import HeaderRow from './HeaderRow'
import Row from './Row'
import DefaultProductIcon from '../../icons/components/DefaultProductIcon'
import DefaultRecipeIcon from '../../icons/components/DefaultRecipeIcon'
import SubIconInProgress from '../../icons/components/SubIconInProgress'
import RecipeIconWithImage from '../../icons/components/RecipeIconWithImage'

const DataGridBase = styled.div`
  display: grid;
  grid-template-rows: repeat(
    ${p => p.numRows},
    ${p => p.theme.sizes.dataGridRowHeight}px
  );
`
const RowLeft = styled.div`
  display: grid;
  grid-template-columns: ${p => p.theme.sizes.componentRowOuterColumsWidth}px auto;
`

const RowRight = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.hasComponent ? '60px' : '0px')} 0px 0px auto ${p =>
      p.theme.sizes.componentRowOuterColumsWidth}px;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${p => p.theme.border.thin};
`

const defaultIconMap = {
  product: DefaultProductIcon,
  recipe: DefaultRecipeIcon
}

const DataGrid = ({
  composite,
  imageUrls,
  renderBodyCellLeft,
  renderBodyCellRight,
  renderHeaderCellLeft,
  renderHeaderCellRight
}) => (
  <DataGridBase
    numRows={
      renderHeaderCellLeft || renderHeaderCellRight
        ? composite.components.length + 1
        : composite.components.length
    }
  >
    {(renderHeaderCellLeft || renderHeaderCellRight) && (
      <HeaderRow>
        <HeaderCellLeft>{renderHeaderCellLeft()}</HeaderCellLeft>
        <HeaderCellRight
          id={`HeaderCellRight${
            composite.type === 'menu' ? composite.menuLineId : ''
          }`}
        >
          {renderHeaderCellRight()}
        </HeaderCellRight>
      </HeaderRow>
    )}
    {composite.components.map((component, componentIndex) => {
      // there is a header row in the grid and grid rows start with 1
      const row = componentIndex + 2
      const lastRow = componentIndex + 1 >= composite.components.length
      const sectionBelow = !!(
        composite.components[componentIndex + 1] &&
        composite.components[componentIndex + 1].section
      )
      const componentId = component.component.id
      const componentType = component.component.type
      const hasComponent = componentId !== '0'
      const imageUrl = hasComponent && imageUrls[componentId]
      const hasImageUrl = !!imageUrl
      const DefaultIcon = defaultIconMap[componentType]

      return (
        <Row key={`${componentId}${componentIndex}`}>
          <RowLeft>
            <DeleteRowButtonContainer
              component={component}
              componentIndex={componentIndex}
              composite={composite}
            />
            <CellLeft
              borderBottom={lastRow || sectionBelow}
              componentIndex={componentIndex}
              row={row}
            >
              {renderBodyCellLeft ? (
                renderBodyCellLeft()
              ) : (
                <SectionInputContainer
                  composite={composite}
                  componentIndex={componentIndex}
                />
              )}
            </CellLeft>
          </RowLeft>
          <RowRight hasComponent={hasComponent}>
            <IconWrapper>
              {!hasComponent ? null : hasImageUrl &&
                componentType === 'product' ? (
                <ProductIcon name={imageUrl} size={40} />
              ) : hasImageUrl && componentType === 'recipe' ? (
                <RecipeIconWithImage imageUrl={imageUrl} size={59} />
              ) : (
                <DefaultIcon
                  renderNotification={() => (
                    <SubIconInProgress visible={component.unmatched} />
                  )}
                  size={59}
                />
              )}
            </IconWrapper>
            <CellRight row={row}>
              {renderBodyCellRight ? (
                renderBodyCellRight()
              ) : (
                <ComponentInputContainer
                  hasComponent={hasComponent}
                  composite={composite}
                  componentIndex={componentIndex}
                />
              )}
            </CellRight>
            <ComponentRowPopOverArrowContainer
              componentIndex={componentIndex}
              composite={composite}
            />
          </RowRight>
        </Row>
      )
    })}
  </DataGridBase>
)

DataGrid.propTypes = {
  composite: PropTypes.object.isRequired,
  imageUrls: PropTypes.object,
  renderBodyCellLeft: PropTypes.func,
  renderBodyCellRight: PropTypes.func,
  renderHeaderCellLeft: PropTypes.func,
  renderHeaderCellRight: PropTypes.func
}

export default DataGrid
