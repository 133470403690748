import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconDiv = styled.div`
  position: relative;
  width: ${p => p.width};
  height: ${p => p.height};
  cursor: ${p => (p.onClick ? 'pointer' : 'normal')};
`

const Svg = styled.img`
  width: ${p => p.size}px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`

const Icon = ({
  className,
  height,
  dataTestId,
  onClick,
  size,
  src,
  style,
  width
}) => (
  <IconDiv
    className={className}
    height={height}
    data-testid={dataTestId}
    onClick={onClick}
    style={style}
    width={width}
  >
    <Svg draggable={false} src={src} size={size} />
  </IconDiv>
)

Icon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  width: PropTypes.string.isRequired
}

export default Icon
