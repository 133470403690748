/*
 this file is used to configure differences like branding, feature set etc..between the 2 client side apps
*/

export const configuration = {
  visibleIndicators: ['co2', 'health', 'water', 'rainforestLabel'],
  visibleIndicatorsOnComponentLevel: ['co2', 'water'],
  visibleNavIcons: ['plus', 'search'],
  webEntryDomain: 'eaternity'
}
