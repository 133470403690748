import React from 'react'
import styled from 'styled-components'
import SearchModalContentContainer from '../containers/SearchModalContentContainer'

const SearchViewContentBase = styled.div`
  height: 100%;
  background-color: ${p => p.theme.colors.background};
  display: flex;
  justify-content: center;
`

const SearchViewContent = () => (
  <SearchViewContentBase>
    <SearchModalContentContainer />
  </SearchViewContentBase>
)

export default SearchViewContent
