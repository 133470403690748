import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {IconMenu} from '../../../assets/icons'
import DefaultProductIcon from '../../icons/components/DefaultProductIcon'
import DefaultRecipeIcon from '../../icons/components/DefaultRecipeIcon'
import ProductIcon from '../../icons/components/ProductIcon'
import RecipeIconWithImage from '../../icons/components/RecipeIconWithImage'
import SubIconCount from '../../icons/components/SubIconCount'

const HitRowBase = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: ${p => p.active && p.theme.colors.mediumGray};
  width: auto;
  ${p => p.theme.fonts.family.default};
  font-size: 17px;
  line-height: 20px;
  border-bottom: ${p => p.theme.border.default};
  padding: ${p => (p.active ? '0 32px' : '0 15px')};
  border-top: ${p => p.active && p.theme.border.default};
  margin-top: ${p => p.active && '20px'};
  margin-top: ${p => p.active && '-1px'};
  margin-left: ${p => p.active && '-17px'};
  margin-right: ${p => p.active && '-17px'};

  & > * {
    pointer-events: none;
  }
`

const HitInfoColumn = styled.div`
  display: flex;
  width: 100%;
  margin-left: 5px;
  flex-direction: column;
  font-size: ${p => p.theme.fonts.size.normal}px;
`

const HitInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  max-width: 270px;
`

const HitInfoSubRow = styled.div`
  color: ${p => p.theme.colors.gray};
  font-size: ${p => p.theme.fonts.size.small}px;
  height: 15px;
`

const ProductIconWrapper = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: 12px;
`

const IconPlaceHolder = styled.div`
  height: 64px;
`

const iconMap = {
  menu: IconMenu,
  product: DefaultProductIcon,
  recipe: DefaultRecipeIcon
}

const HitRow = ({
  hide,
  hovering,
  hoveringOtherRow,
  index,
  isFirst,
  numberOfDuplicates,
  onClick,
  setPreventBlur,
  onMouseEnter,
  onMouseLeave,
  result,
  subRowText
}) => {
  if (hide) {
    return null
  }

  const {title: resultString, type} = result
  const active = hovering || (isFirst && !hoveringOtherRow)
  const maxTitleLength = 28
  const resultStringTooLong = resultString.length > maxTitleLength
  const shortenedResultString = `${resultString.slice(0, maxTitleLength)}...`
  const imageUrl = result.imageUrl
  const hasImageUrl = !!imageUrl
  const Icon = iconMap[type]

  return (
    <HitRowBase
      active={active}
      id={`HitRowBase${index}`}
      onClick={onClick}
      onMouseDown={setPreventBlur ? () => setPreventBlur(true) : null}
      onMouseEnter={isFirst ? undefined : onMouseEnter}
      onMouseLeave={isFirst ? undefined : onMouseLeave}
      onTouchStart={setPreventBlur ? () => setPreventBlur(true) : null}
    >
      {hasImageUrl && type === 'product' ? (
        <ProductIconWrapper>
          <ProductIcon name={imageUrl} size={40} />
        </ProductIconWrapper>
      ) : hasImageUrl && type === 'recipe' ? (
        <RecipeIconWithImage imageUrl={imageUrl} />
      ) : Object.keys(iconMap).includes(type) ? (
        <Icon
          renderNotification={() =>
            numberOfDuplicates > 0 ? (
              <SubIconCount count={numberOfDuplicates} fillColor="#3D454D" />
            ) : null
          }
        />
      ) : (
        <IconPlaceHolder />
      )}
      <HitInfoColumn>
        <HitInfoRow>
          {resultStringTooLong ? shortenedResultString : resultString}
        </HitInfoRow>
        {subRowText && <HitInfoSubRow>{subRowText}</HitInfoSubRow>}
      </HitInfoColumn>
    </HitRowBase>
  )
}

HitRow.propTypes = {
  hide: PropTypes.bool,
  hovering: PropTypes.bool,
  hoveringOtherRow: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isFirst: PropTypes.bool,
  numberOfDuplicates: PropTypes.number,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  result: PropTypes.object,
  setPreventBlur: PropTypes.func,
  subRowText: PropTypes.string
}

export default HitRow
