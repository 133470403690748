import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 534px;
  touch-callout: none;
  user-select: none;
  cursor: default;
  @media print {
    display: none;
  }
`

export const GroupButton = styled.div`
  flex-grow: 1;
  cursor: pointer;
  padding: 11px 0px;
  width: 100%;
  color: ${p => (p.active ? '#FFFFFF' : '#3D454C')};
  background-color: ${p => (p.active ? '#3D454C' : '')};
  ${p => p.theme.fonts.family.default};
  font-size: 14px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  line-height: 16px;
  text-align: center;
  border: 1px solid #3d454c;

  &:hover {
    background-color: #ddd;
    color: #3d454c;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    margin-left: -1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const ScopeChooser = ({gotoScope, goToDayPlanning, location: {pathname}}) => {
  const [t] = useTranslation()

  return (
    <ButtonGroup>
      <GroupButton
        active={pathname === '/weekplanning'}
        onClick={() => gotoScope('/weekplanning')}
      >
        {t('scopechooser.week')}
      </GroupButton>
      <GroupButton
        active={pathname === '/dayplanning'}
        onClick={goToDayPlanning}
      >
        {t('scopechooser.day')}
      </GroupButton>
    </ButtonGroup>
  )
}

ScopeChooser.propTypes = {
  location: PropTypes.object.isRequired,
  gotoScope: PropTypes.func.isRequired,
  goToDayPlanning: PropTypes.func.isRequired
}

export default ScopeChooser
