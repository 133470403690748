import React from 'react'
import PropTypes from 'prop-types'

const ProductIcon = ({name, size}) => (
  <svg
    version="1.1"
    height={size}
    width={size}
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <use xlinkHref={`#${name.toLowerCase()}`}></use>
  </svg>
)

ProductIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
}

export default ProductIcon
