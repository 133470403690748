import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Button from '../../core/components/Button'
import PopOverBody from '../../popover/components/PopOverBody'
import PopOverTitleRow from '../../popover/components/PopOverTitleRow'

const GeneratedMenuBase = styled.div``

const CreateButton = styled(Button)`
  margin-left: auto;
`

const Spacer = styled.div`
  margin-top: 50px;
`

const PopOverContentGeneratedMenu = ({goToDayPlanning, t, titleText}) => {
  return (
    <GeneratedMenuBase>
      <PopOverTitleRow>{titleText}</PopOverTitleRow>
      <PopOverBody>
        <CreateButton
          id="PopOverContentGeneratedMenuCreateButton"
          full
          blue
          onClick={goToDayPlanning}
        >
          {t('generatedMenu.buttonText')}
        </CreateButton>
        <Spacer />
      </PopOverBody>
    </GeneratedMenuBase>
  )
}

PopOverContentGeneratedMenu.propTypes = {
  goToDayPlanning: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired
}

export default PopOverContentGeneratedMenu
