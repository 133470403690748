import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import {setSelectedDate} from '../../../redux/modules/date'
import {subtractADay} from '../../calendar/utils/calendarUtils'
import CalendarDayListBackButton from '../../calendar/components/CalendarDayListBackButton'

const mapStateToProps = state => ({
  selectedDate: state.date.selectedDate
})

const mapDispatchToProps = {
  setSelectedDate
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onClick: ({selectedDate, setSelectedDate}) => () =>
      setSelectedDate(subtractADay(selectedDate))
  })
)(CalendarDayListBackButton)
