import {useEffect, useState} from 'react'
import Bowser from 'bowser'

const useBowserState = () => {
  const [bowserState, setBowserState] = useState({
    isValidBrowser: true,
    platformType: undefined
  })

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent)

    const supportedCrossPlatformBrowsers = {
      chrome: '>=60',
      chromium: '>=60',
      firefox: '>=58',
      opera: '>= 57'
    }

    setBowserState(prevState => ({
      ...prevState,
      isValidBrowser:
        !!window.Cypress ||
        browser.satisfies({
          linux: supportedCrossPlatformBrowsers,
          macos: {
            safari: '>=11',
            ...supportedCrossPlatformBrowsers
          },
          windows: {
            'microsoft edge': '>=16',
            ...supportedCrossPlatformBrowsers
          },
          tablet: {
            safari: '>=11',
            ...supportedCrossPlatformBrowsers
          }
        }) ||
        false,
      platformType: browser.getPlatformType()
    }))
  }, [])

  return bowserState
}

export default useBowserState
