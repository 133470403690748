import {connect} from 'react-redux'
import {selectSearchResults} from '../../../redux/selectors/search'
import AdminSearchResults from '../components/AdminSearchResults'
import {setSelectedBusiness} from '../../../redux/modules/businesses'
import {showAddOrEditOrganisationModal} from '../../../redux/modules/modal'

const mapStateToProps = state => {
  const {groupedSearchResults} = selectSearchResults(state)
  const allResults = Object.values(state.businesses.businesses)

  return {
    results:
      state.search.searchInput === ''
        ? allResults.filter(r => !r.businessPartnerId)
        : groupedSearchResults.businesses.filter(r => !r.businessPartnerId)
  }
}

const mapDispatchToProps = dispatch => ({
  onSearchHitRowClick: result => {
    dispatch(setSelectedBusiness(result))
    dispatch(showAddOrEditOrganisationModal())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearchResults)
