import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import styled from 'styled-components'

const AdminHeaderBase = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${p => p.theme.sizes.adminLeftColumnWidth}px auto;
  grid-template-rows: ${p => p.theme.sizes.headerHeight}px;
  background-color: ${p => p.theme.colors.background};
`

const AdminHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${p => p.theme.colors.background};
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.big}px;
  letter-spacing: 0.35px;
  border-bottom: ${p => p.theme.border.default};
  border-right: ${p => !p.isLast && p.theme.border.default};
  color: ${p => p.theme.colors.darkGray};
`

const AdminHeader = ({
  editedRestaurant,
  location: {pathname},
  selectedBusiness
}) => {
  const [t] = useTranslation()

  const leftColumnHeaderTextMap = {
    '/businesses': upperFirst(t('global.businessPartner')),
    '/restaurants': selectedBusiness
      ? selectedBusiness.name
      : upperFirst(t('global.organisation')),
    '/settings': t('global.userSettings'),
    '/users': editedRestaurant
      ? editedRestaurant.name
      : upperFirst(t('global.restaurant'))
  }

  const rightColumnHeaderTextMap = {
    '/businesses': upperFirst(t('global.organisation', {count: 2})),
    '/business-partners': upperFirst(t('global.businessPartner', {count: 2})),
    '/restaurants': upperFirst(t('global.restaurant', {count: 2})),
    '/settings': t('global.userSettings'),
    '/users': upperFirst(t('global.user', {count: 2}))
  }

  return (
    <AdminHeaderBase>
      <AdminHeaderItem>{leftColumnHeaderTextMap[pathname]}</AdminHeaderItem>
      <AdminHeaderItem isLast>
        {`${upperFirst(t('global.registered'))} ${
          rightColumnHeaderTextMap[pathname]
        }`}
      </AdminHeaderItem>
    </AdminHeaderBase>
  )
}

AdminHeader.propTypes = {
  editedRestaurant: PropTypes.object,
  location: PropTypes.object.isRequired,
  selectedBusiness: PropTypes.object
}

export default withRouter(AdminHeader)
