import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {TextStyle} from './Text'
import ImageOrFallBack from './ImageOrFallback'

const BoxItemBigBase = styled.div`
  border: ${p => p.theme.border.default};
  border-radius: 4px;
  width: 187px;
  height: 197px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
`

const HeaderBar = styled.div`
  ${() => TextStyle};
  font-size: ${p => p.theme.fonts.size.small}px;
  color: white;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 32px;
  align-items: center;
  grid-template-areas: 'leftComponent . rightComponent';
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: ${p => p.theme.colors.blue};
  padding: 0 10px;
`

const HeaderComponentLeft = styled.div`
  grid-area: leftComponent;
`

const HeaderComponentRight = styled.div`
  grid-area: rightComponent;
`

const TitleBox = styled.div`
  ${() => TextStyle};
  color: ${p => p.theme.colors.darkGray};
  font-weight: ${p => p.theme.fonts.weight.bold};
  font-size: ${p => p.theme.fonts.size.small}px;
  display: flex;
  line-height: normal;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  padding: 0 10px;
  border-top: ${p => p.theme.border.default};
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`

const FallBack = styled.div`
  ${() => TextStyle};
  color: ${p => p.theme.colors.white};
  font-weight: ${p => p.theme.fonts.weight.thin};
  font-size: ${p => p.theme.fonts.size.biggerThanHuge}px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${p => p.theme.colors.boxGray};
  height: 123px;
  width: 100%;
  text-shadow: rgba(0, 0, 0, 0.2) 0px 1px;
`

const BoxItemBig = ({
  imageUrl,
  onClick,
  title,
  renderHeaderLeftComponent,
  renderHeaderRightComponent
}) => {
  const titleIsOverlong = title.length > 40
  const shortenedTitle = `${title.slice(0, 37)}...`

  return (
    <BoxItemBigBase data-cy="boxItemBig" onClick={onClick}>
      <HeaderBar>
        <HeaderComponentLeft>
          {renderHeaderLeftComponent && renderHeaderLeftComponent()}
        </HeaderComponentLeft>
        <HeaderComponentRight>
          {renderHeaderRightComponent && renderHeaderRightComponent()}
        </HeaderComponentRight>
      </HeaderBar>
      <ImageOrFallBack
        imageUrl={imageUrl}
        fallback={() => <FallBack>{upperFirst(title.slice(0, 2))}</FallBack>}
      />
      <TitleBox>
        {upperFirst(titleIsOverlong ? shortenedTitle : title)}
      </TitleBox>
    </BoxItemBigBase>
  )
}

BoxItemBig.propTypes = {
  onClick: PropTypes.func,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  renderHeaderLeftComponent: PropTypes.func,
  renderHeaderRightComponent: PropTypes.func
}

export default BoxItemBig
