import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import AdminDrawerContainer from '../../admin/containers/AdminDrawerContainer'

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: ${p =>
    p.adminDrawerOpen ? p.theme.sizes.adminDrawerWidth + 'px 1fr' : '1fr'};
  grid-template-areas:
    '${p => p.adminDrawerOpen && 'adminDrawer'} contentRight'
`

const ContentRight = styled.div`
  grid-area: contentRight;
`

const LayoutWithAdminDrawer = ({adminDrawerOpen, renderContentRight}) => (
  <LayoutGrid adminDrawerOpen={adminDrawerOpen}>
    <AdminDrawerContainer />
    <ContentRight>{renderContentRight()}</ContentRight>
  </LayoutGrid>
)

LayoutWithAdminDrawer.propTypes = {
  adminDrawerOpen: PropTypes.bool.isRequired,
  renderContentRight: PropTypes.func.isRequired
}

export default LayoutWithAdminDrawer
