import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'
import {resetEditedComponentIndex} from '../../../redux/modules/components'
import {setEditedMenuId} from '../../../redux/modules/menus'
import {selectImageUrls} from '../../../redux/selectors/selectors'
import {addComponentWithDefaultProduct} from '../../dataGrid/epics/epics'
import MenuEdit from '../components/MenuEdit'

const mapStateToProps = state => ({
  imageUrls: selectImageUrls(state)
})

const mapDispatchToProps = {
  addComponentWithDefaultProduct,
  resetEditedComponentIndex,
  setEditedMenuId
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onAddButtonClick: ({
      addComponentWithDefaultProduct,
      menu,
      resetEditedComponentIndex,
      setEditedMenuId
    }) => id => {
      setEditedMenuId(id)
      resetEditedComponentIndex()
      addComponentWithDefaultProduct({composite: menu})
    }
  })
)(MenuEdit)
