import {Editor, EditorState, RichUtils} from 'draft-js'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'

const DraftEditorBase = styled.div`
  ${p => p.theme.fonts.family.default};
  box-sizing: border-box;
  color: ${p => p.theme.colors.darkGray};
  cursor: text;
  font-size: ${p => p.theme.fonts.size.normal}px;
  height: 100%;
  max-width: ${p => p.theme.sizes.dataGridRightColumnMaxWidth};
  width: 100%;
`

class DraftEditor extends Component {
  constructor(props) {
    super(props)
    props.contentState
      ? (this.state = {
          editorState: EditorState.createWithContent(props.contentState),
          contentStateHasChanged: false
        })
      : (this.state = {editorState: EditorState.createEmpty()})

    this.onChange = newEditorState => {
      const contentStateHasChanged =
        this.state.editorState.getCurrentContent() !==
        newEditorState.getCurrentContent()

      this.setState({contentStateHasChanged, editorState: newEditorState})
    }
    this.handleKeyCommand = this._handleKeyCommand.bind(this)
    this.onTab = this._onTab.bind(this)
    this.setDomEditorRef = ref => (this.domEditor = ref)
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.contentState.getPlainText() !==
      this.props.contentState.getPlainText()
    ) {
      this.setState({
        editorState: EditorState.createWithContent(this.props.contentState),
        contentStateHasChanged: true
      })
    }
  }

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return true
    }
    return false
  }

  _onTab(e) {
    const maxDepth = 4
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth))
  }

  render() {
    const {className, onBlur, onFocus, style} = this.props
    const {contentStateHasChanged, editorState} = this.state
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let classNameDraft = 'RichEditor-editor'
    const contentState = editorState.getCurrentContent()
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        classNameDraft += ' RichEditor-hidePlaceholder'
      }
    }

    return (
      <DraftEditorBase
        className={className}
        onClick={() => {
          this.domEditor.focus()
          this.focus
        }}
        style={style}
      >
        <div className={classNameDraft}>
          <Editor
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onBlur={() => onBlur({contentState, contentStateHasChanged})}
            onChange={this.onChange}
            onFocus={onFocus}
            onTab={this.onTab}
            ref={this.setDomEditorRef}
            spellCheck={true}
          />
        </div>
      </DraftEditorBase>
    )
  }
}

DraftEditor.propTypes = {
  className: PropTypes.string,
  contentState: PropTypes.object,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  style: PropTypes.object
}

export default DraftEditor
