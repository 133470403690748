export const loadUserInfo = () => {
  try {
    const serializedUserInfo = window.localStorage.getItem('user')
    if (serializedUserInfo === null) {
      return undefined
    }

    return JSON.parse(serializedUserInfo)
  } catch (err) {
    return undefined
  }
}

export const saveUserInfo = userInfo => {
  try {
    const serializedUserInfo = JSON.stringify(userInfo)
    window.localStorage.setItem('user', serializedUserInfo)
  } catch (err) {
    console.error(err)
  }
}
export const loadSelectedRestaurant = () => {
  try {
    const serializedSelectedRestaurant = window.localStorage.getItem(
      'selectedRestaurant'
    )
    if (serializedSelectedRestaurant === null) {
      return undefined
    }

    return JSON.parse(serializedSelectedRestaurant)
  } catch (err) {
    return undefined
  }
}

export const saveSelectedRestaurant = selectedRestaurant => {
  try {
    const serializedSelectedRestaurant = JSON.stringify(selectedRestaurant)
    window.localStorage.setItem(
      'selectedRestaurant',
      serializedSelectedRestaurant
    )
  } catch (err) {
    console.error(err)
  }
}

export const saveToLocalStorage = (fieldname, item) => {
  try {
    const serializedItem = JSON.stringify(item)
    window.localStorage.setItem(fieldname, serializedItem)
  } catch (err) {
    console.error(err)
  }
}

export const loadFromLocalStorage = fieldname => {
  try {
    const serializedItem = window.localStorage.getItem(fieldname)
    if (serializedItem === null) {
      return undefined
    }

    return JSON.parse(serializedItem)
  } catch (err) {
    return undefined
  }
}
