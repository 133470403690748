import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import AddRowWrapper from '../../core/components/AddRowWrapper'
import Button from '../../core/components/Button'
import DataGrid from '../../dataGrid/components/DataGrid'
import MenuLineTitleInputContainer from '../containers/MenuLineTitleInputContainer'
import MenuTitleInputContainer from '../containers/MenuTitleInputContainer'

const AddButton = styled(Button)`
  box-sizing: border-box;
  height: 38px;
`

const MenuEdit = ({imageUrls, menu, onAddButtonClick}) => {
  const [t] = useTranslation()

  return (
    <>
      <DataGrid
        composite={menu}
        imageUrls={imageUrls}
        renderHeaderCellLeft={() => (
          <MenuLineTitleInputContainer menuLineId={menu.menuLineId} />
        )}
        renderHeaderCellRight={() => <MenuTitleInputContainer menu={menu} />}
      />
      <AddRowWrapper onClick={() => onAddButtonClick(menu.id)}>
        <AddButton primary>{t('menuEdit.addRowButtonText')}</AddButton>
      </AddRowWrapper>
    </>
  )
}

MenuEdit.propTypes = {
  imageUrls: PropTypes.object.isRequired,
  menu: PropTypes.object.isRequired,
  onAddButtonClick: PropTypes.func.isRequired
}

export default MenuEdit
