import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {catchError, map, mergeMap} from 'rxjs/operators'
import {saveAs} from 'file-saver'
import moment from 'moment'
import {saveToLocalStorage} from '../../utils/localStorage'
import {showLoginAgainModal} from './modal'

export const initialStateExports = {
  fetchingExport: false
}

export const exportRecipeCsv = createAction('exports/GET_RECIPE_CSV_REQUESTED')
export const exportRecipeCsvSucceeded = createAction(
  'exports/GET_RECIPE_CSV_SUCCEEDED'
)
export const exportRecipeCsvFailed = createAction(
  'exports/GET_RECIPE_CSV_FAILED'
)

export const exportRecipesCsv = createAction(
  'exports/GET_RECIPES_CSV_REQUESTED'
)
export const exportRecipesCsvSucceeded = createAction(
  'exports/GET_RECIPES_CSV_SUCCEEDED'
)
export const exportRecipesCsvFailed = createAction(
  'exports/GET_RECIPES_CSV_FAILED'
)

const reducer = createReducer(
  {
    [exportRecipeCsv]: state => ({
      ...state,
      fetchingExport: true
    }),
    [exportRecipeCsvSucceeded]: state => ({
      ...state,
      fetchingExport: false
    }),
    [exportRecipeCsvFailed]: state => ({
      ...state,
      fetchingExport: false
    }),
    [exportRecipesCsv]: state => ({
      ...state,
      fetchingExport: true
    }),
    [exportRecipesCsvSucceeded]: state => ({
      ...state,
      fetchingExport: false
    }),
    [exportRecipesCsvFailed]: state => ({
      ...state,
      fetchingExport: false
    })
  },
  initialStateExports
)

export const exportRecipeCsvEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(exportRecipeCsv().type),
    mergeMap(({payload: recipe}) => {
      return api.getRecipeCsv(recipe.id).pipe(
        map(({response: recipeCsv}) => {
          const dateString = moment.utc().format('YYYY_MM-DD-hhmmss')

          const recipeCsvBlob = new Blob([recipeCsv], {
            type: 'text/plain;charset=utf-8'
          })

          saveAs(
            recipeCsvBlob,
            `${dateString}_${recipe.title || 'no title'}.csv`
          )
          return exportRecipeCsvSucceeded()
        }),
        catchError(() => {
          saveToLocalStorage('modalType', 'networkError')
          saveToLocalStorage('loginAgainModalOpen', true)
          return of(exportRecipesCsvFailed(), showLoginAgainModal())
        })
      )
    })
  )

export const exportRecipesCsvEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(exportRecipesCsv().type),
    mergeMap(({payload: query}) => {
      const fcnMap = {
        recipes: api.getRecipesCsv,
        menus: api.getMenusCsv
      }
      return fcnMap[query.recipesOrMenus](query).pipe(
        map(({response: recipesCsv}) => {
          const dateString = moment.utc().format('YYYY_MM-DD-hhmmss')

          const recipesCsvBlob = new Blob([recipesCsv], {
            type: 'text/plain;charset=utf-8'
          })

          const withIngredients =
            query.recipesOrIngredients === 'ingredients' ? '_ingredients' : ''
          saveAs(
            recipesCsvBlob,
            `${dateString}_${query.recipesOrMenus}${withIngredients}_export.csv`
          )

          return exportRecipesCsvSucceeded()
        }),
        catchError(() => {
          saveToLocalStorage('modalType', 'networkError')
          saveToLocalStorage('loginAgainModalOpen', true)
          return of(exportRecipesCsvFailed(), showLoginAgainModal())
        })
      )
    })
  )

export const exportsEpics = combineEpics(
  exportRecipeCsvEpic,
  exportRecipesCsvEpic
)

export default reducer
