import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import ComponentDetailsContainer from '../../componentDetails/containers/ComponentDetailsContainer'
import PopOverBody from '../../popover/components/PopOverBody'
import SearchResultsContainer from '../containers/SearchResultsContainer'
import HeaderRowsContainer from '../containers/HeaderRowsContainer'

const SearchAndAddBase = styled.div``

const SearchAndAdd = ({setDayPlanningPopOverContentType}) => (
  <SearchAndAddBase id="SearchAndAddBase">
    <HeaderRowsContainer />
    <PopOverBody
      onKeyDown={({keyCode}) => {
        // keyCode 27 is the esc key
        if (keyCode === 27) {
          setDayPlanningPopOverContentType('compositeDetails')
        }
      }}
    >
      <SearchResultsContainer />
      <ComponentDetailsContainer />
    </PopOverBody>
  </SearchAndAddBase>
)

SearchAndAdd.propTypes = {
  setDayPlanningPopOverContentType: PropTypes.func.isRequired
}

export default SearchAndAdd
