import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {closeServingSetter} from '../../../redux/modules/viewPagerNavigator'
import {
  hideCalendarPopOver,
  setCalendarPopOverContentType,
  setCalendarPopOverPosition,
  setDayPlanningPopOverContentType,
  setMenuLineId
} from '../../../redux/modules/view'
import {parseQueryString} from '../../../utils/utils'
import {selectEditedMenu} from '../../../redux/selectors/selectors'
import {setEditedMenuId} from '../../../redux/modules/menus'
import ScopeChooser from '../components/ScopeChooser'
import theme from '../../../theme'

const mapStateToProps = state => {
  return {
    location: state.router.location,
    editedMenu: selectEditedMenu(state),
    selectedDate: state.date.selectedDate
  }
}

const mapDispatchToProps = {
  hideCalendarPopOver,
  push,
  setCalendarPopOverContentType,
  setDayPlanningPopOverContentType,
  setCalendarPopOverPosition,
  setEditedMenuId,
  setMenuLineId,
  closeServingSetter
}

export default compose(
  withRouter,
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    goToDayPlanning: ({push}) => () => {
      push('/dayplanning')
    },
    gotoScope: ({
      editedMenu,
      hideCalendarPopOver,
      location: {pathname, search},
      push,
      setCalendarPopOverContentType,
      setCalendarPopOverPosition,
      closeServingSetter
    }) => nextPath => {
      closeServingSetter()
      const {menuId} = parseQueryString(search)
      // TODO: move this to an epic like in the goToDayPlanning handler!
      if (!editedMenu) {
        hideCalendarPopOver()
      } else if (
        pathname.includes('dayplanning') &&
        nextPath.includes('weekplanning')
      ) {
        nextPath =
          menuId !== undefined ? `/weekplanning?menuId=${menuId}` : nextPath
        const {generated} = editedMenu
        const {
          sizes: {popOverMargin}
        } = theme
        let calendarPopOverType
        if (generated) {
          calendarPopOverType = 'generatedMenu'
        } else {
          calendarPopOverType = 'editableMenuDetails'
          setCalendarPopOverPosition({top: popOverMargin})
        }
        setCalendarPopOverContentType(calendarPopOverType)
      }

      push(`${nextPath}`)
    }
  })
)(ScopeChooser)
