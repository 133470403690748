import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import Text from '../../core/components/Text'
import useBowserState from '../../../hooks/useBowserState'
import Button from './Button'

const NotSupportedBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${p => p.theme.colors.background};
`

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  padding: 40px 30px;
  border: ${p => p.theme.border.default};
  border-radius: 4px;
  max-width: 400px;
`

const NotSupportedHeader = styled(Text)`
  font-size: ${p => p.theme.fonts.size.bigger}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  color: black;
`

const NotSupportedMessage = styled(Text)`
  margin-top: 20px;
`

const NotSupportedButton = styled(Button)`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`

const NotSupported = () => {
  const {platformType} = useBowserState()
  const [t, i18n] = useTranslation()
  const translateKey = platformType === 'mobile' ? 'mobile' : 'default'

  return (
    <NotSupportedBase>
      {platformType && (
        <MessageBox>
          <NotSupportedHeader>
            {t(`notSupported.${translateKey}.header`)}
          </NotSupportedHeader>
          <NotSupportedMessage>
            {t(`notSupported.${translateKey}.message`)}
          </NotSupportedMessage>
          {platformType !== 'mobile' && (
            <a href={`http://outdatedbrowser.com/${i18n.language}`}>
              <NotSupportedButton full blue>
                {t('notSupported.buttonText')}
              </NotSupportedButton>
            </a>
          )}
        </MessageBox>
      )}
    </NotSupportedBase>
  )
}

export default NotSupported
