import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Icon from '../../icons/components/Icon'
import Text from '../../core/components/Text'

const WelcomeBoxBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 70px;
`

const WelcomeBoxText = styled(Text)`
  margin-top: 90px;
  width: 324px;
  height: 60px;
  font-size: ${p => p.theme.fonts.size.small}px;
`

const WelcomeBox = ({className, style, t}) => (
  <WelcomeBoxBase className={className} style={style}>
    <Icon
      src={`${process.env.PUBLIC_URL}/assets/icons/logo.svg`}
      width="64x"
      height="64px"
      size={260}
    />
    <WelcomeBoxText>{t('welcomeBox.paragraph1')}</WelcomeBoxText>
  </WelcomeBoxBase>
)

WelcomeBox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  t: PropTypes.func.isRequired
}

export default WelcomeBox
