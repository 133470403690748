import {omit} from 'ramda'
import {v4} from 'uuid'

export const generateStandardProduct = ({
  id,
  title,
  permanent,
  productCollectionId
}) => {
  const createAndUpdateDate = Date.now()

  return {
    createdAt: createAndUpdateDate,
    updatedAt: createAndUpdateDate,
    id: id || v4(),
    title: title || '',
    /*
  Adding a generated flag for now to distinguish a new product from one
  coming in from the search. Flag is removed when first posted/added to the
  products slice. Hope this is a good idea...
   */
    generated: true,
    type: 'product',
    productCollectionId,
    permanent: permanent || false
  }
}

export const generateEmptyProduct = () => ({
  id: '0',
  type: 'product'
})

export const checkIfProductIsLinked = ({
  menus,
  recipes,
  composite,
  product
}) => {
  /*
The logic in here is quite straight forward atm because we have all
menus, recipes and products present in the client and we are not taking
into account products that might be owned by an entity higher up the chain
like the business. Expect this logic to move to the node server side
once we stop pulling all data into the client...
*/

  const menusWithoutComposite = omit([composite.id], menus)
  const recipeWithoutComposite = omit([composite.id], recipes)

  const possibleParents = [
    ...Object.values(menusWithoutComposite),
    ...Object.values(recipeWithoutComposite)
  ]

  const linkedProducts = possibleParents
    .map(parent => {
      return parent.components.map(component => component.component)
    })
    .reduce((p, c) => [...p, ...c], [])
    .filter(component => component.type === 'product')
    .map(product => product.id)

  return linkedProducts.includes(product.id)
}
