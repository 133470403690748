import {compose, withHandlers, withState} from 'recompose'
import {connect} from 'react-redux'

import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import {setEditedInstructionIndex} from '../../../redux/modules/recipes'
import {updateInstructionTitle} from '../epics/epics'
import InstructionTitleInput from '../components/InstructionTitleInput'

const mapStateToProps = state => ({
  recipe: selectCompositeByIdFromUrl(state)
})

const mapDispatchToProps = {
  setEditedInstructionIndex,
  updateInstructionTitle
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('editMode', 'setEditMode', false),
  withState('instructionTitle', 'setInstructionTitle', ''),

  withHandlers({
    onFocus: ({setEditMode, setInstructionTitle}) => ({target: {value}}) => {
      setEditMode(true)
      setInstructionTitle(value)
    },
    onChange: ({setInstructionTitle}) => ({target: {value}}) => {
      setInstructionTitle(value)
    },
    onBlur: ({
      editedInstructionIndex,
      instructionTitle,
      recipe,
      setEditMode,
      updateInstructionTitle,
      value
    }) => () => {
      updateInstructionTitle({
        editedInstructionIndex,
        newValue: instructionTitle,
        oldValue: value,
        recipe
      })
      setEditMode(false)
    }
  })
)(InstructionTitleInput)
