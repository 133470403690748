import round from 'lodash/round'
import upperFirst from 'lodash/upperFirst'

export const getComparisonText = ({
  compositeType,
  hasAward,
  improvementPercentage,
  indicatorType,
  indicatorValue,
  t
}) => {
  const improvementPercentageAsNumber = Number(improvementPercentage)
  const absolutePercentage = Math.abs(improvementPercentageAsNumber)
  const isImproved = improvementPercentageAsNumber > 0

  const _diff = isImproved
    ? indicatorValue / (1 - improvementPercentageAsNumber / 100) -
      indicatorValue
    : indicatorValue -
      indicatorValue / (1 - improvementPercentageAsNumber / 100)

  const diff = round(_diff)

  const moreLessText = t(
    `indicatorDetails.comparisonText.moreLessText.${
      isImproved ? 'less' : 'more'
    }`
  )

  const interpolateData = {
    compositeType: t(`compositeType.${compositeType}`, {count: 1}),
    absolutePercentage,
    diff,
    moreLessText
  }

  return t(
    `indicatorDetails.comparisonText.${
      hasAward ? 'award' : 'noAward'
    }.${indicatorType}`,
    interpolateData
  )
}

export const getAwardText = ({compositeType, indicatorType, t}) => {
  return t(`indicatorDetails.awardText.text.${indicatorType}`, {
    compositeType: t(`compositeType.${compositeType}`, {
      count: 1
    }),
    compositeTypePlural: upperFirst(
      t(`compositeType.${compositeType}`, {count: 2})
    )
  })
}

export const getClassificationText = (awards, compositeType) => {
  return awards.water
    ? `This ${compositeType} preserves scarce water.`
    : `This ${compositeType} does not receive any awards.`
}
