import emailValidator from 'email-validator'
import upperFirst from 'lodash/upperFirst'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {openAdminDrawer} from '../../../redux/modules/admin'
import {getBusinesses} from '../../../redux/modules/businesses'
import {
  hideSearchModal,
  showBuyLicenseModal
} from '../../../redux/modules/modal'
import {resetSearchInput} from '../../../redux/modules/search'
import {selectDaysUntilLicenseExpiry} from '../../../redux/selectors/selectors'
import {saveToLocalStorage} from '../../../utils/localStorage'
import Footer from '../components/Footer'

const mapStateToProps = state => {
  const {
    config: {webEntryDomain},
    network: {backendVersion},
    restaurants: {editedRestaurant, selectedRestaurant},
    users: {
      user: {email, firstName, isBusinessPartnerAdmin, lastName}
    }
  } = state
  const restaurantName =
    isBusinessPartnerAdmin && editedRestaurant
      ? editedRestaurant.name
      : selectedRestaurant
      ? selectedRestaurant.name
      : ''
  const isSuperAdmin = email.endsWith('@eaternity.ch')

  return {
    backendVersion: `v${backendVersion}`,
    daysUntilLicenseExpiry: selectDaysUntilLicenseExpiry(state),
    initials:
      firstName && lastName
        ? `${firstName[0]}${lastName[0]}`.toUpperCase()
        : email.slice(0, 2),
    isBusinessPartnerAdmin,
    isSuperAdmin,
    restaurantName: isSuperAdmin
      ? restaurantName
      : emailValidator.validate(restaurantName)
      ? ''
      : restaurantName,
    selectedRestaurant,
    userName:
      firstName && lastName
        ? `${upperFirst(firstName)} ${upperFirst(lastName)}`
        : email,
    webEntryDomain
  }
}

const mapDispatchToProps = {
  getBusinesses,
  hideSearchModal,
  openAdminDrawer,
  resetSearchInput,
  showBuyLicenseModal
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onAdminDrawerToggleClick: ({
      getBusinesses,
      isBusinessPartnerAdmin,
      openAdminDrawer,
      resetSearchInput
    }) => () => {
      if (isBusinessPartnerAdmin) {
        getBusinesses()
      }
      resetSearchInput()
      saveToLocalStorage('adminDrawerOpen', true)
      openAdminDrawer()
    }
  })
)(Footer)
