import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {IconLeftArrowPopover, IconPrint} from '../../../assets/icons'
import {IconSharrow} from '../../../assets/icons/index'
import Button from '../../core/components/Button'
import {NavigationBase, NavigationItem} from '../../core/components/Navigation'
import Icon from '../../icons/components/Icon'
import {downloadRecipeAsCsv} from '../../../utils/recipeUtils'

const RecipeEditNavigationBase = styled(NavigationBase)`
  grid-template-columns: 50px ${p =>
      p.theme.sizes.dataGridLeftColumnWidth - 60}px 1fr auto 40px 40px ${p =>
      p.theme.sizes.popoverWidth +
      p.theme.sizes.popOverMargin +
      p.theme.sizes.dayPlanningContentMarginRight}px;
  grid-template-areas: 'back-arrow  back-arrow-text  nav-hint  back-button print sharrow .';
`

const TextItem = styled.div`
  grid-area: 'back-arrow-text';
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.big}px;
  font-weight: ${p => p.theme.fonts.weight.normal};
`

const DoneButtonItem = styled(NavigationItem)`
  justify-self: end;
`

const PrintIcon = styled(Icon)`
  cursor: pointer;
`

const NavHintItem = styled(NavigationItem)`
  justify-self: center;
`

const RecipeEditNavigation = ({
  parent,
  goBack,
  location: {pathname},
  onSharrowClick,
  resetSearchInput
}) => {
  const [t] = useTranslation()

  const backButtonText = parent
    ? `${
        parent.title.length > 13
          ? parent.title.slice(0, 10) + '...'
          : parent.title
      }`
    : t('recipeEditNavigation.backButtonText')
  const doneButtonText = upperFirst(t('global.back'))
  const showBackButton = !!parent

  const handleBackClick = () => {
    resetSearchInput()
    goBack()
  }

  return (
    <RecipeEditNavigationBase>
      {showBackButton ? (
        <NavigationItem
          area="back-arrow"
          data-cy="recipeEditNavigationBackArrow"
          onClick={handleBackClick}
        >
          <Icon
            draggable={false}
            src={IconLeftArrowPopover}
            width="60px"
            height="60px"
          />
        </NavigationItem>
      ) : (
        <div />
      )}
      {showBackButton ? (
        <NavigationItem area="back-arrow-text" onClick={handleBackClick}>
          <TextItem>{backButtonText}</TextItem>
        </NavigationItem>
      ) : (
        <div />
      )}
      <NavHintItem area="nav-hint">
        <TextItem>
          {t(
            `recipeEditNavigation.${
              pathname === '/select' ? 'select' : 'recipe'
            }.title`
          )}
        </TextItem>
      </NavHintItem>
      <DoneButtonItem
        data-cy="recipeEditNavigationBackButton"
        area="back-button"
      >
        <Button full blue onClick={handleBackClick}>
          {doneButtonText}
        </Button>
      </DoneButtonItem>
      <NavigationItem
        area="sharrow"
        id="RecipeEditNavigationSharrow"
        onClick={onSharrowClick}
      >
        <PrintIcon
          draggable={false}
          src={IconSharrow}
          width="60px"
          height="60px"
        />
      </NavigationItem>
      <NavigationItem
        area="print"
        id="RecipeEditNavigationPrint"
        onClick={() => {
          window.print()
        }}
      >
        <PrintIcon
          draggable={false}
          src={IconPrint}
          width="60px"
          height="60px"
        />
      </NavigationItem>
    </RecipeEditNavigationBase>
  )
}

RecipeEditNavigation.propTypes = {
  onSharrowClick: PropTypes.func.isRequired,
  parent: PropTypes.object,
  goBack: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  resetSearchInput: PropTypes.func.isRequired
}

export default RecipeEditNavigation
