import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {IconCross} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'

const DeleteRowButtonBase = styled.div`
  background-color: ${p => p.theme.colors.background};
  border: ${p => p.theme.border.thin};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-sizing: border-box;
  margin-top: -1px;
  @media print {
    display: none;
  }
`

const DeleteRowButton = ({onClick, show}) => {
  if (!show) {
    return null
  }

  return (
    <DeleteRowButtonBase id="DeleteRowButtonBase" onClick={onClick}>
      <Icon src={IconCross} width="60px" height="60px" size={60} />
    </DeleteRowButtonBase>
  )
}

DeleteRowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default DeleteRowButton
