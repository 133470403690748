import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {IconLeftCalendarArrow} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'

const IconLastWeek = styled(Icon)`
  z-index: 1;
  margin-left: -15px;
  @media print {
    display: none;
  }
`

const CalendarDayListBackButton = ({className, onClick, style}) => (
  <IconLastWeek
    className={className}
    height="40px"
    onClick={onClick}
    src={IconLeftCalendarArrow}
    style={style}
    width="40px"
  />
)

CalendarDayListBackButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
}

export default CalendarDayListBackButton
