import {connect} from 'react-redux'

import {setSelectedDate} from '../../../redux/modules/date'
import CalendarDaySelector from '../components/CalendarDaySelector'

const mapStateToProps = (state, ownProps) => ({
  selectedDate: state.date.selectedDate,
  ...ownProps
})

const mapDispatchToProps = {
  setSelectedDate
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDaySelector)
