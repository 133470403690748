import {createAction, createReducer} from 'redux-act'

export const initialStateComponents = {
  editedComponentIndex: undefined,
  selectedComponent: undefined
}

export const resetEditedComponentIndex = createAction(
  'components/RESET_EDITED_COMPONENT_INDEX'
)

export const resetSelectedComponent = createAction(
  'components/RESET_SELECTED_COMPONENT'
)

export const setSelectedComponent = createAction(
  'components/SET_SELECTED_COMPONENT'
)

export const setEditedComponentIndex = createAction(
  'components/SET_EDITED_COMPONENT_INDEX'
)

const reducer = createReducer(
  {
    [resetEditedComponentIndex]: state => ({
      ...state,
      editedComponentIndex: undefined
    }),
    [resetSelectedComponent]: state => ({
      ...state,
      selectedComponent: undefined
    }),
    [setSelectedComponent]: (state, payload) => ({
      ...state,
      selectedComponent: payload
    }),
    [setEditedComponentIndex]: (state, payload) => ({
      ...state,
      editedComponentIndex: payload
    })
  },
  initialStateComponents
)

export default reducer
