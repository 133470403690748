import {createAction, createReducer} from 'redux-act'

export const initialStateIndicators = {
  indicatorTypes: ['co2', 'profit', 'health', 'water'],
  selectedIndicatorType: ''
}

// read somewhere thet it is a best practice to name action creators
// with verbNoun and types with NOUN_VERB... so here you go
export const setSelectedIndicatorType = createAction(
  'indicators/SELECTED_INDICATOR_TYPE_SET'
)

const reducer = createReducer(
  {
    [setSelectedIndicatorType]: (state, payload) => ({
      ...state,
      selectedIndicatorType: payload
    })
  },
  initialStateIndicators
)

export default reducer
