import {compose} from 'recompose'
import {connect} from 'react-redux'
import InfoPopOver from '../components/InfoPopOver'

const mapStateToProps = state => ({
  contentType: state.view.dayplanningPopOverContentType
})

const mapDispatchToProps = {}

const InfoPopOverContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InfoPopOver)

export default InfoPopOverContainer
