import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {IconLeftArrowPopover} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'
import InputWithFilter from './InputWithFilter'
import {useTranslation} from 'react-i18next'

const SearchBarBase = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: ${p => p.theme.sizes.fullScreenModalMargin}px 1fr ${p =>
      p.theme.sizes.fullScreenModalMargin}px;
  width: 100%;
`

const SearchBar = ({
  className,
  hideSearchModal,
  hideWelcomeBoxAndRecipePreview,
  inputToFocusRef,
  licenseType,
  onKeyDown,
  pathname,
  resetSearchInput,
  searchInput,
  searchTypes,
  setSearchTypes,
  showWelcomeBoxAndRecipePreview,
  style,
  updateSearchInput
}) => {
  const [t] = useTranslation()

  return (
    <SearchBarBase className={className} style={style}>
      {pathname !== '/search' ? (
        <Icon
          dataTestId="searchBarBackButton"
          src={IconLeftArrowPopover}
          onClick={hideSearchModal}
          height="60px"
          width="60px"
        />
      ) : (
        <div />
      )}
      <InputWithFilter
        autoFocus={!pathname.includes('search')}
        licenseType={licenseType}
        showWelcomeBoxAndRecipePreview={showWelcomeBoxAndRecipePreview}
        hideWelcomeBoxAndRecipePreview={hideWelcomeBoxAndRecipePreview}
        onKeyDown={onKeyDown}
        inputToFocusRef={inputToFocusRef}
        resetSearchInput={resetSearchInput}
        searchTypes={searchTypes}
        setSearchTypes={setSearchTypes}
        t={t}
        updateSearchInput={updateSearchInput}
        value={searchInput}
      />
    </SearchBarBase>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  hideSearchModal: PropTypes.func.isRequired,
  hideWelcomeBoxAndRecipePreview: PropTypes.func.isRequired,
  inputToFocusRef: PropTypes.object.isRequired,
  licenseType: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  resetSearchInput: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  searchTypes: PropTypes.array.isRequired,
  setSearchTypes: PropTypes.func.isRequired,
  showWelcomeBoxAndRecipePreview: PropTypes.func.isRequired,
  style: PropTypes.object,
  updateSearchInput: PropTypes.func.isRequired
}

export default SearchBar
