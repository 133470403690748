import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {map, mergeMap} from 'rxjs/operators'
import {normalizeById} from '../../../build/utils/utils'
import {loadFromLocalStorage} from '../../utils/localStorage'
import {handleNetworkError} from '../observables/pipes'

const initialStateBusinesses = {
  businessPartnerName: loadFromLocalStorage('businessPartnerName'),
  creatingOrUpdatingBusinessPartner: false,
  businessPartners: {},
  selectedBusinessPartner: loadFromLocalStorage('selectedBusinessPartner')
}

export const addOrUpdateBusinessPartnerOptimistically = createAction(
  'businessPartners/ADD_OR_UPDATE_BUSINESS_PARTNER_OPTIMISTICALLY'
)
export const getBusinessPartners = createAction(
  'businessPartners/GET_BUSINESS_PARTNERS_REQUESTED'
)
export const getBusinessPartnersSucceeded = createAction(
  'businessPartners/GET_BUSINESS_PARTNERS_SUCCEEDED'
)
export const postBusinessPartner = createAction(
  'businessPartners/POST_BUSINESS_PARTNER_REQUESTED'
)
export const postBusinessPartnerSucceeded = createAction(
  'businessPartners/POST_BUSINESS_PARTNER_SUCCEEDED'
)
export const putBusinessPartner = createAction(
  'businessPartners/PUT_BUSINESS_PARTNER_REQUESTED'
)
export const putBusinessPartnerSucceeded = createAction(
  'businessPartners/PUT_BUSINESS_PARTNER_SUCCEEDED'
)
export const setSelectedBusinessPartner = createAction(
  'businessPartners/SET_SELECTED_BUSINESS_PARTNER'
)
export const setBusinessPartnerName = createAction(
  'businessPartners/SET_BUSINESS_PARTNER_NAME'
)
export const setCreatingOrUpdatingBusinessPartner = createAction(
  'businessPartners/SET_CREATING_OR_UPDATING_BUSINESS_PARTNER'
)

const reducer = createReducer(
  {
    [addOrUpdateBusinessPartnerOptimistically]: (state, payload) => ({
      ...state,
      businessPartners: {...state.businessPartners, [payload.id]: payload}
    }),
    [getBusinessPartnersSucceeded]: (state, payload) => ({
      ...state,
      businessPartners: payload
    }),
    [postBusinessPartner]: state => ({
      ...state,
      creatingOrUpdatingBusinessPartner: true
    }),
    [putBusinessPartner]: state => ({
      ...state,
      creatingOrUpdatingBusinessPartner: true
    }),
    [setSelectedBusinessPartner]: (state, payload) => ({
      ...state,
      selectedBusinessPartner: payload
    }),
    [setBusinessPartnerName]: (state, payload) => ({
      ...state,
      businessPartnerName: payload
    }),
    [setCreatingOrUpdatingBusinessPartner]: (state, payload) => ({
      ...state,
      creatingOrUpdatingBusinessPartner: payload
    })
  },
  initialStateBusinesses
)

const getBusinessPartnersEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(getBusinessPartners().type),
    mergeMap(() =>
      api.getBusinessPartners().pipe(
        map(({response: businessPartners}) => {
          const businessPartnersById = normalizeById(businessPartners)
          return getBusinessPartnersSucceeded(businessPartnersById)
        }),
        handleNetworkError
      )
    )
  )

const postBusinessPartnerEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(postBusinessPartner().type),
    mergeMap(({payload: businessPartner}) => {
      return api.postBusinessPartner(businessPartner).pipe(
        mergeMap(() =>
          of(
            postBusinessPartnerSucceeded(),
            setCreatingOrUpdatingBusinessPartner(false)
          )
        ),
        handleNetworkError
      )
    })
  )

const putBusinessPartnerEpic = (action$, _, {api, of}) =>
  action$.pipe(
    ofType(putBusinessPartner().type),
    mergeMap(({payload: businessPartner}) =>
      api.putBusinessPartner(businessPartner).pipe(
        mergeMap(() =>
          of(
            putBusinessPartnerSucceeded(),
            setCreatingOrUpdatingBusinessPartner(false)
          )
        ),
        handleNetworkError
      )
    )
  )

export const businessPartnerEpics = combineEpics(
  getBusinessPartnersEpic,
  postBusinessPartnerEpic,
  putBusinessPartnerEpic
)

export default reducer
