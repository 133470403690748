import {createAction} from 'redux-act'

/*
These actions are kept here to avoid circular dependecies between different
redux modules...
*/

export const createProductThenCreateMenu = createAction(
  'menus/CREATE_PRODUCT_THEN_CREATE_MENU'
)

export const createProductThenUpdateMenu = createAction(
  'menus/CREATE_PRODUCT_THEN_UPDATE_MENU'
)

export const createRecipeThenCreateMenu = createAction(
  'menus/CREATE_RECIPE_THEN_CREATE_MENU'
)

export const createRecipeThenUpdateMenu = createAction(
  'menus/CREATE_RECIPE_THEN_UPDATE_MENU'
)

export const createProductThenCreateRecipe = createAction(
  'recipes/CREATE_PRODUCT_THEN_CREATE_RECIPE'
)

export const createProductThenUpdateRecipe = createAction(
  'recipes/CREATE_PRODUCT_THEN_UPDATE_RECIPE'
)

export const createRecipeThenCreateRecipe = createAction(
  'recipes/CREATE_RECIPE_THEN_CREATE_RECIPE'
)

export const createRecipeThenUpdateRecipe = createAction(
  'recipes/CREATE_RECIPE_THEN_UPDATE_RECIPE'
)
