import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import RecipePreview from '../components/RecipePreview'
import {processRecipePreviewClick} from '../epics/epics'
import {withTranslation} from 'react-i18next'
import {selectPrevievRecipes} from '../../../redux/selectors/selectors'

const mapStateToProps = state => {
  const {
    config: {webEntryDomain}
  } = state

  return {
    recipes: selectPrevievRecipes(state),
    webEntryDomain
  }
}

const mapDispatchToProps = {
  processRecipePreviewClick
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onRecipeClick: ({processRecipePreviewClick}) => recipe => {
      processRecipePreviewClick(recipe)
    }
  })
)(RecipePreview)
