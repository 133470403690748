import {connect} from 'react-redux'
import {setSearchTypes, updateSearchInput} from '../../../redux/modules/search'
import {getBusinessPartners} from '../../../redux/modules/businessPartners'
import {processAddBusinessPartnerClick} from '../epics/epics'

import BusinessPartnerAdminContent from '../components/BusinessPartnerAdminContent'

const mapStateToProps = state => ({
  businessPartners: [{name: 'CHANGE MY NAME'}],
  isSuperAdmin: state.users.user.email.endsWith('@eaternity.ch'),
  searchInput: state.search.searchInput
})

const mapDispatchToProps = {
  getBusinessPartners,
  onAddBusinessPartnerClick: processAddBusinessPartnerClick,
  setSearchTypes,
  updateSearchInput
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPartnerAdminContent)
