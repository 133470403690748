import {connect} from 'react-redux'

import {selectIndicatorData} from '../../../redux/selectors/indicatorSelectors'
import AwardIndicators from '../components/AwardIndicators'

const mapStateToProps = state => ({
  awards: selectIndicatorData(state),
  webEntryDomain: state.config.webEntryDomain
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AwardIndicators)
