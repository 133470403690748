import React from 'react'
import PropTypes from 'prop-types'

const SubIconInProgress = ({visible}) => {
  return (
    <g
      display={visible ? 'visible' : 'none'}
      id="Icon-/-Notification-grey-progress"
      transform="translate(-2 3.5)"
    >
      <rect id="Rectangle-5" width="64" height="64" />
      <g id="notification" transform="translate(33 13)">
        <circle id="Oval-6" cx="9" cy="9.5" r="8.75" fill="#FFF" />
        <circle id="Oval-6" cx="9" cy="9.5" r="7" fill="#3D454C" />
      </g>
      <g id="Group" fill="#FFF" transform="translate(37 17.5)">
        <path
          id="Path"
          fillRule="nonzero"
          d="M1.68899286 8.28913115C3.02549286 9.62563115 4.96949286 9.97393115 6.62189286 9.34213115 6.32227386 9.13145015 6.12779286 8.78331215 6.12779286 8.39443115L6.12779286 8.24053115C4.91279286 8.67793115 3.50339286 8.41071215 2.53139286 7.43871215 1.67279286 6.57201215 1.37309286 5.36503115 1.61609286 4.25541215 1.71329286 3.80991215 1.90769286 3.38061215 2.18309286 2.99181215L2.18309286 3.78561215C2.18309286 4.05291215 2.40179286 4.27161215 2.66909286 4.27161215 2.93639286 4.27161215 3.15509286 4.05291215 3.15509286 3.78561215L3.15509286 2.08461215 3.15509286 1.64721215C3.15509286 1.37991215 2.93639286 1.16121215 2.66909286 1.16121215L2.37749286 1.16121215.538792861 1.15303115C.271492861 1.15303115.0527928614 1.37173115.0527928614 1.63903115.0527928614 1.90633115.271492861 2.12503115.538792861 2.12503115L1.33259286 2.12503115C1.16249286 2.33563115 1.01669286 2.56243115.887092861 2.79733115-.0606071386 4.55503115.198592861 6.79881215 1.68899286 8.28913115zM7.29419286 8.88853115L7.52091186 8.88853115 9.43259286 8.88853115C9.69989286 8.88853115 9.91859286 8.66991215 9.91859286 8.40253115 9.91859286 8.13531215 9.69989286 7.91653115 9.43259286 7.91653115L8.59019286 7.91653115C8.76029286 7.70593115 8.91419286 7.47921215 9.03569286 7.24431215 10.0076929 5.47843115 9.74031186 3.21853115 8.24991186 1.72813115 6.92159286.383531155 4.96941186.0352311548 3.30891186.667031155 3.62481186.877631155 3.83541186 1.23403115 3.83541186 1.63903115L3.83541186 1.76053115C5.04231186 1.33933115 6.43551186 1.61473115 7.39941186 2.57863115 8.25809286 3.43723115 8.56581186 4.63603115 8.33091186 5.74581215 8.23379286 6.18313115 8.05559286 6.61243115 7.78019286 6.99313115L7.78019286 6.26413115C7.78019286 5.99683115 7.56141186 5.77813115 7.29419286 5.77813115 7.02681186 5.77813115 6.80819286 5.99683115 6.80819286 6.26413115L6.80819286 7.92463115 6.80819286 8.41063115C6.80819286 8.66983115 7.02689286 8.88853115 7.29419286 8.88853115z"
        />
      </g>
    </g>
  )
}

SubIconInProgress.propTypes = {
  visible: PropTypes.bool.isRequired
}

export default SubIconInProgress
