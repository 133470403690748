import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Icon from '../../icons/components/Icon'
import {saveToLocalStorage} from '../../../utils/localStorage'
import {IconCrossGrey} from '../../../assets/icons'

const AdminDrawerBase = styled.div`
  position: relative;
  grid-area: adminDrawer;
  transition: width 2s;
  width: 300px;
  height: 100vh;
  padding-top: 40px;
  background-color: ${p => p.theme.colors.black};
  display: flex;
  flex-direction: column;
`

const AdminDrawerTabText = styled.span`
  display: flex;
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  color: ${p =>
    p.selected && !p.inactive
      ? p.theme.colors.white
      : p.inactive
      ? p.theme.colors.darkGray
      : p.theme.colors.boxGray};
`

const AdminDrawerSubTabText = styled.span`
  display: flex;
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.thin};
  color: ${p => (p.selected ? p.theme.colors.white : p.theme.colors.boxGray)};
`

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const AdminDrawerTab = styled.div`
  margin-top: 40px;
  margin-left: 40px;
`

const ClickBox = styled.div`
  cursor: pointer;
`

const AdminDrawer = ({
  businessPartnerName,
  closeAllSettingsModals,
  editedRestaurant,
  isOpen,
  licenseType,
  licensePurchased,
  match: {path},
  numberOfRestaurants,
  onBillingDetailsClick,
  onCloseClick,
  onExportsClick,
  onLogoutClick,
  onRestaurantSettingsClick,
  onSettingsClick,
  onUserSettingsClick,
  processAdminItemClick,
  selectedBusiness,
  selectedRestaurant,
  showBuyLicenseModal,
  user
}) => {
  const [t] = useTranslation()
  useEffect(() => {
    saveToLocalStorage('adminDrawerOpen', isOpen)
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  const {email, isBusinessPartnerAdmin} = user
  const isRestaurantAdmin =
    selectedBusiness && selectedBusiness.admins.includes(user.id)
  const isSuperAdmin = email.endsWith('@eaternity.ch')

  const adminTabData = [
    {
      dataTestId: 'businessPartnerAdminTab',
      inactive: false,
      onClick: processAdminItemClick,
      tabText: upperFirst(t('global.businessPartner', {count: 2})),
      pathname: '/business-partners',
      subTabText: '',
      show: isSuperAdmin && licenseType !== 'klimateller'
    },
    {
      dataTestId: 'businessPartnerBusinessesTab',
      inactive: false,
      onClick: processAdminItemClick,
      tabText: upperFirst(t('global.businessPartner')),
      pathname: '/businesses',
      subTabText: businessPartnerName,
      show: isBusinessPartnerAdmin
    },
    {
      dataTestId: 'businessPartnerRestaurantsTab',
      inactive: false,
      onClick: processAdminItemClick,
      tabText: isSuperAdmin
        ? upperFirst(t('global.restaurant', {count: 2}))
        : upperFirst(t('global.organisation')),
      pathname: '/restaurants',
      subTabText: selectedBusiness && selectedBusiness.name,
      show: isSuperAdmin || isBusinessPartnerAdmin || licenseType == 'eaternity'
    },
    {
      dataTestId: 'businessPartnerUsersTab',
      inactive: !editedRestaurant,
      onClick: editedRestaurant && processAdminItemClick,
      tabText: upperFirst(t('global.restaurant')),
      pathname: '/users',
      subTabText: editedRestaurant && editedRestaurant.name,
      show: isBusinessPartnerAdmin || licenseType == 'eaternity'
    },
    {
      dataTestId: 'buyLicenseTab',
      inactive: false,
      onClick: () => showBuyLicenseModal(),
      tabText: upperFirst(
        t(
          licensePurchased
            ? 'adminDrawer.changeLicenseTabText'
            : 'adminDrawer.buyLicenseTabText'
        )
      ),
      subTabText: '',
      show: !isBusinessPartnerAdmin && !isSuperAdmin
    },
    {
      dataTestId: 'switchRestaurantsTab',
      inactive: false,
      onClick: processAdminItemClick,
      tabText: upperFirst(t('global.switchRestaurant')),
      subTabText: selectedRestaurant && selectedRestaurant.name,
      pathname: '/restaurants',
      show:
        !isBusinessPartnerAdmin &&
        !isSuperAdmin &&
        licenseType !== 'eaternity' && // these first three conditions exclude this entry to not show duplicate /restaurants link in sidebar
        numberOfRestaurants > 1 &&
        licenseType !== 'klimateller'
    },
    {
      dataTestId: 'goToWeekplanningTab',
      inactive: isBusinessPartnerAdmin && !selectedRestaurant,
      onClick: processAdminItemClick,
      tabText: upperFirst(t('global.menuPlanning')),
      pathname: '/weekplanning',
      subTabText: '',
      show: licenseType !== 'chef' && licenseType !== 'klimateller'
    },
    {
      dataTestId: 'goToSearchTab',
      inactive: false,
      onClick: processAdminItemClick,
      tabText: upperFirst(t('adminDrawer.recipeSearchTabText')),
      pathname: '/search',
      subTabText: '',
      show: licenseType === 'chef' || licenseType === 'klimateller'
    },
    {
      dataTestId: 'restaurantSettingsTab',
      inactive: false,
      onClick: () => onRestaurantSettingsClick(),
      tabText: t('global.restaurantSettings'),
      subTabText: '',
      show:
        !isSuperAdmin &&
        isRestaurantAdmin &&
        selectedRestaurant.termsAccepted &&
        licenseType === 'eaternity'
    },
    {
      dataTestId: 'billingDetailsTab',
      inactive: false,
      onClick: onBillingDetailsClick,
      tabText: t('global.billingDetails'),
      subTabText: '',
      show:
        !isSuperAdmin &&
        isRestaurantAdmin &&
        selectedRestaurant.termsAccepted &&
        licenseType !== 'klimateller'
    },
    {
      dataTestId: 'userSettingsTab',
      inactive: false,
      onClick: () => onUserSettingsClick(),
      tabText: t('global.userSettings'),
      subTabText: '',
      show: !isSuperAdmin && selectedRestaurant.termsAccepted
    },
    {
      dataTestId: 'settingsTab',
      inactive: false,
      onClick: () => onSettingsClick(),
      tabText: upperFirst(t('global.settings')),
      subTabText: '',
      show: licenseType === 'eaternity'
    },
    {
      dataTestId: 'exportTab',
      inactive: false,
      onClick: onExportsClick,
      tabText: 'Recipe exports',
      subTabText: '',
      show: licenseType === 'eaternity'
    },
    {
      dataTestId: 'logoutTab',
      inactive: false,
      onClick: onLogoutClick,
      tabText: upperFirst(t('global.logout')),
      subTabText: '',
      show: true
    }
  ]

  return (
    <AdminDrawerBase data-testid="adminDrawerBase">
      <CloseIcon
        dataTestId="adminDrawerCloseIcon"
        onClick={onCloseClick}
        src={IconCrossGrey}
        width="64px"
        height="64px"
      />

      {adminTabData
        .filter(({show}) => show)
        .map(
          ({dataTestId, inactive, onClick, pathname, tabText, subTabText}) => (
            <AdminDrawerTab key={tabText} data-testid={dataTestId}>
              <ClickBox
                onClick={
                  onClick
                    ? () => {
                        if (dataTestId !== 'logoutTab') {
                          closeAllSettingsModals()
                        }
                        onClick(pathname)
                      }
                    : null
                }
              >
                <AdminDrawerTabText
                  inactive={inactive}
                  selected={pathname === path}
                >
                  {tabText}
                </AdminDrawerTabText>
                <AdminDrawerSubTabText selected={pathname === path}>
                  {subTabText}
                </AdminDrawerSubTabText>
              </ClickBox>
            </AdminDrawerTab>
          )
        )}
    </AdminDrawerBase>
  )
}

AdminDrawer.propTypes = {
  businessPartnerName: PropTypes.string,
  closeAllSettingsModals: PropTypes.func.isRequired,
  editedRestaurant: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  licenseType: PropTypes.string.isRequired,
  licensePurchased: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  numberOfRestaurants: PropTypes.number.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onBillingDetailsClick: PropTypes.func.isRequired,
  onRestaurantSettingsClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onUserSettingsClick: PropTypes.func.isRequired,
  processAdminItemClick: PropTypes.func.isRequired,
  selectedBusiness: PropTypes.object,
  selectedRestaurant: PropTypes.object,
  showBuyLicenseModal: PropTypes.func.isRequired,
  user: PropTypes.object
}

export default AdminDrawer
