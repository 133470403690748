import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import IconNav from '../../icons/components/IconNav'
import ScopeChooserContainer from '../containers/ScopeChooserContainer'
import Header from './Header'

export const NavigationBase = styled(Header)`
  display: grid;
  grid-template-columns: 44px 5px 3fr 1fr 44px 44px 44px 44px 44px;
  grid-template-areas: 'sharrow . scope-chooser . plus stock purchase supplier search';
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  @media print {
    background-color: white;
  }
`

export const NavigationItem = styled.div`
  grid-area: ${p => p.area};
  @media print {
    display: none;
  }
`

const Navigation = ({onPlusClick, onSearchClick, visibleNavIcons}) => {
  const clickHandlerMap = {
    plus: onPlusClick,
    search: onSearchClick
  }

  return (
    <NavigationBase id="NavigationBase">
      <NavigationItem area="scope-chooser">
        <ScopeChooserContainer />
      </NavigationItem>
      {visibleNavIcons.map(iconType => (
        <NavigationItem area={iconType} key={iconType}>
          <IconNav
            id={`IcanNav${upperFirst(iconType)}`}
            iconType={iconType}
            onClick={clickHandlerMap[iconType]}
          />
        </NavigationItem>
      ))}
    </NavigationBase>
  )
}

Navigation.propTypes = {
  onPlusClick: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  visibleNavIcons: PropTypes.array.isRequired
}

export default Navigation
