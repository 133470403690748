import React from 'react'
import styled from 'styled-components'
import Button from '../../core/components/Button'
import PropTypes from 'prop-types'

const SearchModalAddButtonBase = styled(Button)`
  padding-bottom: 18px;
  padding-top: 18px;
  margin-top: 0px;
  margin-bottom: 40px;
`

const SearchModalAddButton = ({onMouseDown, searchInput, t}) => {
  return (
    <SearchModalAddButtonBase
      id="SearchModalAddButton"
      fluid
      onMouseDown={onMouseDown}
      primary
    >
      {t('search.addButtonText', {
        compositeType: t('compositeType.recipe'),
        title: !searchInput
          ? undefined
          : searchInput.length > 30
          ? `"${searchInput.slice(0, 30)}..."`
          : `"${searchInput}"`
      })}
    </SearchModalAddButtonBase>
  )
}

SearchModalAddButton.propTypes = {
  onMouseDown: PropTypes.func.isRequired,
  searchInput: PropTypes.string,
  t: PropTypes.func.isRequired
}

export default SearchModalAddButton
