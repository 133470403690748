import {connect} from 'react-redux'
import {selectSearchResults} from '../../../redux/selectors/search'
import AdminSearchResults from '../components/AdminSearchResults'
import {processUserSearchItemClick} from '../epics/epics'

const mapStateToProps = state => {
  const {groupedSearchResults} = selectSearchResults(state)
  const results = Object.values(state.users.users)

  return {
    results:
      state.search.searchInput === '' ? results : groupedSearchResults.users
  }
}

const mapDispatchToProps = {
  onSearchHitRowClick: processUserSearchItemClick
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearchResults)
