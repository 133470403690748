import PropTypes from 'prop-types'
import React from 'react'

import Cell from './Cell'
import styled from 'styled-components'

const HeaderCellLeftBase = styled(Cell)`
  border-bottom: ${p => p.theme.border.thin};
  border-right: ${p => p.theme.border.thin};
`

const HeaderCellLeft = ({children, style, className}) => (
  <HeaderCellLeftBase style={style} className={className} column={1}>
    {children}
  </HeaderCellLeftBase>
)

HeaderCellLeft.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object,
  className: PropTypes.string
}

export default HeaderCellLeft
