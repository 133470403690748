import {createAction, createReducer} from 'redux-act'
import {loadFromLocalStorage} from '../../utils/localStorage'

export const initialStateModal = {
  addOrEditBusinessPartnerModalOpen: false,
  addOrEditOrganisationModalOpen: false,
  addOrEditRestaurantModalOpen: false,
  addOrEditUserModalOpen: false,
  billingDetailsModalOpen: false,
  authTokenExpiredModalOpen: false,
  buyLicenseModalOpen: false,
  confirmModalOpen: false,
  exportsModalOpen: false,
  linkExpiredModalOpen: false,
  loginAgainModalOpen: loadFromLocalStorage('loginAgainModalOpen') || false,
  logoutWarningModalOpen: false,
  modalType: loadFromLocalStorage('modalType') || '',
  reloadModalOpen: false,
  removeBusinessModalOpen: false,
  removeRestaurantModalOpen: false,
  restaurantSettingsModalOpen: false,
  searchModalOpen: false,
  selectPreviewCollectionModalOpen: false,
  settingsModalOpen: false,
  userSettingsModalOpen: false
}

export const closeAllSettingsModals = createAction(
  'modal/CLOSE_ALL_SETTINGS_MODALS'
)

export const showAddOrEditBusinessPartnerModal = createAction(
  'modal/SHOW_ADD_OR_EDIT_BUSINESS_PARTNER_MODAL'
)
export const hideAddOrEditBusinessPartnerModal = createAction(
  'modal/HIDE_ADD_OR_EDIT_BUSINESS_PARTNER_MODAL'
)
export const showAddOrEditOrganisationModal = createAction(
  'modal/SHOW_ADD_OR_EDIT_ORGANISATION_MODAL'
)
export const hideAddOrEditOrganisationModal = createAction(
  'modal/HIDE_ADD_OR_EDIT_ORGANISATION_MODAL'
)
export const showAddOrEditRestaurantModal = createAction(
  'modal/SHOW_ADD_OR_EDIT_RESTAURANT_MODAL'
)
export const hideAddOrEditRestaurantModal = createAction(
  'modal/HIDE_ADD_OR_EDIT_RESTAURANT_MODAL'
)
export const showAddOrEditUserModal = createAction('modal/SHOW_ADD_USER_MODAL')
export const hideAddOrEditUserModal = createAction('modal/HIDE_ADD_USER_MODAL')
export const showConfirmModal = createAction('modal/SHOW_CONFIRMATION_MODAL')
export const hideConfirmModal = createAction('modal/HIDE_CONFIRMATION_MODAL')
export const hideReloadModal = createAction('modal/HIDE_RELOAD_MODAL')
export const showReloadModal = createAction('modal/SHOW_RELOAD_MODAL')
export const hideRemoveBusinessModal = createAction(
  'modal/HIDE_REMOVE_BUSINESS_MODAL'
)
export const showRemoveBusinessModal = createAction(
  'modal/SHOW_REMOVE_BUSINESS_MODAL'
)
export const hideRemoveRestaurantModal = createAction(
  'modal/HIDE_REMOVE_RESTAURANT_MODAL'
)
export const showRemoveRestaurantModal = createAction(
  'modal/SHOW_REMOVE_RESTAURANT_MODAL'
)
export const hideLoginAgainModal = createAction('modal/HIDE_LOGIN_AGAIN_MODAL')
export const showLoginAgainModal = createAction('modal/SHOW_LOGIN_AGAIN_MODAL')
export const showSearchModal = createAction('modal/SHOW_SEARCH_MODAL')
export const hideSearchModal = createAction('modal/HIDE_SEARCH_MODAL')
export const setModalType = createAction('modal/SET_MODAL_TYPE')
export const hideLinkExpiredModal = createAction(
  'modal/HIDE_LINK_EXPIRED_MODAL'
)
export const showLinkExpiredModal = createAction(
  'modal/SHOW_LINK_EXPIRED_MODAL'
)
export const hideAuthTokenExpiredModal = createAction(
  'modal/HIDE_AUTH_TOKEN_EXPIRED_MODAL'
)
export const showAuthTokenExpiredModal = createAction(
  'modal/SHOW_AUTH_TOKEN_EXPIRED_MODAL'
)
export const hideBuyLicenseModal = createAction('modal/HIDE_BUY_LICENSE_MODAL')
export const showBuyLicenseModal = createAction('modal/SHOW_BUY_LICENSE_MODAL')

export const hideExportsModal = createAction('modal/HIDE_EXPORTS_MODAL')
export const showExportsModal = createAction('modal/SHOW_EXPORTS_MODAL')

export const closeSettingsModal = createAction('modal/CLOSE_SETTINGS_MODAL')
export const openSettingsModal = createAction('modal/OPEN_SETTINGS_MODAL')

export const closeUserSettingsModal = createAction(
  'modal/CLOSE_USER_SETTINGS_MODAL'
)
export const openUserSettingsModal = createAction(
  'modal/OPEN_USER_SETTINGS_MODAL'
)

export const closeBillingDetailsModal = createAction(
  'modal/CLOSE_BILLING_DETAILS_MODAL'
)
export const openBillingDetailsModal = createAction(
  'modal/OPEN_BILLING_DETAILS_MODAL'
)

export const closeRestaurantSettingsModal = createAction(
  'modal/CLOSE_RESTAURANT_SETTINGS_MODAL'
)
export const openRestaurantSettingsModal = createAction(
  'modal/OPEN_RESTAURANT_SETTINGS_MODAL'
)

export const closeLogoutWarningModal = createAction(
  'modal/CLOSE_LOGOUT_WARNING_MODAL'
)
export const openLogoutWarningModal = createAction(
  'modal/OPEN_LOGOUT_WARNING_MODAL'
)
export const closeSelectPreviewCollectionModal = createAction(
  'modal/CLOSE_SELECT_PREVIEW_COLLECTION_MODAL'
)
export const openSelectPreviewCollectionModal = createAction(
  'modal/OPEN_SELECT_PREVIEW_COLLECTION_MODAL'
)

const reducer = createReducer(
  {
    [closeAllSettingsModals]: state => ({
      ...state,
      billingDetailsModalOpen: false,
      restaurantSettingsModalOpen: false,
      userSettingsModalOpen: false
    }),
    [showAddOrEditBusinessPartnerModal]: state => ({
      ...state,
      addOrEditBusinessPartnerModalOpen: true
    }),
    [hideAddOrEditBusinessPartnerModal]: state => ({
      ...state,
      addOrEditBusinessPartnerModalOpen: false
    }),
    [showAddOrEditOrganisationModal]: state => ({
      ...state,
      addOrEditOrganisationModalOpen: true
    }),
    [hideAddOrEditOrganisationModal]: state => ({
      ...state,
      addOrEditOrganisationModalOpen: false
    }),
    [showAddOrEditRestaurantModal]: state => ({
      ...state,
      addOrEditRestaurantModalOpen: true
    }),
    [hideAddOrEditRestaurantModal]: state => ({
      ...state,
      addOrEditRestaurantModalOpen: false
    }),
    [showAddOrEditUserModal]: state => ({
      ...state,
      addOrEditUserModalOpen: true
    }),
    [hideAddOrEditUserModal]: state => ({
      ...state,
      addOrEditUserModalOpen: false
    }),
    [showConfirmModal]: state => ({...state, confirmModalOpen: true}),
    [hideConfirmModal]: state => ({
      ...state,
      confirmModalOpen: false
    }),
    [showExportsModal]: state => ({...state, exportsModalOpen: true}),
    [hideExportsModal]: state => ({
      ...state,
      exportsModalOpen: false
    }),
    [showReloadModal]: state => ({
      ...state,
      reloadModalOpen: true
    }),
    [hideReloadModal]: state => ({...state, reloadModalOpen: false}),
    [showRemoveBusinessModal]: state => ({
      ...state,
      removeBusinessModalOpen: true
    }),
    [hideRemoveBusinessModal]: state => ({
      ...state,
      removeBusinessModalOpen: false
    }),
    [showRemoveRestaurantModal]: state => ({
      ...state,
      removeRestaurantModalOpen: true
    }),
    [hideRemoveRestaurantModal]: state => ({
      ...state,
      removeRestaurantModalOpen: false
    }),
    [showSearchModal]: state => ({...state, searchModalOpen: true}),
    [hideLoginAgainModal]: state => ({...state, loginAgainModalOpen: false}),
    [showLoginAgainModal]: state => ({...state, loginAgainModalOpen: true}),
    [hideLinkExpiredModal]: state => ({...state, linkExpiredModalOpen: false}),
    [showLinkExpiredModal]: state => ({...state, linkExpiredModalOpen: true}),
    [hideAuthTokenExpiredModal]: state => ({
      ...state,
      authTokenExpiredModalOpen: false
    }),
    [showBuyLicenseModal]: state => ({
      ...state,
      buyLicenseModalOpen: true
    }),
    [hideBuyLicenseModal]: state => ({...state, buyLicenseModalOpen: false}),
    [showAuthTokenExpiredModal]: state => ({
      ...state,
      authTokenExpiredModalOpen: true
    }),
    [hideSearchModal]: state => ({...state, searchModalOpen: false}),
    [setModalType]: (state, payload) => ({
      ...state,
      modalType: payload
    }),
    [closeSettingsModal]: state => ({
      ...state,
      settingsModalOpen: false
    }),
    [openSettingsModal]: state => ({
      ...state,
      settingsModalOpen: true
    }),
    [closeUserSettingsModal]: state => ({
      ...state,
      userSettingsModalOpen: false
    }),
    [openUserSettingsModal]: state => ({
      ...state,
      userSettingsModalOpen: true
    }),
    [closeBillingDetailsModal]: state => ({
      ...state,
      billingDetailsModalOpen: false
    }),
    [openBillingDetailsModal]: state => ({
      ...state,
      billingDetailsModalOpen: true
    }),
    [closeRestaurantSettingsModal]: state => ({
      ...state,
      restaurantSettingsModalOpen: false
    }),
    [openRestaurantSettingsModal]: state => ({
      ...state,
      restaurantSettingsModalOpen: true
    }),
    [closeLogoutWarningModal]: state => ({
      ...state,
      logoutWarningModalOpen: false
    }),
    [openLogoutWarningModal]: state => ({
      ...state,
      logoutWarningModalOpen: true
    }),
    [closeSelectPreviewCollectionModal]: state => ({
      ...state,
      selectPreviewCollectionModalOpen: false
    }),
    [openSelectPreviewCollectionModal]: state => ({
      ...state,
      selectPreviewCollectionModalOpen: true
    })
  },
  initialStateModal
)

export default reducer
