import admin from './modules/admin'
import businesses from './modules/businesses'
import businessPartners from './modules/businessPartners'
import components from './modules/components'
import dataGrid from './modules/dataGrid'
import date from './modules/date'
import error from './modules/error'
import exports from './modules/exports'
import indicators from './modules/indicators'
import menuLines from './modules/menuLines'
import menus from './modules/menus'
import modal from './modules/modal'
import network from './modules/network'
import products from './modules/products'
import recipes from './modules/recipes'
import restaurants from './modules/restaurants'
import search from './modules/search'
import toolTip from './modules/toolTip'
import users from './modules/users'
import view from './modules/view'
import viewPagerNavigator from './modules/viewPagerNavigator'
import websocket from './modules/websocket'

export const coreReducers = {
  admin,
  businesses,
  businessPartners,
  components,
  dataGrid,
  date,
  error,
  exports,
  indicators,
  menuLines,
  menus,
  modal,
  network,
  products,
  recipes,
  restaurants,
  search,
  toolTip,
  users,
  view,
  viewPagerNavigator,
  websocket
}
