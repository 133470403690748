import styled from 'styled-components'

const HeaderRow = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns:
    minmax(${p => p.theme.sizes.dataGridLeftColumnWidth}px, 2fr)
    5fr;
  grid-template-rows: 59.5px;
`

export default HeaderRow
