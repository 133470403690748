import {replace} from 'connected-react-router'
import {omit} from 'ramda'
import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, mergeMap} from 'rxjs/operators'
import v4 from 'uuid'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {
  checkIfProductIsLinked,
  generateStandardProduct
} from '../../../utils/productUtils'
import {generateStandardRecipe} from '../../../utils/recipeUtils'
import {parseQueryString} from '../../../utils/utils'
import {replaceComponent} from '../../dataGrid/epics/epics'

export const convertProductToRecipe = createAction(
  'recipeOrProduct/epics/CONVERT_PRODUCT_TO_RECIPE'
)

const convertProductToRecipeEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(convertProductToRecipe().type),
    // do nothing when componentTitle is empty
    filter(
      ({
        payload: {
          componentToAdd: {title},
          searchInput
        }
      }) => title !== '' && searchInput !== ''
    ),
    mergeMap(
      ({
        payload: {
          componentToAdd,
          componentIndex,
          currentComponent,
          parent,
          recipeOrProduct,
          t
        }
      }) => {
        /*
      Although similar to the replaceComponent epic in the
      dataGrid module this epic is specific for the select/recipeOrProduct view
      and I put it here to disentagnle things a bit more...
      A couple of things have to be accomplished here:
      - the currentComponent (which is always a product!) in the parent has to
        be replaced with a new Recipe. To achieve this, a new recipe has to be created with the title of the currentComponent
      - the componentToAdd has to be posted if its a new product and linked
        to that new recipe. We can reuse functions/epics from the dataGrid
        module for that.
      - the new recipe with the linked componentToAdd has to be posted
      - the parent has to be updated
      */

        const {
          menus: {menus},
          recipes: {recipes},
          restaurants: {
            selectedRestaurant: {productCollectionId, recipeCollectionId}
          },
          router: {
            location: {search}
          },
          users: {
            user: {id: authorId}
          }
        } = state$.value

        const {parentComponentIndex} = parseQueryString(search)

        const newRecipeId = v4()
        const newRecipe = {
          ...generateStandardRecipe({
            authorId,
            title: currentComponent.title,
            productionDate: parent.productionDate,
            recipeCollectionId,
            t
          }),
          id: newRecipeId
        }

        const productIsLinked = checkIfProductIsLinked({
          menus: omit([parent.id], menus),
          recipes: omit([parent.id], recipes),
          composite: recipeOrProduct,
          product: componentToAdd
        })

        const recreateProduct =
          currentComponent.id === componentToAdd.id && !productIsLinked

        if (recreateProduct) {
          componentToAdd = generateStandardProduct({
            title: componentToAdd.title,
            productCollectionId
          })
        }

        return of(
          /*
           first replace the component in the new recipe, this will also
           trigger the create-product-then-create-recipe logic
           */
          replaceComponent({
            componentIndex,
            composite: newRecipe,
            componentToAdd,
            // the currentComponent in the recipeOrPoduct:
            currentComponent: newRecipe.components[componentIndex].component,
            forceLink: false
          }),
          // then replace component in parent with new recipe
          replaceComponent({
            componentIndex: Number(parentComponentIndex),
            composite: parent,
            componentToAdd: newRecipe,
            currentComponent,
            // use forceLink here,
            // so that asynchronous operation of above replaceComponent does not matter and
            // so that we don't end up with two recipes (an empty one and a copy with the sub-component)
            forceLink: true
          }),
          /*
            we can dispatch routing actions imported directly from
            connected-react-router in epics without having to pass them through!
          */
          replace(
            `/recipes?recipeId=${newRecipe.id}&parentId=${parent.id}&parentComponentIndex=${parentComponentIndex}`
          ),
          setDayPlanningPopOverContentType('compositeDetails')
        )
      }
    )
  )

export const recipeOrProductEpics = combineEpics(convertProductToRecipeEpic)
