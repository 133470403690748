import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CellInput from '../../dataGrid/components/CellInput'

const InstructionTitleInputBase = styled(CellInput)`
  padding-right: 10px;
  text-align: right;
  width: 100%;
`

const InstructionTitleInput = ({
  editMode,
  editedInstructionIndex,
  instructionTitle,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  value
}) => (
  <InstructionTitleInputBase
    id={`InstructionTitleInputBase${editedInstructionIndex}`}
    editMode={editMode}
    sectionTitle
    onFocus={onFocus}
    onChange={onChange}
    onBlur={onBlur}
    onKeyDown={({keyCode}) => keyCode === 13 && onKeyDown()}
    value={editMode ? instructionTitle : value}
  />
)

InstructionTitleInput.propTypes = {
  editMode: PropTypes.bool,
  editedInstructionIndex: PropTypes.number.isRequired,
  instructionTitle: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.string
}

export default InstructionTitleInput
