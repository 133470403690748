import {replace} from 'connected-react-router'
import {
  flatten,
  map as ramdaMap,
  omit,
  partial,
  pipe,
  remove,
  reverse
} from 'ramda'
import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {concatMap, filter, map, mergeMap} from 'rxjs/operators'
import {
  resetEditedComponentIndex,
  setSelectedComponent
} from '../../../redux/modules/components'
import {
  deleteMenu,
  postMenu,
  putMenu,
  setGenerated,
  updateMenuOptimistic
} from '../../../redux/modules/menus'
import {deleteProduct} from '../../../redux/modules/products'
import {
  deleteRecipe,
  deleteRecipeOptimistic,
  postRecipe,
  putRecipe,
  updateRecipeOptimistic
} from '../../../redux/modules/recipes'
import {
  createProductThenCreateMenu,
  createProductThenCreateRecipe,
  createProductThenUpdateMenu,
  createProductThenUpdateRecipe,
  createRecipeThenCreateMenu,
  createRecipeThenCreateRecipe,
  createRecipeThenUpdateMenu,
  createRecipeThenUpdateRecipe
} from '../../../redux/modules/sharedActions'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {
  getEditedComponentIndex,
  selectCollectionIds,
  selectParentByIdFromUrl
} from '../../../redux/selectors/selectors'
import {
  pickStandardComponentFields,
  unitConversionMapBackward,
  updateComponentField
} from '../../../utils/componentUtils'
import {
  checkIfProductIsLinked,
  generateStandardProduct
} from '../../../utils/productUtils'
import {parseQueryString, replaceAtIndex} from '../../../utils/utils'
import {
  appendEmptyComponentToComponents,
  collectLinkedComponentData,
  flatCopyComposite,
  generateDeepCopyData,
  linkComponentToComposite
} from '../utils/utils'
import {updateProductionDate} from '../../../utils/compositeUtils'
import {v4} from 'uuid'

export const replaceComponent = createAction('dataGrid/REPLACE_COMPONENT')

export const replaceComponentWithProduct = createAction(
  'dataGrid/REPLACE_COMPONENT_WITH_PRODUCT'
)

export const replaceComponentWithRecipe = createAction(
  'dataGrid/REPLACE_COMPONENT_WITH_RECIPE'
)

export const createOrUpdateCompositeWithNewProduct = createAction(
  'dataGrid/CREATE_OR_UPDATE_COMPOSITE_WITH_NEW_PRODUCT'
)

export const createOrUpdateCompositeWithNewRecipe = createAction(
  'dataGrid/CREATE_OR_UPDATE_COMPOSITE_WITH_NEW_RECIPE'
)

export const createOrUpdateComposite = createAction(
  'dataGrid/CREATE_OR_UPDATE_COMPOSITE'
)

export const deleteProductIfNotLinkedOrPermanent = createAction(
  'products/DELETE_PRODUCT_IF_NOT_LINKED_OR_PERMANENT'
)

export const deleteRecipeAndLinkedComponents = createAction(
  'dataGrid/DELETE_RECIPE_AND_LINKED_COMPONENTS'
)

export const addComponentWithDefaultProduct = createAction(
  'dataGrid/ADD_COMPONENT_WITH_DEFAULT_PRODUCT'
)

export const processComponentAmount = createAction(
  'dataGrid/PROCESS_COMPONENT_AMOUNT'
)

export const processComponentTitleKeyDown = createAction(
  'dataGrid/PROCESS_COMPONENT_TITLE_KEY_DOWN'
)

export const processComponentSection = createAction(
  'dataGrid/PROCESS_COMPONENT_SECTION'
)

export const processComponentUnit = createAction(
  'dataGrid/PROCESS_COMPONENT_UNIT'
)

export const processConfigurationChange = createAction(
  'dataGrid/PROCESS_CONFIGURATION_CHANGE'
)

export const processSearchClose = createAction('dataGrid/PROCESS_SEARCH_CLOSE')

export const replaceComponentWithExistingProduct = createAction(
  'dataGrid/REPLACE_COMPONENT_WITH_EXISTING_PRODUCT'
)

export const replaceComponentByProductTitle = createAction(
  'dataGrid/REPLACE_COMPONENT_BY_PRODUCT_TITLE'
)

export const removeComponent = createAction('datagrid/REMOVE_COMPONENT')

const replaceComponentEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(replaceComponent().type),
    // do nothing when currentComponent and componentToAdd are identical
    filter(
      ({payload: {currentComponent, componentToAdd}}) =>
        currentComponent.id !== componentToAdd.id
    ),
    map(({payload}) => {
      const {
        currentComponent: {type: currentComponentType},
        componentToAdd: {type: componentToAddType}
      } = payload

      return {
        ...payload,
        replacementType:
          currentComponentType === 'product' && componentToAddType === 'product'
            ? 'productToProduct'
            : currentComponentType === 'product' &&
              componentToAddType === 'recipe'
            ? 'productToRecipe'
            : currentComponentType === 'recipe' &&
              componentToAddType === 'product'
            ? 'recipeToProduct'
            : currentComponentType === 'recipe' &&
              componentToAddType === 'recipe'
            ? 'recipeToRecipe'
            : undefined
      }
    }),
    // describe
    map(payload => {
      const {composite, componentIndex, ...rest} = payload

      return {
        ...rest,
        componentIndex,
        composite: {
          ...composite,
          components: replaceAtIndex(composite.components, componentIndex, {
            ...pickStandardComponentFields(
              composite.components[componentIndex]
            ),
            unmatched: true,
            configured: false
          })
        }
      }
    }),
    mergeMap(
      ({
        addRow,
        componentToAdd,
        componentIndex,
        currentComponent,
        composite,
        replacementType,
        forceLink
      }) => {
        /*
        This is the point where I think it makes sense to clearly branch the
        action stream depending on the four different replacement types. I hope this makes reasoning about the code much easier!
        We want to either replace a:
        - product with a product with a different title
        - product with a recipe
        - recipe with a product
        - recipe with a recipe
        */
        switch (replacementType) {
          case 'productToProduct':
            return of(
              deleteProductIfNotLinkedOrPermanent({
                composite,
                product: currentComponent
              }),
              replaceComponentWithProduct({
                addRow,
                componentIndex,
                composite,
                product: componentToAdd
              })
            )
          case 'productToRecipe':
            return of(
              deleteProductIfNotLinkedOrPermanent({
                composite,
                product: currentComponent
              }),
              replaceComponentWithRecipe({
                addRow,
                componentIndex,
                composite,
                recipe: componentToAdd,
                forceLink
              })
            )
          case 'recipeToProduct':
            return of(
              deleteRecipeAndLinkedComponents({
                composite,
                recipe: currentComponent
              }),
              replaceComponentWithProduct({
                addRow,
                componentIndex,
                composite,
                product: componentToAdd
              })
            )
          case 'recipeToRecipe':
            return of(
              deleteRecipeAndLinkedComponents({
                composite,
                recipe: currentComponent
              }),
              replaceComponentWithRecipe({
                addRow,
                componentIndex,
                composite,
                recipe: componentToAdd,
                forceLink
              })
            )

          default:
            throw new Error('no replacementType could be')
        }
      }
    )
  )

const replaceComponentWithProductEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(replaceComponentWithProduct().type),
    mergeMap(({payload: {addRow, componentIndex, composite, product}}) => {
      if (
        !!product.configuration &&
        Object.keys(product.configuration).length > 0
      ) {
        /**
         * special case exists for "supplier-products" here. Their id's might
         * change because the supplier products are synced with javaland in the
         * backend. therefore the product has to be copied into the
         * selected restaurants productCollection.
         */

        const {
          products: {products},
          restaurants: {
            selectedRestaurant: {
              productCollectionId: selectedRestaurantProductCollectionId
            }
          }
        } = state$.value

        const copyInSelectedRestaurantProductCollection = Object.values(
          products
        ).find(
          prod =>
            prod.productCollectionId ===
              selectedRestaurantProductCollectionId &&
            prod.title === product.title
        )

        if (!copyInSelectedRestaurantProductCollection) {
          product = {
            ...product,
            id: v4(),
            generated: true, // --> new product will be posted
            permanent: false,
            productCollectionId: selectedRestaurantProductCollectionId
          }
        }

        /**
         * further more "supplier-products" are preconfigured. the configuration
         * is thus copied into the component in the following lines
         */
        const fieldsToUpdate = {
          configured: true,
          preConfigured: true,
          ...product.configuration
        }

        composite = {
          ...composite,
          components: composite.components.map((component, index) => {
            return index === componentIndex
              ? {
                  ...component,
                  ...fieldsToUpdate
                }
              : component
          })
        }
      }

      let returnComposite = linkComponentToComposite({
        composite,
        component: product,
        componentIndex
      })

      if (addRow) {
        returnComposite = appendEmptyComponentToComponents(returnComposite)
      }

      if (product.id === '0') {
        returnComposite = updateComponentField({
          componentIndex,
          fieldName: 'amount',
          composite: returnComposite,
          fieldValue: '100'
        })

        returnComposite = updateComponentField({
          componentIndex,
          fieldName: 'unit',
          composite: returnComposite,
          fieldValue: 'GRAM'
        })
      }
      /*
      If the product is new (generated) the composite cannot simple be updated
      because of contraints in javaland. Instead, the product has to be posted
      first and on succeess the composite can be updated (or created). The following distinction exists for that reason...
       */
      const actionToReturn =
        product.generated !== undefined && product.generated
          ? createOrUpdateCompositeWithNewProduct({
              composite: returnComposite,
              product: omit(['generated'], product)
            })
          : createOrUpdateComposite({
              composite: returnComposite
            })

      return of(actionToReturn, setSelectedComponent(product))
    })
  )

const replaceComponentWithRecipeEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(replaceComponentWithRecipe().type),
    concatMap(
      ({payload: {addRow, componentIndex, composite, recipe, forceLink}}) => {
        const hasSubRecipes =
          recipe.components.filter(({component}) => component.type === 'recipe')
            .length > 0

        const compositeWithUpdatedRecipeAmount = updateComponentField({
          componentIndex,
          fieldName: 'amount',
          composite,
          fieldValue: recipe.gramsPerServing || 100
        })

        // need to make sure that the child recipe belongs to the correct recipe-collection:
        let requiredChildRecipeCollectionId = null
        if (composite.type == 'menu') {
          // need to make sure that the child recipe belongs to the recipeCollection of the restaurant of the parent menuCollection:
          const {
            restaurants: {selectedRestaurant}
          } = state$.value

          if (
            selectedRestaurant.menuCollectionId == composite.menuCollectionId
          ) {
            requiredChildRecipeCollectionId =
              selectedRestaurant.recipeCollectionId
          } else {
            console.error(
              'cannot replaceComponentWithRecipe in a menu of a restaruant that is not the selectedRestaurant!'
            )
            return of()
          }
        } else {
          // require that the child recipe is in the same recipe-collection like the composite:
          requiredChildRecipeCollectionId = composite.recipeCollectionId
        }

        /**
         * whenever a recipe gets added to a parent (composite) it needs to get
         * the productionDate of the parent
         */
        const updateWithParentProductionDate = updateProductionDate(
          composite.productionDate
        )

        const recipeWithParentProductionDate = updateWithParentProductionDate(
          recipe
        )

        /*
      WARNING: Even if recipe.generated==true, we need to make a copy or deep-copy of the recipe that should be added as component, 
      because the server might not have inserted a newly generated recipe yet.
      Therefore, without deep copy, it could happen that a newly generated recipe can add itself as a component leading to cycles.
      Instead only to linking if forceLink==true...
      */
        if (forceLink) {
          // make sure that the child collection is set correctly:
          recipe.recipeCollectionId = requiredChildRecipeCollectionId

          const compositeWithLinkedRecipe = linkComponentToComposite({
            composite,
            component: recipeWithParentProductionDate,
            componentIndex
          })

          return of(
            createOrUpdateComposite({
              composite: addRow
                ? appendEmptyComponentToComponents(compositeWithLinkedRecipe)
                : compositeWithLinkedRecipe
            }),
            setSelectedComponent(recipeWithParentProductionDate)
          )
        } else if (hasSubRecipes) {
          const {
            recipes: {recipes}
          } = state$.value

          const deepCopyData = generateDeepCopyData({
            id: recipe.id,
            recipes,
            depth: 0
          })

          const {recipe: deepCopiedRecipe, recipePostList} = deepCopyData

          // make sure that all deep children belong to the same recipe-collection:
          deepCopiedRecipe.recipeCollectionId = requiredChildRecipeCollectionId
          recipePostList.forEach(recipe => {
            recipe.recipeCollectionId = requiredChildRecipeCollectionId
          })

          const deepCopiedRecipeWithParentProductionDate = updateWithParentProductionDate(
            deepCopiedRecipe
          )

          const compositeWithLinkedDeepCopiedRecipe = linkComponentToComposite({
            composite: compositeWithUpdatedRecipeAmount,
            componentIndex,
            component: deepCopiedRecipeWithParentProductionDate
          })

          const generateRecipesToPost = pipe(
            reverse,
            ramdaMap(updateWithParentProductionDate)
          )
          const recipesToPost = generateRecipesToPost(recipePostList)

          return of(
            ...flatten(
              recipesToPost.map(recipe => [
                updateRecipeOptimistic({recipe}),
                postRecipe(recipe)
              ])
            ),
            createOrUpdateComposite({
              composite: addRow
                ? appendEmptyComponentToComponents(
                    compositeWithLinkedDeepCopiedRecipe
                  )
                : compositeWithLinkedDeepCopiedRecipe
            }),
            setSelectedComponent(deepCopiedRecipeWithParentProductionDate)
          )
        } else {
          const flatRecipeCopy = flatCopyComposite(
            recipeWithParentProductionDate
          )

          flatRecipeCopy.recipeCollectionId = requiredChildRecipeCollectionId

          const compositeWithLinkedFlatRecipeCopy = linkComponentToComposite({
            composite: compositeWithUpdatedRecipeAmount,
            component: flatRecipeCopy,
            componentIndex
          })

          return of(
            createOrUpdateCompositeWithNewRecipe({
              composite: addRow
                ? appendEmptyComponentToComponents(
                    compositeWithLinkedFlatRecipeCopy
                  )
                : compositeWithLinkedFlatRecipeCopy,
              recipe: flatRecipeCopy
            }),
            setSelectedComponent(flatRecipeCopy)
          )
        }
      }
    )
  )

const createOrUpdateCompositeWithNewProductEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(createOrUpdateCompositeWithNewProduct().type),
    mergeMap(({payload: {composite, product}}) => {
      const sliceType = composite.type

      const optimisticUpdateFns = {
        menu: partial(updateMenuOptimistic, [{menu: composite}]),
        recipe: partial(updateRecipeOptimistic, [{recipe: composite}])
      }

      const createFunctions = {
        menu: partial(createProductThenCreateMenu, [
          {
            menu: composite,
            product
          }
        ]),
        recipe: partial(createProductThenCreateRecipe, [
          {
            recipe: composite,
            product
          }
        ])
      }

      const updateFunctions = {
        menu: partial(createProductThenUpdateMenu, [
          {
            menu: composite,
            product
          }
        ]),
        recipe: partial(createProductThenUpdateRecipe, [
          {
            recipe: composite,
            product
          }
        ])
      }

      const updateOptimistic = optimisticUpdateFns[sliceType]

      const createOrUpdateFn = composite.generated
        ? createFunctions[sliceType]
        : updateFunctions[sliceType]

      return of(updateOptimistic(), createOrUpdateFn())
    })
  )

const createOrUpdateCompositeWithNewRecipeEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(createOrUpdateCompositeWithNewRecipe().type),
    mergeMap(({payload: {composite, recipe}}) => {
      const sliceType = composite.type

      const optimisticUpdateFns = {
        menu: partial(updateMenuOptimistic, [{menu: composite}]),
        recipe: partial(updateRecipeOptimistic, [{recipe: composite}])
      }

      const createFunctions = {
        menu: partial(createRecipeThenCreateMenu, [
          {
            menu: composite,
            recipe
          }
        ]),
        recipe: partial(createRecipeThenCreateRecipe, [
          {
            recipe: composite,
            subrecipe: recipe
          }
        ])
      }

      const updateFunctions = {
        menu: partial(createRecipeThenUpdateMenu, [
          {
            menu: composite,
            recipe
          }
        ]),
        recipe: partial(createRecipeThenUpdateRecipe, [
          {
            recipe: composite,
            subrecipe: recipe
          }
        ])
      }

      const updateOptimistic = optimisticUpdateFns[sliceType]

      const createOrUpdateFn = composite.generated
        ? createFunctions[sliceType]
        : updateFunctions[sliceType]

      return of(updateOptimistic(), createOrUpdateFn())
    })
  )

const createOrUpdateCompositeEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(createOrUpdateComposite().type),
    map(({payload: {composite}}) => {
      const state = state$.value
      const {
        router: {
          location: {search}
        }
      } = state

      const isEmptyMenu = composite.components.reduce((prev, next) => {
        return (
          prev && next.component.type === 'product' && next.component.id === '0'
        )
      }, composite.type === 'menu' && composite.title === '')
      /*
      side effect: if the edited composite ends up with only empty
      components, it is replace with a product in the composite
       */
      const isEmptyRecipe = composite.components.reduce((prev, next) => {
        return (
          prev && next.component.type === 'product' && next.component.id === '0'
        )
      }, composite.type === 'recipe')

      const isSubRecipe = parseQueryString(search).parentId !== undefined

      return {
        composite,
        isEmptyRecipe,
        isEmptyMenu,
        isSubRecipe
      }
    }),
    mergeMap(({composite, isEmptyRecipe, isEmptyMenu, isSubRecipe}) => {
      const sliceType = composite.type

      const optimisticUpdateFns = {
        menu: partial(updateMenuOptimistic, [{menu: composite}]),
        recipe: partial(updateRecipeOptimistic, [{recipe: composite}])
      }

      const updateFunctions = {
        menu: partial(putMenu, [{menu: composite}]),
        recipe: partial(putRecipe, [{recipe: composite}])
      }

      const createFunctions = {
        menu: partial(postMenu, [{menu: composite}]),
        recipe: partial(postRecipe, [composite])
      }

      const updateOptimistic = optimisticUpdateFns[sliceType]

      const createOrUpdateFn = composite.generated
        ? createFunctions[sliceType]
        : updateFunctions[sliceType]

      if (isEmptyMenu) {
        return of(deleteMenu({menu: composite}), setGenerated(composite))
      }

      if (isEmptyRecipe && isSubRecipe) {
        // have to rely on the global state here to get the grandParent and
        // productCollectionId
        const state = state$.value
        const {
          router: {
            location: {search}
          }
        } = state
        const {parentComponentIndex} = parseQueryString(search)
        const {productCollectionId} = selectCollectionIds(state)
        const grandParent = selectParentByIdFromUrl(state)
        const editedComponentIndex = getEditedComponentIndex(state)
        const productToReplaceRecipe = generateStandardProduct({
          productCollectionId,
          title: composite.title
        })

        return of(
          replaceComponent({
            componentToAdd: productToReplaceRecipe,
            currentComponent: composite,
            componentIndex: editedComponentIndex,
            composite: grandParent
          }),
          replace(
            `/select?productId=${productToReplaceRecipe.id}&parentId=${grandParent.id}&parentComponentIndex=${parentComponentIndex}`
          )
        )
      } else {
        return of(updateOptimistic(), createOrUpdateFn())
      }
    })
  )

const processComponentAmountEpic = action$ =>
  action$.pipe(
    ofType(processComponentAmount().type),
    filter(({payload: {oldValue, newValue}}) => oldValue !== newValue),
    map(({payload: {componentIndex, newValue: value, oldValue, composite}}) => {
      const sanitizedValue = Number(value.replace(',', '.'))
      const validatedValue = isNaN(sanitizedValue) ? oldValue : sanitizedValue

      const updatedComposite = updateComponentField({
        componentIndex,
        fieldName: 'amount',
        composite,
        fieldValue: validatedValue
      })

      return createOrUpdateComposite({composite: updatedComposite})
    })
  )

const processComponentUnitEpic = action$ =>
  action$.pipe(
    ofType(processComponentUnit().type),
    map(({payload: {componentIndex, value, composite}}) => {
      const updatedComposite = updateComponentField({
        componentIndex,
        fieldName: 'unit',
        composite,
        fieldValue: unitConversionMapBackward[value]
      })

      return createOrUpdateComposite({composite: updatedComposite})
    })
  )

const processComponentSectionEpic = action$ =>
  action$.pipe(
    ofType(processComponentSection().type),
    filter(({payload: {oldValue, newValue}}) => oldValue !== newValue),
    map(({payload: {componentIndex, composite, newValue: value}}) => {
      const updatedComposite = updateComponentField({
        componentIndex,
        fieldName: 'section',
        composite,
        fieldValue: value
      })

      return createOrUpdateComposite({composite: updatedComposite})
    })
  )

const addComponentWithDefaultProductEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(addComponentWithDefaultProduct().type),
    mergeMap(({payload: {composite}}) => {
      const compositeWithComponentAppended = appendEmptyComponentToComponents(
        composite
      )

      return of(
        createOrUpdateComposite({composite: compositeWithComponentAppended}),
        /*
        setting the popover content to menuDetails because there is no
        focus on any input after addition of the new row...
         */
        setDayPlanningPopOverContentType('compositeDetails')
      )
    })
  )

const deleteProductIfNotLinkedOrPermanentEpic = (action$, state$) =>
  action$.pipe(
    ofType(deleteProductIfNotLinkedOrPermanent().type),
    // do not proceed if the product is permanent - null product is permanent
    filter(({payload: {product}}) => {
      return !product.permanent
    }),
    // do not proceed if the product is present in the parent multiple times
    filter(({payload: {composite, product}}) => {
      const multipleCopiesInComposite =
        composite.components
          .map(c => c.component.id)
          .filter(id => id === product.id).length > 1
      return !multipleCopiesInComposite
    }),
    // do not proceed if the product is linked!
    filter(({payload: {composite, product}}) => {
      const {
        menus: {menus},
        recipes: {recipes}
      } = state$.value

      const productIsLinked = checkIfProductIsLinked({
        menus,
        recipes,
        composite,
        product
      })

      return !productIsLinked
    }),
    map(({payload: {product}}) => {
      return deleteProduct(product)
    })
  )

const deleteRecipeAndLinkedComponentsEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(deleteRecipeAndLinkedComponents().type),
    // do not proceed if the recipe is permanent
    filter(({payload: {recipe}}) => {
      return !recipe.permanent
    }),
    mergeMap(({payload: {composite, recipe}}) => {
      const {
        products: {products},
        recipes: {recipes}
      } = state$.value

      const ignoreIds = {}
      ignoreIds[recipe.id] = true
      const componentsToDelete = flatten(
        collectLinkedComponentData({recipes, composite: recipe, ignoreIds})
      )

      const recipesToDelete = componentsToDelete.filter(
        c => c.type === 'recipe'
      )

      const productsToDelete = componentsToDelete
        .filter(c => c.type === 'product' && c.id !== '0')
        .map(p => ({
          ...p,
          permanent: products[p.id] && products[p.id].permanent
        }))

      /*
       deleteProductIfNotLinkedOrPermanent for all collected products, recipes
       can be deleted just like that cause they are deep copies
      */
      return of(
        ...flatten(
          recipesToDelete.map(recipe => [
            deleteRecipeOptimistic({recipe}),
            deleteRecipe({recipe})
          ])
        ),
        deleteRecipeOptimistic({recipe}),
        deleteRecipe({recipe}),
        ...productsToDelete.map(product =>
          deleteProductIfNotLinkedOrPermanent({composite, product})
        )
      )
    })
  )

const removeComponentEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(removeComponent().type),
    mergeMap(({payload: {composite, componentIndex}}) => {
      /*
        Currently this epic only gets called with a componentIndex pointing
        to an empty product. In the future we might have to deal with the
        component that is removed, e.g. if it is a product ->
        deleteProductIfNotLinkedOrPermanent, if it is a recipe -> wanr user
        etc...
      */
      const compositeWithComponentRemoved = {
        ...composite,
        components: remove(componentIndex, 1, composite.components)
      }

      return of(
        createOrUpdateComposite({composite: compositeWithComponentRemoved}),
        resetEditedComponentIndex()
      )
    })
  )

const processComponentTitleKeyDownEpic = action$ =>
  action$.pipe(
    ofType(processComponentTitleKeyDown().type),
    map(({payload}) => {
      const {addRow, composite, currentComponent, componentToAdd} = payload

      return addRow && currentComponent.id === componentToAdd.id
        ? createOrUpdateComposite({
            composite: appendEmptyComponentToComponents(composite)
          })
        : replaceComponent(payload)
    })
  )

export const dataGridEpics = combineEpics(
  addComponentWithDefaultProductEpic,
  createOrUpdateCompositeEpic,
  createOrUpdateCompositeWithNewProductEpic,
  createOrUpdateCompositeWithNewRecipeEpic,
  deleteProductIfNotLinkedOrPermanentEpic,
  deleteRecipeAndLinkedComponentsEpic,
  processComponentAmountEpic,
  processComponentTitleKeyDownEpic,
  processComponentSectionEpic,
  processComponentUnitEpic,
  removeComponentEpic,
  replaceComponentEpic,
  replaceComponentWithRecipeEpic,
  replaceComponentWithProductEpic
)
