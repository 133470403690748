import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Group = styled.div``

const ButtonGroup = ({className, children}) => {
  const childrenWithNewProps = React.Children.map(children, (child, index) => {
    const first = index === 0
    const last = index === children.length - 1
    const nth = !first && !last
    return React.cloneElement(child, {first, last, nth})
  })

  return <Group className={className}>{childrenWithNewProps}</Group>
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default ButtonGroup
