import {createAction, createReducer} from 'redux-act'

export const initialToolTip = {
  isOpen: false,
  position: {
    left: 0,
    top: 0
  }
}

export const closeToolTip = createAction('toolTip/CLOSE')
export const openToolTip = createAction('toolTip/OPEN')
export const setToolTipPosition = createAction('toolTip/SET_TOOL_TIP_POSITION')

const reducer = createReducer(
  {
    [closeToolTip]: state => ({...state, isOpen: false}),
    [openToolTip]: state => ({...state, isOpen: true}),
    [setToolTipPosition]: (state, payload) => ({...state, position: payload})
  },
  initialToolTip
)

export default reducer
