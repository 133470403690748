import React from 'react'
import PropTypes from 'prop-types'

const DefaultRecipeIcon = ({renderNotification, size = 64}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 64 70"
  >
    <g id="recipe-icon-multiple-unmatched" fill="none" fillRule="evenodd">
      <g id="recipe-icon" fill="#9DA2A6">
        <path
          id="Shape"
          fillRule="nonzero"
          d="M20.8345026,8.73018974 L16.2593345,8.73018974 L15.3129385,8.73018974 L15.3129385,7.62587692 L15.3129385,3.20862564 L16.4172513,3.20862564 L17.5215641,6.5215641 L20.8345026,7.62587692 L20.8345026,8.73018974 Z M19.7301897,14.2517538 L4.26981026,14.2517538 L4.26981026,13.147441 L19.7301897,13.147441 L19.7301897,14.2517538 Z M19.7301897,16.4603795 L4.26981026,16.4603795 L4.26981026,15.3560667 L19.7301897,15.3560667 L19.7301897,16.4603795 Z M19.7301897,18.6690051 L4.26981026,18.6690051 L4.26981026,17.5646923 L19.7301897,17.5646923 L19.7301897,18.6690051 Z M14.1070289,20.8776308 L4.26981026,20.8776308 L4.26981026,19.7733179 L14.1070289,19.7733179 L14.1070289,20.8776308 Z M16.4172513,1 L0.956871799,1 L0.956871799,28.6078205 L23.0431282,28.6078205 L23.0431282,7.62587692 L16.4172513,1 Z"
          transform="translate(18 20)"
        />
      </g>
      {renderNotification && renderNotification()}
    </g>
  </svg>
)

DefaultRecipeIcon.propTypes = {
  renderNotification: PropTypes.func,
  size: PropTypes.number
}

export default DefaultRecipeIcon
