import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import IssueIndicator from '../components/IssueIndicator'

const mapStateToProps = state => {
  const {
    type: compositeType,
    serviceStatus: {matchingStatus}
  } = selectCompositeByIdFromUrl(state)

  return {
    hide: matchingStatus === undefined || matchingStatus === 200,
    translateKey: String(matchingStatus),
    translateData: {
      compositeType
    }
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(IssueIndicator)
