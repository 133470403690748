export {default as AwardHealth} from './SmallAwards/Health.svg'
export {default as AwardClimate} from './SmallAwards/Climate.svg'
export {default as AwardWater} from './SmallAwards/Water.svg'
export {default as AwardEnergy} from './SmallAwards/Energy.svg'
export {default as AwardForest} from './SmallAwards/Forest.svg'
export {default as AwardAnimal} from './SmallAwards/Animal.svg'
export {default as AwardSeason} from './SmallAwards/Season.svg'
export {default as AwardLocal} from './SmallAwards/Local.svg'
export {default as AwardProfit} from './SmallAwards/Profit.svg'
export {default as AwardEaternity} from './SmallAwards/Eaternity.svg'

export {default as EnvironmentBanner} from './AwardsBanner/EnvironmentBanner.svg'
export {default as HealthBanner} from './AwardsBanner/HealthBanner.svg'
export {default as IconAnimalWelfareActive} from './IndicatorColor/AnimalWelfare.svg'
export {default as IconAnimalWelfareInactive} from './IndicatorGrey/AnimalWelfare.svg'
export {default as IconArrowRight} from './ArrowRightGrey.svg'
export {default as IconAwardVitaScore} from './Awards_Big/Vita Award.svg'
export {default as IconAwardWater} from './Awards_Big/Water Award.svg'
export {default as IconCO2Inactive} from './IndicatorGrey/CO2.svg'
export {default as IconCoinActive} from './IndicatorColor/Coin.svg'
export {default as IconConfirm} from './Confirm.svg'
export {default as IconCoinInactive} from './IndicatorGrey/Coin.svg'
export {default as IconCross} from './Cross.svg'
export {default as IconCrossGrey} from './CrossGrey.svg'
export {default as IconDashboard} from './Dashboard.svg'
export {default as IconEnergyInactive} from './IndicatorGrey/Energy.svg'
export {default as IconEnergyActive} from './IndicatorColor/Energy.svg'
export {default as IconForestActive} from './IndicatorColor/Forest.svg'
export {default as IconForestInactive} from './IndicatorGrey/Forest.svg'
export {default as IconHeartActive} from './IndicatorColor/Heart.svg'
export {default as IconHeartInactive} from './IndicatorGrey/Heart.svg'
export {default as IconLeftArrowPopover} from './LeftArrowPopover.svg'
export {default as IconLeftCalendarArrow} from './LeftCalendarArrow.svg'
export {default as IconLocalActive} from './IndicatorColor/Local.svg'
export {default as IconLocalInactive} from './IndicatorGrey/Local.svg'
export {default as IconLogo} from './Logo.svg'
export {default as IconMenu} from './Menu.svg'
export {default as IconMinus} from './Minus.svg'
export {default as IconNoAwardVitaScore} from './Awards_Big/Vita noAward.svg'
export {default as IconNoAwardWater} from './Awards_Big/Water noAward.svg'
export {default as IconOptions} from './Options.svg'
export {default as IconOptionsGrey} from './OptionsGrey.svg'
export {default as IconOptionsLightGrey} from './OptionsLightGrey.svg'
export {default as IconPinUp} from './PinUp.svg'
export {default as IconPinUpYellow} from './PinUpYellow.svg'
export {default as IconPlus} from './Plus.svg'
export {default as IconPlusGrey} from './PlusGrey.svg'
export {default as IconPurchase} from './Purchase.svg'
export {default as IconRightCalendarArrow} from './RightCalendarArrow.svg'
export {default as IconSearch} from './Search.svg'
export {default as IconSearchGrey} from './SearchGrey.svg'
export {default as IconSeasonActive} from './IndicatorColor/Season.svg'
export {default as IconSeasonInactive} from './IndicatorGrey/Season.svg'
export {default as IconSettings} from './Settings.svg'
export {default as IconSharrow} from './Sharrow.svg'
export {default as IconPrint} from './Print.svg'
export {default as IconStock} from './Stock.svg'
export {default as IconSupplier} from './Supplier.svg'
export {default as IconWaterActive} from './IndicatorColor/Water.svg'
export {default as IconWaterInactive} from './IndicatorGrey/Water.svg'
export {default as LeftArrowPopover} from './LeftArrowPopover.svg'
export {default as WaterBanner} from './AwardsBanner/WaterBanner.svg'
export {default as SeasonBanner} from './AwardsBanner/SeasonBanner.svg'
export {default as AnimalBanner} from './AwardsBanner/AnimalBanner.svg'
export {default as ForestBanner} from './AwardsBanner/ForestBanner.svg'
export {default as LocalBanner} from './AwardsBanner/LocalBanner.svg'
export {default as Confirm} from './Confirm.svg'
