import PropTypes from 'prop-types'
import React, {useState} from 'react'
import styled from 'styled-components'

const ImageOrFallBackBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: ${p => p.width};
  height: auto;
`

const ImageOrFallBack = ({className, imageUrl, fallback}) => {
  const [loading, setLoading] = useState(true)

  return (
    <ImageOrFallBackBase className={className}>
      {loading && fallback()}
      <Image
        draggable={false}
        onLoad={() => setLoading(false)}
        src={imageUrl}
        width={loading ? 0 : '100%'}
      />
    </ImageOrFallBackBase>
  )
}

ImageOrFallBack.propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string,
  fallback: PropTypes.func.isRequired
}

export default ImageOrFallBack
