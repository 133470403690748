import qs from 'qs'
import {curry, uniqBy} from 'ramda'
import React from 'react'
import {inspect} from 'util'

export const withSliceType = (Component, sliceType) => {
  const ComponentWithSliceType = () => <Component sliceType={sliceType} />
  return ComponentWithSliceType
}

const checkoutOutOfBounds = (array, index) => {
  const arrayLength = array.length
  const regularIndexOutOfBounds = index > arrayLength - 1
  const reverseIndexOutOfBounds = index < 0 && Math.abs(index) > arrayLength
  const indexOutOfBounds = regularIndexOutOfBounds || reverseIndexOutOfBounds

  if (indexOutOfBounds) {
    throw new Error('Index out of bounds')
  }
}

/**
 * Function to replace an array item at the given index with the given item
 * @param  {Array} array     Array containing item to be replaced
 * @param  {Number} index    Index of item to be replaced
 * @param  {Object} newItem     Any value. Will be placed in the array at index
 * @return {Array}          Copy of the original array containing new item
 */
export const replaceAtIndex = (array, index, newItem) => {
  if (typeof index !== 'number') {
    throw new Error('index provided to replaceAtIndex is not a number')
  }

  checkoutOutOfBounds(array, index)

  return [...array.slice(0, index), newItem, ...array.slice(index + 1)]
}

export const addAfterIndex = (array, index, item) => {
  return [...array.slice(0, index + 1), item, ...array.slice(index + 1)]
}

/**
 * Function to turn an array of objects into an object holding all objects by
 * id. Each of the objects in the array must have a id field containing a
 * unique id!
 * @param  {Array} array array of objects (which each have an id)
 * @return {Object}      object holding all objects by id
 */
const normalizeByKey = curry((key, array) => {
  if (!Array.isArray(array)) {
    return {}
  }

  if (array.length === 0) {
    return {}
  }

  const allObjectsHaveKey = array.every(obj => obj[key])
  const allKeysAreUnique =
    uniqBy(obj => obj[key], array).length === array.length

  if (!allObjectsHaveKey || !allKeysAreUnique) {
    console.error(
      `normalizeByKey: array provided as input contains objects without key ${key} or objects with non unique keys`
    )
  }

  return array.reduce((accumulator, obj) => {
    accumulator[obj[key]] = obj
    return accumulator
  }, {})
})

export const normalizeById = normalizeByKey('id')

export const normalizeByEmail = normalizeByKey('email')

// extract as little npm module ;-) catch-keys
export const catchKeys = ({return: returnFn, esc: escFn}) => event => {
  const isKeyEvent =
    event.keyCode && (event.type === 'keydown' || event.type === 'keyup')

  if (!isKeyEvent) {
    throw new Error(
      `Error in key-catch: non keyboard event passed to catchReturn function`
    )
  }

  switch (event.keyCode) {
    case 13:
      return returnFn(event)

    case 27:
      return escFn(event)
    default:
      return null
  }
}

export const refetchIfNeeded = ({
  getAllMenus,
  getMenusSucceeded = true,
  getAllProducts,
  getProductsSucceeded = true,
  getAllRecipes,
  getRecipesSucceeded = true,
  getUserRestaurants,
  getUserRestaurantsSucceeded = true
}) => () => {
  !getUserRestaurantsSucceeded && getUserRestaurants()
  !getMenusSucceeded && getAllMenus()
  !getRecipesSucceeded && getAllRecipes()
  !getProductsSucceeded && getAllProducts()
}

export const moveCursorToEndOfLine = input => {
  input.focus()
  const tmpValue = input.value
  input.value = ''
  input.value = tmpValue
}

export const parseQueryString = queryString => qs.parse(queryString.slice(1))

/**
 * logo === log object. helper function to deep console.log an object
 * @param  {[type]} object [description]
 * @return {[type]}        [description]
 */
export const logo = object => console.log(inspect(object, {depth: null}))

export const addImageTransformation = (
  uploadCareUrl,
  transformString,
  twoX = true
) => {
  if (!uploadCareUrl || !transformString) {
    return undefined
  }

  const splitUrl = uploadCareUrl.split('/')
  const splitUrlLength = splitUrl.length
  const splitUrlWithTransformString = [
    ...splitUrl.slice(0, splitUrlLength - 1),
    transformString,
    splitUrl[splitUrlLength - 1]
  ]

  const imageUrl = splitUrlWithTransformString.join('/')

  return twoX ? `${imageUrl} 2x` : imageUrl
}

/**
 * @param {string} str
 * @returns {string} string without whitespace
 */
export const removeWhiteSpace = str => str.split(/\s+/).join('')
