import React from 'react'
import PropTypes from 'prop-types'

const SubIconCount = ({count, fillColor}) => {
  return (
    <g id="Icon-/-Notification">
      <rect id="Rectangle-5" x="0" y="0" width="64" height="64" />
      <g id="notification" transform="translate(33.000000, 13.000000)">
        <circle id="Oval-6" fill="#F0F2F5" cx="9" cy="9.5" r="8.75" />
        <circle id="Oval-6" fill={fillColor} cx="9" cy="9.5" r="7" />
        <text
          id="NotificationCounter"
          fill="#FFF"
          fontFamily="'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','sans-serif"
          fontSize="11"
          fontWeight="500"
        >
          <tspan
            id="IconNotificationCount"
            x="9"
            y="13.5"
            textAnchor="middle"
            width="14"
          >
            {count}
          </tspan>
        </text>
      </g>
    </g>
  )
}

SubIconCount.propTypes = {
  count: PropTypes.number,
  fillColor: PropTypes.string
}

export default SubIconCount
