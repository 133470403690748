import {createSelector} from 'reselect'

import {
  generateEmptyProduct,
  generateStandardProduct
} from '../../utils/productUtils'
import {
  getEditedComponentIndex,
  getRecipes,
  getSearchInput,
  getSelectedComponent,
  selectCollectionIds,
  selectCompositeByIdFromUrl,
  selectProducts,
  selectRecipes
} from './selectors'
import {selectSearchResults, selectTopSuggestion} from './search'

export const selectSelectedComponent = createSelector(
  [getSelectedComponent, selectProducts, getRecipes],
  (selectedComponent, products, recipes) => {
    if (selectedComponent === undefined) {
      return undefined
    }

    const componentsMap = {
      recipe: recipes,
      product: products
    }

    return componentsMap[selectedComponent.type][selectedComponent.id]
  }
)

export const selectComponentDetailsOpen = createSelector(
  [selectSelectedComponent, selectSearchResults, selectTopSuggestion],
  (selectedComponent, searchResults, topSuggestion) => {
    const {hasPerfectMatch, hasMultiplePerfectMatches} = searchResults
    const component = selectedComponent || topSuggestion
    const isNullProduct = component && component.id === '0'

    return (
      (selectedComponent !== undefined && !isNullProduct) ||
      (hasPerfectMatch && !hasMultiplePerfectMatches)
    )
  }
)

export const selectCompleteComponent = createSelector(
  [
    selectProducts,
    selectRecipes,
    selectCompositeByIdFromUrl,
    getEditedComponentIndex
  ],
  (productsById, recipesById, selectedComposite, editedComponentIndex) => {
    if (
      editedComponentIndex === undefined ||
      !selectedComposite ||
      (selectedComposite &&
        editedComponentIndex > selectedComposite.components.length - 1)
    ) {
      return undefined
    }

    const component = selectedComposite.components[editedComponentIndex]

    const {
      component: {id: componentId}
    } = component

    const completeComponent =
      recipesById[componentId] || productsById[componentId]

    return component.component.id !== '0'
      ? {
          ...component,
          title: completeComponent && completeComponent.title,
          imageUrl: completeComponent && completeComponent.imageUrl
        }
      : undefined
  }
)

export const selectComponentToAdd = createSelector(
  [
    selectCollectionIds,
    getSearchInput,
    getSelectedComponent,
    selectTopSuggestion
  ],
  (collectionIds, searchInput, selectedComponent, topSuggestion) => {
    /*
    The componentToAdd title can come from either:
    - the component that was selected from the search results onMouseDown
      which fired before this handler here! (aka. the selectedComponent)
    - the topSuggestion from the search
    - a new product with title === searchInput when search results are empty
     */
    const {productCollectionId} = collectionIds

    return searchInput.trim() === ''
      ? generateEmptyProduct()
      : selectedComponent && selectedComponent.type === 'recipe'
      ? {...selectedComponent, permanent: false}
      : selectedComponent ||
        topSuggestion ||
        generateStandardProduct({
          title:
            (selectedComponent && selectedComponent.title) ||
            (topSuggestion && topSuggestion.title) ||
            searchInput.trim(),
          permanent:
            (selectedComponent && selectedComponent.permanent) ||
            (topSuggestion && topSuggestion.permanent),
          productCollectionId
        })
  }
)
