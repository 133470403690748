import moment from 'moment'
import {useTranslation} from 'react-i18next'

const useMoment = () => {
  const locale = useTranslation()[1].language

  try {
    const [localeRoot] = locale.split('-')
    const notDefault = locale !== 'en-us' && locale !== 'en'
    // english is the default locale and can't be required
    if (notDefault) {
      try {
        require(`moment/locale/${locale}`)
        moment.locale(locale)
      } catch (err) {
        require(`moment/locale/${localeRoot}`)
        moment.locale(localeRoot)
        console.warn(
          `${err}. Imported moment locale data for '${localeRoot}' instead!`
        )
      }
    }
  } catch (err) {
    console.warn(err)
  }

  return moment
}

export default useMoment
