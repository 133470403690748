import {extendMoment} from 'moment-range'
import values from 'lodash/values'
import _moment from 'moment'

import {dateToProductionDateString} from '../../../utils/dateUtils'
import {generateStandardMenu} from '../../../utils/menuUtils'

const moment = extendMoment(_moment)

export const getStartOfWeekMoment = date => moment.utc(date).startOf('isoweek')
export const getStartOfWeek = date => getStartOfWeekMoment(date).toDate()
export const getEndOfWeek = date =>
  moment
    .utc(date)
    .endOf('isoweek')
    .toDate()

export const generateDateRange = (fromDate, toDate) =>
  Array.from(moment.range(fromDate, toDate).by('day')).map(d => d.toDate())

/**
 * This function generates an array of moments spanning a week
 * @param  {Number} date    date as unix time in ms
 * @return {Array}          an array of moments representing a week
 */
export const generateWeekRange = date => {
  const weekStart = getStartOfWeek(date)
  const weekEnd = getEndOfWeek(date)
  return generateDateRange(weekStart, weekEnd)
}

export const addAWeek = date =>
  moment
    .utc(date)
    .add(7, 'days')
    .toDate()

export const addADay = date =>
  moment
    .utc(date)
    .add(1, 'days')
    .toDate()

export const subtractAWeek = date =>
  moment
    .utc(date)
    .subtract(7, 'days')
    .toDate()

export const subtractADay = date =>
  moment
    .utc(date)
    .subtract(1, 'days')
    .toDate()

export const isToday = date => moment().isSame(date, 'd')

/**
 * This function generates all data needed to render a week in Calendar
 * @param  {Array} menus           array of all menus
 * @param  {Array} menuLines     array of menuLines
* @param  {Date} weekStart        start of the week date

 * @return {Object}                all data needed to render a week in Calendar
 */
export const generateWeekData = (menus, menuLines, weekStart, t) => {
  const days = generateWeekRange(weekStart)

  const grid = menuLines.map(menuLine =>
    days.map(
      day =>
        values(menus).filter(
          menu =>
            menu.productionDate === dateToProductionDateString(day) &&
            menu.menuLineId === menuLine.id
        )[0] ||
        generateStandardMenu({
          productionDate: dateToProductionDateString(day),
          menuLineId: menuLine.id,
          t
        })
    )
  )
  return {days, grid}
}

export const calculatePopoverPosition = (editedMenuId, generated, theme) => {
  // TODO: this is violating every React rule
  // how to do this the legal way? ;-)
  const elementIllegallyTakenFromTheDOM = document.getElementById(
    `CalendarItem${editedMenuId}`
  )

  if (!elementIllegallyTakenFromTheDOM) {
    return
  }

  const windowWidth = window.innerWidth
  const boundingRect = elementIllegallyTakenFromTheDOM.getBoundingClientRect()

  // TODO: empty BoxItem is smaller! Correct distance to box?
  const {left: boundingRectLeft, right: boundingRectRight} = boundingRect
  // TODO: Feels weird to use the theme here but ok for now
  // if this has to be done more often think about an alternative...
  const {
    sizes: {popOverArrowWidth, popOverMargin}
  } = theme
  const boundingRectWidth = boundingRectRight - boundingRectLeft
  const boundingRectMiddle = boundingRectLeft + boundingRectWidth / 2
  const showRightOfElement = boundingRectLeft < windowWidth / 2
  const left = boundingRectMiddle + boundingRectWidth / 2 + popOverArrowWidth
  const right = windowWidth - boundingRectLeft + popOverArrowWidth

  return {
    // TODO: 100 is a magic number again. Document in theme and use from there..
    top: generated ? boundingRect.top - 100 : popOverMargin,
    left: showRightOfElement ? left : 'auto',
    right: showRightOfElement ? 'auto' : right
  }
}

export const calculateArrowPosition = ({height, top}) => {
  return top + height / 2
}
