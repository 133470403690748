import {combineEpics, ofType} from 'redux-observable'
import {createAction} from 'redux-act'
import {filter, mergeMap} from 'rxjs/operators'

import {createOrUpdateComposite} from '../../dataGrid/epics/epics'
import {deleteMenu} from '../../../redux/modules/menus'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'

export const processMenuTitle = createAction(
  'dayPlanningView/PROCESS_MENU_TITLE'
)

//TODO: merge into ../../dataGrid/epics/epics.js
export const processMenuTitleEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processMenuTitle().type),
    filter(({payload: {newValue, oldValue}}) => newValue !== oldValue),
    mergeMap(({payload: {menu, newValue}}) => {
      const updatedEditedMenu = {
        ...menu,
        title: newValue
      }

      if (menu.generated && newValue === '') {
        // this will only delete the menu in the redux store because
        // deleteMenuEpic filters out generated
        return of(deleteMenu(updatedEditedMenu))
      } else {
        return of(
          createOrUpdateComposite({composite: updatedEditedMenu}),
          setDayPlanningPopOverContentType('compositeDetails')
        )
      }
    })
  )

export const dayPlanningViewEpics = combineEpics(processMenuTitleEpic)
