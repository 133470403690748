import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

import {getAwardText, getComparisonText} from '../utils/utils'
import {
  getSelectedIndicatorType,
  selectSelectedIndicatorData
} from '../../../redux/selectors/indicatorSelectors'
import {goBack} from '../../../redux/modules/viewPagerNavigator'
import {
  selectCompositeByIdFromUrl,
  getWebEntryDomain
} from '../../../redux/selectors/selectors'
import IndicatorDetails from '../components/IndicatorDetails'

const mapStateToProps = (state, {t}) => {
  const {type: compositeType} = selectCompositeByIdFromUrl(state)
  const indicatorType = getSelectedIndicatorType(state)
  const {
    hasAward,
    improvementPercentage,
    value: indicatorValue
  } = selectSelectedIndicatorData(state)

  return {
    awardText: getAwardText({
      compositeType,
      indicatorType,
      t
    }),
    comparisonText: getComparisonText({
      compositeType,
      hasAward,
      improvementPercentage,
      indicatorType,
      indicatorValue,
      t
    }),
    hasAward: hasAward,
    indicatorType,
    compositeType,
    indicatorValue,
    webEntryDomain: getWebEntryDomain(state)
  }
}

const mapDispatchToProps = {
  goBack
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(IndicatorDetails)
