import {push} from 'connected-react-router'
import round from 'lodash/round'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {
  resetEditedComponentIndex,
  setEditedComponentIndex,
  setSelectedComponent
} from '../../../redux/modules/components'
import {openConfigureProductModal} from '../../../redux/modules/products'
import {resetSearchInput} from '../../../redux/modules/search'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {
  selectCompleteComponent,
  selectSelectedComponent
} from '../../../redux/selectors/componentSelectors'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import {unitConversionMapForward} from '../../../utils/componentUtils'
import ComponentDetails from '../components/ComponentDetails'

const mapStateToProps = state => {
  const completeComponent = selectCompleteComponent(state)
  const composite = selectCompositeByIdFromUrl(state)
  const cloudIsAvailable =
    composite && String(composite.serviceStatus.cloudStatus)[0] === '2'

  // TODO: can/should we return the matching status on the incredient level?
  const ingredientMatched = !!(
    completeComponent &&
    completeComponent.co2Value &&
    completeComponent.co2Rating
  )

  const componentAmountString =
    composite &&
    composite.servings &&
    completeComponent &&
    completeComponent.quantity &&
    `${round(completeComponent.quantity.amount / composite.servings, 2)} ${
      unitConversionMapForward[completeComponent.quantity.unit]
    }`

  return {
    cloudIsAvailable,
    component: completeComponent,
    componentAmountString,
    editedComponentIndex: state.components.editedComponentIndex,
    ingredientMatched,
    open: !!selectSelectedComponent(state),
    parentId: composite && composite.id,
    visibleIndicatorsOnComponentLevel:
      state.config.visibleIndicatorsOnComponentLevel,
    servings: composite && composite.servings
  }
}

const mapDispatchToProps = {
  openConfigureProductModal,
  push,
  resetEditedComponentIndex,
  resetSearchInput,
  setDayPlanningPopOverContentType,
  setEditedComponentIndex,
  setSelectedComponent
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onMouseDown: ({
      component,
      editedComponentIndex,
      openConfigureProductModal,
      parentId,
      push,
      resetEditedComponentIndex,
      resetSearchInput,
      setDayPlanningPopOverContentType
    }) => () => {
      const {
        configured: componentIsConfigured,
        component: {id: componentId, type: componentType}
      } = component

      if (componentType === 'product' && componentIsConfigured) {
        openConfigureProductModal()
      } else {
        const path =
          componentType === 'recipe'
            ? `/recipes?recipeId=${componentId}&parentId=${parentId}`
            : `/select?productId=${componentId}&parentId=${parentId}&parentComponentIndex=${editedComponentIndex}`
        setDayPlanningPopOverContentType('search')
        resetSearchInput()
        resetEditedComponentIndex()
        push(path)
      }
    }
  })
)(ComponentDetails)
