import PropTypes from 'prop-types'
import React from 'react'
import DayPicker from 'react-day-picker'
import momentLocaleUtils from 'react-day-picker/moment'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {
  IconLeftCalendarArrow,
  IconRightCalendarArrow
} from '../../../assets/icons'
import theme from '../../../theme'
import Icon from '../../icons/components/Icon'

const NavButton = styled(Icon)`
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const IconNext = styled(NavButton)``

const IconBack = styled(NavButton)`
  margin-right: 1.5em;
`

const DayPickerNavBarElement = ({onPreviousClick, onNextClick}) => (
  <>
    <IconBack
      height="40px"
      onClick={() => onPreviousClick()}
      src={IconLeftCalendarArrow}
      width="40px"
    />
    <IconNext
      height="40px"
      onClick={() => onNextClick()}
      src={IconRightCalendarArrow}
      width="40px"
    />
  </>
)

DayPickerNavBarElement.propTypes = {
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired
}

const StyledDayPicker = ({onDayClick, selectedDate}) => {
  const [t, i18n] = useTranslation()

  const todayStyle = `
    .DayPicker-Day--today {
      background-color: ${theme.colors.darkGray};
      color: white;
     }
  `

  const selectedDateStyle = `
    .DayPicker-Day--selectedDate {
      background-color: ${theme.colors.blue};
      color: white;
     }
  `

  const modifiers = {
    today: new Date(),
    selectedDate
  }

  const FIRST_DAY = {
    de: 1, // Use Monday as first day of the week
    en: 1,
    'en-gb': 1,
    'en-us': 0,
    fr: 1
  }

  const getFirstDayOfWeek = locale => FIRST_DAY[locale] || 1

  return (
    <>
      <style>{todayStyle}</style>
      <style>{selectedDateStyle}</style>
      <DayPicker
        locale={i18n.language}
        localeUtils={{...momentLocaleUtils, getFirstDayOfWeek}}
        navbarElement={DayPickerNavBarElement}
        onDayClick={onDayClick}
        onTodayButtonClick={onDayClick}
        modifiers={modifiers}
        month={selectedDate}
        todayButton={t('daypicker.todayButtonText')}
      />
    </>
  )
}

export default StyledDayPicker
