import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import LayoutContentRight from '../../core/components/LayoutContentRight'
import LayoutWithAdminDrawerContainer from '../../core/containers/LayoutWithAdminDrawerContainer'
import LoadingIndicatorModalContainer from '../../modal/containers/LoadingIndicatorModalContainer'
import SearchViewContent from './SearchViewContent'
import FooterContainer from '../../core/containers/FooterContainer'

const SearchView = ({getAllDataSucceeded, onDidMount, refetchIfNeeded}) => {
  useEffect(() => {
    refetchIfNeeded()
    onDidMount()
  }, [])

  return getAllDataSucceeded ? (
    <LayoutWithAdminDrawerContainer
      renderContentRight={() => (
        <LayoutContentRight
          renderMain={() => <SearchViewContent />}
          renderFooter={() => <FooterContainer />}
        />
      )}
    />
  ) : (
    <LoadingIndicatorModalContainer isOpen />
  )
}

SearchView.propTypes = {
  onDidMount: PropTypes.func.isRequired,
  getAllDataSucceeded: PropTypes.bool.isRequired,
  refetchIfNeeded: PropTypes.func.isRequired
}

export default SearchView
