import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {
  resetRegisterState,
  registerUser,
  resetRegLog,
  updateUserEmail
} from '../../../redux/modules/users'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import Register from '../components/Register'

const mapStateToProps = ({
  config: {webEntryDomain},
  users: {
    isAuthenticated,
    registering,
    registrationEmailSend,
    user,
    userExistsWarning
  }
}) => ({
  email: user.email,
  isOpen: !isAuthenticated,
  registering,
  registrationEmailSend,
  user,
  userExistsWarning,
  webEntryDomain
})

const mapDispatchToProps = {
  registerUser,
  resetRegisterState,
  resetRegLog,
  updateUserEmail
}

const RegisterContainer = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onChange: ({updateUserEmail}) => ({target: {value}}) => {
      updateUserEmail(value)
    },
    register: ({registerUser, t, user, webEntryDomain}) => () => {
      registerUser({
        buttonText: t('email.register.buttonText'),
        closing1: t('email.logReg.closing1'),
        closing2: t('email.logReg.closing2'),
        greeting: t('email.register.greeting'),
        linkInfoText: t('email.logReg.linkInfoText'),
        paragraph1: t('email.register.paragraph1'),
        paragraph2: t('email.register.paragraph2'),
        paragraph3:
          webEntryDomain === 'klimateller'
            ? ''
            : t('email.register.paragraph3'),
        subject: t('email.register.subject'),
        user,
        webEntryDomain
      })
    },
    goToLogin: ({history: {push}, resetRegisterState}) => () => {
      resetRegisterState()
      push('/login')
    },
    goToTerms: ({history: {push}}) => () => {
      push('/terms')
    },
    resetRegister: ({history: {push}, resetRegLog}) => () => {
      resetRegLog()
      push('/register')
    }
  })
)(Register)

export default RegisterContainer
