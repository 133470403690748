import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import AddRowWrapper from '../../core/components/AddRowWrapper'
import Button from '../../core/components/Button'
import RowHeader from '../../core/components/RowHeader'
import SpacerBar from '../../core/components/SpacerBar'
import ServingsSetterContainer from '../../core/containers/ServingsSetterContainer'
import DataGrid from '../../dataGrid/components/DataGrid'
import HeaderCellLeft from '../../dataGrid/components/HeaderCellLeft'
import HeaderCellRight from '../../dataGrid/components/HeaderCellRight'
import HeaderRow from '../../dataGrid/components/HeaderRow'
import InfoPopOverContainer from '../../dayPlanningView/containers/InfoPopOverContainer'
import AwardIndicatorsContainer from '../../indicators/containers/AwardIndicatorsContainer'
import DuplicateRecipeListContainer from '../containers/DuplicateRecipeListContainer'
import RecipeTitleInputContainer from '../containers/RecipeTitleInputContainer'
import Instructions from './Instructions'
import DefaultRecipeIcon from '../../icons/components/DefaultRecipeIcon'

const RecipeEditBase = styled.div``

const ScrollContainer = styled.div`
  height: ${p => p.theme.sizes.dataGridHeightRecipe}
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  @media print {
    overflow: visible;
  }
`

const AddButton = styled(Button)`
  height: 38px;
`

const TwoColumns = styled.div`
  display: flex;
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${p => p.theme.sizes.dayPlanningContentMarginRight}px;
`

const Spacer = styled.div`
  height: 30px;
  margin-left: 60px;
`

const RightColumn = styled.div`
  margin-top: ${p =>
    -(
      p.theme.sizes.spacerBarHeight +
      p.theme.sizes.headerHeight -
      p.theme.sizes.popOverMargin
    )}px;
  margin-bottom: ${p =>
    -(p.theme.sizes.footerHeight - p.theme.sizes.popOverMargin)}px;
  z-index: 1;
`

const RecipeEditSpaceBar = styled(SpacerBar)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const IconPositioner = styled.div`
  display: flex;
  align-itmes: center;
  margin-left: auto;
  margin-right: 5px;
  align-self: center;
`

const RecipeEditMainRowHeader = styled(RowHeader)`
  margin-left: 60px;
  margin-bottom: 0;
  @media print {
    display: none;
  }
`

const RecipeEditMainServingsSetterContainer = styled(ServingsSetterContainer)`
  justify-content: flex-start;
  margin-left: 60px;
`

const RecipeEditMain = ({
  addRowToInstructions,
  className,
  hasDuplicateRecipes,
  imageUrls,
  onAddRowClick,
  recipe,
  style
}) => {
  const [t] = useTranslation()

  if (!recipe) {
    return null
  }

  return (
    <RecipeEditBase style={style} className={className}>
      {hasDuplicateRecipes ? (
        <DuplicateRecipeListContainer />
      ) : (
        <RecipeEditSpaceBar imageUrl={recipe.imageUrl}>
          <AwardIndicatorsContainer />
        </RecipeEditSpaceBar>
      )}
      <TwoColumns>
        <LeftColumn>
          <HeaderRow>
            <HeaderCellLeft>
              <IconPositioner>
                {!recipe.imageUrl && <DefaultRecipeIcon size={64} />}
              </IconPositioner>
            </HeaderCellLeft>
            <HeaderCellRight id="HeaderCellRight">
              <RecipeTitleInputContainer recipe={recipe} />
            </HeaderCellRight>
          </HeaderRow>
          <ScrollContainer>
            <DataGrid composite={recipe} imageUrls={imageUrls} />
            <AddRowWrapper onClick={onAddRowClick}>
              <AddButton primary>
                {t('recipeEditMain.addRowButtonText')}
              </AddButton>
            </AddRowWrapper>
            <Spacer />
            <RecipeEditMainRowHeader>
              {t('recipeEditMain.rowHeader')}
            </RecipeEditMainRowHeader>
            <RecipeEditMainServingsSetterContainer />
            <Spacer />
            <Instructions instructions={recipe.instructions} />
            <AddRowWrapper>
              <AddButton
                id="InstructionsAddRowButton"
                primary
                onClick={() => addRowToInstructions(recipe)}
              >
                {t('recipeEditMain.addRowButtonText')}
              </AddButton>
            </AddRowWrapper>
            <Spacer />
          </ScrollContainer>
        </LeftColumn>
        <RightColumn>
          <InfoPopOverContainer sliceType={'recipe'} />
        </RightColumn>
      </TwoColumns>
    </RecipeEditBase>
  )
}

RecipeEditMain.propTypes = {
  addRowToInstructions: PropTypes.func.isRequired,
  className: PropTypes.string,
  decreaseRecipeServings: PropTypes.func.isRequired,
  hasDuplicateRecipes: PropTypes.bool.isRequired,
  imageUrls: PropTypes.object.isRequired,
  increaseRecipeServings: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  onAddRowClick: PropTypes.func.isRequired,
  recipe: PropTypes.object,
  style: PropTypes.object
}

export default RecipeEditMain
