import {compose, withHandlers, withState} from 'recompose'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'

import {parseQueryString} from '../../../utils/utils'
import {processMenuTitle} from '../epics/epics'
import {
  resetEditedComponentIndex,
  resetSelectedComponent
} from '../../../redux/modules/components'
import {
  selectMenusOfDay,
  selectQueryParams
} from '../../../redux/selectors/selectors'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {setEditedMenuId} from '../../../redux/modules/menus'
import {setSearchTypes, updateSearchInput} from '../../../redux/modules/search'
import HeaderRight from '../../dataGrid/components/HeaderRight'

const mapStateToProps = (state, {menu, t}) => {
  const menusOfDay = selectMenusOfDay(state, t)

  /*
   the menuId in the url is sometimes outdated... if the day contains no menu,
   the first menu title input should be autofocused.
   */
  const {menuId} = parseQueryString(state.router.location.search)
  const dayHasMenu =
    menusOfDay.map(m => m.id).includes(menuId) &&
    !menusOfDay.every(m => m.generated)

  const isEditedMenu = menu.id === selectQueryParams(state).menuId

  return {
    generated: menu.generated,
    value: menu.title,
    showArrow:
      isEditedMenu && state.components.editedComponentIndex === undefined,
    autoFocus: isEditedMenu || (!dayHasMenu && menu.menuLineId === 1),
    tempValue: state.search.searchInput
  }
}

const mapDispatchToProps = {
  processMenuTitle,
  resetEditedComponentIndex,
  resetSelectedComponent,
  setDayPlanningPopOverContentType,
  setEditedMenuId,
  setSearchTypes,
  updateSearchInput
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('editMode', 'setEditMode', false),
  withHandlers({
    onFocus: ({
      resetEditedComponentIndex,
      resetSelectedComponent,
      setDayPlanningPopOverContentType,
      setEditMode,
      setSearchTypes,
      updateSearchInput,
      value
    }) => () => {
      setDayPlanningPopOverContentType(`${'compositeDetails'}`)
      resetEditedComponentIndex()
      resetSelectedComponent()
      setSearchTypes(['menus'])
      setEditMode(true)
      updateSearchInput(value)
    },
    onChange: ({updateSearchInput}) => ({target: {value}}) => {
      updateSearchInput(value)
    },
    onBlurOrKeyDown: ({
      menu,
      processMenuTitle,
      tempValue,
      setEditMode,
      value
    }) => () => {
      processMenuTitle({menu, newValue: tempValue, oldValue: value})
      setEditMode(false)
    }
  })
)(HeaderRight)
