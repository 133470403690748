import {connect} from 'react-redux'
import {pipe, slice} from 'ramda'

import {selectSearchResults} from '../../../redux/selectors/search'
import {selectSelectedComponent} from '../../../redux/selectors/componentSelectors'
import {setHoveringOtherRow} from '../../../redux/modules/view'
import {
  sortResults,
  selectPermanentOrLatestProducts,
  selectPermanentOrLatestRecipes
} from '../../../utils/searchUtils'
import SearchResults from '../components/SearchResults'

const mapStateToProps = state => {
  const {hasMultiplePerfectMatches, searchResults} = selectSearchResults(state)
  const {searchInput} = state.search
  const componentSelected = selectSelectedComponent(state) !== undefined

  const prepareSearchResults = pipe(
    selectPermanentOrLatestProducts,
    selectPermanentOrLatestRecipes,
    sortResults(searchInput),
    slice(0, 25)
  )

  return {
    pseudoHitContainerHidden: searchInput === '' || hasMultiplePerfectMatches,
    hide: componentSelected,
    searchResults: prepareSearchResults(searchResults)
  }
}

const mapDispatchToProps = {
  setHoveringOtherRow
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults)
