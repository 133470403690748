import {compose} from 'recompose'
import {connect} from 'react-redux'

import {getClassificationText} from '../utils/utils'
import {goBack, goToView} from '../../../redux/modules/viewPagerNavigator'
import EnvironmentOverview from '../components/EnvironmentOverview'

const mapStateToProps = (state, {sliceType}) => {
  const {awards} = state.indicators

  return {
    classificationText: getClassificationText(awards, sliceType),
    awards
  }
}

const mapDispatchToProps = {
  goBack,
  goToView
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnvironmentOverview)
