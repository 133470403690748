import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {IconSearchGrey} from '../../../assets/icons'
import TextInput from '../../core/components/TextInput'
import Icon from '../../icons/components/Icon'

const AdminSearchBarBase = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px auto;
  grid-template-rows: 60px;
  background-color: ${p => p.theme.colors.white};
  border-bottom: ${p => p.theme.border.thin};
`

const SearchLogo = styled(Icon)`
  justify-self: stretch;0
`

const Input = styled(TextInput)`
  height: 59px;
  ${p => p.theme.fonts.family.default};
  text-align: start;
  margin-left: -60px;
  padding-left: 60px;
`

const AdminSearchBar = ({
  placeholderText,
  searchInput,
  textInputRef,
  updateSearchInput
}) => (
  <AdminSearchBarBase>
    <SearchLogo
      onClick={() => textInputRef.current.focus()}
      draggable={false}
      src={IconSearchGrey}
      width="60px"
      height="60px"
    />
    <Input
      onChange={({target: {value}}) => updateSearchInput(value)}
      ref={textInputRef}
      placeholder={placeholderText}
      value={searchInput}
    />
  </AdminSearchBarBase>
)

AdminSearchBar.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  searchInput: PropTypes.string.isRequired,
  textInputRef: PropTypes.object.isRequired,
  updateSearchInput: PropTypes.func.isRequired
}

export default AdminSearchBar
