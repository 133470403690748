import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import PopOverArrow from '../../popover/components/PopOverArrow'
import theme from '../../../theme'

const ComponentRowPopOverArrowBase = styled(PopOverArrow)`
  grid-column: ${p => p.column};
`

const ComponentRowPopOverArrow = ({show}) => (
  <ComponentRowPopOverArrowBase
    show={show}
    border
    left
    marginLeft={`${theme.sizes.componentRowOuterColumsWidth}px`}
  />
)

ComponentRowPopOverArrow.propTypes = {
  show: PropTypes.bool.isRequired
}

export default ComponentRowPopOverArrow
