import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {IconPinUp, IconPinUpYellow} from '../../../assets/icons/index'
import {getDateDetails} from '../../../utils/dateUtils'
import {addImageTransformation} from '../../../utils/utils'
import Icon from '../../icons/components/Icon'
import CloudIssueIndicatorContainer from '../containers/CloudIssueIndicatorContainer'
import Button from './Button'
import ImageOrFallBack from './ImageOrFallback'
import {TextStyle} from './Text'

const ImageOrHeaderDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: auto;
  height: 80px;
  background-color: ${p => p.theme.colors.boxGray};
  border-bottom: 0.5px solid #787c80;

  @media print {
    display: none;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${() => TextStyle}
  height: 82px;
  color: ${p => p.theme.colors.lightestGray2};
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.huge}px;
  line-height: 44px;
  text-shadow: 0px 1px rgba(0, 0, 0, 0.2);
`

const ButtonRightPositioner = styled.div`
  position: absolute;
  top: 0px;
  right: 17px;
`

const ButtonLeftPositioner = styled.div`
  position: absolute;
  top: 0px;
  left: 17px;
`

const CompositeInfo = styled.div`
  display: grid;
  grid-template-rows: 20px auto 10px auto 10px auto 20px;
  grid-template-areas: '.' 'date-line' '.' 'title' '.' 'price' '.';
  padding: auto 50px;
  margin: ${p => p.theme.sizes.popOverContentMargin}px;
`

const CompositeInfoItem = styled.div`
  ${p => (p.title ? p.theme.fonts.family.tall : p.theme.fonts.family.default)};
  grid-area: ${p => p.area};
  letter-spacing: 1px;
  text-align: center;
  line-height: 25px;
  font-size: ${p => (p.title ? 20 : p.theme.fonts.size.big)}px;
  font-weight: ${p =>
    p.title ? p.theme.fonts.weight.bold : p.theme.fonts.weight.thin};
`

const ShareButton = styled(Button)`
  margin-top: 0;
`

const ShareButtonWrapper = styled.div`
  padding: 20px;
`

const CompositeFactsHeader = ({
  composite,
  goToDayPlanning,
  isPreviewRecipeCopy,
  isSuperAdmin,
  onMakePrevievRecipeClick,
  onShareRecipeClick,
  recipeIsPinned,
  selectedDate,
  showEditButton
}) => {
  const [t] = useTranslation()

  if (!composite) {
    return null
  }

  const {imageUrl, title} = composite
  const maxTitleLength = 70
  const slicedTitle = `${title.slice(0, maxTitleLength)}...`
  const hasOverLongTitle = title.length > maxTitleLength
  const hasTitle = title !== undefined && title !== ''
  const headerText = title ? title.slice(0, 2) : ''
  const {dayOfWeek, month, dayOfMonthShort} = getDateDetails(selectedDate)
  const imageUrlWithTransformParams = addImageTransformation(
    imageUrl,
    '-/progressive/yes/-/scale_crop/748x160/center/-/blur/100'
  )

  return (
    <>
      <ImageOrHeaderDiv>
        <ImageOrFallBack
          imageUrl={imageUrlWithTransformParams}
          fallback={() => <Header>{headerText}</Header>}
        />
      </ImageOrHeaderDiv>
      {showEditButton && (
        <ButtonRightPositioner>
          <Button
            id="CompositeFactsHeaderEditButton"
            full
            blue
            onClick={goToDayPlanning}
          >
            {t('compositeFactsHeader.editButtonText')}
          </Button>
        </ButtonRightPositioner>
      )}
      {recipeIsPinned && (
        <ButtonRightPositioner>
          <Icon draggable={false} src={IconPinUp} width="60px" height="60px" />
        </ButtonRightPositioner>
      )}
      {isSuperAdmin && isPreviewRecipeCopy && (
        <ButtonLeftPositioner>
          <Icon
            draggable={false}
            src={IconPinUpYellow}
            width="60px"
            height="60px"
          />
        </ButtonLeftPositioner>
      )}
      <CloudIssueIndicatorContainer />
      <CompositeInfo>
        <CompositeInfoItem area="date-line">
          {`${dayOfWeek} ${dayOfMonthShort}. ${month}`}
        </CompositeInfoItem>
        <CompositeInfoItem area="title" title={hasTitle ? title : ''}>
          {hasOverLongTitle ? slicedTitle : title}
        </CompositeInfoItem>
      </CompositeInfo>
      {composite.type === 'recipe' && !isSuperAdmin && (
        <>
          <ShareButtonWrapper>
            <ShareButton
              data-cy="shareUnshareRecipeButton"
              onClick={() => onShareRecipeClick()}
              fluid
              primary
              big
            >
              {t(
                `compositeFactsHeader.${
                  recipeIsPinned ? 'unshare' : 'share'
                }.shareButtonText`
              )}
            </ShareButton>
          </ShareButtonWrapper>
        </>
      )}
      {composite.type === 'recipe' && isSuperAdmin && (
        <ShareButtonWrapper>
          <ShareButton
            data-cy="shareUnshareRecipeButton"
            onClick={() => onMakePrevievRecipeClick(composite)}
            fluid
            primary
            big
          >
            {isPreviewRecipeCopy
              ? 'Remove from preview recipes'
              : 'Add to preview recipes'}
          </ShareButton>
        </ShareButtonWrapper>
      )}
    </>
  )
}

CompositeFactsHeader.propTypes = {
  composite: PropTypes.object,
  goToDayPlanning: PropTypes.func.isRequired,
  onMakePrevievRecipeClick: PropTypes.func.isRequired,
  onShareRecipeClick: PropTypes.func.isRequired,
  recipeIsPinned: PropTypes.bool.isRequired,
  selectedDate: PropTypes.object.isRequired,
  showEditButton: PropTypes.bool.isRequired
}

export default CompositeFactsHeader
