import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {getAllProducts} from '../../../redux/modules/products'
import {getAllRecipes} from '../../../redux/modules/recipes'
import {getUserRestaurants} from '../../../redux/modules/restaurants'
import {resetSearchInput, setSearchTypes} from '../../../redux/modules/search'
import {setDayPlanningPopOverContentType} from '../../../redux/modules/view'
import {refetchIfNeeded} from '../../../utils/utils'
import SearchView from '../components/SearchView'

const mapStateToProps = ({
  network: {
    getProductsSucceeded,
    getRecipesSucceeded,
    getUserRestaurantsSucceeded
  }
}) => ({
  getProductsSucceeded,
  getRecipesSucceeded,
  getUserRestaurantsSucceeded,
  getAllDataSucceeded:
    getProductsSucceeded && getRecipesSucceeded && getUserRestaurantsSucceeded
})

const mapDispatchToProps = {
  getAllProducts,
  getAllRecipes,
  getUserRestaurants,
  refetchIfNeeded,
  resetSearchInput,
  setDayPlanningPopOverContentType,
  setSearchTypes
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDidMount: ({
      resetSearchInput,
      setDayPlanningPopOverContentType,
      setSearchTypes
    }) => () => {
      resetSearchInput()
      setSearchTypes(['recipes'])
      setDayPlanningPopOverContentType('compositeDetails')
    },
    refetchIfNeeded
  })
)(SearchView)
