import {push} from 'connected-react-router'
import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {mergeMap} from 'rxjs/operators'
import {hideSearchModal} from '../../../../build/redux/modules/modal'
import {setEditedUser} from '../../../redux/modules/admin'
import {
  showAddOrEditRestaurantModal,
  showAddOrEditUserModal,
  showAddOrEditBusinessPartnerModal,
  showReloadModal,
  setModalType
} from '../../../redux/modules/modal'
import {handleNetworkError} from '../../../redux/observables/pipes'
import {setEditedRestaurant} from '../../../redux/modules/restaurants'
import {resetSearchInput} from '../../../redux/modules/search'
import {processSwitchToRestaurantClick} from '../../modal/epics/epics'
import {setSelectedBusinessPartner} from '../../../redux/modules/businessPartners'

export const processBusinessPartnerSearchItemClick = createAction(
  'admin/PROCESS_BUSINESS_PARTNER_SEARCH_ITEM_CLICK'
)

export const processRestaurantSearchItemClick = createAction(
  'admin/PROCESS_RESTAURANT_SEARCH_ITEM_CLICK'
)

export const processSwitchRestaurant = createAction(
  'admin/PROCESS_SWITCH_RESTAURANT'
)

export const changeDefaultRestaurant = createAction(
  'admin/CHANGE_DEFAULT_RESTAURANT'
)

export const processUserSearchItemClick = createAction(
  'admin/PROCESS_USER_SEARCH_ITEM_CLICK'
)

export const processAddUserButtonClick = createAction(
  'admin/PROCESS_ADD_USER_BUTTON_CLICK'
)

export const processAddBusinessPartnerClick = createAction(
  'admin/PROCESS_ADD_BUSINESS_PARTNER_CLICK'
)

export const processAddRestaurantClick = createAction(
  'admin/PROCESS_ADD_RESTAURANT_CLICK'
)

export const processAdminItemClick = createAction(
  'admin/PROCESS_ADMIN_ICON_CLICK'
)

const processAdminItemClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processAdminItemClick().type),
    mergeMap(({payload: nextPath}) => {
      const baseActions = [resetSearchInput(), push(nextPath)]
      const actions =
        nextPath === '/search'
          ? baseActions
          : [hideSearchModal(), ...baseActions]

      return of(...actions)
    })
  )

const processAddBusinessPartnerClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processAddBusinessPartnerClick().type),
    mergeMap(() => {
      return of(showAddOrEditBusinessPartnerModal())
    })
  )

const processAddRestaurantClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processAddRestaurantClick().type),
    mergeMap(() => {
      return of(setEditedRestaurant(undefined), showAddOrEditRestaurantModal())
    })
  )

const processBusinessPartnerSearchItemClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processBusinessPartnerSearchItemClick().type),
    mergeMap(({payload: businessPartner}) => {
      return of(
        setSelectedBusinessPartner(businessPartner),
        showAddOrEditBusinessPartnerModal()
      )
    })
  )

const processRestaurantSearchItemClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processRestaurantSearchItemClick().type),
    mergeMap(({payload: restaurant}) => {
      const {
        users: {
          user: {isBusinessPartnerAdmin}
        }
      } = state$.value

      let actions
      if (isBusinessPartnerAdmin) {
        actions = [
          setEditedRestaurant(restaurant),
          showAddOrEditRestaurantModal()
        ]
      } else {
        actions = [
          setEditedRestaurant(restaurant),
          processSwitchToRestaurantClick()
        ]
      }
      return of(...actions)
    })
  )

const processSwitchRestaurantEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processSwitchRestaurant().type),
    mergeMap(({payload: restaurant}) => {
      console.log('in processSwitchRestaurantEpic', restaurant)
      let actions = [
        setEditedRestaurant(restaurant),
        processSwitchToRestaurantClick(),
        push('/')
      ]
      return of(...actions)
    })
  )

const changeDefaultRestaurantEpic = (action$, state$, {api, of}) =>
  action$.pipe(
    ofType(changeDefaultRestaurant().type),
    mergeMap(({payload: restaurant}) => {
      console.log('in changeDefaultRestaurantEpic', restaurant)
      return api.postDefaultRestaurant(restaurant.id).pipe(
        mergeMap(({response: restaurantId}) => {
          console.log('success changedDefaultRestaurant to ', restaurantId)
          var modalType = 'dataEditedElsewhere'
          return of(
            processSwitchRestaurant(restaurant),
            setModalType(modalType),
            showReloadModal()
          )
        }),
        handleNetworkError
      )
    })
  )

const processAddUserButtonClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processAddUserButtonClick().type),
    mergeMap(({payload: restaurant}) => {
      return of(setEditedRestaurant(restaurant), showAddOrEditUserModal())
    })
  )

const processUserSearchItemClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processUserSearchItemClick().type),
    mergeMap(({payload: user}) => {
      return of(setEditedUser(user), showAddOrEditUserModal())
    })
  )

export const adminInterfaceEpics = combineEpics(
  processAddBusinessPartnerClickEpic,
  processAddRestaurantClickEpic,
  processAddUserButtonClickEpic,
  processAdminItemClickEpic,
  processBusinessPartnerSearchItemClickEpic,
  processRestaurantSearchItemClickEpic,
  processSwitchRestaurantEpic,
  changeDefaultRestaurantEpic,
  processUserSearchItemClickEpic
)
