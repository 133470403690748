import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {TextStyle} from '../../core/components/Text'
import Icon from '../../icons/components/Icon'

const WelcomeBoxKlimaTellerBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 50px;
`

const WelcomeBoxKlimaTellerTextWrapper = styled.div`
  margin-top: 70px;
  width: 450px;
  height: 140px;
`

const WelcomeBoxKlimaTellerText = styled.span`
  ${() => TextStyle};
  font-size: ${p => p.theme.fonts.size.normal}px;
  line-height: 1.3;
`

const Spacer = styled.div`
  padding-top: 10px;
`

const WelcomeBoxKlimaTellerHeaderText = styled.div`
  ${() => TextStyle};
  font-size: ${p => p.theme.fonts.size.big}px;
  font-weight: ${p => p.theme.fonts.weight.bold}px;
`

const WelcomeBoxKlimaTeller = ({className, style, t}) => (
  <WelcomeBoxKlimaTellerBase className={className} style={style}>
    <Icon
      src={`${process.env.PUBLIC_URL}/assets/icons/logo.svg`}
      width="64x"
      height="64px"
      size={200}
    />
    <WelcomeBoxKlimaTellerTextWrapper>
      <WelcomeBoxKlimaTellerHeaderText>
        {upperFirst(t('global.welcome'))}!
      </WelcomeBoxKlimaTellerHeaderText>
      <Spacer />
      <Spacer />
      <WelcomeBoxKlimaTellerText>
        {t('welcomeBox.paragraph1')}
      </WelcomeBoxKlimaTellerText>
      <Spacer />
      <WelcomeBoxKlimaTellerText>
        {t('welcomeBox.paragraph2')}
      </WelcomeBoxKlimaTellerText>
      <Spacer />
      <WelcomeBoxKlimaTellerText>
        {t('welcomeBox.paragraph3')}
      </WelcomeBoxKlimaTellerText>
    </WelcomeBoxKlimaTellerTextWrapper>
  </WelcomeBoxKlimaTellerBase>
)

WelcomeBoxKlimaTeller.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  t: PropTypes.func.isRequired
}

export default WelcomeBoxKlimaTeller
