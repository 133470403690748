import styled from 'styled-components'

const RegLogHeader = styled.div`
  font-size: ${p => p.theme.fonts.size.huge}px;
  font-weight: ${p => p.theme.fonts.weight.normal};
  color: ${p => p.theme.colors.almostBlack};
  ${p => p.theme.fonts.family.default};
  line-height: 1.4em;
  letter-spacing: 2px;
`

export default RegLogHeader
