import {ContentState, convertFromRaw, convertToRaw} from 'draft-js'
import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import {setEditedInstructionIndex} from '../../../redux/modules/recipes'
import {updateInstruction} from '../epics/epics'
import InstructionEditor from '../components/InstructionEditor'

const mapStateToProps = (state, {instruction}) => ({
  contentState:
    typeof instruction === 'object'
      ? convertFromRaw(instruction)
      : ContentState.createFromText(instruction),
  recipe: selectCompositeByIdFromUrl(state)
})

const mapDispatchToProps = {
  updateInstruction,
  setEditedInstructionIndex
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    onBlur: ({editedInstructionIndex, updateInstruction, recipe}) => ({
      contentState,
      contentStateHasChanged
    }) => {
      // raw intructions are the object that is suposed to be saved see:
      // https://draftjs.org/docs/api-reference-data-conversion.html#content
      const rawInstructions = convertToRaw(contentState)

      updateInstruction({
        editedInstructionIndex,
        contentStateHasChanged,
        rawInstructions,
        recipe
      })
    }
  })
)(InstructionEditor)
