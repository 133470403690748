import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React, {createRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Button from '../../core/components/Button'
import BusinessPartnerSearchResultsContainer from '../containers/BusinessPartnerSearchResultsContainer'
import AdminOptionsList from './AdminOptionsList'
import AdminSearchBar from './AdminSearchBar'
import AdminViewLeftColumn from './AdminViewLeftColumn'
import AdminViewRightColumnGrid from './AdminViewRightColumnGrid'
import ButtonAndTextPanel from './ButtonAndTextPanel'

const BusinessPartnerAdminContentBase = styled.div`
  display: grid;
  grid-template-columns:
    ${p => p.theme.sizes.adminLeftColumnWidth}px
    minmax(
      300px,
      ${p => p.theme.sizes.maxWidth - p.theme.sizes.adminLeftColumnWidth}px
    );
  grid-template-rows: calc(
    100vh - ${p => p.theme.sizes.headerHeight + p.theme.sizes.footerHeight}px
  );
`

const SearchList = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-sizing: border-box;
  padding: 25px 60px;
`

const BusinessPartnerAdminContent = ({
  getBusinessPartners,
  isSuperAdmin,
  onAddBusinessPartnerClick,
  searchInput,
  setSearchTypes,
  updateSearchInput
}) => {
  const [t] = useTranslation()

  useEffect(() => {
    if (isSuperAdmin) {
      getBusinessPartners()
    }
    setSearchTypes(['businessPartners'])
  }, [getBusinessPartners, isSuperAdmin, setSearchTypes])

  const textInputRef = createRef()

  const adminOptions = [
    {text: t('businessPartnerAdmin.businessPartnerAdministration')}
  ]

  return (
    <BusinessPartnerAdminContentBase>
      <AdminViewLeftColumn>
        <ButtonAndTextPanel />
        <AdminOptionsList options={adminOptions} />
      </AdminViewLeftColumn>
      <AdminViewRightColumnGrid>
        <AdminSearchBar
          placeholderText={upperFirst(
            t('businessPartnerAdmin.searchPlaceholder')
          )}
          searchInput={searchInput}
          textInputRef={textInputRef}
          updateSearchInput={updateSearchInput}
        />
        <SearchList>
          <Button
            primary
            huge
            fluid={!isSuperAdmin}
            onClick={() => onAddBusinessPartnerClick()}
          >
            {t('businessPartnerAdmin.addButtonText')}
          </Button>
        </SearchList>
        <BusinessPartnerSearchResultsContainer />
      </AdminViewRightColumnGrid>
    </BusinessPartnerAdminContentBase>
  )
}

BusinessPartnerAdminContent.propTypes = {
  getBusinessPartners: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  onAddBusinessPartnerClick: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  setSearchTypes: PropTypes.func.isRequired,
  updateSearchInput: PropTypes.func.isRequired
}

export default BusinessPartnerAdminContent
