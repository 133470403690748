import {curry, omit, pick, zipObj} from 'ramda'
import {generateEmptyProduct} from './productUtils'
import {replaceAtIndex} from './utils'

export const generateStandardComponent = (section, component) => ({
  section: section || '',
  component: component || generateEmptyProduct(),
  quantity: {
    amount: 100,
    unit: 'GRAM'
  }
})

export const unitConversionMapForward = {
  GRAM: 'g',
  KILOGRAM: 'kg',
  MILLILITER: 'ml',
  LITER: 'l'
}

export const unitConversionMapBackward = {
  g: 'GRAM',
  kg: 'KILOGRAM',
  ml: 'MILLILITER',
  l: 'LITER'
}

export const getComponentUpdateFunction = fieldName => {
  const _updateComponentField = (fieldName, fieldValue, component) => ({
    ...component,
    component: {
      ...component.component,
      [fieldName]: fieldValue
    }
  })

  const _updateComponentQuantityField = (fieldName, fieldValue, component) => ({
    ...component,
    quantity: {
      ...component.quantity,
      [fieldName]: fieldValue
    }
  })

  const _updateComponentRootField = (fieldName, fieldValue, component) => ({
    ...component,
    [fieldName]: fieldValue
  })

  const updateFunctions = {
    amount: _updateComponentQuantityField,
    id: _updateComponentField,
    configured: _updateComponentRootField,
    origin: _updateComponentRootField,
    section: _updateComponentRootField,
    transport: _updateComponentRootField,
    conservation: _updateComponentRootField,
    production: _updateComponentRootField,
    processing: _updateComponentRootField,
    packaging: _updateComponentRootField,
    title: _updateComponentField,
    type: _updateComponentField,
    unit: _updateComponentQuantityField
  }

  return curry(updateFunctions[fieldName])(fieldName)
}

/**
 * @param editedComposite either a recipe or a menu
 * @param editedComponentIndex
 * @param fieldName
 * @param fieldValue
 * @returns {{components: Array}}
 */
export const updateComponentField = ({
  componentIndex,
  fieldName,
  fieldValue,
  composite
}) => {
  const components = composite.components
  const componentToUpdate = components[componentIndex]
  const updateComponent = getComponentUpdateFunction(fieldName)
  const updatedComponent = updateComponent(fieldValue, componentToUpdate)

  return {
    ...composite,
    components: replaceAtIndex(components, componentIndex, updatedComponent)
  }
}

export const CONFIGURATION_KEYS = [
  'origin',
  'transport',
  'producer',
  'production',
  'conservation',
  'processing',
  'packaging'
]

export const omitConfigurationKeys = omit(CONFIGURATION_KEYS)

export const pickStandardComponentFields = pick([
  'section',
  'component',
  'quantity'
])

export const getComponentEnrichFields = component => {
  const enrichFields = [
    'co2Value',
    'co2Rating',
    'co2ValueImprovementPercentage',
    'conservation',
    'foodUnit',
    'kCal',
    'production',
    'waterScarcity',
    'waterScarcityRating',
    'waterScarcityImprovementPercentage'
  ]

  return zipObj(
    enrichFields,
    enrichFields.map(field => component[field])
  )
}
