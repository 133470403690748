import {omit} from 'ramda'

export const omitGenerated = omit(['generated'])

export const updateMenuInReducer = (state, payload) => {
  const {menus} = state
  const {menu} = payload

  return {
    ...state,
    menus: {
      ...menus,
      [menu.id]: omitGenerated(menu)
    }
  }
}

export const updateRecipeInReducer = (state, {recipe}) => {
  return {
    ...state,
    recipes: {
      ...state.recipes,
      [recipe.id]: omitGenerated(recipe)
    }
  }
}

export const updateRecipesInReducer = (state, {recipe, subrecipe}) => {
  return {
    ...state,
    recipes: {
      ...state.recipes,
      [recipe.id]: omitGenerated(recipe),
      [subrecipe.id]: omitGenerated(subrecipe)
    }
  }
}

export const updateProductInReducer = (state, {product}) => {
  return {
    ...state,
    products: {
      ...state.products,
      [product.id]: omitGenerated(product)
    }
  }
}

/*
 * [getEditedMenuOrRecipe description]
 * @param  {[type]} state     [description]
 * @param  {[type]} sliceType [description]
 * @return {[type]}           [description]
 */
export const getEditedMenuOrRecipe = (state, sliceType) => {
  const {
    menus: {menus, editedMenuId},
    recipes: {recipes, editedRecipeId}
  } = state

  const slices = {
    menu: menus[editedMenuId],
    recipe: recipes[editedRecipeId]
  }

  return slices[sliceType]
}

export const replaceMenusInReducer = (state, payload) => {
  return {
    ...state,
    menus: payload
  }
}

export const addMenus = (state, payload) => ({
  ...state,
  menus: {
    ...state.menus,
    ...payload
  }
})

export const addRecipes = (state, payload) => ({
  ...state,
  recipes: {
    ...state.recipes,
    ...payload
  }
})

export const updateMenuWithEnrichData = (state, payload) => {
  return {
    ...state,
    menus: {
      ...state.menus,
      [payload.id]: omitGenerated({...state.menus[payload.id], ...payload})
    }
  }
}

export const updateRecipeWithEnrichData = (state, payload) => {
  return {
    ...state,
    recipes: {
      ...state.recipes,
      [payload.id]: omitGenerated({...state.recipes[payload.id], ...payload})
    }
  }
}
