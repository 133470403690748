import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SubIconCount from './SubIconCount'

const SVG = styled.svg`
  position: absolute;
  z-index: 2;
  left: 50%;
  width: 64px;
  height: 64px;
  transform: translateX(-50%);
`

const IconNotification = ({count, fillColor = '#0095F9'}) => (
  <SVG viewBox="0 0 64 64">
    <title>Icon / Notification</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <SubIconCount count={count} fillColor={fillColor} />
    </g>
  </SVG>
)

IconNotification.propTypes = {
  count: PropTypes.number,
  fillColor: PropTypes.string
}

export default IconNotification
