import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React, {createRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import Button from '../../core/components/Button'
import RestaurantSearchResultsContainer from '../containers/RestaurantSearchResultsContainer'
import AdminOptionsList from './AdminOptionsList'
import AdminSearchBar from './AdminSearchBar'
import AdminViewLeftColumn from './AdminViewLeftColumn'
import AdminViewRightColumnGrid from './AdminViewRightColumnGrid'
import ButtonAndTextPanel from './ButtonAndTextPanel'

const RestaurantAdminContentBase = styled.div`
  display: grid;
  grid-template-columns:
    ${p => p.theme.sizes.adminLeftColumnWidth}px
    minmax(
      300px,
      ${p => p.theme.sizes.maxWidth - p.theme.sizes.adminLeftColumnWidth}px
    );
  grid-template-rows: calc(
    100vh - ${p => p.theme.sizes.headerHeight + p.theme.sizes.footerHeight}px
  );
`

const SearchList = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-sizing: border-box;
  padding: 25px 60px;
`

const RestaurantAdminContent = ({
  defaultBusinessSelected,
  getAllRestaurants,
  getBusinessRestaurants,
  getUserRestaurants,
  isBusinessPartnerAdmin,
  isSuperAdmin,
  onAddRestaurantClick,
  searchInput,
  selectedBusiness,
  setSearchTypes,
  updateSearchInput
}) => {
  const [t] = useTranslation()

  useEffect(() => {
    if (selectedBusiness && isBusinessPartnerAdmin) {
      getBusinessRestaurants(selectedBusiness.id)
    } else {
      getUserRestaurants()
    }
    setSearchTypes(['restaurants'])
  }, [
    getBusinessRestaurants,
    getUserRestaurants,
    isBusinessPartnerAdmin,
    selectedBusiness,
    setSearchTypes
  ])

  const textInputRef = createRef()

  const adminOptions = [{text: t('restaurantAdmin.restaurantAdministration')}]

  return (
    <RestaurantAdminContentBase>
      <AdminViewLeftColumn>
        <ButtonAndTextPanel />
        <AdminOptionsList options={adminOptions} />
      </AdminViewLeftColumn>
      <AdminViewRightColumnGrid>
        <AdminSearchBar
          placeholderText={upperFirst(t('restaurantAdmin.searchPlaceholder'))}
          searchInput={searchInput}
          textInputRef={textInputRef}
          updateSearchInput={updateSearchInput}
        />
        <SearchList>
          {isSuperAdmin && (
            <Button primary huge fluid onClick={() => getAllRestaurants()}>
              Update Restaurant List
            </Button>
          )}
          {isBusinessPartnerAdmin && !defaultBusinessSelected && (
            <Button
              primary
              huge
              disabled={isSuperAdmin}
              fluid={!isSuperAdmin}
              onClick={() => onAddRestaurantClick()}
            >
              Add Restaurant
            </Button>
          )}
        </SearchList>
        <RestaurantSearchResultsContainer />
      </AdminViewRightColumnGrid>
    </RestaurantAdminContentBase>
  )
}

RestaurantAdminContent.propTypes = {
  defaultBusinessSelected: PropTypes.bool.isRequired,
  getAllRestaurants: PropTypes.func.isRequired,
  getBusinessRestaurants: PropTypes.func.isRequired,
  getUserRestaurants: PropTypes.func.isRequired,
  isBusinessPartnerAdmin: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  selectedBusiness: PropTypes.object,
  onAddRestaurantClick: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  setSearchTypes: PropTypes.func.isRequired,
  updateSearchInput: PropTypes.func.isRequired
}

export default RestaurantAdminContent
