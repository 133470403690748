import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {mergeMap} from 'rxjs/operators'
import {replaceAtIndex} from '../../../utils/utils'
import {closeConfigureProductModal} from '../../../redux/modules/products'
import {createOrUpdateComposite} from '../../dataGrid/epics/epics'
import {goBack} from 'connected-react-router'
import {omitConfigurationKeys} from '../../../utils/componentUtils'

export const processDeleteConfiguration = createAction(
  'recipeOrProduct/epics/PROCESS_DELETE_CONFIGURATION'
)
export const processProductConfiguration = createAction(
  'recipeOrProduct/epics/PROCESS_PRODUCT_CONFIGURATION'
)

const processProductConfigurationEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processProductConfiguration().type),
    mergeMap(
      ({
        payload: {componentIndex, composite, formValues, productComponent}
      }) => {
        const {
          router: {
            location: {pathname}
          }
        } = state$.value
        const {productName, ...productConfiguration} = formValues

        if (productName !== productComponent.component.title) {
          // fire postProductThenUpdateComposite epic
        }

        const updatedComponent = {
          ...productComponent,
          ...productConfiguration,
          configured: true
        }

        const updatedComposite = {
          ...composite,
          components: replaceAtIndex(
            composite.components,
            componentIndex,
            updatedComponent
          )
        }

        let actions = [
          createOrUpdateComposite({composite: updatedComposite}),
          closeConfigureProductModal()
        ]

        if (pathname === '/select') {
          actions = [...actions, goBack()]
        }

        return of(...actions)
      }
    )
  )

const processDeleteConfigurationEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processDeleteConfiguration().type),
    mergeMap(({payload: {componentIndex, composite, productComponent}}) => {
      const updatedComponent = {
        ...omitConfigurationKeys(productComponent),
        configured: false
      }

      const updatedComposite = {
        ...composite,
        components: replaceAtIndex(
          composite.components,
          componentIndex,
          updatedComponent
        )
      }

      return of(
        createOrUpdateComposite({composite: updatedComposite}),
        closeConfigureProductModal()
      )
    })
  )

export const productEditViewEpics = combineEpics(
  processDeleteConfigurationEpic,
  processProductConfigurationEpic
)
