import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {getAllMenus} from '../../../redux/modules/menus'
import {getAllProducts} from '../../../redux/modules/products'
import {getAllRecipes} from '../../../redux/modules/recipes'
import {getUserRestaurants} from '../../../redux/modules/restaurants'
import {refetchIfNeeded} from '../../../utils/utils'
import WeekPlanning from '../components/WeekPlanning'

const mapStateToProps = ({
  admin: {adminDrawerOpen},
  network: {
    getMenusSucceeded,
    getProductsSucceeded,
    getRecipesSucceeded,
    getUserRestaurantsSucceeded
  }
}) => ({
  adminDrawerOpen,
  getMenusSucceeded,
  getProductsSucceeded,
  getRecipesSucceeded,
  getUserRestaurantsSucceeded,
  getAllDataSucceeded:
    getMenusSucceeded &&
    getProductsSucceeded &&
    getRecipesSucceeded &&
    getUserRestaurantsSucceeded
})

const mapDispatchToProps = {
  getAllMenus,
  getAllProducts,
  getAllRecipes,
  getUserRestaurants
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    refetchIfNeeded
  })
)(WeekPlanning)
