import {curry, omit} from 'ramda'
import {v4} from 'uuid'
import {generateStandardComponent} from '../../../utils/componentUtils'
import {replaceAtIndex} from '../../../utils/utils'

/**
 * Function to link a recipe or product to a menu or recipe. Linking is aka
 * adding the id of the recipe or product to the components array of the menu
 * or recipe.
 * @param  {Object} component      component to add
 * @param  {Number} componentIndex index at which to add the component
 * @param  {Object} composite      Menu or recipe to  which the component
 *                                 should be added
 * @return {Object}                Menu or recipe with component added at index
 */
export const linkComponentToComposite = ({
  component,
  componentIndex,
  composite
}) => {
  const componentToReplace = composite.components[componentIndex]
  const componentToAdd = {
    ...componentToReplace.component,
    id: component.id,
    /*
     we are building up redudancy here but title is needed for
     generation of matching items in javaland
     */
    title: component.title,
    type: component.type
  }

  return {
    ...composite,
    components: replaceAtIndex(composite.components, componentIndex, {
      ...componentToReplace,
      component:
        component.type === 'product'
          ? componentToAdd
          : omit(['title'], componentToAdd)
    })
  }
}

/**
 * Curried version of linkComponentToComposite. More convenient in some
 * situations
 * @param  {Object} component      component to add
 * @param  {Number} componentIndex index at which to add the component
 * @param  {Object} composite      Menu or recipe to  which the component
 *                                 should be added
 * @return {Object}                Menu or recipe with component added at index
 */
export const linkToComposite = curry((component, componentIndex, composite) => {
  const componentToReplace = composite.components[componentIndex]

  return {
    ...composite,
    components: replaceAtIndex(composite.components, componentIndex, {
      ...componentToReplace,
      component: {
        ...componentToReplace.component,
        id: component.id,
        /*
         we are building up redudancy here but title is needed for
         generation of matching items in javaland
         */
        title: component.title,
        type: component.type
      }
    })
  }
})

/**
 * Function to add a component to the components array of a composite (menu or
 * recipe) after the last element.
 * @param  {[type]} composite [description]
 * @param  {[type]} component [description]
 * @return {[type]}           [description]
 */
export const appendToComponents = curry((component, composite) => {
  return {
    ...composite,
    components: [...composite.components, component]
  }
})

export const appendEmptyComponentToComponents = appendToComponents(
  generateStandardComponent()
)

/**
 * Function to copy a recipe. Copying means replacing the id with a new id. and
 * setting a new productionDate.
 * @param  {Object} recipe a recipe
 * @return {Object}        identical recipe with new v4 id
 */
export const flatCopyComposite = composite => {
  if (!(composite.type === 'recipe' || composite.type === 'menu')) {
    throw new Error('Object passed to copyComposite is not a recipe or menu')
  } else {
    return {
      ...composite,
      id: v4()
    }
  }
}

/**
 * Function to generateDeepCopyData that is needed to deep copy a composite.
 * @param  {Object} composite     menu or recipe
 * @param  {Array}  recipes       array of all recipes
 * @param  {Number} depth         the recursion depth, needed for sorting later
 * @param  {Array}  subrecipeData accumulator for subrecipeData
 * @return {Array}           composite with links to new subrecipe copies
 */
export const generateDeepCopyData = ({id, depth, recipes, ignoreIds}) => {
  const originalComposite = recipes[id]
  if (!originalComposite) {
    return {
      recipe: null,
      recipePostList: []
    }
  }

  if (!ignoreIds) {
    // if not yet given in input, create as empty object that tracks which component ids are from here to root node:
    ignoreIds = {}
  }
  // add myself:
  ignoreIds[id] = true

  const flatCompositeCopy = flatCopyComposite(originalComposite)
  let recipePostList = []
  const hasSubRecipes =
    originalComposite.components.filter(
      ({component}) => component.type === 'recipe'
    ).length > 0

  // base case: no subrecipes!
  if (!hasSubRecipes) {
    return {recipe: flatCompositeCopy, recipePostList: [flatCompositeCopy]}
  } else {
    const components = flatCompositeCopy.components.filter(
      c => !ignoreIds.hasOwnProperty(c.component.id)
    )

    const flatCompositeCopyWithNewLinks = {
      ...flatCompositeCopy,
      components: components.reduce((result, component) => {
        const {id, type} = component.component
        if (type !== 'recipe') {
          result.push(component)
          return result
        } else {
          const {
            recipe: deepCopiedRecipe,
            recipePostList: subRecipePostList
          } = generateDeepCopyData({
            id,
            depth: depth + 1,
            recipes,
            ignoreIds: {...ignoreIds} // to create shallow copy
          })
          if (!deepCopiedRecipe) {
            // if sub-recipe was not found, then skip it:
            console.warn(
              `sub component with id ${id} was not found... removing from composite`
            )
            return result
          }
          recipePostList = [...recipePostList, ...subRecipePostList]
          result.push({
            ...component,
            component: {
              ...component.component,
              id: deepCopiedRecipe.id
            }
          })
          return result
        }
      }, [])
    }

    return {
      recipe: flatCompositeCopyWithNewLinks,
      recipePostList: [flatCompositeCopyWithNewLinks, ...recipePostList]
    }
  }
}

export const collectLinkedComponentData = ({recipes, composite, ignoreIds}) => {
  const fullComposite = composite.components ? composite : recipes[composite.id]
  if (!fullComposite) {
    return []
  }

  if (!ignoreIds) {
    // if not yet set in input,
    // then create an object that tracks which component ids were visited to prevent stackoverflow due to cyclic references:
    ignoreIds = {}
    ignoreIds[composite.id] = true
  }

  // filter components to only deep copy if they were not yet copied before:
  const components = fullComposite.components
    .map(({component}) => component)
    .filter(c => !ignoreIds.hasOwnProperty(c.id))

  const subRecipes = components.reduce((filtered, c) => {
    if (c.type === 'recipe') {
      let subRecipe = recipes[c.id]
      if (subRecipe) {
        filtered.push(subRecipe)
      }
    }
    return filtered
  }, [])

  let collectedComponents = []

  const hasSubRecipes = subRecipes.length > 0

  components.forEach(c => {
    ignoreIds[c.id] = true
  })

  if (hasSubRecipes) {
    collectedComponents = subRecipes.map(subrecipe => {
      return collectLinkedComponentData({
        recipes,
        composite: subrecipe,
        ignoreIds
      })
    })

    return [...collectedComponents, ...components]
  } else {
    // base case: no subrecipes!
    return [...collectedComponents, ...components]
  }
}
