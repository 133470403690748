import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import BoxItemBig from '../../core/components/BoxItemBig'
import {TextStyle} from '../../core/components/Text'
import Icon from '../../icons/components/Icon'
import {addImageTransformation} from '../../../utils/utils'
import {extractIndicatorData} from '../../../utils/compositeUtils'

const RecipePreviewBase = styled.div`
  margin-top: ${p => p.marginTop}px;
  margin-bottom: 120px;
`

const RecipePreviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16.5px;
  margin-top: 15px;
`

const PreviewHeaderRow = styled.span`
  ${() => TextStyle};
  font-weight: ${p => p.theme.fonts.weight.bold};
  font-size: ${p => p.theme.fonts.size.small}px;
`

const IconHeader = styled(Icon)`
  margin-right: 15px;
`

const RecipePreview = ({onRecipeClick, recipes, t, webEntryDomain}) => {
  if (recipes.length === 0) {
    return null
  }

  return (
    <RecipePreviewBase marginTop={webEntryDomain === 'klimateller' ? 120 : 30}>
      <PreviewHeaderRow>
        {t('searchModal.recipePreviewHeaderText').toUpperCase()}
      </PreviewHeaderRow>
      <RecipePreviewGrid>
        {recipes.map(recipe => {
          const imageUrlWithTransformParams = addImageTransformation(
            recipe.imageUrl,
            '-/progressive/yes/-/scale_crop/374x246/center'
          )
          const {
            co2: {hasAward}
          } = extractIndicatorData(webEntryDomain, recipe)

          return (
            <BoxItemBig
              imageUrl={imageUrlWithTransformParams}
              key={recipe.id}
              onClick={() => onRecipeClick(recipe)}
              title={recipe.title}
              renderHeaderLeftComponent={() =>
                recipe.co2Value ? `${recipe.co2Value} g CO₂` : <div />
              }
              renderHeaderRightComponent={() =>
                hasAward && (
                  <IconHeader
                    src={`${process.env.PUBLIC_URL}/assets/icons/logo-white.svg`}
                    width="64x"
                    height="64px"
                    size={30}
                  />
                )
              }
            />
          )
        })}
      </RecipePreviewGrid>
    </RecipePreviewBase>
  )
}

RecipePreview.propTypes = {
  onRecipeClick: PropTypes.func.isRequired,
  recipes: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default RecipePreview
