import {connect} from 'react-redux'

import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import {selectSelectedIndicatorData} from '../../../redux/selectors/indicatorSelectors'
import RainforestRow from '../components/RainforestRow'

const mapStateToProps = (state, {indicatorType}) => {
  const composite = selectCompositeByIdFromUrl(state)
  const cloudIsAvailable =
    String(composite.serviceStatus.cloudStatus)[0] === '2'
  const matchingStatusOk =
    String(composite.serviceStatus.matchingStatus)[0] !== '6'

  const {hasAward} = selectSelectedIndicatorData(state, indicatorType)
  return {
    indicatorType,
    rainforestLabel: cloudIsAvailable && matchingStatusOk ? hasAward : false
  }
}

export default connect(mapStateToProps)(RainforestRow)
