import styled from 'styled-components'

const RowItem = styled.div`
  color: ${p => p.theme.colors.darkGray};
  ${p => p.theme.fonts.family.tall};
  font-size: ${p => p.theme.fonts.size.big}px;
  margin-left: ${p =>
    p.isComment ? '-80px' : p.isOnly ? 'auto' : p.isFirst ? '18px' : '-18px'};
  margin-right: ${p => (p.isLast ? '18px' : 'auto')};
`

export default RowItem
