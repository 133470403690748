import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {getAllMenus} from '../../../redux/modules/menus'
import {getAllProducts} from '../../../redux/modules/products'
import {getAllRecipes} from '../../../redux/modules/recipes'
import {getUserRestaurants} from '../../../redux/modules/restaurants'
import {refetchIfNeeded} from '../../../utils/utils'
import RecipeEdit from '../components/RecipeEdit'
import {getLicenseType} from '../../../utils/restaurantUtils'

const mapStateToProps = ({
  config: {webEntryDomain},
  network: {
    getMenusSucceeded,
    getProductsSucceeded,
    getRecipesSucceeded,
    getUserRestaurantsSucceeded
  },
  restaurants: {selectedRestaurant}
}) => {
  const licenseType = getLicenseType(
    webEntryDomain,
    selectedRestaurant.licenses
  )

  let succeededInfo = {
    getProductsSucceeded,
    getRecipesSucceeded,
    getUserRestaurantsSucceeded,
    getAllDataSucceeded:
      getProductsSucceeded && getRecipesSucceeded && getUserRestaurantsSucceeded
  }

  if (licenseType === 'eaternity') {
    succeededInfo = {
      ...succeededInfo,
      getMenusSucceeded,
      getAllDataSucceeded:
        succeededInfo.getAllDataSucceeded && getMenusSucceeded
    }
  }

  return succeededInfo
}

const mapDispatchToProps = {
  getAllMenus,
  getAllProducts,
  getAllRecipes,
  getUserRestaurants
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    refetchIfNeeded
  })
)(RecipeEdit)
