import {omit} from 'ramda'
import {createAction, createReducer} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, map, mergeMap} from 'rxjs/operators'
import {normalizeById} from '../../utils/utils'
import {handleNetworkError} from '../observables/pipes'
import {
  addRecipes,
  updateRecipeInReducer,
  updateRecipesInReducer
} from '../utils'
import {
  createProductThenCreateRecipe,
  createProductThenUpdateRecipe,
  createRecipeThenCreateMenu,
  createRecipeThenCreateRecipe,
  createRecipeThenUpdateMenu,
  createRecipeThenUpdateRecipe
} from './sharedActions'
import {checkLicenseOfSelectedRestaurant} from '../../utils/restaurantUtils'

export const initialStateRecipes = {
  editedRecipeId: undefined,
  editedInstructionIndex: undefined,
  recipes: {},
  recipeCollections: {}
}

export const addRecipeOptimistic = createAction('recipes/ADD_RECIPE_OPTIMISTIC')
export const getAllRecipes = createAction('recipes/GET_ALL_RECIPES_REQUESTED')
export const getAllRecipesSucceeded = createAction(
  'recipes/GET_ALL_RECIPES_SUCCEEDED'
)
export const postRecipe = createAction('recipes/POST_RECIPE_REQUESTED')
export const postRecipeSucceeded = createAction('recipes/POST_RECIPE_SUCCEEDED')

export const setEditedInstructionIndex = createAction(
  'recipes/SET_EDITED_INSTRUCTION_INDEX'
)

export const putRecipe = createAction('recipes/PUT_RECIPE_REQUESTED')
export const putRecipeSucceeded = createAction('recipes/PUT_RECIPE_SUCCEEDED')
export const setEditedRecipeId = createAction('recipes/SET_EDITED_RECIPE_ID')

export const decreaseRecipeServings = createAction(
  'recipes/DECREASE_RECIPE_SERVINGS'
)

export const deleteRecipe = createAction('recipes/DELETE_RECIPE_REQUESTED')

export const deleteRecipeSucceeded = createAction(
  'recipes/DELETE_RECIPE_SUCCEEDED'
)

export const deleteRecipeOptimistic = createAction(
  'recipes/DELETE_RECIPE_OPTIMISTIC'
)

export const increaseRecipeServings = createAction(
  'recipes/INCREASE_RECIPE_SERVINGS'
)

export const updateRecipeOptimistic = createAction(
  'recipes/UPDATE_RECIPE_OPTIMISTIC'
)

export const replaceWithEnrichedRecipes = createAction(
  'recipes/REPLACE_WITH_ENRICHED_RECIPES'
)

const reducer = createReducer(
  {
    [addRecipeOptimistic]: updateRecipeInReducer,

    [deleteRecipeOptimistic]: (state, {recipe}) => {
      const {recipes} = state

      return {
        ...state,
        recipes: {
          ...omit([recipe.id], recipes)
        }
      }
    },

    [getAllRecipesSucceeded]: (state, {recipes, recipeCollections}) => {
      return {
        ...state,
        recipes,
        recipeCollections
      }
    },
    [replaceWithEnrichedRecipes]: addRecipes,

    [setEditedRecipeId]: (state, payload) => ({
      ...state,
      editedRecipeId: payload
    }),

    [setEditedInstructionIndex]: (state, payload) => ({
      ...state,
      editedInstructionIndex: payload
    }),

    [updateRecipeOptimistic]: updateRecipeInReducer,
    [createRecipeThenCreateMenu]: updateRecipeInReducer,
    [createRecipeThenUpdateMenu]: updateRecipeInReducer,
    [createProductThenCreateRecipe]: updateRecipeInReducer,
    [createProductThenUpdateRecipe]: updateRecipeInReducer,
    [createRecipeThenCreateRecipe]: updateRecipesInReducer,
    [createRecipeThenUpdateRecipe]: updateRecipesInReducer
  },
  initialStateRecipes
)

export const getAllRecipesEpic = (action$, state$, {api}) =>
  action$.pipe(
    ofType(getAllRecipes().type),
    filter(() => checkLicenseOfSelectedRestaurant(state$)),
    mergeMap(() => {
      const {
        businesses: {
          selectedBusiness: {id: selectedBusinessId}
        },
        restaurants: {
          selectedRestaurant: {id: selectedRestaurantId}
        }
      } = state$.value

      return api.getAllRecipes({selectedBusinessId, selectedRestaurantId}).pipe(
        map(({response: {recipes, recipeCollections}}) => {
          const recipesById = normalizeById(recipes)
          const recipeCollectionsById = normalizeById(recipeCollections)

          return getAllRecipesSucceeded({
            recipes: recipesById,
            recipeCollections: recipeCollectionsById
          })
        }),
        handleNetworkError
      )
    })
  )

export const postRecipeEpic = (action$, state$, {api}) =>
  action$.pipe(
    ofType(postRecipe().type),
    mergeMap(({payload}) => {
      const {
        recipes: {editedRecipeId, recipes}
      } = state$.value
      const editedRecipe = recipes[editedRecipeId]
      const recipeToPost = payload || editedRecipe

      return api.postRecipe(recipeToPost).pipe(
        map(({response: responseRecipe}) =>
          postRecipeSucceeded(responseRecipe)
        ),
        handleNetworkError
      )
    })
  )

export const putRecipeEpic = (action$, state$, {api}) =>
  action$.pipe(
    ofType(putRecipe().type),
    mergeMap(({payload: {recipe}}) => {
      return api.putRecipe(recipe).pipe(
        map(({response: responseRecipe}) => putRecipeSucceeded(responseRecipe)),
        handleNetworkError
      )
    })
  )

export const deleteRecipeEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(deleteRecipe().type),
    mergeMap(({payload: {recipe}}) => {
      return api.deleteRecipe(recipe.id).pipe(
        map(response => deleteRecipeSucceeded(response)),
        handleNetworkError
      )
    })
  )

export const createProductThenUpdateRecipeEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(createProductThenUpdateRecipe().type),
    mergeMap(({payload: {recipe, product}}) => {
      return api.postProduct(product).pipe(
        map(() => putRecipe({recipe})),
        handleNetworkError
      )
    })
  )

export const createRecipeThenCreateRecipeEpic = (action$, _, {of, api}) =>
  action$.pipe(
    ofType(createRecipeThenCreateRecipe().type),
    mergeMap(({payload: {recipe, subrecipe}}) => {
      return api.postRecipe(subrecipe).pipe(
        mergeMap(({response: responseRecipe}) => {
          return of(postRecipeSucceeded(responseRecipe), postRecipe(recipe))
        }),
        handleNetworkError
      )
    })
  )

export const createRecipeThenUpdateRecipeEpic = (action$, _, {of, api}) =>
  action$.pipe(
    ofType(createRecipeThenUpdateRecipe().type),
    mergeMap(({payload: {recipe, subrecipe}}) => {
      return api.postRecipe(subrecipe).pipe(
        mergeMap(({response: responseRecipe}) => {
          return of(postRecipeSucceeded(responseRecipe), putRecipe({recipe}))
        })
      )
    }),
    handleNetworkError
  )

export const createProductThenCreateRecipeEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(createProductThenCreateRecipe().type),
    mergeMap(({payload: {recipe, product}}) => {
      return api.postProduct(product).pipe(map(() => postRecipe(recipe)))
    }),
    handleNetworkError
  )

export const recipeEpics = combineEpics(
  createProductThenCreateRecipeEpic,
  createProductThenUpdateRecipeEpic,
  createRecipeThenCreateRecipeEpic,
  createRecipeThenUpdateRecipeEpic,
  deleteRecipeEpic,
  getAllRecipesEpic,
  postRecipeEpic,
  putRecipeEpic
)

export default reducer
