import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {verifyUser} from '../../../redux/modules/users'
import Verify from '../components/Verify'

const mapStateToProps = ({
  config: {webEntryDomain},
  error: {linkExpired},
  restaurants: {selectedRestaurant},
  users: {user}
}) => ({
  isOpen: true,
  linkExpired,
  termsAccepted: selectedRestaurant && selectedRestaurant.termsAccepted,
  user,
  webEntryDomain
})

const mapDispatchToProps = {
  push,
  verifyUser
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    verify: ({
      match: {
        params: {token}
      },
      location,
      verifyUser
    }) => () => {
      verifyUser({token, isLogin: location.search.includes('case=login')})
    },
    redirect: ({push}) => () => {
      push('/')
    }
  })
)(Verify)
