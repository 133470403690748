import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {IconEnergyActive, IconEnergyInactive} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'
import RowValue from '../../core/components/RowValue'

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconEnergy = styled(Icon)`
  margin-right: -12px;
`

const EnergyRow = ({nutritionalBalanced, kcalPerServing}) => (
  <RowContainer>
    <RowValue>{kcalPerServing || '0'} kcal</RowValue>
    <IconEnergy
      src={nutritionalBalanced ? IconEnergyActive : IconEnergyInactive}
      width="50px"
      height="25px"
    />
  </RowContainer>
)

EnergyRow.propTypes = {
  kcalPerServing: PropTypes.string,
  nutritionalBalanced: PropTypes.bool
}

export default EnergyRow
