import styled from 'styled-components'

const Button = styled.button`
  box-sizing: border-box;
  cursor: pointer;
  padding-top: ${p => (p.big ? '15px' : p.huge ? '19px' : '9px')};
  padding-bottom: ${p => (p.big ? '15px' : p.huge ? '19px' : '9px')};
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 3px;
  border: ${p => (p.primary ? '1px solid' : 'none')};
  border-color: ${p => (p.primary ? p.theme.colors.gray : '')};
  background-color: ${p =>
    p.disabled
      ? p.theme.colors.grayButton
      : p.primary
      ? p.theme.colors.lightGray
      : p.full
      ? p.blue
        ? p.theme.colors.blue
        : p.green
        ? p.theme.colors.green
        : p.red
        ? p.theme.colors.red
        : ''
      : ''};
  color: ${p =>
    p.full
      ? p.theme.colors.white
      : p.disabled
      ? p.theme.colors.lightestGray
      : p.theme.colors.blue};
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  line-height: 20px;
  width: ${p => (p.fluid ? '100%' : p.wide ? '160px' : '')};
  letter-spacing: 0.35px;
  text-align: center;
  white-space: nowrap;
  margin: 15px 0px;
  pointer-events: auto;
  border-top-right-radius: ${p => (p.first | p.nth ? '0px' : '4px')};
  border-bottom-right-radius: ${p => (p.first | p.nth ? '0px' : '4px')};
  border-top-left-radius: ${p => (p.last | p.nth ? '0px' : '4px')};
  border-bottom-left-radius: ${p => (p.last | p.nth ? '0px' : '4px')};
  margin-right: ${p => (p.first ? '-1px' : p.nth ? '0px' : '')};
  margin-left: ${p => (p.last ? '-1px' : p.nth ? '0px' : '')};

  &:focus {
    outline: 0;
  }

  @media print {
    display: none;
  }
`

export default Button
