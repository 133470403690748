import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  IconDashboard,
  IconPlus,
  IconPurchase,
  IconSearch,
  IconSharrow,
  IconStock,
  IconSupplier
} from '../../../assets/icons'
import IconNotification from './IconNotification'

const IconDiv = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 64px;

  &:hover {
    background-color: #ddd;
    color: #3d454c;
  }
`

const Icon = styled.img`
  position: absolute;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
`

const iconMap = {
  plus: IconPlus,
  dashboard: IconDashboard,
  purchase: IconPurchase,
  search: IconSearch,
  sharrow: IconSharrow,
  stock: IconStock,
  supplier: IconSupplier
}

const IconNav = ({count, iconType, id, onClick}) => (
  <IconDiv id={id} onClick={onClick}>
    <Icon draggable={false} src={iconMap[iconType]} />
    {count > 0 && <IconNotification count={count} />}
  </IconDiv>
)

IconNav.propTypes = {
  count: PropTypes.number,
  iconType: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func
}

export default IconNav
