import styled from 'styled-components'

const SearchTypeIndicator = styled.span`
  ${p => p.theme.fonts.family.default};
  align-items: center;
  border-right: ${p => p.theme.border.default};
  color: ${p => p.theme.colors.blue};
  display: flex;
  font-size: ${p => p.theme.fonts.size.normal}px;
  font-weight: ${p => p.theme.fonts.weight.bold};
  height: 100%;
  justify-content: center;
  padding: 0 15px;
`

export default SearchTypeIndicator
