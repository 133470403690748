import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose, withHandlers, withState} from 'recompose'
import {setPreventBlur} from '../../../redux/modules/dataGrid'
import {setHoveringOtherRow} from '../../../redux/modules/view'
import {selectDuplicateRecipeData} from '../../../redux/selectors/search'
import HitRow from '../components/HitRow'
import {processHitRowClick} from '../epics/epics'

const mapStateToProps = (state, {result}) => {
  const {
    hasDuplicateRecipes,
    numberOfDuplicateRecipes
  } = selectDuplicateRecipeData(state, result)

  return {
    hoveringOtherRow: state.view.hoveringOtherRow,
    numberOfDuplicates:
      result.type === 'recipe' && hasDuplicateRecipes
        ? numberOfDuplicateRecipes
        : 0,
    result
  }
}

const mapDispatchToProps = {
  processHitRowClick,
  setHoveringOtherRow,
  setPreventBlur
}

const HitRowContainer = compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('hovering', 'setHovering', false),
  withHandlers({
    onMouseEnter: ({setHovering, setHoveringOtherRow}) => () => {
      setHovering(true)
      setHoveringOtherRow(true)
    },
    onMouseLeave: ({setHovering, setHoveringOtherRow}) => () => {
      setHovering(false)
      setHoveringOtherRow(false)
    },
    onClick: ({processHitRowClick, result, t}) => () => {
      processHitRowClick({result, t})
    }
  })
)(HitRow)

export default HitRowContainer
