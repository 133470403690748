import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {TextStyle} from '../../core/components/Text'
import Link from '../../core/components/Link'
import {useTranslation} from 'react-i18next'

const AgreementTextBase = styled.div`
  ${() => TextStyle};
`

const AgreementText = ({className, style, webEntryDomain}) => {
  const [t] = useTranslation()

  const termsAndConditionsHrefMap = {
    eaternity: 'http://www.eaternity.org/legal',
    klimateller: 'https://www.klimateller.de/nutzungsbedingungen'
  }
  const privacyNoticeHrefMap = {
    eaternity: 'http://www.eaternity.org/legal',
    klimateller: 'https://www.klimateller.de/datenschutz'
  }
  const termsAndConditionsHref = termsAndConditionsHrefMap[webEntryDomain]
  const privacyNoticeHref = privacyNoticeHrefMap[webEntryDomain]

  return (
    <AgreementTextBase className={className} style={style}>
      {t('terms.leadingText')}{' '}
      <Link href={termsAndConditionsHref}>{t('terms.termsAndConditions')}</Link>
      {t('terms.betweenText')}
      <Link href={privacyNoticeHref}>{t('terms.privacyNotice')}</Link>
      {t('terms.laggingText')}
    </AgreementTextBase>
  )
}

AgreementText.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  webEntryDomain: PropTypes.string.isRequired
}

export default AgreementText
