import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {
  HealthBanner,
  IconAwardVitaScore,
  IconAwardWater,
  IconNoAwardVitaScore,
  IconNoAwardWater,
  WaterBanner
} from '../../../assets/icons'
import FactRow from '../../core/components/FactRow'
import RowValue from '../../core/components/RowValue'
import Text, {TextStyle} from '../../core/components/Text'
import CloudIssueIndicatorContainer from '../../core/containers/CloudIssueIndicatorContainer'
import Icon from '../../icons/components/Icon'
import IndicatorGroupContainer from '../containers/IndicatorGroupContainer'
import PopoverHeaderBar from '../../core/components/PopoverHeaderBar'
import theme from '../../../theme'

export const IndicatorDetailsBase = styled.div``

export const Content = styled.div`
  border-top: ${p => p.theme.border.default};
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: ${p => p.theme.fonts.size.normal}px;
  padding-bottom: 40px;
  height: 100%;
  ${p => p.theme.fonts.family.default};
`

export const IndicatorBannerWrapper = styled.div`
  margin: 25px 0px;
  display: flex;
  justify-content: center;
`

export const ComparisonText = styled(Text)`
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 0 15px;
  text-align: center;
  color: ${p => p.theme.colors.darkGray};
`

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 15px;
  width: 100%;
`

const AwardLabelText = styled.div`
  ${() => TextStyle};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${p => p.color};
  font-weight: ${p => p.theme.fonts.weight.bold};
  padding-left: 15px;
`

const AwardText = styled(Text)`
  margin: 10px;
`

const iconSources = {
  check: {
    positive: `${process.env.PUBLIC_URL}/assets/icons/check-positive.svg`,
    negative: `${process.env.PUBLIC_URL}/assets/icons/check-negative.svg`
  },
  co2: {
    award: `${process.env.PUBLIC_URL}/assets/icons/co2-award-big.svg`,
    banner: `${process.env.PUBLIC_URL}/assets/icons/co2-banner.svg`,
    noAward: `${process.env.PUBLIC_URL}/assets/icons/co2-no-award-big.svg`
  },
  health: {
    award: IconAwardVitaScore,
    banner: HealthBanner,
    noAward: IconNoAwardVitaScore
  },
  water: {
    award: IconAwardWater,
    banner: WaterBanner,
    noAward: IconNoAwardWater
  }
}

const IndicatorDetails = ({
  awardText,
  comparisonText,
  goBack,
  hasAward,
  indicatorType,
  indicatorValue,
  t,
  webEntryDomain
}) => {
  const awardLabelColorMap = {
    co2:
      webEntryDomain === 'klimateller'
        ? theme.colors.klimatellerBlue
        : theme.colors.co2Orange,
    water: theme.colors.waterScarcityBlue,
    health: theme.colors.vitaGreen
  }

  const lineData = [
    {
      label: t('indicatorDetails.rating'),
      showIconRight: false,
      render: function ratingRow() {
        return <IndicatorGroupContainer indicatorType={indicatorType} />
      }
    },
    {
      label: t(`factRows.${indicatorType}.labelSecondRow`),
      showIconRight: false,
      render: function valueUnitRow() {
        return (
          <RowValue>{`${indicatorValue}${t(
            `indicatorDetails.unit.${indicatorType}`
          )}`}</RowValue>
        )
      }
    },
    {
      label: t('indicatorDetails.award'),
      showIconRight: true,
      render: function awardRow() {
        return <RowValue />
      }
    }
  ]

  return (
    <IndicatorDetailsBase>
      <PopoverHeaderBar
        title={t(`indicatorDetails.awardTitle.${indicatorType}`)}
        onArrowClick={goBack}
      />
      <CloudIssueIndicatorContainer />
      <Content>
        <IndicatorBannerWrapper>
          <Icon
            src={iconSources[indicatorType].banner}
            width="127px"
            height="113px"
          />
        </IndicatorBannerWrapper>
        <ComparisonText>{comparisonText}</ComparisonText>
        <div>
          {lineData.map(({label, render, showIconRight}, index) => (
            <FactRow
              isFirst={index === 0}
              isLast={index === lineData.length - 1}
              key={index}
              label={label}
              render={render}
              showIconRight={showIconRight}
              iconRightType={hasAward ? 'award' : 'noAward'}
            />
          ))}
        </div>
        <BottomRow>
          <Icon
            src={
              hasAward
                ? iconSources[indicatorType].award
                : iconSources[indicatorType].noAward
            }
            height="64px"
            width="64px"
          />
          {hasAward && (
            <AwardLabelText color={awardLabelColorMap[indicatorType]}>
              {t(`indicatorDetails.${indicatorType}.awardLabelText`)}
            </AwardLabelText>
          )}
        </BottomRow>
        <AwardText>{awardText}</AwardText>
      </Content>
    </IndicatorDetailsBase>
  )
}

IndicatorDetails.propTypes = {
  awardText: PropTypes.string.isRequired,
  comparisonText: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  hasAward: PropTypes.bool.isRequired,
  indicatorType: PropTypes.string.isRequired,
  indicatorValue: PropTypes.string,
  t: PropTypes.func.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default IndicatorDetails
