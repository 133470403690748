import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import PopoverHeaderBar from './PopoverHeaderBar'
import {
  Content,
  IndicatorDetailsBase
} from '../../indicators/components/IndicatorDetails'
import ServingsSetterContainer from '../containers/ServingsSetterContainer'
import RowHeader from './RowHeader'
import Text from './Text'

const ExplanationText = styled(Text)`
  margin-bottom: 20px;
  padding-top: 15px;
  text-align: left;
`
const PopOverServingsSetter = ({className, goBack}) => {
  const [t] = useTranslation()

  return (
    <IndicatorDetailsBase className={className}>
      <PopoverHeaderBar
        title={t('servingsSetter.header')}
        onArrowClick={goBack}
      />
      <Content>
        <RowHeader>{t('servingsSetter.rowHeader')}</RowHeader>
        <ServingsSetterContainer />
        <ExplanationText>{t('servingsSetter.explanationText')}</ExplanationText>
      </Content>
    </IndicatorDetailsBase>
  )
}

PopOverServingsSetter.propTypes = {
  className: PropTypes.string,
  goBack: PropTypes.func.isRequired
}

export default PopOverServingsSetter
