import {createAction, createReducer} from 'redux-act'

export const initialStateSearch = {
  searchInput: '',
  searchTypes: [],
  welcomeBoxAndRecipePreviewOpen: true
}

export const resetSearchInput = createAction('search/RESET_SEARCH_INPUT')
export const setSearchTypes = createAction('search/SET_SEARCH_TYPES')
export const updateSearchInput = createAction('search/UPDATE_SEARCH_INPUT')
export const hideWelcomeBoxAndRecipePreview = createAction(
  'search/HIDE_WELCOME_BOX_AND_RECIPE_PREVIEW'
)
export const showWelcomeBoxAndRecipePreview = createAction(
  'search/SHOW_WELCOME_BOX_AND_RECIPE_PREVIEW'
)

const reducer = createReducer(
  {
    [hideWelcomeBoxAndRecipePreview]: state => ({
      ...state,
      welcomeBoxAndRecipePreviewOpen: false
    }),
    [showWelcomeBoxAndRecipePreview]: state => ({
      ...state,
      welcomeBoxAndRecipePreviewOpen: true
    }),
    [resetSearchInput]: state => ({...state, searchInput: ''}),
    [setSearchTypes]: (state, payload) => ({...state, searchTypes: payload}),
    [updateSearchInput]: (state, payload) => ({
      ...state,
      searchInput: payload
    })
  },
  initialStateSearch
)

export default reducer
