import styled from 'styled-components'

const TextInput = styled.input.attrs({type: 'text'})`
  ${p => p.theme.fonts.family.default};
  color: ${p => p.theme.colors.darkGray};
  font-weight: ${p => p.theme.fonts.weight.normal}px;
  font-size: ${p => p.theme.fonts.size.normal}px;
  border: none;
  box-sizing: border-box;
  outline: none;

  ::placeholder {
    color: ${p => p.theme.colors.darkerGray};
    font-size: ${p => p.theme.fonts.size.normal}px;
    font-weight: ${p => p.theme.fonts.weight.normal};
    line-height: 1.42;
    letter-spacing: 0.35px;
  }
`

export default TextInput
