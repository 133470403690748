import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const transparent = '13px solid transparent'
const solidWhite = '11px solid rgba(255, 255, 255, 1)'
const solidGrey = '11px solid rgba(0, 0, 0, 0.1);'

const PopOverArrowBase = styled.div`
  position: relative;
  align-self: center;
`

const PopOverArrowGrey = styled.div`
  position: absolute;
  content: '';
  transform: ${p =>
    p.left ? 'translate(-100%, -50%)' : 'translate(-50%, -50%)'};
  margin-left: ${p => p.marginLeft};
  border-right: ${p => (p.left ? solidGrey : transparent)};
  border-top: ${p => p.theme.sizes.popOverArrowWidth}px solid transparent;
  border-bottom: ${p => p.theme.sizes.popOverArrowWidth}px solid transparent;
  border-left: ${p => (p.right ? solidGrey : transparent)};
  z-index: 3;
`

const PopOverArrowWhite = styled.div`
  position: absolute;
  content: '';
  transform: ${p =>
    p.left ? 'translate(-100%, -50%)' : 'translate(-50%, -50%)'};
  margin-left: ${p => p.marginLeft};
  left: ${p => (p.border ? (p.right ? '-1px' : '1px') : '')};
  border-right: ${p => (p.left ? solidWhite : transparent)};
  border-top: ${p => p.theme.sizes.popOverArrowWidth}px solid transparent;
  border-bottom: ${p => p.theme.sizes.popOverArrowWidth}px solid transparent;
  border-left: ${p => (p.right ? solidWhite : transparent)};
  z-index: 3;
`

const PopOverArrow = ({
  style,
  className,
  left,
  right,
  border,
  marginLeft,
  show = true
}) => {
  if ((left && right) || (!left && !right)) {
    throw Error('Please provide left OR right prop to PopOverArrow')
  }

  return show ? (
    <PopOverArrowBase style={style} className={className}>
      <PopOverArrowGrey
        marginLeft={marginLeft}
        left={left}
        right={right}
        border={border}
      />
      <PopOverArrowWhite
        marginLeft={marginLeft}
        left={left}
        right={right}
        border={border}
      />
    </PopOverArrowBase>
  ) : null
}

PopOverArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  marginLeft: PropTypes.string,
  left: PropTypes.bool,
  right: PropTypes.bool,
  border: PropTypes.bool,
  show: PropTypes.bool
}

export default PopOverArrow
