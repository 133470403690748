import styled from 'styled-components'

const Link = styled.a`
  ${p => p.theme.fonts.family.default};
  color: ${p => p.theme.colors.blue};
  font-weight: ${p => p.theme.fonts.weight.bold};
  text-decoration: none;
  cursor: pointer;
`

export default Link
