import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import MenuEditContainer from '../containers/MenuEditContainer'

const DayEditBase = styled.div`
  height: ${p => p.theme.sizes.dataGridHeightMenu};
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  @media print {
    overflow: visible;
  }
`

const MenuEditWrapper = styled.div`
  border-top: ${p => !p.isFirst && p.theme.border.default};
  margin-top: ${p => !p.isFirst && '60px'};
`

const MenuEditColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const DayEdit = ({menusOfDay, onMenuFocus}) => (
  <DayEditBase>
    <MenuEditColumn numRows={menusOfDay.length}>
      {menusOfDay.map((menu, index) => (
        <MenuEditWrapper
          id={`MenuEditWrapper${index}`}
          isFirst={index === 0}
          onFocus={() => onMenuFocus(menu)}
          key={index}
        >
          <MenuEditContainer menu={menu} />
        </MenuEditWrapper>
      ))}
    </MenuEditColumn>
  </DayEditBase>
)

DayEdit.propTypes = {
  menusOfDay: PropTypes.arrayOf(PropTypes.object).isRequired,
  onMenuFocus: PropTypes.func.isRequired
}

export default DayEdit
