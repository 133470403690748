import React from 'react'
import styled from 'styled-components'

const BlinkBase = styled.div`
  display: flex;
`

const Blink1 = styled.div`
  animation: 4s blink step-end infinite;
  @keyframes blink {
    0% {
      color: transparent;
    }
    75% {
      color: ${p => p.color || 'white'};
    }
    100% {
      color: transparent;
    }
  }
`

const Blink2 = styled.div`
  animation: 4s blink2 step-end infinite;
  @keyframes blink2 {
    0% {
      color: transparent;
    }
    50% {
      color: ${p => p.color || 'white'};
    }
    100% {
      color: transparent;
    }
  }
`

const Blink3 = styled.div`
  animation: 4s blink3 step-end infinite;
  @keyframes blink3 {
    0% {
      color: transparent;
    }
    25% {
      color: ${p => p.color || 'white'};
    }
    100% {
      color: transparent;
    }
  }
`

const Blink = ({className, color}) => {
  return (
    <BlinkBase className={className}>
      <Blink3 color={color}>.</Blink3>
      <Blink2 color={color}>.</Blink2>
      <Blink1 color={color}>.</Blink1>
    </BlinkBase>
  )
}

export default Blink
