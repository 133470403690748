import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {IconOptionsGrey} from '../../../assets/icons'
import useMoment from '../../../hooks/useMoment'
import theme from '../../../theme'
import Icon from '../../icons/components/Icon'
import PopOver from '../../popover/components/PopOver'
import StyledDayPicker from '../../core/components/StyledDayPicker'

const DatePickerBase = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-left: 17px;
  @media print {
    display: none;
  }
`

const TriggerBase = styled.div`
  display: inline-flex;
`

const Month = styled.div`
  color: #000000;
  ${p => p.theme.fonts.family.default};
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  cursor: pointer;
`

const Year = styled.span`
  color: #000000;
  ${p => p.theme.fonts.family.default};
  font-size: 32px;
  font-weight: 200;
  line-height: 38px;
  padding-left: 5px;
`

const DatePickerOptionsIcon = styled(Icon)`
  cursor: pointer;
  @media print {
    display: none;
  }
`

const DatePicker = ({
  adminDrawerOpen,
  className,
  open,
  selectedDate,
  setSelectedDate,
  toggleDatePicker
}) => {
  const moment = useMoment()

  const handleDayClick = datePickerDate => {
    const date = datePickerDate || Date.now()
    setSelectedDate(
      moment
        .utc(date)
        .startOf('day')
        .toDate()
    )
    toggleDatePicker()
  }

  const popOverPositionLeft = adminDrawerOpen
    ? 114 + theme.sizes.adminDrawerWidth
    : 114

  return (
    <DatePickerBase className={className}>
      <TriggerBase onClick={() => toggleDatePicker()}>
        <Month>
          {upperFirst(moment(selectedDate).format('MMMM'))}
          <Year>{moment(selectedDate).format('YYYY')}</Year>
        </Month>
        <DatePickerOptionsIcon
          width="44px"
          height="40px"
          src={IconOptionsGrey}
        />
      </TriggerBase>
      <PopOver
        showArrows={false}
        popOverPosition={{top: 120, left: popOverPositionLeft, offsetLeft: 0}}
        popOverOpen={open}
        dialogStyle={{width: 'auto'}}
        wideShadow
        onClickOutside={() => toggleDatePicker()}
        hideOnOutsideClick
      >
        <StyledDayPicker
          onDayClick={handleDayClick}
          selectedDate={selectedDate}
        />
      </PopOver>
    </DatePickerBase>
  )
}

DatePicker.propTypes = {
  adminDrawerOpen: PropTypes.bool,
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  toggleDatePicker: PropTypes.func.isRequired
}

export default DatePicker
