import PropTypes from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'
import upperFirst from 'lodash/upperFirst'

import {IconCross, IconSearch} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'
import SearchTypeChooser from './SearchTypeChooser'
import SearchTypeIndicator from './SearchTypeIndicator'
import TextInput from '../../core/components/TextInput'

const InputWithFilterBase = styled.div`
  align-items: center;
  border-radius: 4px;
  border: ${p => p.theme.border.default};
  box-sizing: border-box;
  display: flex;
  padding-right: 8px;
  height: 60px;
`

const Input = styled(TextInput)`
  flex: 1;
  border-radius: 4px;
  padding-left: 20px;
  padding-bottom: 18px;
  padding-top: 18px;
  line-height: 1.3333;
  &:disabled {
    background: none;
  }
`

class InputWithFilter extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool.isRequired,
    className: PropTypes.string,
    inputToFocusRef: PropTypes.object.isRequired,
    licenseType: PropTypes.string.isRequired,
    showWelcomeBoxAndRecipePreview: PropTypes.func.isRequired,
    hideWelcomeBoxAndRecipePreview: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    resetSearchInput: PropTypes.func.isRequired,
    searchTypes: PropTypes.array.isRequired,
    setSearchTypes: PropTypes.func.isRequired,
    style: PropTypes.object,
    t: PropTypes.func.isRequired,
    updateSearchInput: PropTypes.func.isRequired,
    value: PropTypes.string
  }

  state = {
    searchTypeChooserOpen: true,
    searchTypeChooserExpanded: false,
    inputIsFocused: false,
    preventClick: false
  }

  onSearchTypeChooserItemClick = searchType => {
    const {inputToFocusRef, setSearchTypes} = this.props
    setSearchTypes([searchType])
    inputToFocusRef.current.focus()
    this.setState({
      searchTypeChooserOpen: false
    })
  }

  clearState = () => {
    const {
      inputToFocusRef,
      licenseType,
      resetSearchInput,
      setSearchTypes
    } = this.props

    const searchTypes =
      licenseType === 'eaternity' ? ['menus', 'recipes'] : ['recipes']

    resetSearchInput()
    setSearchTypes(searchTypes)
    inputToFocusRef.current.focus()
    this.setState({
      searchTypeChooserOpen: licenseType === 'eaternity',
      searchTypeChooserExpanded: false
    })
  }

  onExpandButtonClick = () => {
    const {inputToFocusRef} = this.props
    inputToFocusRef.current.focus()
    this.setState({
      searchTypeChooserExpanded: true
    })
  }

  render() {
    const {
      autoFocus,
      className,
      inputToFocusRef,
      showWelcomeBoxAndRecipePreview,
      hideWelcomeBoxAndRecipePreview,
      onKeyDown,
      updateSearchInput,
      searchTypes,
      style,
      t,
      value
    } = this.props

    const [searchType1, searchType2] = searchTypes
    const searchTypeSelected = searchTypes.length === 1

    const laggingText =
      searchTypes.length === 1
        ? t(`inputWithFilter.searchType.${searchType1}`)
        : `${t(`inputWithFilter.searchType.${searchType1}`)} ${t(
            `global.or`
          )} ${t(`inputWithFilter.searchType.${searchType2}`)}`

    const placeholderString = searchTypeSelected
      ? searchType1 === 'recipes'
        ? `${upperFirst(t('inputWithFilter.placeholder.searchOrCreate'))} ...`
        : `${upperFirst(t('inputWithFilter.placeholder.search'))} ...`
      : `${t('inputWithFilter.placeholder.leadingText')} ${laggingText}`

    const searchTypeToCompositeTypeMap = {
      menus: 'menu',
      recipes: 'recipe'
    }

    return (
      <InputWithFilterBase
        data-cy="inputWithFilter"
        className={className}
        style={style}
      >
        {searchTypeSelected ? (
          <SearchTypeIndicator
            onClick={() => {
              if (!this.state.preventClick) {
                inputToFocusRef.current.focus()
              }
            }}
            onMouseDown={() => {
              if (!this.state.inputIsFocused && this.state.preventClick) {
                this.setState({preventClick: false})
              }
            }}
          >
            {upperFirst(
              t(
                `inputWithFilter.searchTypeIndicatorText.${
                  searchTypeToCompositeTypeMap[searchTypes[0]]
                }`
              )
            )}
          </SearchTypeIndicator>
        ) : (
          <Icon
            dataTestId="inputWithFilterSearchIcon"
            height="58px"
            size={58}
            src={IconSearch}
            width="58px"
          />
        )}
        <Input
          autoFocus={autoFocus}
          ref={inputToFocusRef}
          onBlur={() => {
            if (this.state.inputIsFocused) {
              this.setState({preventClick: true})
            }
            this.setState({inputIsFocused: false})
            showWelcomeBoxAndRecipePreview()
          }}
          onChange={({target: {value}}) => {
            updateSearchInput(value)
          }}
          onFocus={() => {
            this.setState({inputIsFocused: true})
            hideWelcomeBoxAndRecipePreview()
          }}
          onKeyDown={({keyCode}) =>
            onKeyDown({
              keyCode,
              clearInputWithFilterState: this.clearState,
              inputToFocusRef
            })
          }
          placeholder={placeholderString}
          value={value}
        />
        {this.state.searchTypeChooserOpen && !searchTypeSelected ? (
          <SearchTypeChooser
            expanded={this.state.searchTypeChooserExpanded}
            onSearchTypeChooserItemClick={this.onSearchTypeChooserItemClick}
            onExpandButtonClick={this.onExpandButtonClick}
            searchTypes={searchTypes}
            t={t}
          />
        ) : value !== '' ? (
          <Icon
            dataTestId="inputWithFilterXButton"
            height="58px"
            onClick={this.clearState}
            size={58}
            src={IconCross}
            width="58px"
          />
        ) : null}
      </InputWithFilterBase>
    )
  }
}

export default InputWithFilter
