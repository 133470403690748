import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {goBack} from '../../../redux/modules/viewPagerNavigator'
import {selectCompositeByIdFromUrl} from '../../../redux/selectors/selectors'
import PopOverServingsSetter from '../../core/components/PopOverServingsSetter'
import {processServingsChange} from '../epics/epics'

const mapStateToProps = state => {
  const composite = selectCompositeByIdFromUrl(state)
  return {
    composite,
    servings: composite && composite.servings
  }
}

const mapDispatchToProps = {
  processServingsChange,
  goBack
}

export default compose(
  withRouter,
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    decreaseServings: ({composite, processServingsChange}) => () => {
      processServingsChange({composite, type: 'decrease'})
    },
    increaseServings: ({composite, processServingsChange}) => () => {
      processServingsChange({composite, type: 'increase'})
    },
    updateServings: ({composite, processServingsChange}) => value => {
      processServingsChange({composite, type: 'update', value})
    }
  })
)(PopOverServingsSetter)
