import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {addImageTransformation} from '../../../../build/utils/utils'
import ImageOrFallBack from './ImageOrFallback'

const SpacerBarBase = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: ${p => p.theme.sizes.spacerBarHeight}px;
  border-bottom: ${p => p.theme.border.default};
  background-color: ${p => p.theme.colors.background};

  @media print {
    background-color: white;
  }
`

const SpaceBarImageOrFallBack = styled(ImageOrFallBack)`
  position: relative;
  top: 61px;
  left: 12px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  width: 170px;
  height: 133px;
  border: ${p => p.theme.border.default};
  z-index: 1;
`

const SpacerBar = ({className, children, imageUrl}) => {
  const imageUrlWithTransformParams =
    imageUrl &&
    addImageTransformation(
      imageUrl,
      '-/progressive/yes/-/scale_crop/340x266/center'
    )

  return (
    <SpacerBarBase className={className}>
      {imageUrl && (
        <SpaceBarImageOrFallBack
          imageUrl={imageUrlWithTransformParams}
          fallback={() => null}
        />
      )}
      {children}
    </SpacerBarBase>
  )
}

SpacerBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  imageUrl: PropTypes.string
}

export default SpacerBar
