import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

const IssueIndicatorBase = styled.div`
  background-color: ${p => p.theme.colors.darkGray};
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;
  text-align: center;
  color: ${p => p.theme.colors.white};
  min-height: 46px;
  padding-top: 20px;
  padding-left: 37px;
  padding-right: 37px;
  padding-bottom: 20px;
`

const IssueIndicator = ({hide, translateData, translateKey}) => {
  const [t] = useTranslation()

  if (hide) {
    return null
  }

  return (
    <IssueIndicatorBase>
      {t(`issueIndicator.${translateKey}`, {
        compositeType: t(`compositeType.${translateData.compositeType}`)
      })}
    </IssueIndicatorBase>
  )
}

IssueIndicator.propTypes = {
  hide: PropTypes.bool.isRequired,
  translateData: PropTypes.object,
  translateKey: PropTypes.string
}

export default IssueIndicator
