import {connect} from 'react-redux'
import {selectBrandname} from '../../../redux/selectors/selectors'
import LogoModal from '../components/LogoModal'

const mapStateToProps = state => ({
  brandName: selectBrandname(state),
  isOpen: true
})

export default connect(mapStateToProps)(LogoModal)
