import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {restaurantLicenseCheck} from '../../../utils/dateUtils'
import LinkLine from '../../login/components/LinkLine'
import RegLogBase from '../../login/components/RegLogBase'
import RegLogContent from '../../login/components/RegLogContent'
import RegLogHeader from '../../login/components/RegLogHeader'
import RegLogLogo from '../../login/components/RegLogLogo'
import RegLogText from '../../login/components/RegLogText'
import Button from './Button'
import {Select, Option} from 'informed'

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UnlicensedLinkLine = styled(LinkLine)`
  margin-bottom: 15px;
  margin-top: -0px;
`

const SubHeaderText = styled(RegLogText)`
  height: 40px;
`

const SelectRestaurantLabel = styled(RegLogText)`
  margin-bottom: 10px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const OpenBuyLicenseModalButton = styled(Button)`
  margin-top: 40px;
`

const Unlicensed = ({
  goHome,
  onUseOtherAccountClick,
  onSelectRestaurant,
  selectedRestaurant,
  restaurants,
  getUserRestaurants,
  getUserRestaurantsSucceeded,
  showBuyLicenseModal,
  webEntryDomain
}) => {
  const [t] = useTranslation()

  const [tmpSelectedRestaurantId, setTmpSelectedRestaurantId] = useState('none')

  useEffect(() => {
    if (!getUserRestaurantsSucceeded) {
      getUserRestaurants()
    }
    if (selectedRestaurant) {
      const isLicensed = restaurantLicenseCheck(
        webEntryDomain,
        selectedRestaurant.licenses
      )
      if (isLicensed) {
        goHome()
      }
      console.log('selectedRestaurant.id', selectedRestaurant.id)
      setTmpSelectedRestaurantId(selectedRestaurant.id)
    }
  }, [
    getUserRestaurantsSucceeded,
    getUserRestaurants,
    selectedRestaurant,
    webEntryDomain,
    restaurants,
    goHome
  ])

  const handleSelectRestaurant = event => {
    var restaurantId = event.target.value
    var restaurant = restaurants[restaurantId]
    onSelectRestaurant(restaurant)
  }

  return (
    <RegLogBase>
      <ContentWrapper>
        <RegLogContent>
          <RegLogLogo />
          <RegLogHeader>{t('unlicensed.header')}</RegLogHeader>
          <SubHeaderText width="320">{t('unlicensed.subheader')}</SubHeaderText>
          <ColumnDiv>
            <OpenBuyLicenseModalButton
              big
              blue
              full
              onClick={showBuyLicenseModal}
            >
              {t('unlicensed.buttonText')}
            </OpenBuyLicenseModalButton>
            <RegLogText width="320">
              {t('unlicensed.useOtherAccountText')}
            </RegLogText>
            <br />
            <UnlicensedLinkLine
              linkText={t('unlicensed.loginLinkText')}
              onClick={onUseOtherAccountClick}
            />
            {Object.entries(restaurants).length > 1 && (
              <SelectRestaurantLabel>
                Or select a different default restaurant:
                <Select
                  value={tmpSelectedRestaurantId}
                  field="restaurant"
                  onChange={handleSelectRestaurant}
                >
                  <Option value="" disabled>
                    Select Restaurant...
                  </Option>
                  {Object.entries(restaurants).map(([id, restaurant]) => (
                    <Option key={restaurant.id} value={restaurant.id}>
                      {restaurant.name}
                    </Option>
                  ))}
                </Select>
              </SelectRestaurantLabel>
            )}
          </ColumnDiv>
        </RegLogContent>
      </ContentWrapper>
    </RegLogBase>
  )
}

Unlicensed.propTypes = {
  goHome: PropTypes.func.isRequired,
  onUseOtherAccountClick: PropTypes.func.isRequired,
  selectedRestaurant: PropTypes.object.isRequired,
  getUserRestaurants: PropTypes.func.isRequired,
  getUserRestaurantsSucceeded: PropTypes.bool.isRequired,
  restaurants: PropTypes.object.isRequired,
  showBuyLicenseModal: PropTypes.func.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default Unlicensed
