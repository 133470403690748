import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {
  login,
  resetLoginState,
  resetRegLog,
  updateUserEmail
} from '../../../redux/modules/users'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import Login from '../components/Login'

const mapStateToProps = ({
  config: {webEntryDomain},
  users: {
    isAuthenticated,
    loginEmailSend,
    loggingIn,
    user,
    userDoesNotExistWarning
  }
}) => ({
  email: user.email,
  isOpen: !isAuthenticated,
  loggingIn,
  loginEmailSend,
  user,
  userDoesNotExistWarning,
  webEntryDomain
})

const mapDispatchToProps = {
  login,
  resetLoginState,
  resetRegLog,
  updateUserEmail
}

const LoginContainer = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onChange: ({updateUserEmail}) => ({target: {value}}) => {
      updateUserEmail(value)
    },
    login: ({login, t, user, webEntryDomain}) => () => {
      login({
        buttonText: t('email.login.buttonText'),
        closing1: t('email.logReg.closing1'),
        closing2: t('email.logReg.closing2'),
        greeting: t('email.login.greeting'),
        linkInfoText: t('email.logReg.linkInfoText'),
        paragraph1: t('email.login.paragraph1'),
        paragraph2: t('email.login.paragraph2'),
        paragraph3:
          webEntryDomain === 'klimateller' ? '' : t('email.login.paragraph3'),
        subject: t('email.login.subject'),
        user,
        webEntryDomain
      })
    },
    goToRegister: ({history: {push}, resetLoginState}) => () => {
      resetLoginState()
      push('/register')
    },
    resetLogin: ({history: {push}, resetRegLog}) => () => {
      resetRegLog()
      push('/login')
    }
  })
)(Login)

export default LoginContainer
