import {push} from 'connected-react-router'
import {flatten, partial, reverse} from 'ramda'
import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {filter, map, mergeMap, switchMap} from 'rxjs/operators'
import {putMenu, updateMenuOptimistic} from '../../../redux/modules/menus'
import {
  hideSearchModal,
  openSelectPreviewCollectionModal,
  setModalType,
  showConfirmModal
} from '../../../redux/modules/modal'
import {
  addRecipeOptimistic,
  deleteRecipe,
  deleteRecipeOptimistic,
  postRecipe,
  putRecipe,
  updateRecipeOptimistic
} from '../../../redux/modules/recipes'
import {goToRoot} from '../../../redux/modules/viewPagerNavigator'
import {handleNetworkError} from '../../../redux/observables/pipes'
import {
  selectCompositeByIdFromUrl,
  selectRecipeIsPinned,
  selectRecipesOfSelectedBusiness,
  selectUserIsSharedRecipeAuthor
} from '../../../redux/selectors/selectors'
import {
  createCompositeUpdateActions,
  getCompositeCopy,
  getSubrecipes
} from '../../../utils/compositeUtils'
import {generateStandardRecipe} from '../../../utils/recipeUtils'
import {removeWhiteSpace} from '../../../utils/utils'
import {createOrUpdateComposite} from '../../dataGrid/epics/epics'
import {
  flatCopyComposite,
  generateDeepCopyData
} from '../../dataGrid/utils/utils'

export const processServingsChange = createAction(
  'core/epics/PROCESS_SERVINGS_CHANGE'
)
export const processPlannedPortionsChange = createAction(
  'core/epics/PROCESS_PORTIONS_CHANGE'
)

export const processDeleteRecipeClick = createAction(
  'core/epics/PROCESS_DELETE_RECIPE_CLICK'
)

export const processNavIconPlusClick = createAction(
  'core/epics/PROCESS_NAV_ICON_PLUS_CLICK'
)

export const processImageChanged = createAction(
  'core/epics/PROCESS_IMAGE_CHANGED'
)

export const processShareRecipeClick = createAction(
  'core/epics/PROCESS_SHARE_RECIPE_CLICK'
)

export const processMakePreviewRecipeClick = createAction(
  'core/epics/PROCESS_MAKE_PREVIEW_RECIPE_CLICK'
)

export const deleteImage = createAction('core/epics/DELETE_IMAGE_REQUESTED')
export const deleteImageSucceeded = createAction('core/epics/SUCCEEDED')

export const removeImage = createAction('core/epics/REMOVE_IMAGE')

export const processServingsChangeEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processServingsChange().type),
    switchMap(({payload: {composite, type, value}}) => {
      const newValueMap = {
        decrease: composite.servings - 1,
        increase: composite.servings + 1,
        // TODO: add proper input validation!!
        update: isNaN(Number(value)) ? composite.servings : Number(value)
      }

      const newValue = newValueMap[type] >= 1 ? newValueMap[type] : 1
      const updatedComposite = {
        ...composite,
        servings: newValue
      }

      const optimisticUpdateFns = {
        menu: partial(updateMenuOptimistic, [{menu: updatedComposite}]),
        recipe: partial(updateRecipeOptimistic, [{recipe: updatedComposite}])
      }

      const putFnMap = {
        menu: partial(putMenu, [{menu: updatedComposite}]),
        recipe: partial(putRecipe, [{recipe: updatedComposite}])
      }

      const updateOptimistic = optimisticUpdateFns[composite.type]
      const putComposite = putFnMap[composite.type]

      return of(updateOptimistic(), putComposite())
    })
  )

export const processPlannedPortionsChangeEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processPlannedPortionsChange().type),
    switchMap(({payload: {composite, type, value}}) => {
      const newValueMap = {
        decrease: composite.plannedPortions - 1,
        increase: composite.plannedPortions + 1,
        // TODO: add proper input validation!!
        update: isNaN(Number(value)) ? composite.plannePortions : Number(value)
      }

      const newValue = newValueMap[type] >= 1 ? newValueMap[type] : 1
      const updatedComposite = {
        ...composite,
        plannedPortions: newValue
      }

      const optimisticUpdateFns = {
        menu: partial(updateMenuOptimistic, [{menu: updatedComposite}]),
        recipe: partial(updateRecipeOptimistic, [{recipe: updatedComposite}])
      }

      const putFnMap = {
        menu: partial(putMenu, [{menu: updatedComposite}]),
        recipe: partial(putRecipe, [{recipe: updatedComposite}])
      }

      const updateOptimistic = optimisticUpdateFns[composite.type]
      const putComposite = putFnMap[composite.type]

      return of(updateOptimistic(), putComposite())
    })
  )

const processDeleteRecipeClickEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processDeleteRecipeClick().type),
    mergeMap(() => of(setModalType('compositeDelete'), showConfirmModal()))
  )

const processNavIconPlusClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processNavIconPlusClick().type),
    mergeMap(({payload: {newRecipeTitle, recipeCollectionId, t}}) => {
      const {
        recipes: {recipes},
        users: {
          user: {id: authorId}
        }
      } = state$.value

      const newRecipe = generateStandardRecipe({
        authorId,
        permanent: true,
        title: newRecipeTitle,
        recipeCollectionId,
        t
      })

      const [identicalPermanentRecipe] = Object.values(recipes).filter(
        r =>
          r.title === newRecipeTitle &&
          r.permanent &&
          r.components.every(c => c.component.id === '0')
      )

      const identicalPermanentRecipeExists =
        identicalPermanentRecipe !== undefined

      let actions = []
      if (identicalPermanentRecipeExists) {
        actions = [push(`/recipes?recipeId=${identicalPermanentRecipe.id}`)]
      } else {
        actions = [
          addRecipeOptimistic({recipe: newRecipe}),
          postRecipe(newRecipe),
          push(`recipes?recipeId=${newRecipe.id}`)
        ]
      }

      return of(...actions, hideSearchModal())
    })
  )

const processImageChangedEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processImageChanged().type),
    mergeMap(({payload: {composite, file}}) => {
      const filenameWhithouWhiteSpace = removeWhiteSpace(file.name)
      const compositeWithNewImageUrl = {
        ...composite,
        imageUrl: `https://ucarecdn.com/${file.uuid}/${filenameWhithouWhiteSpace}`
      }
      const actions = createCompositeUpdateActions(compositeWithNewImageUrl)

      return of(...actions, goToRoot())
    })
  )

const deleteImageEpic = (action$, _, {api}) =>
  action$.pipe(
    ofType(deleteImage().type),
    mergeMap(({payload: uuid}) =>
      api.deleteImage(uuid).pipe(
        map(({response}) => deleteImageSucceeded(response)),
        handleNetworkError
      )
    )
  )

const removeImageEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(removeImage().type),
    mergeMap(({payload: composite}) => {
      const compositeWithOutImageUrl = {
        ...composite,
        imageUrl: ''
      }
      const imageUuid = composite.imageUrl.split('/')[3]
      const actions = createCompositeUpdateActions(compositeWithOutImageUrl)

      return of(...actions, deleteImage(imageUuid), goToRoot())
    })
  )

const processShareRecipeClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processShareRecipeClick().type),
    mergeMap(() => {
      const {
        businesses: {selectedBusiness},
        recipes: {recipes}
      } = state$.value
      const recipe = selectCompositeByIdFromUrl(state$.value)
      const recipeIsPinned = selectRecipeIsPinned(state$.value)

      const hasSubRecipes =
        recipe.components.filter(({component}) => component.type === 'recipe')
          .length > 0

      let actions = []
      let _recipeCopy
      if (recipeIsPinned) {
        // get all subrecipes and delete them
        const recipesOfSelectedBusiness = selectRecipesOfSelectedBusiness(
          state$.value
        )
        const subrecipes = getSubrecipes(recipes, recipe)

        const recipesToDelete = [recipe, ...subrecipes].map(r => {
          return getCompositeCopy(recipesOfSelectedBusiness, r)
        })

        actions = [
          ...flatten(
            recipesToDelete.map(recipe => {
              return [deleteRecipeOptimistic({recipe}), deleteRecipe({recipe})]
            })
          )
        ]
      } else {
        if (hasSubRecipes) {
          const deepCopyData = generateDeepCopyData({
            id: recipe.id,
            recipes,
            depth: 0
          })
          const {recipe: deepCopiedRecipe, recipePostList} = deepCopyData
          _recipeCopy = deepCopiedRecipe
          const reversedRecipePostList = reverse(recipePostList)

          actions = [
            ...flatten(
              reversedRecipePostList.map(_recipe => {
                const recipe = {
                  ..._recipe,
                  recipeCollectionId: selectedBusiness.recipeCollectionId
                }
                return [updateRecipeOptimistic({recipe}), postRecipe(recipe)]
              })
            )
          ]
        } else {
          _recipeCopy = {
            ...flatCopyComposite(recipe)
          }

          // add the recipe to the users private recipeCollection
          const recipeCopy = {
            ..._recipeCopy,
            generated: true,
            recipeCollectionId: selectedBusiness.recipeCollectionId
          }

          actions = [
            ...actions,
            createOrUpdateComposite({composite: recipeCopy})
          ]
        }
      }

      return of(...actions)
    })
  )

const processMakePreviewRecipeClickEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processMakePreviewRecipeClick().type),
    filter(({payload: recipe}) => {
      const hasSubRecipes =
        recipe.components.filter(({component}) => component.type === 'recipe')
          .length > 0

      if (hasSubRecipes) {
        console.warn(
          'recipe has subrecipes and cannot be turned into a preview recipe'
        )
      }

      return !hasSubRecipes
    }),
    map(() => openSelectPreviewCollectionModal())
  )

export const coreEpics = combineEpics(
  deleteImageEpic,
  processDeleteRecipeClickEpic,
  processImageChangedEpic,
  processMakePreviewRecipeClickEpic,
  processNavIconPlusClickEpic,
  processPlannedPortionsChangeEpic,
  processServingsChangeEpic,
  processShareRecipeClickEpic,
  removeImageEpic
)
