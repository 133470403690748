import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {coreReducers} from 'eaternity-core/build/redux/coreReducers'
import config from './modules/config'

export default history =>
  combineReducers({
    router: connectRouter(history),
    ...coreReducers,
    config
  })
