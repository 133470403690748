import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {replace} from 'connected-react-router'
import {withTranslation} from 'react-i18next'

import {addMenu, setEditedMenuId} from '../../../redux/modules/menus'
import {selectMenusOfDay} from '../../../redux/selectors/selectors'
import {
  setDayPlanningPopOverContentType,
  setMenuLineId
} from '../../../redux/modules/view'
import DayEdit from '../components/DayEdit'

const mapStateToPros = (state, {t}) => ({
  menusOfDay: selectMenusOfDay(state, t),
  menuCollectionId: state.restaurants.selectedRestaurant.menuCollectionId
})

const mapDispatchToProps = {
  addMenu,
  replace,
  setDayPlanningPopOverContentType,
  setEditedMenuId,
  setMenuLineId
}

export default compose(
  withTranslation(),
  connect(mapStateToPros, mapDispatchToProps),
  withHandlers({
    onMenuFocus: ({
      addMenu,
      menuCollectionId,
      replace,
      setEditedMenuId,
      setMenuLineId
    }) => menu => {
      if (menu.generated) {
        addMenu({...menu, menuCollectionId})
      }
      setEditedMenuId(menu.id)
      setMenuLineId(menu.menuLineId)
      replace(`/dayplanning?menuId=${menu.id}`)
    }
  })
)(DayEdit)
