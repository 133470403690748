import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {useWindowSize} from '../../../hooks/useWindowSize'

const PlaceHolderBase = styled.div`
  @media print {
    display: none;
  }
`

const PlaceHolderBar = styled.div`
  box-sizing: border-box;
  height: ${p => p.theme.sizes.spacerBarHeight}px;
  background-color: ${p => p.theme.colors.background};
  border-bottom: ${p => !p.admin && p.theme.border.default};
`

const PlaceHolderMain = styled.div`
  box-sizing: border-box;
  height: ${p => p.height}px;
  background-color: ${p => p.theme.colors.white};
`

const PlaceHolderFooter = styled.div`
  box-sizing: border-box;
  background-color: ${p => p.theme.colors.darkGray};
  height: ${p => p.theme.sizes.footerHeight}px;
`

const PlaceHolder = ({admin = false}) => {
  const {innerHeight} = useWindowSize()

  return (
    <PlaceHolderBase>
      <PlaceHolderMain height={innerHeight}>
        {!admin && <PlaceHolderBar />}
      </PlaceHolderMain>
      <PlaceHolderFooter />
    </PlaceHolderBase>
  )
}

PlaceHolder.propTypes = {
  admin: PropTypes.bool
}

export default PlaceHolder
