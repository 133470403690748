import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import CellInput from '../../dataGrid/components/CellInput'
import ComponentRowPopOverArrow from '../../dataGrid/components/ComponentRowPopOverArrow'
import DataGrid from '../../dataGrid/components/DataGrid'

const RecipeOrProductGridBase = styled.div`
  margin-top: -${p => p.theme.border.width.default}px;
`

const ComponentInputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

const RecipeOrProductHeaderCellInput = styled(CellInput)`
  height: 58px;
  text-align: center;
  font-weight: ${p => p.theme.fonts.weight.bold};
`

const RecipeOrProductCellInput = styled(CellInput)`
  padding-left: 10px;
`

const RecipeOrProductGrid = ({
  recipeOrProduct,
  onComponentChange,
  onComponentTitleBlurOrKeyDown,
  onComponentFocus
}) => {
  const [t] = useTranslation()

  if (!recipeOrProduct) {
    return null
  }

  return (
    <RecipeOrProductGridBase>
      <DataGrid
        renderBodyCellLeft={() => null}
        renderBodyCellRight={() => (
          <ComponentInputWrapper id="RecipeOrProductComponentInputWrapper">
            <RecipeOrProductCellInput
              autoFocus
              placeholder={t('recipeOrProductGrid.placeholder')}
              onBlur={onComponentTitleBlurOrKeyDown}
              onChange={onComponentChange}
              onFocus={onComponentFocus}
              onKeyDown={onComponentTitleBlurOrKeyDown}
            />
            <ComponentRowPopOverArrow show />
          </ComponentInputWrapper>
        )}
        composite={recipeOrProduct}
        renderHeaderCellLeft={() => null}
        renderHeaderCellRight={() => (
          <RecipeOrProductHeaderCellInput
            autoFocus={false}
            disabled
            value={recipeOrProduct.title}
          />
        )}
      />
    </RecipeOrProductGridBase>
  )
}

RecipeOrProductGrid.propTypes = {
  recipeOrProduct: PropTypes.object,
  onComponentTitleBlurOrKeyDown: PropTypes.func.isRequired,
  onComponentChange: PropTypes.func.isRequired,
  onComponentFocus: PropTypes.func.isRequired
}

export default RecipeOrProductGrid
